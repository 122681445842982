import React from 'react';
import styled from 'styled-components';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

const Header = styled.h1`
  height: 36px;
  object-fit: contain;
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  padding-left: 8px;
  padding-right: 8px;
  background-color: ${COLORS.white};
  color: ${COLORS.blackTwo};

  margin-bottom: 32px;
  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    margin-bottom: 0px;
  }
`;
const SubHeader = styled.h2`
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${COLORS.blackTwo};
  margin-top: 8px;
`;

function LoginHeader({ isLogin = false }) {
  return (
    <React.Fragment>
      <Header>{COPY.headerText}</Header>
      <SubHeader>
        {isLogin ? COPY.subHeaderLogin : COPY.subHeaderSignup}
      </SubHeader>
    </React.Fragment>
  );
}

const COPY = {
  headerText: 'Your results are ready. 🌿',
  subHeaderLogin: `Login to your Shine Account to see them.`,
  subHeaderSignup: `Create a Shine Account to see them.`,
};

export default LoginHeader;
