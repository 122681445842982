import styled from 'styled-components';
import COLORS from '../colors';
import SIZES from '../screenSizes';
import FONTS from '../fonts';

// Images
import { logo, fbIconWhite, appleIconWhite } from '../image.config';
import spinner from '../../assets/images/spinner.gif';

// Template
const Button = styled.button`
  border: none;
  border-radius: 8px;
  cursor: pointer;
  height: 58px;
  width: 100%;
  outline: none;
`;

export const BlueButton = styled(Button)`
  align-items: center;
  background-color: ${COLORS.fbBlue};
  position: relative;
`;

export const BlackButton = styled(Button)`
  align-items: center;
  background-color: ${COLORS.black};
  position: relative;
`;

export const FormSubmitButton = styled(Button)`
  background-color: ${COLORS.marigold};
`;
export const GreyButton = styled(Button)`
  background-color: ${COLORS.lightGrey};
`;

export const ButtonText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: ${COLORS.white};

  @media (max-width: ${SIZES.phone}) {
    font-size: 15px;
  }
`;

export const DisabledButtonText = styled(ButtonText)`
  color: ${COLORS.blackTwo};
`;

export const FbIcon = styled.img.attrs({ src: fbIconWhite })`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 19px;
  left: 24px;

  @media (max-width: ${SIZES.iphone5sWidth}) {
    left: 10px;
  }
`;

export const AppleIcon = styled.img.attrs({ src: appleIconWhite })`
  width: 58px;
  height: 58px;
  position: absolute;
  top: 0;
  left: 0;

  @media (max-width: ${SIZES.iphone5sWidth}) {
    left: -5px;
  }
`;

export const Logo = styled.img.attrs({ src: logo })`
  align-self: center;
  width: auto;
  height: 90px;
  margin: 30px 0;
  @media (max-width: ${SIZES.phone}) {
    width: 64px;
    height: 64px;
  }
`;

export const LogoLink = styled.a`
  width: auto;
  display: flex;
  align-self: center;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: ${SIZES.phone}) {
    height: 64px;
    width: 64px;
    margin: 24px;
  }
`;

export const Spinner = styled.img.attrs({ src: spinner })`
  margin-top: 20px;
  margin-bottom: 15px;
  width: auto;
  height: auto;
  @media (max-width: 700px) {
    margin-top: 10px;
    margin-bottom: 0px;
    width: auto;
    height: auto;
  }
`;

export const YellowButton = styled.div`
  display: flex;
  width: 198px;
  height: 50px;
  background: ${COLORS.deepGoldenYellow};
  border-radius: 47px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.poppinsPrimaryRegular};
  color: ${COLORS.darkMagentaPink};
  z-index: 1;
  transition: background 0.5s;

  &:hover {
    background: ${COLORS.shineYellow};
    cursor: pointer;
  }
`;
