import styled from 'styled-components';
import { Link } from 'react-router-dom';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import FONTS from '../../constants/fonts';

//Form Container
export const FormContainer = styled.div`
  align-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 100%;
  }
`;

// White Container in checkout flow
export const Card = styled.div`
  align-content: center;
  align-self: center;
  background-color: ${COLORS.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 90%;
  padding: 32px 20px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 600px
    padding: 40px 24px;
  }
`;

export const FormTitle = styled.p`
  height: 28px;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${COLORS.blackTwo};
  margin: 5px 0;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  height: 80%;
`;

export const FormInput = styled.input`
  height: 58px;
  width: 100%;
  padding-left: 20px;
  margin-bottom: 24px;

  border: 1px solid ${COLORS.softGrey};
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: none;
  line-height: 110%;

  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: ${COLORS.grey};
  text-align: left;

  @media only screen and (max-width: ${SIZES.phone}) {
    align-self: center;
    margin-bottom: 16px;
    font-size: 15px;
  }
`;

export const FormButtonContainer = styled.div`
  text-align: center;
  align-self: center;
  width: 100%;
  padding 15px 0;
`;
export const CardBottom = styled.div`
  text-align: center;
  align-self: center;
  width: 100%;
  padding 20px 0;
`;

export const FormInputPasswordContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* Show the tooltip text when you focus on the parent password container */
  &:focus-within .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .tooltiptext {
    width: 265px;
    background-color: ${COLORS.greyShadow75};
    color: ${COLORS.white};
    font-family: ${FONTS.workSansPrimaryRegular}
    font-size: 16px;
    text-align: left;
    border-radius: 6px;
    padding: 10px;

    /* Hide text box and fade in opacity on focus */
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;

    /* Tooltip box positioning */
    position: absolute;
    z-index: 1;
    bottom: 105%;
    right: 0;
    
    /* Tooltip arrow */
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px; /* center the arrow with the box */
      border-width: 5px;
      border-style: solid;
      border-color: ${COLORS.greyShadow75} transparent transparent transparent;
    }
  }
}
`;

export const ShowHidePwButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 50%;
  font-family: ${FONTS.workSansPrimaryRegular};
  font-weight: bold;
  padding-right: 2%;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    right: 5%;
    font-size: 13px;
    padding-right: 2%;
  }
`;

export const BodyText = styled.p`
  color: ${COLORS.blackTwo};
  height: 20px;
  font-family: ${FONTS.workSansPrimaryRegular};
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: 0.39px;
  margin: 5px;
`;

export const SmallText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: ${COLORS.grey};
  margin: 4px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 14px;
    line-height: 175%;
    text-align: center;
  }
`;

export const SmallLinkText = styled.a`
  color: ${COLORS.black};
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.02em;
  margin: 4px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 14px;
    line-height: 175%;
    text-align: center;
  }
`;

export const StyledLink = styled(Link)`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: ${COLORS.black};
  margin: 4px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 14px;
    line-height: 150%;
    text-align: center;
  }
`;

export const ErrorMessage = styled(SmallText)`
  color: ${COLORS.paleRed};
  font-size: 14px;
  font-weight: bold;
  width: 100%;

  @media only screen and (max-width: ${SIZES.phone}) {
    text-align: left;
    font-size: 10px;
    min-height: 50px;
    overflow-y: scroll;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border: solid 1px ${COLORS.darkMagentaPink};
  margin-bottom: 24px;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-bottom: 16px;
  }
`;

export const GiftCard = styled.img`
  width: 55%;
  object-fit: contain;
  align-self: center;
  border: 1px solid ${COLORS.grey};
  margin-bottom: 24px;
  border-radius: 8px;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 75%;
    margin-bottom: 16px;
  }
`;

export const SkipButtonWrapper = styled.div`
  align-self: flex-end;
  margin-top: 10px;
  text-decoration: none;
`;

export const SkipButton = styled.span`
  width: 70px;
  height: 23px;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-size: 18px;
  font-weight: 500;
  color: ${COLORS.activeYellow};

  @media (max-width: ${SIZES.phone}) {
    font-size: 16px;
`;
