import React from 'react';
import styled from 'styled-components';

import {
  YellowSectionContainer,
  Title,
  Label,
  FieldsOneColumn,
} from '../styles';

const StyledTitle = styled(Title)`
  padding: 0;
`;

const FreePlanView = () => (
  <>
    <YellowSectionContainer>
      <FieldsOneColumn>
        <StyledTitle>{COPY.freeTitle}</StyledTitle>
        <Label>{COPY.description}</Label>
      </FieldsOneColumn>
    </YellowSectionContainer>
  </>
);

const COPY = {
  freeTitle: 'You have a free Shine account',
  description: 'Make the most of the app by upgrading to Shine Premium.',
  perYear: ' per year',
  eyebrowText: 'Shine Premium',
  buttonCopy: 'Upgrade to Premium',
};

export default FreePlanView;
