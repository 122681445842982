import React from 'react';
import styled, { keyframes } from 'styled-components';
import Modal from 'react-responsive-modal';
import { Slide } from 'react-awesome-reveal';

// Constants
import { TRACKLIST } from '../../../constants/config';
import { sampleArrow } from '../../../constants/image.config';
import SIZES from '../../../constants/screenSizes';

// Components
import { Player } from '../../audio';
import TrackCard from './TrackCard';

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin-top: -150px;
  @media only screen and (max-width: ${SIZES.desktop}) {
    margin-top: -40px;
`;

const Bounce = keyframes`
  10%, 20%, 30%, 40%, 50% {transform: translateY(0);}
  15% {transform: translateY(-15px);}
  25% {transform: translateY(-8px);}
`;

const TrySomeSamplesArrow = styled.img.attrs({ src: sampleArrow })`
  align-self: flex-end;
  height: 150px;
  width: auto;
  margin-right: 20%;
  margin-bottom: -20px;

  animation: ${Bounce} 3.5s linear infinite;

  @media only screen and (max-width: ${SIZES.desktop}) {
    height: 100px;
    margin-right: 15%;
    margin-bottom: -10px;
`;

const ScrollContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 15px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface Props {}

interface State {
  showModal: boolean;
  trackInfo: TrackInfo;
}

interface TrackInfo {
  title: string;
  speaker: string;
  trackUrl: string;
  playerBackground: string | typeof import('*.jpg');
  theme: string;
}

class TrackList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      trackInfo: {
        title: '',
        speaker: '',
        trackUrl: '',
        playerBackground: '',
        theme: '',
      },
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleShowModal(trackInfo: TrackInfo) {
    this.setState({ showModal: true, trackInfo });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal, trackInfo } = this.state;
    return (
      <Container>
        <TrySomeSamplesArrow />
        <ScrollContainer>
          <Slide triggerOnce direction="up" cascade>
            {TRACKLIST.map((track) => {
              return (
                <TrackCard
                  key={track.title}
                  track={track}
                  openPlayer={this.handleShowModal.bind(this, track)}
                  className={track.title}
                />
              );
            })}
            <Modal
              open={showModal}
              onClose={this.handleCloseModal}
              closeIconSize={45}
              center
            >
              <Player trackInfo={trackInfo} />
            </Modal>
          </Slide>
        </ScrollContainer>
      </Container>
    );
  }
}

export default TrackList;
