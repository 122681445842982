import React from 'react';
import styled from 'styled-components';

// Constants
import {
  FOOTER_IOS_DOWNLOAD_LINK,
  FOOTER_ANDROID_DOWNLOAD_LINK,
} from '../../../constants/sharedCopy';
import SIZES from '../../../constants/screenSizes';

// Assets
import { iosBadge, androidBadge } from '../../../constants/image.config';

// Components
import { CategoryTitle } from './styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media only screen and (min-width: ${SIZES.tablet}) {
    justify-content: flex-start;
  }
`;

const Badge = styled.a<{ source: typeof import('*.png') }>`
  height: 46px;
  width: 49%;
  background-image: ${(props) => (props.source ? `url(${props.source})` : '')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 0px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    height: 56px;
    width: 182px;
    margin-right: 32px;
  }
`;

const DownloadShine = () => {
  return (
    <Container>
      <CategoryTitle>Download Shine</CategoryTitle>
      <BadgeContainer>
        <Badge
          href={FOOTER_IOS_DOWNLOAD_LINK}
          source={iosBadge}
          target="_blank"
          rel="noopener noreferrer"
        />
        <Badge
          href={FOOTER_ANDROID_DOWNLOAD_LINK}
          source={androidBadge}
          target="_blank"
          rel="noopener noreferrer"
        />
      </BadgeContainer>
    </Container>
  );
};

export default DownloadShine;
