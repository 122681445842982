import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';
import { testimonialBg, quote } from '../../../constants/image.config';
import { Section, Title, TwoColumnContainer } from '../B2BStyles';

const PurpleSection = styled(Section)`
  flex-direction: column;
  background: ${COLORS.eggplant};
  background-image: url(${testimonialBg});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 20%;
  padding: 50px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    padding: 25px;
  }
`;

const WhiteTitle = styled(Title)`
  color: ${COLORS.white};
`;

const StyledTwoColumnContainer = styled(TwoColumnContainer)`
  align-items: center;

  @media only screen and (min-width: ${SIZES.desktop}) {
    align-items: flex-start;
  }
`;

const TestimonialItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 70%;
  background-color: ${COLORS.white};
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 0;

  :nth-child(1) {
    margin-bottom: 30px;
  }

  @media only screen and (min-width: ${SIZES.desktop}) {
    width: 80%;
    padding: 50px;

    :nth-child(1) {
      margin-right: 30px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (min-width: ${SIZES.laptop}) {
    width: 430px;
  }
`;

const QuoteImg = styled.img`
  width: 30px;
  height: auto;
  align-self: flex-start;
  margin: 8px 15px 0px -5px;

  @media only screen and (min-width: ${SIZES.desktop}) {
    width: 60px;
  }
`;

const TestimonialText = styled.p`
  font-family: 'Poppins';
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${COLORS.darkMagentaPink};
  text-align: left;
  line-height: 160%;
  letter-spacing: 0.02em;

  @media only screen and (min-width: ${SIZES.desktop}) {
    font-size: 20px;
    margin: 0 0 20px 0;
  }
`;

const renderTestimonials = (testimonials: Array<string>) => {
  return testimonials.map((item: string) => (
    <TestimonialItem key={item}>
      <QuoteImg src={quote} />
      <TestimonialText>{item}</TestimonialText>
    </TestimonialItem>
  ));
};

const TestimonialsSection = () => (
  <PurpleSection>
    <WhiteTitle>{COPY.title}</WhiteTitle>
    <StyledTwoColumnContainer>
      {renderTestimonials(COPY.testimonials)}
    </StyledTwoColumnContainer>
  </PurpleSection>
);

const COPY = {
  title: 'What our Shine at Work Partners are Saying',
  quote: '“',
  testimonials: [
    "Our folks really love having access to the Shine app, and that it is flexible to meet every individual's needs and interests.",
    "It's such an inclusive & culturally responsive wellness app. The meditations are the perfect amount of time and are always super relevant.",
  ],
};

export default TestimonialsSection;
