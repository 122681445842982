import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { selectPaymentPlan } from '../../actions/billing';

// Constants
import { WARM, COLD, B2B } from '../../constants/sharedCopy';

// Components
import TemplateWarm from './TemplateWarm';
import TemplateCold from './TemplateCold';
import TemplateB2B from './TemplateB2B';
import { Container, Page } from '../../constants/styles';

/**
 * COMPONENT
 */
class TemplateHandler extends Component {
  constructor() {
    super();
    this.selectPlan = this.selectPlan.bind(this);
  }
  selectPlan = () => {
    const { isPromo, selectPaymentPlan, pageId } = this.props;
    if (isPromo && pageId) {
      // Lock users into promo plan
      selectPaymentPlan(pageId);
    }
  };

  renderTemplate = (props) => {
    const { templateType } = props;
    switch (templateType) {
      case COLD:
        return <TemplateCold {...props} buttonAction={this.selectPlan} />;
      case WARM:
        return <TemplateWarm {...props} buttonAction={this.selectPlan} />;
      case B2B:
        return <TemplateB2B {...props} buttonAction={this.selectPlan} />;
      default:
        break;
    }
  };

  componentDidMount() {
    // Once a user lands on a promo page, pre-select the promo plan
    this.selectPlan();
  }

  render() {
    return (
      <Page>
        <Container>{this.renderTemplate(this.props)}</Container>
      </Page>
    );
  }
}

/**
 * CONTAINER
 */
const mapState = (state) => {
  return {
    isPromo: state.promo.isPromo,
    pageId: state.promo.pageId,
    templateType: state.promo.templateType,
    heroImage: state.promo.heroImage,
    heroHeader: state.promo.heroHeader,
    heroCopy: state.promo.heroCopy,
    buttonCopy: state.promo.buttonCopy,
    isExpired: state.promo.isExpired,
    bgImage: state.promo.headerBackgroundImage,
    theme: state.promo.theme,
    limitedTimeText: state.promo.limitedTimeText,
    discountedPrice: state.promo.discountedPrice,
    originalPrice: state.promo.originalPrice,
    discountPercent: state.promo.discountPercent,
    discountFlatAmount: state.promo.discountFlatAmount,
    displayMonthlyPrice: state.promo.displayMonthlyPrice,
    enableCompassionQuiz: state.promo.enableCompassionQuiz,
    stepSectionEyeBrowTitle: state.promo.stepSectionEyeBrowTitle,
    stepSectionTitle: state.promo.stepSectionTitle,
    stepSectionImage: state.promo.stepSectionImage,
    programSteps: state.promo.programSteps,
    customLogo: state.promo.customLogo,
    maxSeatsRedeemed: state.promo.maxSeatsRedeemed,
    utmParams: state.utmParams,
    stripeCouponPercentOff: state.promo.stripeCouponPercentOff,
    email: state.user.userData.email,
  };
};

const mapDispatch = {
  selectPaymentPlan,
};

export default connect(mapState, mapDispatch)(TemplateHandler);

/**
 * PROP TYPES
 */
TemplateHandler.propTypes = {
  isPromo: PropTypes.bool.isRequired,
  pageId: PropTypes.string.isRequired,
  templateType: PropTypes.string.isRequired,
  heroImage: PropTypes.string.isRequired,
  heroHeader: PropTypes.string.isRequired,
  heroCopy: PropTypes.string.isRequired,
  buttonCopy: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  bgImage: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  originalPrice: PropTypes.number.isRequired,
  maxSeatsRedeemed: PropTypes.bool.isRequired,
  utmParams: PropTypes.object,
  stripeCouponPercentOff: PropTypes.number.isRequired,
};
