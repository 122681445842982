import React from 'react';
import { Page } from '../../constants/styles';

import {
  Header,
  PressSection,
  OurTeamSection,
  TeamValuesSection,
  DataSection,
  BlackLivesMatterSection,
  SupportForEmployeesSection,
  BottomCTA,
} from './index';
import Navbar from '../sharedPartials/Navbar';
import Footer from '../sharedPartials/Footer';

const AboutPage = () => {
  return (
    <Page>
      <Navbar />
      <Header />
      <PressSection />
      <OurTeamSection />
      <TeamValuesSection />
      <DataSection />
      <BlackLivesMatterSection />
      <SupportForEmployeesSection />
      <BottomCTA />
      <Footer />
    </Page>
  );
};

export default AboutPage;
