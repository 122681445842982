import * as TYPES from '../constants/actionTypes';

const googleTagManagerMiddleware = () => (next) => (action) => {
  if (
    action.type === TYPES.AUTH_LOGIN_SUCCESS ||
    action.type === TYPES.AUTH_SIGNUP_SUCCESS ||
    action.type === TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS ||
    action.type === TYPES.AUTH_APPLE_LOGIN_SUCCESS
  ) {
    const {
      user: { uid, email },
    } = action;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      customerUserId: uid,
      userEmail: email,
      event: 'email_capture',
    });
  }
  return next(action);
};

export default googleTagManagerMiddleware;
