import styled from 'styled-components';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import SIZES from '../../constants/screenSizes';
import { Link as RouterLink } from 'react-router-dom';

export const BoldTitle = styled.h1`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  font-size: 38px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${COLORS.blackTwo};

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 28px;
  }
`;

export const DefaultText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 190%;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${COLORS.blackTwo};
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  align-self: center;
  cursor: pointer;
`;
