import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import debounce from 'lodash.debounce';
import { CardContainer } from './BillingStyles';
import { FormSubmitButton, ButtonText } from '../../constants/styles';
import { ErrorMessage } from '../form/FormStyles';
import { ANNUAL } from '../../constants/sharedCopy';

class CardInfo extends Component {
  constructor(props) {
    super(props);
    const amount = Number((props.selectedPlan.cost * 100).toFixed());
    // For full documentation of the available paymentRequest options, see:
    // https://stripe.com/docs/stripe.js#the-payment-request-object
    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Shine Premium',
        amount: amount,
      },
    });

    paymentRequest.on('token', ({ complete, token, ...data }) => {
      const {
        card: { last4: lastFour, brand, id: cardId },
        id: tokenId,
      } = token;
      this.setState(
        { lastFour, brand, cardId, tokenId, validCard: true },
        () => {
          // Add price to data layer
          window.dataLayer.push({
            price: parseFloat(props.selectedPlan.cost).toFixed(2),
            event: 'purchase',
          });
          props.onConfirmSubscription(this.state);
          complete('success');
        }
      );
    });

    this.state = {
      lastFour: '',
      brand: '',
      validCard: false,
      cardId: '',
      tokenId: '',
      errorMessage: props.captchaError
        ? 'Your internet connection has been flagged as insecure. Please try again later.'
        : '',
      paymentRequest,
    };

    this.submit = this.submit.bind(this);
  }

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  async submit(ev) {
    ev.preventDefault();
    const {
      email,
      isLoading,
      selectedPlan: { cost },
    } = this.props;

    // Ignore subsequent clicks while we're processing
    if (isLoading) {
      return;
    }

    let { token, error } = await this.props.stripe.createToken({ email });
    if (error) {
      window.alert(error.message);
      this.handleChange({ error });
      return;
    }

    const {
      card: { last4: lastFour, brand, id: cardId },
      id: tokenId,
    } = token;
    const validCard = tokenId && cardId;
    this.setState({ lastFour, brand, cardId, tokenId, validCard }, () => {
      // Add price to data layer
      window.dataLayer.push({
        price: parseFloat(cost).toFixed(2),
        event: 'purchase',
      });
      this.props.onConfirmSubscription(this.state);
    });
  }

  renderButtonCopy = () => {
    const { isLoading, selectedPlan } = this.props;
    const defaultCopy = 'Submit payment';
    const trialCopy = 'Start free trial';
    // If discount is NOT promo and plan is annual, use free trial copy
    // ie Free Trial is only available to annual non-promo subs
    const isTrial =
      selectedPlan.billingCycle === ANNUAL && !selectedPlan.couponId;

    let renderCopy = defaultCopy;
    if (isLoading) {
      renderCopy = 'Loading';
    } else if (isTrial) {
      renderCopy = trialCopy;
    }

    return <ButtonText>{renderCopy}</ButtonText>;
  };

  renderPaymentForm = () => {
    return (
      <React.Fragment>
        <CardContainer>
          <CardElement onChange={this.handleChange} />
          {this.renderErrorMsg()}
          <FormSubmitButton
            href="#"
            onClick={debounce(this.submit, 1000, {
              leading: true,
              trailing: false,
            })}
            style={{ margin: '24px 0 12px 0' }}
          >
            {this.renderButtonCopy()}
          </FormSubmitButton>
        </CardContainer>
      </React.Fragment>
    );
  };

  renderErrorMsg = () => {
    // Update error message?
    if (this.state.errorMessage) {
      return <ErrorMessage>{this.state.errorMessage}</ErrorMessage>;
    }
    return null;
  };

  render() {
    return <React.Fragment>{this.renderPaymentForm()}</React.Fragment>;
  }
}
export default injectStripe(CardInfo);
