import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import { extendSubscription } from '../../actions/billing';

// Constants
import { surveyBannerP2 } from '../../constants/image.config';
import { RETENTION_SURVEY_COUPON_ID } from '../../constants/config';
import { SurveyConfig } from '../../types/retention-survey';

// Components
import {
  SurveyContainerNoPadding,
  BannerContainer,
  BannerImage,
  GoldBadgeWrapper,
  GoldBadgeImage,
  GoldBadgeText,
  SubBannerContainerWithBadge,
  SurveyTitle,
  SurveyText,
  SurveyYellowButton,
  SurveyYellowBorderButton,
  ButtonsWrapperVertical,
} from './styles';

class SurveyPageP2 extends Component {
  onConfirmResubscription = async (params) => {
    const { subscriptionId, planId, couponId } = params;
    const { extendSubscription } = this.props;

    try {
      await extendSubscription({
        subscriptionId,
        planId,
        couponId,
      });
    } catch (err) {
      console.error(err);
      window.alert(
        'Error encountered on resubscription. Please reach out to help@headspace.com for assistance.'
      );
    }
  };

  render() {
    const { subscriptionId } = this.props;
    const planId = process.env.REACT_APP_STRIPE_ANNUAL_PLAN_ID;
    const couponId = RETENTION_SURVEY_COUPON_ID;

    return (
      <SurveyContainerNoPadding>
        <BannerContainer>
          <BannerImage src={surveyBannerP2} />
          <GoldBadgeWrapper>
            <GoldBadgeImage />
            <GoldBadgeText>{COPY.discount}</GoldBadgeText>
          </GoldBadgeWrapper>
        </BannerContainer>
        <SubBannerContainerWithBadge>
          <SurveyTitle>{COPY.title}</SurveyTitle>
          <SurveyText dangerouslySetInnerHTML={{ __html: COPY.description }} />
          <ButtonsWrapperVertical>
            <SurveyYellowButton
              onClick={() =>
                this.onConfirmResubscription({
                  subscriptionId,
                  planId,
                  couponId,
                })
              }
            >
              {COPY.buttonKeep}
            </SurveyYellowButton>
            <SurveyYellowBorderButton
              onClick={() =>
                this.props.onCancel(SurveyConfig.CANCELLED_GIVE_FEEDBACK)
              }
            >
              {COPY.buttonCancel}
            </SurveyYellowBorderButton>
          </ButtonsWrapperVertical>
        </SubBannerContainerWithBadge>
      </SurveyContainerNoPadding>
    );
  }
}

const COPY = {
  title: 'Continue your Shine Premium membership at a discounted rate.',
  discount: '50%',
  description:
    'Get 50% off your next year of Shine Premium and continue taking care of your mental health with Shine for just $32.49.<br><br><strong>What would you like to do with your membership?</strong>',
  buttonKeep: 'Get 50% off my next year',
  buttonCancel: 'Cancel my membership',
};

const mapState = ({ user }) => {
  return {
    subscriptionId: user.subscriptionData.subscription.subscription_id,
  };
};

const mapDispatch = {
  extendSubscription,
};

export default connect(mapState, mapDispatch)(SurveyPageP2);
