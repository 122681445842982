import React from 'react';
import {
  SidebarWrapper,
  SidebarNavContainer,
  SidebarNavItem,
  SidebarIcon,
} from '../styles';

// Custom SVGs
import { ReactComponent as AccountIcon } from '../../../assets/images/portal/account.svg';
import { ReactComponent as SubscriptionIcon } from '../../../assets/images/portal/subscription.svg';
import { ReactComponent as EventsIcon } from '../../../assets/images/portal/events.svg';

class SidebarNav extends React.Component {
  // This is a bit wordy but is one approach to importing only a single SVG icon and changing the fill color in CSS.
  showSidebarIcon(copy) {
    if (copy === 'My Account') {
      return <AccountIcon />;
    }
    if (copy === 'Subscription') {
      return <SubscriptionIcon />;
    }
    if (copy === '2022 Self-Care Themes') {
      return <EventsIcon />;
    }
    if (copy === 'Premium Events') {
      return <EventsIcon />;
    }
  }

  render() {
    return (
      <SidebarWrapper>
        <SidebarNavContainer>
          {NAV_LINKS.map((item) => (
            <SidebarNavItem
              key={item.copy}
              to={item.link}
              activeClassName="active"
              target={item.target}
            >
              <SidebarIcon>{this.showSidebarIcon(item.copy)}</SidebarIcon>
              {item.copy}
            </SidebarNavItem>
          ))}
        </SidebarNavContainer>
      </SidebarWrapper>
    );
  }
}

const NAV_LINKS = [
  {
    link: '/account',
    copy: 'My Account',
  },
  {
    link: '/subscription',
    copy: 'Subscription',
  },
  {
    link: '/2022-monthly-self-care-themes.pdf',
    copy: '2022 Self-Care Themes',
    target: '_blank',
  },
  {
    link: '/events',
    copy: 'Premium Events',
  },
];
export default SidebarNav;
