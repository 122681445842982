import React from 'react';
import styled from 'styled-components';

import { DownloadShine, Footnotes, Navigation, Social } from './footer/index';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';

const Section = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 1009px;
  justify-content: flex-start;
  margin-top: 24px;
  background-color: ${COLORS.white};
  @media only screen and (min-width: ${SIZES.tablet}) {
    flex-direction: column;
    min-height: 600px;
    justify-content: space-evenly;
    margin-top: 0;
  }
  @media print {
    display: none;
  }
`;

const Wrapper = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${SIZES.tablet}) {
    flex-direction: row;
  }
`;

const VerticalLeftContainer = styled.div`
  display: flex;
  flex-grow: 0.5;
  flex-direction: column;
`;
const VerticalRightContainer = styled.div`
  display: flex;
  flex-grow: 0.5;
  flex-direction: column;
`;

const Line = styled.hr`
  border: 1px solid ${COLORS.faintLavender};
  width: 85%;
`;

const Footer = () => {
  return (
    <Section>
      <Wrapper>
        <VerticalLeftContainer>
          <Social />
        </VerticalLeftContainer>
        <VerticalRightContainer>
          <Navigation />
          <DownloadShine />
        </VerticalRightContainer>
      </Wrapper>
      <Line />
      <Footnotes />
    </Section>
  );
};

export default Footer;
