import styled from 'styled-components';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import FONTS from '../../constants/fonts';
import { portalIcons, goldBadge } from '../../constants/image.config';

export const SurveyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 25px auto;
  z-index: 1;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: auto;
    margin: 25px 20px;
  }
`;

export const BackButton = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${COLORS.darkBeige};
  margin: 16px 0px;
  &:hover {
    cursor: pointer;
    color: ${COLORS.darkMagentaPink};
  }
`;

export const SurveyContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.paleGoldenShadow};
  border-radius: 12px;
  width: 736px;
  margin: 0;
  padding: 45px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 100%;
    padding: 20px;
    margin-bottom: 25px;
  }
`;

export const SurveyContainerNoPadding = styled(SurveyContainer)`
  padding: 0;
  width: 100%;
`;

export const SurveyIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

export const SurveyIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${COLORS.paleGoldenShadow};

  :first-of-type {
      border-top: 1px solid ${COLORS.paleGoldenShadow};

  @media only screen and (max-width:${SIZES.desktop}){
    justify-content: flex-start;
  }
`;

export const SurveyIcons = styled.div`
  width: 24px;
  height: 24px;
  margin: 16px;
  background-image: url(${portalIcons});
  background-repeat: no-repeat;

  @media only screen and (max-width:${SIZES.desktop}){
  margin: 10px;
`;

export const ThumbsUpIcon = styled(SurveyIcons)`
  background-position: 0px -48px;
`;

export const MeditationIcon = styled(SurveyIcons)`
  background-position: -24px -48px;
`;

export const StarBadgeIcon = styled(SurveyIcons)`
  background-position: -48px -48px;
`;

export const HoldingHeartIcon = styled(SurveyIcons)`
  background-position: -72px -48px;
`;

export const SurveyTitle = styled.h1`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: ${COLORS.darkMagentaPink};
  margin: 0 0 20px 0;

  @media only screen and (max-width: ${SIZES.desktop}) {
    font-size: 20px;
  }
`;

export const SurveyText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${COLORS.darkMagentaPink};
  margin: 8px 0px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    font-size: 14px;
  }
`;

export const SurveyBulletText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${COLORS.darkMagentaPink};
  width: 90%;
  margin: 12px 0px;
  text-align: left;

  @media only screen and (max-width: ${SIZES.desktop}) {
    font-size: 12px;
    width: 85%;
  }
`;

export const SurveyReasonText = styled(SurveyBulletText)`
  width: 100%;
`;

export const SurveyYellowButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 45px;
  width: fit-content;
  border-radius: 47px;
  padding: 0 20px;
  background-color: ${COLORS.activeYellow};

  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${COLORS.white};

  &:hover {
    cursor: pointer;
    background: ${COLORS.shineYellow};
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 100%;
    padding: 0;
  }
`;

export const SurveyYellowBorderButton = styled(SurveyYellowButton)`
  height: 41px;
  color: ${COLORS.activeYellow};
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.activeYellow};
  margin-left: 0;

  &:hover {
    color: ${COLORS.white};
    background: ${COLORS.shineYellow};
    border: 2px solid ${COLORS.shineYellow};
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 100%;
    margin-left: 0px;
    padding: 0;
  }
`;

export const CancelReasonButton = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 54px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.paleGoldenShadow};
  border-radius: 8px;
  padding-left: 16px;
  margin-bottom: 10px;
  cursor: pointer;

  :hover {
    background-color: ${COLORS.paleGoldenShadow};
  }

  :active {
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.paleGoldenShadow};
  }
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: auto;
`;

export const GoldBadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 162px;
  height: auto;
  margin-top: -12%;
  margin-right: 6%;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-right: 0;
  }
`;

export const GoldBadgeImage = styled.img.attrs({ src: goldBadge })`
  @media only screen and (max-width: ${SIZES.phone}) {
    width: 55%;
  }
`;

export const GoldBadgeText = styled.p`
  position: absolute;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: ${COLORS.white};

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 14px;
  }
`;

export const SubBannerContainer = styled.div`
  display: flex;
  width: 520px;
  height: auto;
  flex-direction: column;
  padding: 45px;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: auto;
    padding: 20px;
  }
`;

export const SubBannerContainerWithBadge = styled(SubBannerContainer)`
  margin-top: -12%;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-top: -6%;
  }
`;

export const ButtonsWrapperHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;

  @media only screen and (max-width: ${SIZES.phone}) {
    align-items: flex-start;
    width: 100%;
`;

export const ButtonsWrapperVertical = styled(ButtonsWrapperHorizontal)`
  flex-direction: column;
  align-items: flex-start;
`;

export const TextArea = styled.textarea`
  width: auto;
  height: 128px;
  resize: none;
  border: 1px solid ${COLORS.paleGoldenShadow};
  border-radius: 4px;
  margin: 20px 0;
  padding: 20px;

  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${COLORS.darkMagentaPink};
`;
