import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MainContent } from '../styles';
import FreePlanView from './FreePlanView';
import PremiumPlanView from './PremiumPlanView';
import { PageLoading } from '../../sharedPartials';
import {
  MOBILE_APP_SUB_STATES,
  PAYMENT_PLATFORM,
  STRIPE_SUB_STATE,
} from '../../../constants/config';
import { findCustomerSubscriptions } from '../../../utils/stripeUtils';

class SubscriptionBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchingStripeStatus: false,
      stripeStatus: undefined,
      freeTrialStatus: false,
    };
  }

  async componentDidMount() {
    const { customerId, paymentPlatform, subscriptionStatus } = this.props;

    // Save free trial status to show in Subscription Details Section
    if (subscriptionStatus === MOBILE_APP_SUB_STATES.TRIAL) {
      this.setState({
        freeTrialStatus: true,
        stripeStatus: STRIPE_SUB_STATE.TRIALING,
      });
    }

    // Get a customer's Stripe status (active, unpaid, past due) to determine what shows in Subscription Details Section
    else if (paymentPlatform === 'stripe') {
      this.setState({ fetchingStripeStatus: true });

      let stripeStatus;
      const subscriptions = await findCustomerSubscriptions(customerId);
      if (subscriptions.length > 0) {
        stripeStatus = subscriptions[0].status;
      } else {
        stripeStatus = STRIPE_SUB_STATE.CANCELED;
      }

      this.setState({ fetchingStripeStatus: false, stripeStatus });
    }
  }

  render() {
    const { fetchingStripeStatus } = this.state;

    if (fetchingStripeStatus) {
      return <PageLoading />;
    } else {
      return <MainContent>{this.checkSubStatus()}</MainContent>;
    }
  }

  checkSubStatus() {
    const { paymentPlatform, subscriptionStatus } = this.props;
    const { stripeStatus, freeTrialStatus } = this.state;

    // AH Note: Because there are more individual cases for Premium subscriptions in active, trialing, and mid-canceled states, the PremiumPlanView will be shown to everyone _except_ for free trial eligible users, canceled app subscribers, and canceled Stripe users.

    if (
      subscriptionStatus === MOBILE_APP_SUB_STATES.TRIAL_ELIGIBLE ||
      (paymentPlatform !== PAYMENT_PLATFORM.STRIPE &&
        subscriptionStatus === MOBILE_APP_SUB_STATES.EXPIRED) ||
      (paymentPlatform === PAYMENT_PLATFORM.STRIPE &&
        subscriptionStatus === MOBILE_APP_SUB_STATES.EXPIRED &&
        stripeStatus === STRIPE_SUB_STATE.CANCELED)
    ) {
      return <FreePlanView />;
    } else {
      return (
        <PremiumPlanView
          stripeStatus={stripeStatus}
          freeTrialStatus={freeTrialStatus}
        />
      );
    }
  }
}

const mapState = ({ user }) => {
  return {
    customerId: user.subscriptionData.subscription.stripe_id,
    paymentPlatform: user.subscriptionData.platform,
    subscriptionStatus: user.subscriptionData.subscription.subscription_status,
  };
};

export default connect(mapState)(SubscriptionBody);
