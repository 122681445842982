import React from 'react';
import { Link } from 'react-router-dom';

// CONSTANTS
import { createInviteFriendHeader, B2B } from '../../../constants/sharedCopy';

// ASSETS
import shineLogo from '../../../assets/shine-logo.png';
import shineAtWorkLogo from '../../../assets/images/b2b/shine-at-work-logo-circle.png';

// COMPONENTS
import { LandingPageButton, LimitedTimeEyebrow } from '../index';
import {
  HeaderContainer,
  HeaderInnerLeftContainer,
  HeaderTitle,
  HeaderDescription,
  CTAContainer,
  HeaderInnerRightContainer,
  HeroImage,
  Logo,
  CustomLogo,
  HeaderSectionWrapper,
  LogoWrapper,
  HeaderSmallText,
  RedeemedText,
} from './HeaderStyles';

// UTIL FUNCTIONS
const renderNonPromoCTAButton = (props) => (
  <LandingPageButton {...props} classId="header-cta" isHeader isColdTemplate />
);
const renderPromoCTAButton = (props) => {
  const { theme, limitedTimeText, templateType } = props;
  return (
    <React.Fragment>
      <LimitedTimeEyebrow
        className="limited-time-text"
        theme={theme}
        limitedTimeText={limitedTimeText}
      />
      <LandingPageButton {...props} classId="header-cta" isHeader />
      {templateType === B2B ? (
        <HeaderSmallText>
          If you have questions or any issues, check out our FAQs for 
          <a
            href="https://shinetext.zendesk.com/hc/en-us/categories/360005079891-Shine-at-Work"
            className="cta-shine-at-work"
            target="_blank"
            rel="noopener noreferrer"
          >
            Shine at Work
          </a>
          .
        </HeaderSmallText>
      ) : null}
    </React.Fragment>
  );
};

const renderCTA = (props) => {
  return (
    <CTAContainer>
      {props.isPromo
        ? renderPromoCTAButton(props)
        : renderNonPromoCTAButton(props)}
    </CTAContainer>
  );
};

const renderLeftContainer = (props) => {
  const { theme, heroHeader, heroCopy, nameOfInviter } = props;
  return (
    <React.Fragment>
      <HeaderTitle theme={theme}>
        {nameOfInviter ? createInviteFriendHeader(nameOfInviter) : heroHeader}
      </HeaderTitle>
      <HeaderDescription
        theme={theme}
        dangerouslySetInnerHTML={{ __html: heroCopy }}
      />
      {!props.maxSeatsRedeemed ? renderCTA(props) : renderRedeemedCopy()}
    </React.Fragment>
  );
};

const renderRightContainer = ({ heroImage }) => {
  // Warm promo & non-promo render image
  return (
    <HeaderInnerRightContainer>
      <HeroImage src={heroImage} />
    </HeaderInnerRightContainer>
  );
};

export const renderPartnerLogo = ({ customLogo, companyLogo }) => {
  if (customLogo || companyLogo) {
    const logo = customLogo || companyLogo;
    return <CustomLogo src={logo} />;
  }
  return null;
};

export const renderShineLogo = ({ templateType }) => {
  if (templateType === B2B) {
    return (
      <Link to="/shine-at-work">
        <Logo src={shineAtWorkLogo} isLink />
      </Link>
    );
  }
  return (
    <Link to="/">
      <Logo src={shineLogo} isLink />
    </Link>
  );
};

const renderRedeemedCopy = () => (
  <RedeemedText>
    Oh no! All of your Shine at Work subscriptions have been filled. Please
    contact your HR Administrator for access.
  </RedeemedText>
);
/**
 * COMPONENT
 */
const Header = (props) => {
  return (
    <HeaderSectionWrapper id="header">
      <HeaderContainer>
        <HeaderInnerLeftContainer>
          <LogoWrapper>
            {renderShineLogo(props)}
            {renderPartnerLogo(props)}
          </LogoWrapper>
          {renderLeftContainer(props)}
        </HeaderInnerLeftContainer>
        {renderRightContainer(props)}
      </HeaderContainer>
    </HeaderSectionWrapper>
  );
};

export default Header;
