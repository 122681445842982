import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  MOBILE_APP_SUB_STATES,
  PAYMENT_PLATFORM,
  STRIPE_SUB_STATE,
} from '../../../constants/config';
import { formatDate } from '../../../utils/common';
import { findCustomerSubscriptions } from '../../../utils/stripeUtils';

import {
  LeftColumn,
  Label,
  Data,
  FieldItem,
  IconWrapper,
  StripeIcon,
  ItunesIcon,
  GooglePlayIcon,
} from '../styles';

class SubscriptionDetailsSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptionWillCancel: false,
    };
  }

  async componentDidMount() {
    const { customerId } = this.props;
    const subscriptions = await findCustomerSubscriptions(customerId);

    if (subscriptions.length > 0 && subscriptions[0].cancel_at_period_end) {
      this.setState({ subscriptionWillCancel: true });
    }
  }

  // Update payment label based on subscription status & scheduled cancellations
  formatPaymentLabel() {
    const { subscriptionStatus, freeTrialStatus } = this.props;
    if (
      subscriptionStatus === MOBILE_APP_SUB_STATES.CANCELLED_MID_TRIAL ||
      (freeTrialStatus && this.state.subscriptionWillCancel)
    ) {
      return <Label>{COPY.trialEndLabel}</Label>;
    } else if (
      subscriptionStatus === MOBILE_APP_SUB_STATES.CANCELLED_MID_CYCLE ||
      this.state.subscriptionWillCancel
    ) {
      return <Label>{COPY.subscriptionEndLabel}</Label>;
    } else if (subscriptionStatus === MOBILE_APP_SUB_STATES.TRIAL) {
      return <Label>{COPY.firstPaymentLabel}</Label>;
    } else {
      return <Label>{COPY.nextPaymentLabel}</Label>;
    }
  }

  formatPaymentPlan() {
    const { paymentPlan, stripeStatus, freeTrialStatus } = this.props;
    let statusInfo;
    if (stripeStatus === STRIPE_SUB_STATE.PAST_DUE) {
      statusInfo = COPY.expiredPastDue;
    } else if (stripeStatus === STRIPE_SUB_STATE.UNPAID) {
      statusInfo = COPY.expiredUnpaid;
    } else if (freeTrialStatus) {
      statusInfo = COPY.inFreeTrial;
    }

    if (paymentPlan && paymentPlan.includes('Annual')) {
      return (
        <Data>
          {COPY.annual}
          {statusInfo ? ` (${statusInfo})` : ''}
        </Data>
      );
    }
    if (paymentPlan && paymentPlan.includes('Monthly')) {
      return (
        <Data>
          {COPY.monthly}
          {statusInfo ? ` (${statusInfo})` : ''}
        </Data>
      );
    } else return <Data>{COPY.notAvailable}</Data>;
  }

  showPaymentPlatform() {
    const { paymentPlatform } = this.props;
    if (paymentPlatform === PAYMENT_PLATFORM.STRIPE) {
      return (
        <IconWrapper>
          <StripeIcon />
          <Data>{COPY.stripe}</Data>
        </IconWrapper>
      );
    }
    if (paymentPlatform === PAYMENT_PLATFORM.ITUNES) {
      return (
        <IconWrapper>
          <ItunesIcon />
          <Data>{COPY.itunes}</Data>
        </IconWrapper>
      );
    }
    if (paymentPlatform === PAYMENT_PLATFORM.ANDROID) {
      return (
        <IconWrapper>
          <GooglePlayIcon />
          <Data>{COPY.googlePlay}</Data>
        </IconWrapper>
      );
    } else return <Data>{COPY.notAvailable}</Data>;
  }

  render() {
    const { expDate, subscriptionStatus, paymentPlatform } = this.props;

    if (paymentPlatform === PAYMENT_PLATFORM.STRIPE_B2B) {
      return (
        <LeftColumn>
          <Data>{COPY.b2bMessage}</Data>
        </LeftColumn>
      );
    } else {
      return (
        <LeftColumn>
          {subscriptionStatus !== MOBILE_APP_SUB_STATES.EXPIRED ? (
            <FieldItem>
              {this.formatPaymentLabel()}
              <Data>{formatDate(expDate)}</Data>
            </FieldItem>
          ) : null}
          <FieldItem>
            <Label>{COPY.subLabel}</Label>
            {this.formatPaymentPlan()}
          </FieldItem>
          <FieldItem>
            <Label>{COPY.paymentMethodLabel}</Label>
            {this.showPaymentPlatform()}
          </FieldItem>
          <Label>{COPY.description}</Label>
        </LeftColumn>
      );
    }
  }
}

const COPY = {
  premiumTitle: 'Shine Premium',
  firstPaymentLabel: 'First Payment Date',
  trialEndLabel: 'Trial End Date',
  subscriptionEndLabel: 'Subscription End Date',
  nextPaymentLabel: 'Next Recurring Payment',
  subLabel: 'Subscription Plan',
  paymentMethodLabel: 'Payment Method',
  annual: 'Annual',
  inFreeTrial: 'In Free Trial',
  monthly: 'Monthly',
  stripe: 'Stripe',
  itunes: 'iTunes',
  googlePlay: 'Google Play',
  notAvailable: 'Not Available',
  expiredPastDue: 'Past Due',
  expiredUnpaid: 'Unpaid',
  b2bMessage:
    "Our records indicate you have a subscription through Shine at Work. For questions about your membership, please contact your company's Shine at Work administrator.",
};

const mapState = ({ user }) => {
  return {
    customerId: user.subscriptionData.subscription.stripe_id,
    email: user.userData.email,
    expDate: user.subscriptionData.subscription.exp_date,
    paymentPlan: user.subscriptionData.subscription.payment_plan,
    paymentPlatform: user.subscriptionData.platform,
    subscriptionStatus: user.subscriptionData.subscription.subscription_status,
  };
};

export default connect(mapState, null)(SubscriptionDetailsSection);
