import React, { Component, Suspense } from 'react';
import { Redirect, withRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../../ScrollToTop';
import REDIRECTS from './redirects.json';

import { connect } from 'react-redux';
import { getUrlParams } from '../../utils/common';
import { saveUTMParams, saveEmailFromUrl } from '../../actions/urlParams';

// Content pages
const Homepage = React.lazy(() => import('../homepage/Homepage'));
const PromoChecker = React.lazy(() => import('../PromoChecker'));
const AboutPage = React.lazy(() => import('../about/AboutPage'));
const AudioPreviewPage = React.lazy(() => import('../audio/AudioPreviewPage'));
const PodcastsPage = React.lazy(() => import('../podcasts/PodcastsPage.tsx'));
const EpisodeChecker = React.lazy(() =>
  import('../podcasts/EpisodeChecker.tsx')
);

// Shine At Work pages
const ShineAtWorkTemplate = React.lazy(() =>
  import('../promoTemplates/TemplateEnterprise')
);
const B2BPage = React.lazy(() => import('../b2b/B2BPage'));

// Quiz pages
const CompassionQuiz = React.lazy(() => import('../quiz/index'));
const QuizResults = React.lazy(() => import('../quiz/Results'));

// Auth pages
const PasswordResetForm = React.lazy(() => import('../form/PasswordResetForm'));
const BillingPage = React.lazy(() => import('../billing/BillingPage'));
const ProtectedRoutes = React.lazy(() =>
  import('../navigation/ProtectedRoutes')
);
const ConfirmationPage = React.lazy(() => import('../checkout/Confirmation'));

class OpenedRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromEmail: false,
    };
  }

  componentDidMount() {
    const params = getUrlParams();
    const { utmParams, email } = params;
    if (
      utmParams.utm_source ||
      utmParams.utm_medium ||
      utmParams.utm_campaign
    ) {
      this.props.saveUTMParams(utmParams);
    }
    if (email) {
      this.props.saveEmailFromUrl(email);
      this.setState({ fromEmail: true });
    }
  }

  render() {
    const { isAuthenticated } = this.props;
    const { fromEmail } = this.state;

    return (
      <ScrollToTop>
        <Suspense fallback={null}>
          <Switch>
            {/* Routes placed here are available to all visitors */}

            {/** Shine At Work Pages */}
            <Route path="/work/:contentfulId" component={ShineAtWorkTemplate} />
            <Route exact path="/shine-at-work" component={B2BPage} />

            {/** Content Pages */}
            <Route exact path="/" component={Homepage} />
            <Route path="/promo/:promoCode" component={PromoChecker} />
            <Route path="/about" component={AboutPage} />
            <Route exact path="/shine-at-work" component={B2BPage} />
            <Route path="/audio/:id" component={AudioPreviewPage} />
            <Route exact path="/podcasts" component={PodcastsPage} />
            <Route path="/podcasts/fwmhb/:guest" component={EpisodeChecker} />

            {/** Quiz pages */}
            <Route exact path="/quiz/:step" component={CompassionQuiz} />
            <Route
              exact
              path="/results/:personalityType"
              component={QuizResults}
            />

            {/** Auth pages */}
            <Route exact path="/password/reset" component={PasswordResetForm} />
            <Route
              path="/billing"
              render={() =>
                fromEmail || isAuthenticated ? (
                  <BillingPage />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              path="/confirmation"
              render={(props) =>
                fromEmail || isAuthenticated ? (
                  <ConfirmationPage {...props} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />

            {Object.keys(REDIRECTS).map((fromPath) => (
              <Route
                key={fromPath}
                path={fromPath}
                render={() => window.location.replace(REDIRECTS[fromPath])}
              />
            ))}
            <ProtectedRoutes />
          </Switch>
        </Suspense>
      </ScrollToTop>
    );
  }
}

const mapState = (state) => {
  return {
    utmParams: state.utmParams,
    isAuthenticated: state.user.isAuthenticated,
  };
};

const mapDispatch = {
  saveUTMParams: saveUTMParams,
  saveEmailFromUrl: saveEmailFromUrl,
};

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(connect(mapState, mapDispatch)(OpenedRoutes));
