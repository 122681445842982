import React from 'react';
import { SurveyContainer, SurveyText } from './styles';

const SurveyPageE2 = () => (
  <SurveyContainer>
    <SurveyText dangerouslySetInnerHTML={{ __html: COPY.description }} />
  </SurveyContainer>
);

const COPY = {
  description:
    'We rely on your feedback to help us improve the Shine experience. Thank you for taking the time to share your thoughts with us.<br><br>Thank you and take care!<br><br>- Shine HQ',
};

export default SurveyPageE2;
