import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

// Assets
import { teamPhoto } from '../../constants/image.config';

// Components
import { BoldTitle, DefaultText } from './AboutPageStyles';

const Section = styled.section`
  background-color: ${COLORS.white};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 100px 0 80px 0;
  @media only screen and (max-width: ${SIZES.tablet}) {
    align-items: center;
    margin: 60px 0 32px 0;
  }
`;

const Title = styled(BoldTitle)`
  max-width: 710px;
  margin: 0 0 19px 122px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 85%
    margin: 0 0 19px 0;
  }
`;

const Description = styled(DefaultText)`
  max-width: 710px;
  margin: 0 0 0 122px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 85%
    margin: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: bottom;
  max-height: 640px;
  min-height: 224px;
`;

const OurTeamSection = () => (
  <Section id="our-team">
    <TextWrapper>
      <Title>Our Team</Title>
      <Description>
        In our office (and over Zoom): “I love you” isn’t strange to hear. We’re
        driven by our individual values and passions. As a team that’s 80%
        BIPOC, we’re proud of the diverse range of experiences, identities, and
        skills we all bring to this company.
      </Description>
    </TextWrapper>
    <Fade triggerOnce>
      <Image src={teamPhoto} />
    </Fade>
  </Section>
);

export default OurTeamSection;
