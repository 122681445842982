import React, { Component } from 'react';
import styled from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import {
  mashableColor,
  cbsColor,
  nbcColor,
  popsugarColor,
  marieclaireColor,
  teenvogueColor,
} from '../../constants/image.config';

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PressIconsContainer = styled.div`
  width: 100%;
  height: 180px
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    flex-wrap: wrap;
    align-content: center;
    height: 130px;
  }
`;

const PressIcon = styled.img`
  margin: 0 2%;
  width: 13%;
  max-width: 185px;
  object-fit: contain;
  cursor: ${(props) => (props.isClickable ? 'pointer' : '')};
  filter: contrast(0);

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 25%;
    height: 30px;
    margin: 3%;
  }
`;

class PressSection extends Component {
  handleHoverOn = (event) => {
    event.currentTarget.style.filter = 'unset';
  };

  handleHoverOff = (event) => {
    event.currentTarget.style.filter = 'contrast(0)';
  };

  handleClick = (event) => {
    const id = event.currentTarget.id;
    const { link } = PRESS_ICONS[id];

    event.currentTarget.style.filter = 'unset';
    if (link) window.open(link);
  };

  render() {
    return (
      <Section id="press">
        <PressIconsContainer>
          {PRESS_ICONS.list.map(({ name, img, altText, isClickable }) => (
            <PressIcon
              key={name}
              src={img}
              id={name}
              alt={altText}
              onMouseEnter={this.handleHoverOn}
              onMouseLeave={this.handleHoverOff}
              onClick={this.handleClick}
              isClickable={isClickable}
            />
          ))}
        </PressIconsContainer>
      </Section>
    );
  }
}

export default PressSection;

const PRESS_ICONS = {
  list: [
    {
      name: 'mashable',
      img: mashableColor,
      altText: 'Mashable logo',
      isClickable: true,
    },
    { name: 'cbs', img: cbsColor, altText: 'CBS News logo', isClickable: true },
    {
      name: 'popsugar',
      img: popsugarColor,
      altText: 'Popsugar logo',
      isClickable: true,
    },
    {
      name: 'marieclaire',
      img: marieclaireColor,
      altText: 'Marie Claire logo',
      isClickable: true,
    },
    {
      name: 'teenvogue',
      img: teenvogueColor,
      altText: 'Teen Vogue logo',
      isClickable: true,
    },
    { name: 'nbc', img: nbcColor, altText: 'NBC News logo', isClickable: true },
  ],
  mashable: {
    link: 'https://mashable.com/article/meditation-apps-guides-voices-headspace-calm-insight-timer-shine/',
  },
  cbs: {
    link: 'https://www.cbsnews.com/video/app-aims-to-help-marginalized-americans-care-for-their-mental-health/',
  },
  popsugar: {
    link: 'https://www.popsugar.com/fitness/shine-black-owned-self-care-app-interview-47596506',
  },
  marieclaire: {
    link: 'https://www.marieclaire.com/health-fitness/a33267426/racism-mental-health-crisis-black-americans/',
  },
  teenvogue: {
    link: 'https://www.teenvogue.com/story/mental-health-stigma-could-change-shine-self-care-app',
  },
  nbc: {
    link: 'https://www.nbcnews.com/know-your-value/feature/shine-s-co-founders-5-ways-rise-above-representation-burnout-ncna1024666 ',
  },
};
