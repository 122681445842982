import React, { Component } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import SIZES from '../../../constants/screenSizes';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { leftBlob, checkmark } from '../../../constants/image.config';
import { Section, Title } from '../B2BStyles';
const WhiteTitle = styled(Title)`
  color: ${COLORS.white};
`;

const TealSection = styled(Section)`
  flex-direction: column;
  padding: 50px;
  background-color: ${COLORS.darkModerateCyan};
  background-image: url(${leftBlob});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 15%;

  @media only screen and (max-width: ${SIZES.desktop}) {
    padding: 25px;
    background-size: 30%;
  }
`;

const ListsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 40px 0 0 0;
  z-index: 1;

  @media only screen and (max-width: ${SIZES.tablet}) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 338px;
  margin: 0 20px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    margin: 0 2%;
  }
  @media only screen and (max-width: ${SIZES.tablet}) {
    margin-bottom: 20px;
  }
`;

const ListBulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const ListHeader = styled.div`
  background-color: ${COLORS.lightYellow};
  border-radius: 12px;
  width: 100%;
  padding: 5px 0px;
  margin-bottom: 10px;
  text-align: center;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-weight: 800;
  font-size: 16px;
  color: ${COLORS.darkMagentaPink};
`;

const ListBulletText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-weight: 400;
  font-size: 16px;
  color: ${COLORS.white};
  margin: 8px 0px;
  text-align: left;
`;

const BulletIcon = styled.img.attrs({ src: checkmark })`
  width: 19px;
  height: 19px;
  margin: 12px 15px 8px 0px;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 16px;
    height: 16px;
  }
`;

class PlansSection extends Component {
  renderListSection = (header: string, bullets: Object[]) => {
    return (
      <ListContainer>
        <ListHeader>{header}</ListHeader>
        {bullets.map((bullet, index) => {
          return (
            <ListBulletContainer key={index}>
              <Fade triggerOnce cascade>
                <BulletIcon />
                <ListBulletText>{bullet}</ListBulletText>
              </Fade>
            </ListBulletContainer>
          );
        })}
      </ListContainer>
    );
  };

  render() {
    return (
      <TealSection>
        <WhiteTitle>{COPY.title}</WhiteTitle>
        <ListsWrapper>
          {this.renderListSection(COPY.column1Header, COPY.column1Bullets)}
          {this.renderListSection(COPY.column2Header, COPY.column2Bullets)}
          {this.renderListSection(COPY.column3Header, COPY.column3Bullets)}
        </ListsWrapper>
      </TealSection>
    );
  }
}

const COPY = {
  title: 'Everything you need to launch',
  column1Header: 'Launch Implementation',
  column2Header: 'Engagement',
  column3Header: 'Metrics & Data',
  column1Bullets: [
    'Shine at Work annual access',
    'Customized enrollment page with your logo',
    'Dedicated account team',
    'Pre-launch consultation',
    'Custom live launch webinar',
    'Digital launch & engagement promotional toolkit',
    'Technical member support',
  ],
  column2Bullets: [
    'Custom content and meditations',
    'Shine membership newsletter',
    'Monthly admin Shine guide',
    'Live and interactive monthly events',
    'Comprehensive programming via self-care calendar',
  ],
  column3Bullets: [
    'Real-time employee sentiment, usage data, and administrative portal',
    'Monthly check-in',
    'Bi-Annual business reviews',
    'Ongoing wellbeing surveys',
  ],
};

export default PlansSection;
