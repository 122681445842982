import React, { Component } from 'react';
// Components
import { LogoHeader } from '../sharedPartials';
import SendGiftForm from './SendGiftForm';
// StyleSheets
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
} from '../checkout/styles';
import { FormContainer } from '../form/FormStyles';

/**
 * COMPONENT
 */
class SendGiftPage extends Component {
  render() {
    return (
      <CheckoutBackground>
        <CheckoutBlobTop />
        <CheckoutPageContainer>
          <LogoHeader
            isB2b={false}
            templateType={''}
            customLogo={''}
            companyId={''}
          />
          <FormContainer>
            <SendGiftForm />
          </FormContainer>
        </CheckoutPageContainer>
        <CheckoutBlobBottom />
      </CheckoutBackground>
    );
  }
}

export default SendGiftPage;
