import { combineReducers } from 'redux';
import userReducer from './user';
import billingReducer from './billing';
import promoReducer from './promo';
import quizReducer from './quiz';
import utmParamsReducer from './utmParams';
import enterpriseReducer from './enterprise';

export const rootReducer = combineReducers({
  user: userReducer,
  billing: billingReducer,
  promo: promoReducer,
  quiz: quizReducer,
  utmParams: utmParamsReducer,
  enterprise: enterpriseReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
