import styled from 'styled-components';
import COLORS from '../colors';
import SIZES from '../screenSizes';

export const LinkText = styled.a`
  color: ${COLORS.blackTwo};
  height: 26px;
  font-family: 'Work Sans';
  font-size: 16px;
  font-weight: bold;
  line-height: 1.63;
  letter-spacing: 0.4px;
`;

export const DetailsText = styled.p`
  color: ${COLORS.blackTwo};
  font-family: 'Work Sans';
  letter-spacing: 0.5px;
  line-height: 1.44;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 14px;
  }
  @media only screen and (min-width: ${SIZES.tablet}) {
    font-size: 14px;
  }
  @media only screen and (min-width: ${SIZES.desktop}) {
    font-size: 18px;
  }
`;

// Used in all Confirmation Page & Reset Password Form
export const Title = styled.p`
  color: ${COLORS.blackTwo};
  height: 28px;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: 0.5px;
  text-align: center;

  @media only screen and (max-width: ${SIZES.iphone5sWidth}) {
    font-size: 14px;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 18px;
  }
  @media only screen and (min-width: ${SIZES.tablet}) {
    font-size: 20px;
  }
  @media only screen and (min-width: ${SIZES.desktop}) {
    font-size: 24px;
  }
`;

export const Line = styled.hr`
  border: 1px solid ${COLORS.paleGrey};
  width: 100%;
`;
