import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  ButtonLink,
  ButtonsWrapper,
  LeftColumn,
  YellowButton,
  YellowBorderButton,
} from '../styles';
import { goToCustomerPortal } from '../../../utils/stripeUtils';
import {
  MOBILE_APP_SUB_STATES,
  PAYMENT_PLATFORM,
  APP_CANCEL_TYPEFORM_LINK,
} from '../../../constants/config';

class SubscriptionCancelSection extends Component {
  showCancelOptions() {
    const { paymentPlatform, subscriptionStatus, stripeId } = this.props;
    if (paymentPlatform === PAYMENT_PLATFORM.STRIPE) {
      return (
        <ButtonsWrapper>
          <YellowButton onClick={() => goToCustomerPortal(stripeId)}>
            {COPY.updateLabel}
          </YellowButton>
          {subscriptionStatus === MOBILE_APP_SUB_STATES.ACTIVE_AUTO_RENEW ||
          subscriptionStatus === MOBILE_APP_SUB_STATES.TRIAL ? (
            <ButtonLink to="/cancel">
              <YellowBorderButton>{COPY.cancelLabel}</YellowBorderButton>
            </ButtonLink>
          ) : null}
        </ButtonsWrapper>
      );
    } else if (paymentPlatform === PAYMENT_PLATFORM.STRIPE_B2B) {
      return null;
    } else {
      return (
        <a
          href={APP_CANCEL_TYPEFORM_LINK}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <YellowBorderButton className="app-cancel-button">
            {COPY.cancelLabel}
          </YellowBorderButton>
        </a>
      );
    }
  }

  render() {
    return <LeftColumn>{this.showCancelOptions()}</LeftColumn>;
  }
}

const COPY = {
  updateLabel: 'Update Membership',
  cancelLabel: 'Cancel Membership',
};

const mapState = ({ user }) => {
  return {
    paymentPlatform: user.subscriptionData.platform,
    stripeId: user.subscriptionData.subscription.stripe_id,
    subscriptionStatus: user.subscriptionData.subscription.subscription_status,
  };
};

export default connect(mapState, null)(SubscriptionCancelSection);
