import netlifyFnClient from '../api/netlifyFnClient';
import { API_ACTIONS } from './stripeShared';
import { LEANPLUM_ATTRIBUTES } from '../constants/config';
import { UTMParams, UserData } from '../types/actions/auth-actions';
import {
  InputUserInfo,
  SubscriptionData,
} from '../types/utils/stripeUtils-types';

interface B2BTags {
  company?: string;
  isB2b?: boolean;
}
/**
 * @typedef {object} InputUserData
 * @property {string} email User's email
 * @property {string} firstName User's first name
 * @property {string} [token] Token for default card payment
 * @property {string} [uid] User's Firebase ID
 */

/**
 * https://stripe.com/docs/api/coupons/object
 *
 * @typedef {object} Coupon
 * @property {string} id
 * @property {number} percent_off
 */

/**
 * https://stripe.com/docs/api/customers/object
 *
 * @typedef {object} Customer
 * @property {string} id
 * @property {string} name
 * @property {object} subscriptions
 * @property {object} metadata
 * @property {Subscription[]} subscriptions.data
 */

/**
 * https://stripe.com/docs/api/subscriptions/object
 *
 * @typedef {object} Subscription
 * @property {string} id
 * @property {boolean} cancel_at_period_end
 * @property {number} current_period_end Timestamp in seconds of the end of the current invoice period
 * @property {number} current_period_start Timestamp in seconds of the start of the current invoice period
 * @property {SubscriptionPlan} plan
 * @property {SubscriptionSchedule} schedule
 * @property {string} status Possible values are 'incomplete', 'incomplete_expired', 'trialing', 'active', 'past_due', 'canceled', or 'unpaid'.
 * @property {number} trial_end If there's a trial, timestamp in seconds of the end of the trial period
 * @property {number} trial_start If there's a trial, timestamp in seconds of the start of the trial period
 *
 */

/**
 * https://stripe.com/docs/api/subscription_schedules/
 *
 * @typedef {object} SubscriptionSchedule
 * @property {string} id
 * @property {Phases} phases
 *
 */

/**
 * https://stripe.com/docs/api/subscription_schedules/object#subscription_schedule_object-phases
 *
 * @typedef {object} Phases
 * @property {string} items.price ID of the price to which the customer should be subscribed.
 * @property {number} start_date Timestamp in seconds of the start of this phase of the subscription schedule
 * @property {number} end_date Timestamp in seconds of the end of this phase of the subscription schedule.
 * @property {string} coupon ID of the coupon to use during this phase of the subscription schedule.
 * @property {number} iterations Integer representing the multiplier applied to the price interval. If set, end_date must not be set.
 * @property {string} end_behavior Configures how the subscription schedule behaves when it ends. 'release' (default) will end the subscription schedule and keep the underlying subscription running. 'cancel' will end the subscription schedule and cancel the underlying subscription.
 *
 */

/**
 * https://stripe.com/docs/api/plans/object
 *
 * @typedef {object} SubscriptionPlan
 * @property {string} id
 * @property {string} interval One of 'day', 'week', 'month' or 'year'
 */

/**
 * A custom customer data object with Stripe customerId and subscription data.
 *
 * @typedef {object} CustomerData
 * @property {string | undefined} customerId
 * @property {object[]} subscriptionData
 */

/**
 * Fetches customer data from Stripe if they already exist. If not, then create
 * the customer resource.
 * @return {Promise.<CustomerData>}
 */
export const findOrCreateCustomer = async function (
  user: UserData,
  utmParams: UTMParams = { utm_source: '', utm_medium: '', utm_campaign: '' },
  b2bTags: B2BTags = { company: '', isB2b: false }
) {
  const { email, firstName, uid } = user;
  const { utm_source, utm_medium, utm_campaign } = utmParams;
  let customerId;
  let subscriptionData = [];
  let customer = await findCustomer(email);
  if (customer) {
    customerId = customer.id;
    subscriptionData = customer.subscriptions.data;

    // Update customer name and utm tags if it did not previously exist
    if (
      (!customer.name && firstName) ||
      (utm_source && utm_source !== '') ||
      (utm_medium && utm_medium !== '') ||
      (utm_campaign && utm_campaign !== '') ||
      b2bTags.isB2b
    )
      await updateCustomer(customerId, {
        firstName,
        uid,
        utm_source,
        utm_medium,
        utm_campaign,
        company: b2bTags.company,
        isB2b: b2bTags.isB2b,
      });
  } else {
    customerId = await createCustomer({
      email,
      firstName,
      uid,
      utm_source,
      utm_medium,
      utm_campaign,
      ...b2bTags,
    });
  }

  return { customerId, subscriptionData };
};

/**
 * Fetch customer data for the provided email.
 * @return {Promise.<Customer | undefined>} The customer's Stripe data, if found
 */
export const findCustomer = async function (email: string) {
  const customer = await netlifyFnClient.get('stripe-handler', {
    params: {
      action: API_ACTIONS.FIND_CUSTOMERS,
      email,
    },
  });

  const {
    data: { data },
  } = customer;

  if (data.length > 0) {
    return data[0];
  } else {
    return undefined;
  }
};

/**
 * Create the customer resource on Stripe.
 * @return {string | undefined} The newly created customer ID, if successful
 */
export const createCustomer = async function ({
  email,
  firstName,
  token,
  uid,
  utm_source,
  utm_medium,
  utm_campaign,
  isB2b,
  company,
}: InputUserInfo) {
  let data = {
    email,
    name: firstName,
  } as { [key: string]: any };

  // Only set new default payment if token exists
  if (token) {
    data.source = token;
  }

  if (uid) {
    data['metadata[uid]'] = uid;
  }

  if (utm_source) {
    data['metadata[utm_source]'] = utm_source;
  }
  if (utm_medium) {
    data['metadata[utm_medium'] = utm_medium;
  }
  if (utm_campaign) {
    data['metadata[utm_campaign]'] = utm_campaign;
  }
  if (isB2b && company) {
    data['metadata[isB2b]'] = isB2b;
    data['metadata[company]'] = company;
  }

  const customer = await netlifyFnClient.post('stripe-handler', {
    action: API_ACTIONS.CREATE_CUSTOMER,
    data,
  });

  if (customer && customer.data) {
    return customer.data.id; // Customer ID
  } else {
    return undefined;
  }
};

/**
 * Update a customer's data on Stripe.
 * @return {Promise.<customerId | undefined>} The customer ID, if successful
 */
export const updateCustomer = async function (
  customerId: string,
  {
    email,
    firstName,
    token,
    uid,
    utm_source,
    utm_medium,
    utm_campaign,
    isB2b,
    company,
    discountPercent,
    discountFlatAmount,
    pageId,
  }: InputUserInfo
) {
  // Only update fields that have data
  let data = {} as { [key: string]: any };
  data['metadata[subscriptionSource]'] = LEANPLUM_ATTRIBUTES.WEB;
  if (email) data.email = email;
  if (firstName) data.name = firstName;
  if (token) data.source = token; // default card payment
  if (uid) data['metadata[uid]'] = uid;
  if (utm_source) data['metadata[utm_source]'] = utm_source;
  if (utm_medium) data['metadata[utm_medium'] = utm_medium;
  if (utm_campaign) data['metadata[utm_campaign]'] = utm_campaign;
  if (isB2b && company) {
    data['metadata[isB2b]'] = isB2b;
    data['metadata[company]'] = company;
  }
  if (discountPercent) data['metadata[discount]'] = `${discountPercent}pct`;
  if (discountFlatAmount)
    data['metadata[discount]'] = `${discountFlatAmount}flat`;
  if (pageId) {
    data['metadata[webpageSubscribe]'] = pageId;
  } else {
    data['metadata[webpageSubscribe]'] = LEANPLUM_ATTRIBUTES.HOMEPAGE;
  }

  const customer = await netlifyFnClient.post('stripe-handler', {
    action: API_ACTIONS.UPDATE_CUSTOMER,
    customerId,
    data,
  });

  if (customer && customer.data) {
    return customer.data.id;
  } else {
    return undefined;
  }
};

/**
 * Create subscription for a customer with the given params.
 */

export const subscribeUser = async function (
  customerId: string,
  planId: string,
  couponId = '',
  trial = true
) {
  await netlifyFnClient.post('stripe-handler', {
    action: API_ACTIONS.SUBSCRIBE_USER,
    customer: customerId,
    items: [{ price: planId }],
    coupon: couponId,
    trial_from_plan: trial,
  });
};

/**
 * Find a subscription schedule with the given subscription id.
 * @return {Promise.<SubscriptionSchedule | undefined>} subscription schedule object, if it exists
 */

export const findSubscriptionSchedule = async (subscriptionId: string) => {
  const schedule = await netlifyFnClient.get('stripe-handler', {
    params: {
      action: API_ACTIONS.FIND_SUBSCRIPTION_SCHEDULE,
      subscriptionId: subscriptionId,
    },
  });
  const scheduleData = schedule.data;
  return scheduleData;
};

/**
 * Migrate an existing subscription to be managed by a subscription schedule. When using this parameter, other parameters (such as phase values) cannot be set.
 * @return {Promise.<SubscriptionSchedule>}
 */

export const createSubscriptionSchedule = async function (
  subscriptionId: string
) {
  const schedule = await netlifyFnClient.post('stripe-handler', {
    action: API_ACTIONS.CREATE_SUBSCRIPTION_SCHEDULE,
    from_subscription: subscriptionId,
  });
  const scheduleData = schedule.data;
  return scheduleData;
};

/**
 * Update an existing subscription schedule by passing in the current phase, along with a new phase with the given params.
 */

export const updateSubscriptionSchedule = async function (
  scheduleId: string,
  currentPlanId: string,
  currentStartDate: number,
  currentEndDate: number,
  trial: boolean,
  planId: string,
  couponId?: string
) {
  await netlifyFnClient.post('stripe-handler', {
    action: API_ACTIONS.UPDATE_SUBSCRIPTION_SCHEDULE,
    scheduleId: scheduleId,
    phases: [
      {
        items: [{ price: currentPlanId, quantity: 1 }],
        start_date: currentStartDate,
        end_date: currentEndDate,
        trial: trial,
      },
      {
        items: [{ price: planId, quantity: 1 }],
        coupon: couponId,
        start_date: currentEndDate,
        iterations: 1,
      },
    ],
  });
};

/**
 * Releases the subscription schedule immediately, which will stop scheduling of its phases, but leave any existing subscription in place.
 */

export const releaseSubscriptionSchedule = async function (scheduleId: string) {
  await netlifyFnClient.post('stripe-handler', {
    action: API_ACTIONS.RELEASE_SUBSCRIPTION_SCHEDULE,
    scheduleId: scheduleId,
  });
};

/**
 * Get a customer's non-canceled subscriptions.
 * @return {Promise.<Subscription[]>} array of non-canceled subscriptions, if any
 */
export const findCustomerSubscriptions = async function (customerId: string) {
  const subscriptionsList = await netlifyFnClient.get('stripe-handler', {
    params: {
      action: API_ACTIONS.FIND_CUSTOMER_SUBSCRIPTIONS,
      customer: customerId,
    },
  });

  const {
    data: { data: subscriptions },
  } = subscriptionsList;

  return subscriptions;
};

/**
 * Updates an existing subscription from applying a gift card.
 */
export const updateSubscriptionFromGiftCard = async function (
  subscriptionData: SubscriptionData,
  giftcardInterval: string,
  isLifetimeDiscount: boolean
) {
  const {
    id: subscriptionId,
    plan: { id: planId, interval: currentSubscriptionInterval },
  } = subscriptionData;
  // Determine if the should be discounted once for annual/monthly or a percentage off an annual plan
  // Or if the user should be discounted for 12 months
  const couponId = determineCouponType(
    currentSubscriptionInterval,
    giftcardInterval,
    isLifetimeDiscount
  );

  await upsertSubscriptionSchedule({ subscriptionId, planId, couponId });
};

/**
 * Get Stripe coupon data.
 * @return {Promise.<Coupon>}
 */
export const findCouponById = async (couponId: string) => {
  const couponData = await netlifyFnClient.get('stripe-handler', {
    params: {
      action: API_ACTIONS.FIND_COUPON,
      couponId: couponId,
    },
  });

  const { data: coupon } = couponData;

  return coupon;
};

/**
 * Determine which couponId to use.
 */

function determineCouponType(
  subscriptionInterval: string,
  giftcardInterval: string,
  isLifetimeDiscount: boolean
) {
  if (isLifetimeDiscount)
    return process.env.REACT_APP_STRIPE_100PERCENT_FOREVER_DISCOUNT;
  if (subscriptionInterval === giftcardInterval) {
    // If the users current subscription and the giftcard interval are the same apply coupon once
    return process.env.REACT_APP_STRIPE_100PERCENT_DISCOUNT;
  } else if (subscriptionInterval === 'year') {
    // Apply partial discount to next payment
    return process.env.REACT_APP_STRIPE_ONE_MONTH_OFF_ANNUAL;
  } else {
    // Apply discount for 12 months
    return process.env.REACT_APP_STRIPE_12_MONTH_OFF_MONTLY;
  }
}

/**
 * Cancel a customer’s subscription at period end.
 */
export const cancelSubscriptionAtPeriodEnd = async (subscriptionId: string) => {
  await netlifyFnClient.post('stripe-handler', {
    action: API_ACTIONS.CANCEL_SUBSCRIPTION_AT_PERIOD_END,
    subscriptionId: subscriptionId,
  });
};

/**
 * Direct user to Stripe portal
 */
export const goToCustomerPortal = async function (stripeId: string) {
  const originUrl = window.location.href;
  const response = await netlifyFnClient.post(
    'create-customer-portal-session',
    {
      customerId: stripeId,
      origin: originUrl,
    }
  );

  if (response.data.url) {
    window.location.href = response.data.url;
  }
};

/**
 * Get additional params for updating subscription schedule by checking first if there's an existing schedule. Then inserts/creates new subscription schedule with the given params.
 */

export const upsertSubscriptionSchedule = async ({
  subscriptionId,
  planId,
  couponId,
}: {
  subscriptionId: string;
  planId: string;
  couponId?: string;
}) => {
  let scheduleId;
  let currentPlanId;
  let currentStartDate;
  let currentEndDate;
  let trial;

  // If there's a schedule already on the subscription, save data.
  const existingSchedule = await findSubscriptionSchedule(subscriptionId);

  if (existingSchedule) {
    scheduleId = existingSchedule.id;
    currentPlanId = existingSchedule.phases[0].plans[0].price;
    currentStartDate = existingSchedule.phases[0].start_date;
    currentEndDate = existingSchedule.phases[0].end_date;
    trial = existingSchedule.phases[0].trial;
  } else {
    // Create a subscription schedule from an existing subscription Id and save data
    const newSchedule = await createSubscriptionSchedule(subscriptionId);
    if (newSchedule) {
      scheduleId = newSchedule.id;
      currentPlanId = newSchedule.phases[0].plans[0].price;
      currentStartDate = newSchedule.phases[0].start_date;
      currentEndDate = newSchedule.phases[0].end_date;
      trial = newSchedule.phases[0].trial;
    }
  }

  // Updates a customer's subscription with a new subscription schedule with the given params.
  await updateSubscriptionSchedule(
    scheduleId,
    currentPlanId,
    currentStartDate,
    currentEndDate,
    trial,
    planId,
    couponId
  );
};

interface GenericErrorType {
  message?: string;
}
/**
 *  Direct SMB interests to Stripe checkout for bulk purchases at a discounted rate.
 * @return {Promise<object>} Response object
 */

export async function goToSMBStripeCheckout(
  couponId: string,
  cancelUrl: string,
  successUrl: string
) {
  const session = await netlifyFnClient.post('create-smb-checkout-session', {
    couponId: couponId,
    cancel_url: cancelUrl,
    success_url: successUrl,
  });

  try {
    window.location.href = session.data.url;
  } catch (error) {
    const e = error as GenericErrorType;
    return e.message;
  }
}
