import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import FormInputComponent from './FormInputComponent';
import { sendPasswordResetEmail } from '../../actions/auth';
import history from '../../history';
// Components
import FormFooter from './FormFooter';
//StyleSheets
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
} from '../checkout/styles';
import { Title, ButtonText, FormSubmitButton } from '../../constants/styles';
import { Form, Card, CardBottom, SmallText, SmallLinkText } from './FormStyles';
import SIZES from '../../constants/screenSizes';
import { LogoHeader } from '../sharedPartials';

//Form Container
export const FormContainer = styled.div`
  align-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 20px;
  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 95%;
  } 
`;

/**
 * COMPONENT
 */
class PasswordResetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordResetEmailAttempt: false,
      passwordResetEmailSent: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.redirectToLogin = this.redirectToLogin.bind(this);
  }

  render() {
    return (
      <CheckoutBackground>
        <CheckoutBlobTop />
        <CheckoutPageContainer>
          <LogoHeader />
          <FormContainer>
            <Card>
              {!this.state.passwordResetEmailAttempt ? (
                <Form onSubmit={this.handleSubmit}>
                  <FormInputComponent
                    key="Email*"
                    label="Email*"
                    inputName="email"
                    inputType="email"
                  />
                  <CardBottom style={{ height: '5%', margin: '3% 0' }}>
                    <FormSubmitButton type="submit">
                      <ButtonText>{COPY.formSubmitButton}</ButtonText>
                    </FormSubmitButton>
                  </CardBottom>
                  <SmallText style={{ alignSelf: 'center' }}>
                    {COPY.returnToLogin}
                  </SmallText>
                  <SmallLinkText style={{ alignSelf: 'center' }}>
                    <Link to={COPY.loginLink}>{COPY.loginLinkText}</Link>
                  </SmallLinkText>
                </Form>
              ) : (
                <div>
                  {this.state.passwordResetEmailSent ? (
                    <Title>{COPY.successMessage}</Title>
                  ) : null}
                  {this.state.passwordResetEmailSent ? (
                    <SmallText style={{ alignSelf: 'center' }}>
                      {COPY.successHelperText}
                    </SmallText>
                  ) : (
                    <SmallText style={{ alignSelf: 'center' }}>
                      {COPY.failureText}
                    </SmallText>
                  )}
                  <FormSubmitButton onClick={this.redirectToLogin}>
                    <ButtonText>{COPY.backToLoginButton}</ButtonText>
                  </FormSubmitButton>
                </div>
              )}
            </Card>
            <FormFooter />
          </FormContainer>
        </CheckoutPageContainer>
        <CheckoutBlobBottom />
      </CheckoutBackground>
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    const email = event.target.email.value;

    try {
      sendPasswordResetEmail(email);
      this.setState({ passwordResetEmailAttempt: true });
      this.setState({ passwordResetEmailSent: true });
    } catch (e) {
      this.setState({ passwordResetEmailAttempt: true });
      this.setState({ passwordResetEmailSent: false });
    }
  }

  redirectToLogin(event) {
    event.preventDefault();
    history.push(`${COPY.loginLink}`);
  }
}

const COPY = {
  formSubmitButton: 'Reset Password',
  returnToLogin: 'Just Remembered?',
  loginLinkText: 'Login Now',
  loginLink: '../login',
  successMessage: 'Request Sent',
  successHelperText:
    'Check your email for next steps on resetting your password.',
  failureText:
    'There was an error sending the password reset email. Please contact us at help@headspace.com if this persists.',
  backToLoginButton: 'Back',
};

export default PasswordResetForm;
