import { GET_PROMO_DATA, INVALID_PROMO_ID } from '../constants/actionTypes';
/**
 * INITIAL STATE
 */
const initialState = {
  type: 'promo',
  isChecking: true,
  pageId: '',
  couponId: '',
  bannerCopy: '',
  heroImage: '',
  heroHeader: '',
  heroCopy: '',
  buttonCopy: '',
  customLogo: '',
  templateType: 'warm',
  emailDomain: '',
  emailDomainList: [],

  discountedPrice: null, // price after discount
  discountPercent: null, // % discount i.e. 50
  discountFlatAmount: null, // $ discount i.e. 23.99
  originalPrice: null,
  displayMonthlyPrice: '', //
  isExpired: false,
  headerBackgroundImage: '',
  theme: '',
  limitedTimeText: '',
  isPromo: false, // bool
  //Info about the coupon will be added here from Stripe <number>
  stripeCouponPercentOff: null,
  enableCompassionQuiz: false, // bool
  stepSectionEyeBrowTitle: '',
  stepSectionTitle: '',
  stepSectionImage: '',
  programSteps: false,
  maxSeatsRedeemed: false,
};

/**
 * REDUCER
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROMO_DATA:
      return {
        ...action.data,
        isChecking: false,
      };
    case INVALID_PROMO_ID:
      return {
        ...initialState,
        isChecking: false,
      };
    default:
      return state;
  }
}
