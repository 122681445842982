import React, { Component } from 'react';

// Components
import { LogoHeader } from '../sharedPartials';
import FormHandler from './FormHandler';
import FormFooter from './FormFooter';
// StyleSheets
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
} from '../checkout/styles';
import { FormContainer } from './FormStyles';
// Copy
import { authMethods } from '../../constants/config';
import {
  DEFAULT_SIGNUP_BUTTON_COPY,
  DEFAULT_SIGNUP_FB_BUTTON_COPY,
  DEFAULT_SIGNUP_APPLE_BUTTON_COPY,
  SWITCH_TO_LOGIN,
} from '../../constants/sharedCopy';
/**
 * COMPONENT
 */
class SignupPage extends Component {
  render() {
    const buttonText = {
      formSubmitButton: DEFAULT_SIGNUP_BUTTON_COPY,
      fbButton: DEFAULT_SIGNUP_FB_BUTTON_COPY,
      appleButton: DEFAULT_SIGNUP_APPLE_BUTTON_COPY,
    };
    return (
      <CheckoutBackground>
        <CheckoutBlobTop />
        <CheckoutPageContainer>
          <LogoHeader />
          <FormContainer>
            <FormHandler
              authType={authMethods.SIGNUP}
              buttonCopy={buttonText}
              switchAuthCopy={SWITCH_TO_LOGIN}
            />
          </FormContainer>
          <FormFooter />
        </CheckoutPageContainer>
        <CheckoutBlobBottom />
      </CheckoutBackground>
    );
  }
}

export default SignupPage;
