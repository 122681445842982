import axios from 'axios';
import { pathOr } from 'ramda';
import { MONTHLY, ANNUAL } from '../constants/sharedCopy';

interface ContentfulPromoConfigFields {
  fields: {
    isPromo: boolean;
    pageId: string;
    couponId?: string;
    bannerCopy: string;
    heroHeader: string;
    heroCopy: string;
    buttonCopy: string;
    templateType: 'warm' | 'cold' | 'b2b';
    discountedPrice?: number;
    discountPercent?: number;
    discountFlatAmount?: number;
    displayMonthlyPrice: boolean;
    originalPrice: number;
    expired: boolean;
    theme: string;
    limitedTimeText: string;
    enableCompassionQuiz: boolean;
    programSteps?: string[];
    stepSectionEyeBrowTitle?: string;
    stepSectionTitle?: string;
    subscriptionOffered: 'Monthly' | 'Annual';
    emailDomain?: string;
    emailDomainList?: string[];

    // Images
    heroImage: {
      sys: { id: string };
    };
    headerBackgroundImage: {
      sys: { id: string };
    };
    customLogoId?: {
      sys: { id: string };
    };
    stepSectionImageId?: {
      sys: { id: string };
    };
  };
}

interface ContentfulShineAtWorkFields {
  fields: {
    pageId: string;
    companyId: string;
    heroHeader: string;
    heroText: string;
    buttonText: string;
    buttonEyebrowText: string;
    emailDomainList?: string[];
    // Images
    heroImage: {
      sys: { id: string };
    };
    stripeCustomerId: string;
    subscriptionStartDate: string;
    companyLogo?: {
      sys: { id: string };
    };
    showMemberIdField: boolean;
    memberIdPlaceholder: string;
  };
}

interface ContentfulAsset {
  sys: { id: string };
  fields: {
    file: {
      url: string;
    };
  };
}

interface ContentfulPromoConfigResponseData {
  items: ContentfulPromoConfigFields[];
  includes: {
    Asset: ContentfulAsset[];
  };
}

interface ContentfulShineAtWorkResponseData {
  items: ContentfulShineAtWorkFields[];
  includes: {
    Asset: ContentfulAsset[];
  };
}

export const getPromoContent = async (urlSlug: string) => {
  // Retrieve promo content
  let { data } = await axios.get<ContentfulPromoConfigResponseData>(
    `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/entries?access_token=${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}&fields.pageId=${urlSlug}&content_type=promoConfig`
  );

  if (data.items.length < 1) {
    let customError = new Error(
      `Entry not found in Contentful for: ${urlSlug}`
    );
    throw customError;
  }
  // Extract image ids from response
  const imgIds = {
    heroImageId: data.items[0].fields.heroImage.sys.id,
    headerBackgroundImageId: data.items[0].fields.headerBackgroundImage.sys.id,
    customLogoId: pathOr(
      undefined,
      ['items', 0, 'fields', 'customLogo', 'sys', 'id'],
      data
    ),
    stepSectionImageId: pathOr(
      undefined,
      ['items', 0, 'fields', 'stepSectionImage', 'sys', 'id'],
      data
    ),
  };

  // Extract Asset array from response
  let assets = data.includes.Asset;
  // Extract Image Urls from response
  const { heroImage, headerBackgroundImage, stepSectionImage, customLogo } =
    extractImgURLs(imgIds, assets);

  // Check if promo offered applies to monthly or annual plan
  let planId;
  let subscriptionOffered;
  if (data.items[0].fields.subscriptionOffered === 'Monthly') {
    planId = process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID;
    subscriptionOffered = MONTHLY;
  } else {
    planId = process.env.REACT_APP_STRIPE_ANNUAL_PLAN_ID;
    subscriptionOffered = ANNUAL;
  }

  let promoContent = {
    isPromo: data.items[0].fields.isPromo,
    pageId: data.items[0].fields.pageId,
    couponId: data.items[0].fields.couponId,
    bannerCopy: data.items[0].fields.bannerCopy,
    heroHeader: data.items[0].fields.heroHeader,
    heroCopy: data.items[0].fields.heroCopy,
    buttonCopy: data.items[0].fields.buttonCopy,
    templateType: data.items[0].fields.templateType,
    discountedPrice: data.items[0].fields.discountedPrice,
    discountPercent: data.items[0].fields.discountPercent,
    discountFlatAmount: data.items[0].fields.discountFlatAmount,
    displayMonthlyPrice: data.items[0].fields.displayMonthlyPrice,
    originalPrice: data.items[0].fields.originalPrice,
    isExpired: data.items[0].fields.expired,
    theme: data.items[0].fields.theme,
    limitedTimeText: data.items[0].fields.limitedTimeText,
    enableCompassionQuiz: data.items[0].fields.enableCompassionQuiz,
    emailDomain: data.items[0].fields.emailDomain,
    emailDomainList: data.items[0].fields.emailDomainList,
    programSteps: data.items[0].fields.programSteps,
    stepSectionEyeBrowTitle: data.items[0].fields.stepSectionEyeBrowTitle,
    stepSectionTitle: data.items[0].fields.stepSectionTitle,
    subscriptionOffered,
    // Data is slightly different when retrieving it from Contentful
    heroImage: `https:${heroImage}`,
    headerBackgroundImage: `https:${headerBackgroundImage}`,
    stepSectionImage: stepSectionImage
      ? `https:${stepSectionImage}`
      : undefined,
    customLogo: customLogo ? `https:${customLogo}` : undefined,
    planId,
  };

  return promoContent;
};

export const getShineAtWorkContent = async (urlSlug: string) => {
  // Retrieve promo content
  const { data } = await axios.get<ContentfulShineAtWorkResponseData>(
    `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/entries?access_token=${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}&fields.pageId=${urlSlug}&content_type=shineAtWork`
  );

  if (data.items.length < 1) {
    let customError = new Error(
      `Entry not found in Contentful for: ${urlSlug}`
    );
    throw customError;
  }

  // // Extract image ids from response
  const imgIds = {
    heroImageId: data.items[0].fields.heroImage.sys.id,
    companyLogoId: pathOr(
      undefined,
      ['items', 0, 'fields', 'companyLogo', 'sys', 'id'],
      data
    ),
  };

  // Extract Asset array from response
  const assets = data.includes.Asset;
  // Extract Image Urls from response

  const { heroImage, companyLogo } = extractImgURLs(imgIds, assets);
  const shineAtWorkContent = {
    pageId: data.items[0].fields.pageId,
    companyId: data.items[0].fields.companyId,
    heroHeader: data.items[0].fields.heroHeader,
    heroText: data.items[0].fields.heroText,
    buttonText: data.items[0].fields.buttonText,
    buttonEyebrowText: data.items[0].fields.buttonEyebrowText,
    emailDomainList: data.items[0].fields.emailDomainList,
    stripeCustomerId: data.items[0].fields.stripeCustomerId,
    subscriptionStartDate: data.items[0].fields.subscriptionStartDate,
    showMemberIdField: data.items[0].fields.showMemberIdField,
    memberIdPlaceholder: data.items[0].fields.memberIdPlaceholder,
    companyLogo: companyLogo ? `https:${companyLogo}` : undefined,
    heroImage: `https:${heroImage}`,
    // Data is slightly different when retrieving it from Contentful
  };

  return shineAtWorkContent;
};

// Contentful Util
interface ImageIds {
  heroImageId: string;
  headerBackgroundImageId?: string;
  stepSectionImageId?: string;
  customLogoId?: string;
  companyLogoId?: string;
}
/**
 * Util function that:
 * 1) loops through array of assets from the response data
 * 2) validate we are extracting the correct img from the array by checking id
 * 3) extract the URLs & return them in an object
 */
const extractImgURLs = (ids: ImageIds, assets: ContentfulAsset[]) => {
  const {
    heroImageId,
    headerBackgroundImageId,
    stepSectionImageId,
    customLogoId,
    companyLogoId,
  } = ids;
  let urls = {
    heroImage: '',
    headerBackgroundImage: '',
    stepSectionImage: '',
    customLogo: '',
    companyLogo: '',
  };

  assets.forEach((asset) => {
    const assetId = asset.sys.id;
    const assetUrl = asset.fields.file.url;
    switch (assetId) {
      case heroImageId:
        urls.heroImage = assetUrl;
        break;
      case headerBackgroundImageId:
        urls.headerBackgroundImage = assetUrl;
        break;
      case stepSectionImageId:
        urls.stepSectionImage = assetUrl;
        break;
      case customLogoId:
        urls.customLogo = assetUrl;
        break;
      case companyLogoId:
        urls.companyLogo = assetUrl;
        break;
      default:
        break;
    }
  });

  return urls;
};
