import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

// ACTIONS
import { API_ACTIONS } from '../../utils/apiActions';
import { postUserActivity } from '../../utils/common';
import { cancelSubscription } from '../../actions/billing';

// TYPEDEFS
import { UserState } from '../../types/reducers/user-state';
import { SurveyConfig, SubmitFeedback } from '../../types/retention-survey';

// COMPONENTS
import TopNav from './partials/TopNav';
import Footer from './partials/Footer';
import {
  ButtonLink,
  Page,
  PortalBackground,
  PortalBlobTop,
  PortalBlobBottom,
} from './styles';
import { BackButton, SurveyWrapper } from '../retention-survey/styles';
import {
  SurveyPageP0,
  SurveyPageP1,
  SurveyPageP2,
  // SurveyPageP3,
  SurveyPageP4,
  SurveyPageP5,
  SurveyPageP6,
  SurveyPageE1,
  SurveyPageE2,
} from '../retention-survey/index';

interface Props extends PropsFromRedux {}

interface State {
  surveyKey: SurveyConfig;
}

class CancelPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      surveyKey: SurveyConfig.START,
    };
  }

  updateSurveyPage = (surveyKey: SurveyConfig) => {
    this.setState({ surveyKey: surveyKey });
  };

  // on submit feedback clicks that save answer and cancelSub (as needed) -> go to next page
  onSubmit = async ({
    question,
    answer,
    nextPage,
    cancelSub,
  }: SubmitFeedback) => {
    const { uid, cancelSubscription, subscriptionId } = this.props;
    try {
      if (cancelSub) {
        await cancelSubscription(subscriptionId);
      }
      if (answer) {
        await postUserActivity(API_ACTIONS.SAVE_FEEDBACK, {
          uid,
          question,
          answer,
        });
      }
      this.updateSurveyPage(nextPage);
    } catch (error) {
      console.error(error);
    }
  };

  // On cancel button clicks that just cancel subscription -> go to next page
  onCancel = async (nextPage: SurveyConfig) => {
    const { subscriptionId, cancelSubscription } = this.props;
    try {
      await cancelSubscription(subscriptionId);
      this.updateSurveyPage(nextPage);
    } catch (error) {
      console.error(error);
    }
  };

  renderSurveyPage = (surveyKey: SurveyConfig) => {
    switch (surveyKey) {
      case SurveyConfig.START:
        return <SurveyPageP0 updateSurveyPage={this.updateSurveyPage} />;
      case SurveyConfig.REASONS:
        return <SurveyPageP1 onSubmit={this.onSubmit} />;
      case SurveyConfig.REASON_EXPENSIVE:
        return (
          <SurveyPageP2
            updateSurveyPage={this.updateSurveyPage}
            onCancel={this.onCancel}
          />
        );
      case SurveyConfig.REASON_NOT_USING:
        return (
          <SurveyPageP2
            updateSurveyPage={this.updateSurveyPage}
            onCancel={this.onCancel}
          />
        );
      case SurveyConfig.REASON_NOT_ENOUGH_CONTENT:
        return (
          <SurveyPageP4
            updateSurveyPage={this.updateSurveyPage}
            onCancel={this.onCancel}
          />
        );
      case SurveyConfig.CANCELLED_GIVE_FEEDBACK:
        return <SurveyPageP5 onSubmit={this.onSubmit} />;
      case SurveyConfig.REASON_OTHER_GIVE_FEEDBACK:
        return <SurveyPageP6 onSubmit={this.onSubmit} />;
      case SurveyConfig.STAY_GIVE_FEEDBACK:
        return <SurveyPageE1 onSubmit={this.onSubmit} />;
      case SurveyConfig.FEEDBACK_SUBMITTED:
        return <SurveyPageE2 />;
      default:
        break;
    }
  };
  render() {
    return (
      <Page>
        <TopNav />
        <PortalBackground>
          <PortalBlobTop />
          <SurveyWrapper>
            <ButtonLink to="/subscription">
              <BackButton>{COPY.backButton}</BackButton>
            </ButtonLink>
            {this.renderSurveyPage(this.state.surveyKey)}
          </SurveyWrapper>
          <PortalBlobBottom />
          <Footer />
        </PortalBackground>
      </Page>
    );
  }
}

const COPY = {
  backButton: '< Back to Subscription',
};

const mapState = ({ user }: { user: UserState }) => {
  return {
    uid: user.userData.uid,
    subscriptionId: user.subscriptionData.subscription.subscription_id,
  };
};

const mapDispatch = {
  cancelSubscription,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CancelPage);
