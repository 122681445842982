import React from 'react';
import {
  SurveyContainer,
  SurveyTitle,
  CancelReasonButton,
  SurveyReasonText,
} from './styles';
import { SurveyConfig } from '../../types/retention-survey';

const SurveyPageP1 = (props) => (
  <SurveyContainer>
    <SurveyTitle>{COPY.title}</SurveyTitle>
    {COPY.cancelReasons.map((item) => (
      <CancelReasonButton
        key={item.description}
        onClick={() =>
          props.onSubmit({
            question: COPY.title,
            answer: item.description,
            nextPage: item.nextSurveyKey,
            cancelSub: false,
          })
        }
      >
        <SurveyReasonText className="cancel-reason">
          {item.description}
        </SurveyReasonText>
      </CancelReasonButton>
    ))}
  </SurveyContainer>
);

const COPY = {
  title:
    "Please tell us why you'd like to cancel your Shine Premium membership.",
  cancelReasons: [
    {
      description: 'The membership is too expensive',
      nextSurveyKey: SurveyConfig.REASON_EXPENSIVE,
    },
    {
      description: "I wasn't using it enough to justify paying",
      nextSurveyKey: SurveyConfig.REASON_NOT_USING,
    },
    {
      description: "The content doesn't speak to my needs",
      nextSurveyKey: SurveyConfig.REASON_NOT_ENOUGH_CONTENT,
    },
    {
      description: "There wasn't enough new content",
      nextSurveyKey: SurveyConfig.REASON_NOT_ENOUGH_CONTENT,
    },
    {
      description: 'Another reason',
      nextSurveyKey: SurveyConfig.REASON_OTHER_GIVE_FEEDBACK,
    },
  ],
};

export default SurveyPageP1;
