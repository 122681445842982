import React from 'react';
import styled from 'styled-components';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

// Assets
import { darkGreyBlob } from '../../constants/image.config';

// Components
import { BoldTitle, DefaultText } from './AboutPageStyles';

const Section = styled.section`
  background-color: ${COLORS.greyBlack};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  max-width: 710px;
  display: flex;
  flex-direction: column;
  margin: 210px 0 120px 0;

  align-self: center;
  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 85%
    margin: 60px 0 32px 0;
  }
`;

const Title = styled(BoldTitle)`
  width: 100%;
  margin: 0 0 44px 0;
  color: ${COLORS.white};

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin: 0 0 19px 0;
  }
`;

const Description = styled(DefaultText)`
  width: 100%;
  margin: 0 0 0 0;
  color: ${COLORS.white};
  font-size: 24px;
  z-index: 1;

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin: 0;
    font-size: 18px;
  }
`;

const DarkGreyBlob = styled.img.attrs({ src: darkGreyBlob })`
  align-self: flex-start;
  margin-top: -28.5%;
  width: 25vw;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 50vw;
    margin-top: -57%;
  }
`;

const BlackLivesMatterSection = () => (
  <Section id="blm">
    <TextWrapper>
      <Title>Black Lives Matter</Title>
      <Description>
        Shine is committed to fighting for Black lives and Black mental health.
        As a team that identifies as 30% Black, 80% BIPOC, and 100%
        antiracist—this isn’t a one-time campaign for us. It’s a lifelong
        commitment to stand with you and fight against racial injustice.
      </Description>
    </TextWrapper>
    <DarkGreyBlob />
  </Section>
);

export default BlackLivesMatterSection;
