import styled from 'styled-components';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';

export const CategoryTitle = styled.p`
  color: ${COLORS.darkMagentaPink};
  display: inline-block;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 110%;
  margin-bottom: 24px;
  text-align: left;
`;

export const Link = styled.a`
  color: ${COLORS.darkGrey};
  display: inline-block;
  text-decoration: none;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  font-size: 15px;
  text-align: left;

  &:hover {
    color: ${COLORS.darkMagentaPink};
    text-decoration: underline;
  }
`;
