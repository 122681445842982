import axios from 'axios';
import firebase from 'firebase';

export default class Profile {
  /**
   * Constructor
   *
   * @param {string} glowURL Base URL to Shine's Glow API
   */
  constructor(glowURL) {
    this.glowURL = glowURL;
  }

  /**
   * Changes the user's password.
   *
   * @param {string} newPassword User's proposed new password
   * @return {Promise.<void>}
   */
  async changePassword(newPassword) {
    return firebase.auth().currentUser.updatePassword(newPassword);
  }

  /**
   * Updates basic user profile info in Firebase and our own database.
   *
   * @param {object} profile Object of attributes to update
   * @param {string} profile.birthdate Birthday in format YYYY-MM-DD
   * @param {string} profile.email
   * @param {string} profile.giftCardNumber
   * @param {string} profile.firstName
   * @param {string} profile.lastName
   * @param {string} profile.phone Phone number must start with `+${country code}` and only contain numeric characters
   * @param {string} profile.photo URL of photo
   * @return {Promise.<object>} User's profile on success
   */
  async update({
    birthdate,
    email,
    firstName,
    giftCardNumber,
    lastName,
    phone,
    photo,
  }) {
    // Update user in Firebase
    if (email) {
      await firebase.auth().currentUser.updateEmail(email);
    }

    let firebaseProfile = {};
    if (firstName) {
      firebaseProfile.displayName = firstName;
    }
    if (photo) {
      firebaseProfile.photoURL = photo;
    }
    if (Object.keys(firebaseProfile).length > 0) {
      await firebase.auth().currentUser.updateProfile(firebaseProfile);
    }

    // Update user in Photon
    return await Profile.updateGlow(this.glowURL, {
      birthdate,
      email,
      firstName,
      giftCardNumber,
      lastName,
      phone,
      photo,
    });
  }

  /**
   * Helper method for doing the actual POST request to the glow API.
   *
   * @param {string} glowURL Glow API base url
   * @param {object} profile User profile
   * @return {Promise.<object>}
   */
  static async updateGlow(glowURL, profile) {
    try {
      // Convert to API's expected naming
      let payload = profile;
      if (profile.birthdate) {
        payload.birthday = profile.birthdate;
        delete payload.birthdate;
      }

      if (payload.firstName) {
        payload.first_name = profile.firstName;
        delete payload.firstName;
      }

      if (payload.lastName) {
        payload.last_name = profile.lastName;
        delete payload.lastName;
      }

      const idToken = await firebase.auth().currentUser.getIdToken();
      const result = await axios({
        method: 'post',
        url: `${glowURL}/profile`,
        headers: {
          'Id-Token': idToken,
        },
        data: payload,
      });

      if (result && result.data) {
        return Profile.transformResponse(result.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Gets a user's profile.
   *
   * @return {Promise.<object>}
   */
  async getProfile() {
    if (!firebase.auth().currentUser) {
      return null;
    }

    const idToken = await firebase.auth().currentUser.getIdToken();
    const result = await axios({
      method: 'get',
      url: `${this.glowURL}/profile`,
      responseType: 'json',
      headers: {
        'Id-Token': idToken,
      },
    });

    if (result && result.data) {
      return Profile.transformResponse(result.data.data);
    }
  }

  /**
   * Changes certain properties from snake case to camelcase for consistency of
   * properties passed in to update and returned in response.
   *
   * @param {object} profile
   * @return {object}
   */
  static transformResponse(profile) {
    if (profile.first_name) {
      profile.firstName = profile.first_name;
      delete profile.first_name;
    }

    if (profile.last_name) {
      profile.lastName = profile.last_name;
      delete profile.last_name;
    }

    if (profile.birthday) {
      profile.birthdate = profile.birthday;
      delete profile.birthday;
    }

    return profile;
  }
}
