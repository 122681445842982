import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

// Assets
import { modelLoungingImg, goldBlob } from '../../constants/image.config';

// Components
import { BoldTitle, DefaultText } from './AboutPageStyles';

const Image = styled.img.attrs({ src: modelLoungingImg })`
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 567px;
  min-height: 224px;
  margin-bottom: -5px;
`;

const Section = styled.section`
  background-color: ${COLORS.lightYellow};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const GoldBlob = styled.img.attrs({ src: goldBlob })`
  align-self: flex-start;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 40vw;
  }
`;

const TextWrapper = styled.div`
  max-width: 949px;
  width: 85%;
  display: flex;
  flex-direction: column;
  margin-top: -17%
  align-self: center;

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin-top: -19%
  }

`;

const Title = styled(BoldTitle)`
  width: 100%;
  margin: 0px 0 91px 0;
  color: ${COLORS.blackTwo};
  font-size: 32px;
  line-height: 160%;

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 22px;
    margin: 0px 0 60px 0;
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    margin: 0px 0 24px 0;
  }
`;

const BulletList = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 949px;
  margin-bottom: 108px;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-bottom: 60px;
  }
`;

const BulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  margin-bottom: 32px;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-bottom: 19px;
  }
`;

const BulletContent = styled(DefaultText)`
  width: 100%;
  font-size: 24px;
  line-height: 150%;
  color: ${COLORS.blackTwo};
  text-align: left;
  margin: 0;

  strong {
    color: ${COLORS.gold};
  }

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 16px;
  }
`;

const BulletPoint = styled.div`
  background-color: ${COLORS.gold};
  height: 10px;
  width: 11px;
  border-radius: 50px;
  margin: 0 33px 0 0;
  text-align: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    height: 7px;
    width: 8px;
    margin: 0 18px 0 0;
  }
`;

const SourceText = styled(BulletContent)`
  font-style: italic;
  font-size: 16px;
  margin-top: 32px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 12px;
    margin-top: 19px;
  }
`;

const SourceLink = styled.a`
  color ${COLORS.blackTwo};
  margin: 0 5px;

  &:hover {
    color: ${COLORS.darkGrey}
  }
`;

const DataSection = () => (
  <React.Fragment>
    <Fade triggerOnce>
      <Image />
    </Fade>
    <Section id="mental-health-data">
      <GoldBlob />
      <TextWrapper>
        <Title>
          We believe that fighting for the mental health of marginalized groups
          helps fight for their lives and rights—and it’s one of the most
          important ways to improve the global landscape of mental health.
        </Title>
        <BulletList>
          {BULLET_COPY.map((bullet, index) => (
            <BulletContainer>
              <BulletPoint />
              <BulletContent dangerouslySetInnerHTML={{ __html: bullet }} />
            </BulletContainer>
          ))}
          <SourceText>
            Sources:{' '}
            {SOURCES.map(({ name, link }) => {
              return (
                <React.Fragment key={name}>
                  <SourceLink
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {name},
                  </SourceLink>
                </React.Fragment>
              );
            })}
          </SourceText>
        </BulletList>
      </TextWrapper>
    </Section>
  </React.Fragment>
);

export default DataSection;

const BULLET_COPY = [
  'Women globally are <strong> affected to a greater extent </strong> by depression, anxiety, and psychological distress than men',
  'Black adults in the U.S. are <strong> more likely </strong> to report persistent psychological distress than white adults',
  'Lesbian, gay, and bisexual adults are <strong> 2x as likely </strong> to have a mental health condition as heterosexual adults',
  'Transgender individuals are <strong> nearly 4x </strong> as likely to experience a mental health condition as cisgender individuals',
  'Only <strong> 1 in 3 </strong> Black adults in the U.S. who need mental health care will receive it',
  'Only <strong> 33% </strong> of Latino adults with a mental illness are likely to get treatment',
  'Asian American adults are <strong> 3x less likely </strong> than white adults to seek treatment for mental health conditions',
  'In 2015, <strong> 86% </strong> of psychologists in the U.S. were white, while only 5% were Asian, 5% Hispanic, 4% Black, and 1% multiracial',
];

const SOURCES = [
  {
    name: 'World Health Organization',
    link: 'https://www.who.int/mental_health/prevention/genderwomen/en/',
  },
  {
    name: 'National Alliance on Mental Illness',
    link: 'https://www.nami.org/Your-Journey/Identity-and-Cultural-Dimensions',
  },
  {
    name: 'American Psychological Association',
    link: 'https://www.psychiatry.org/psychiatrists/cultural-competency/education/african-american-patients',
  },
  {
    name: 'Anxiety and Depression Association of America',
    link: 'https://adaa.org/finding-help/asian-americans#Facts',
  },
];
