import React, { Component } from 'react';
import styled from 'styled-components';
import SIZES from '../../../constants/screenSizes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
`;

const Form = styled.div`
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 450px;
  @media only screen and (max-width: ${SIZES.phone}) {
    width: 350px;
    margin-bottom: 20px;
  }
`;

class HubspotContactForm extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    // Pseudo-jQuery for Hubspot callbacks
    window.jQuery =
      window.jQuery ||
      (() => ({
        // these are all methods required by HubSpot
        change: () => {},
        trigger: () => {},
      }));

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '20041740',
          formId: '46bcdd78-24c0-4ec4-b70f-253b50219181',
          sfdcCampaignId: '7014x000000MH7lAAG',
          target: '#hubspotForm',
          onFormSubmit: function () {
            const formTopPosition =
              document.getElementById('b2b-form-container').offsetTop;
            window.scroll({
              top: formTopPosition,
              behavior: 'smooth',
            });
          },
        });
      }
    });
  }

  render() {
    return (
      <Container id="b2b-form-container">
        <Form id="hubspotForm"></Form>
      </Container>
    );
  }
}

export default HubspotContactForm;
