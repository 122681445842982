import React from 'react';
import { FooterSection, Line } from '../styles';
import { Footnotes } from '../../sharedPartials/footer/index';

const Footer = () => {
  return (
    <FooterSection>
      <Line />
      <Footnotes />
    </FooterSection>
  );
};

export default Footer;
