import styled from 'styled-components';
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';
import logo from '../../../assets/shine-logo.png';

export const Logo = styled.img.attrs({ src: logo })`
  display: flex;
  height: 60px;
  width: auto;
  margin: 1.5em 0;
  align-self: flex-start;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'default')};
  @media only screen and (min-width: ${SIZES.tablet}) {
    margin-top: 2em;
    margin-bottom: 2em;
    height: 90px;
    width: auto;
  }
`;

export const CustomLogo = styled(Logo)`
  margin-left: 25px;
  cursor: default;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${SIZES.tablet}) {
    justify-content: flex-start;
  }
`;

// STYLESHEETS
export const HeaderSectionWrapper = styled.div`
  background-color: ${COLORS.lightYellow};
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const HeaderTitle = styled.h1`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 110%;
  color: ${COLORS.darkMagentaPink};
  letter-spacing: 0.02em;
  width: 95%;
  text-align: center;
  margin: 0 0 15px;
  @media only screen and (min-width: ${SIZES.tablet}) {
    text-align: left;
    font-size: 60px;
  }
`;

export const HeaderDescription = styled.p`
  color: ${COLORS.darkMagentaPink};
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  font-size: 16px;
  width: 95%;
  text-align: center;
  margin-top: 0;
  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 100%;
    text-align: left;
    font-size: 20px;
  }
`;

export const HeaderSmallText = styled.p`
  color: ${COLORS.darkMagentaPink};
  font-family: Poppins;
  font-style: italic;
  font-weight: normal;
  line-height: 150%;
  font-size: 12px;
  width: 95%;
  text-align: center;
  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 100%;
    text-align: left;
    font-size: 14px;
    margin-top: 40px;
  }

  .cta-shine-at-work {
    color: ${COLORS.darkMagentaPink};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    max-width: 1345px;
    flex-direction: row;
  }
`;

export const HeaderInnerLeftContainer = styled.div`
  flex: 1;
  @media only screen and (min-width: ${SIZES.tablet}) {
    text-align: left;
  }
`;

export const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    padding-bottom: 40px;
    align-items: flex-start;
  }
`;

export const HeaderInnerRightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;

  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    align-self: flex-end;
    margin-left: 20px;
  }
`;

export const HeaderInnerRightFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  height: 625px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 45%;
    height: auto;
    justify-content: center;
  }
`;

export const HeroImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
  max-width: 548px;
  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    justify-content: center;
    max-width: 583px;
    object-fit: cover;
  }
`;

export const RedeemedText = styled(HeaderDescription)`
  font-style: italic;
  font-weight: bold;
  margin-top: 32px;
`;
