import * as TYPES from '../constants/actionTypes';
import { EnterpriseReducerAction } from '../types/reducers/enterprise-state';

const initialState = {
  buttonText: '',
  buttonEyebrowText: '',
  companyLogo: '',
  companyId: '',
  emailDomainList: [],
  heroHeader: '',
  heroImage: '',
  heroText: '',
  pageId: '',
  stripeCustomerId: '',
  subscriptionStartDate: '',
  memberId: '',
  showMemberIdField: false,
  memberIdPlaceholder: '',
  errorFetchingEnterpriseContent: false,
  errorRedeemingEnterpriseSubscription: false,
  errorMessage: '',
  isLoading: false,
};

export default function (
  state = initialState,
  action: EnterpriseReducerAction
) {
  switch (action.type) {
    case TYPES.GET_ENTERPRISE_CONTENT_REQUEST:
      return {
        ...state,
        errorFetchingEnterpriseContent: false,
      };
    case TYPES.GET_ENTERPRISE_CONTENT_SUCCESS:
      return {
        ...state,
        ...action.payload.enterpriseContent,
        errorFetchingEnterpriseContent: false,
      };
    case TYPES.GET_ENTERPRISE_CONTENT_FAIL:
      return {
        ...state,
        errorFetchingEnterpriseContent: true,
      };
    case TYPES.REDEEM_ENTERPRISE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        errorRedeemingEnterpriseSubscription: false,
        isLoading: true,
        errorMessage: '',
      };
    case TYPES.REDEEM_ENTERPRISE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        errorRedeemingEnterpriseSubscription: false,
        isLoading: false,
        errorMessage: '',
      };
    case TYPES.REDEEM_ENTERPRISE_SUBSCRIPTION_FAIL:
      return {
        ...state,
        errorRedeemingEnterpriseSubscription: true,
        isLoading: false,
        errorMessage: action.errorMessage,
      };
    case TYPES.SAVE_MEMBER_ID:
      return {
        ...state,
        memberId: action.payload.memberId,
      };
    default:
      return state;
  }
}
