/**
 * Util for Stripe calls shared between our frontend and backend code.
 */

export const API_ACTIONS = {
  // POST actions
  CREATE_CUSTOMER: 'createCustomer',
  SUBSCRIBE_USER: 'subscribeUser',
  CREATE_SUBSCRIPTION_SCHEDULE: 'createSubscriptionSchedule',
  UPDATE_SUBSCRIPTION_SCHEDULE: 'updateSubscriptionSchedule',
  RELEASE_SUBSCRIPTION_SCHEDULE: 'releaseSubscriptionSchedule',
  UPDATE_CUSTOMER: 'updateCustomer',
  UPDATE_SUBSCRIPTION_FROM_GIFTCARD: 'updateSubscriptionFromGiftCard',
  CANCEL_SUBSCRIPTION_AT_PERIOD_END: 'cancelSubscriptionAtPeriodEnd',

  // GET actions
  FIND_COUPON: 'findCouponById',
  FIND_SUBSCRIPTION_SCHEDULE: 'findSubscriptionSchedule',
  FIND_CUSTOMER_SUBSCRIPTIONS: 'findCustomerSubscriptions',
  FIND_CUSTOMERS: 'findCustomers',
};
