import * as TYPES from '../constants/actionTypes';
import { MOBILE_APP_ACCESS, MOBILE_APP_SUB_STATES } from '../constants/config';
import { UserState, UserReducerAction } from '../types/reducers/user-state';

/**
 * INITIAL STATE
 */
const initialState: UserState = {
  userData: {
    email: '',
    uid: '',
    firstName: '',
    providerId: '',
  },
  isAuthenticated: false,
  isAuthenticating: true,
  isProtected: true,
  isLoading: false,
  errorMsg: '', // string
  subscriptionData: {
    platform: '',
    subscription: {
      subscription_status: MOBILE_APP_SUB_STATES.TRIAL_ELIGIBLE,
      payment_plan: '', // string
      access_type: MOBILE_APP_ACCESS.FREE,
      exp_date: '', // number
      stripe_id: '',
      subscription_id: '',
    },
  },
};

/**
 * REDUCER
 */
export default function (state = initialState, action: UserReducerAction) {
  switch (action.type) {
    case TYPES.AUTH_APPLE_LOGIN_REQUEST:
    case TYPES.AUTH_FACEBOOK_LOGIN_REQUEST:
    case TYPES.AUTH_LOGIN_REQUEST:
    case TYPES.AUTH_SIGNUP_REQUEST:
    case TYPES.REDEEM_GIFT_SUBSCRIPTION_REQUEST:
    case TYPES.AUTH_REAUTHENTICATE_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        isProtected: true,
        isLoading: true,
      };

    case TYPES.AUTH_APPLE_LOGIN_SUCCESS:
    case TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS:
    case TYPES.AUTH_LOGIN_SUCCESS:
    case TYPES.AUTH_SIGNUP_SUCCESS:
    case TYPES.AUTH_REAUTHENTICATE_SUCCESS:
      return {
        ...state,
        userData: { ...action.user },
        isAuthenticating: false,
        isAuthenticated: true,
        isProtected: false,
      };
    case TYPES.AUTH_APPLE_LOGIN_FAIL:
    case TYPES.AUTH_FACEBOOK_LOGIN_FAIL:
    case TYPES.AUTH_LOGIN_FAIL:
    case TYPES.AUTH_SIGNUP_FAIL:
    case TYPES.AUTH_FAIL:
    case TYPES.REDEEM_GIFT_SUBSCRIPTION_FAILED:
    case TYPES.AUTH_REAUTHENTICATE_FAIL:
    case TYPES.AUTH_DISPLAY_ERROR_STATE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        isProtected: true,
        isLoading: false,
        errorMsg: action.errorMsg,
      };
    case TYPES.AUTH_LOGOUT_SUCCESS:
      return { ...initialState, isAuthenticating: false };
    case TYPES.AUTH_CLEAR_ERROR_STATE:
      return {
        ...state,
        errorMsg: '',
      };
    case TYPES.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscriptionData: action.subscriptionData,
      };
    case TYPES.SAVE_SUBSCRIPTION_DATA:
      return {
        ...state,
        subscriptionData: action.subscriptionData,
      };
    case TYPES.GET_SUBSCRIPTION_FAIL:
      return {
        ...state,
        errorMsg: action.errorMsg,
        isLoading: false,
      };
    case TYPES.SAVE_EMAIL_FROM_URL:
      return {
        ...state,
        userData: {
          ...state.userData,
          email: action.email,
        },
      };
    default:
      return state;
  }
}
