import React, { useState } from 'react';
import { sendPasswordResetEmail } from '../../../actions/auth';
import {
  SectionContainer,
  SectionHeader,
  FieldsOneColumn,
  Title,
  Label,
  UpdatedText,
  GreenButton,
  LockIcon,
} from '../styles';

const PasswordSection = ({ email }) => {
  const [showUpdateMessage, setshowUpdateMessage] = useState(false);

  const handlePasswordReset = (email) => {
    try {
      sendPasswordResetEmail(email);
      setshowUpdateMessage(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SectionContainer>
      <SectionHeader>
        <Title>{COPY.title}</Title>
      </SectionHeader>
      <FieldsOneColumn>
        <Label>{COPY.description}</Label>
        <GreenButton onClick={() => handlePasswordReset(email)}>
          <LockIcon />
          {COPY.buttonText}
        </GreenButton>
        {showUpdateMessage ? <UpdatedText>{COPY.emailSent}</UpdatedText> : null}
      </FieldsOneColumn>
    </SectionContainer>
  );
};

const COPY = {
  title: 'Password',
  description:
    'Forgot your password? We got you. We’ll send you an email to reset it.',
  buttonText: 'Reset Password',
  emailSent: 'Reset Password email has been sent',
};

export default PasswordSection;
