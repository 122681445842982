import React, { Component } from 'react';

// Page Components
import { LogoHeader } from '../sharedPartials';
// Form Components
import FormHandler from './FormHandler';
import FormFooter from './FormFooter';
// StyleSheets
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
} from '../checkout/styles';
import { FormContainer } from './FormStyles';
import { authMethods } from '../../constants/config';
import {
  DEFAULT_LOGIN_BUTTON_COPY,
  DEFAULT_LOGIN_FB_BUTTON_COPY,
  DEFAULT_LOGIN_APPLE_BUTTON_COPY,
  SWITCH_TO_SIGNUP,
} from '../../constants/sharedCopy';

/**
 * COMPONENT
 */
class LoginPage extends Component {
  render() {
    const buttonText = {
      formSubmitButton: DEFAULT_LOGIN_BUTTON_COPY,
      fbButton: DEFAULT_LOGIN_FB_BUTTON_COPY,
      appleButton: DEFAULT_LOGIN_APPLE_BUTTON_COPY,
    };
    return (
      <CheckoutBackground>
        <CheckoutBlobTop />
        <CheckoutPageContainer>
          <LogoHeader />
          <FormContainer>
            <FormHandler
              authType={authMethods.LOGIN}
              buttonCopy={buttonText}
              switchAuthCopy={SWITCH_TO_SIGNUP}
            />
          </FormContainer>
          <FormFooter />
        </CheckoutPageContainer>
        <CheckoutBlobBottom />
      </CheckoutBackground>
    );
  }
}

export default LoginPage;
