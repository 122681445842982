import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';
import { Section, Subtitle, TwoColumnContainer } from '../B2BStyles';
import {
  leftBlob,
  rightBlob,
  sdcLogo,
  fbLogo,
  targetLogo,
} from '../../../constants/image.config';

const BlobWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: ${COLORS.darkModerateCyan};
  background-image: url(${leftBlob});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 15%;
  @media only screen and (max-width: ${SIZES.phone}) {
    background-size: 25%;
  }
`;

const TealSection = styled(Section)`
  margin: 50px;
  @media only screen and (max-width: ${SIZES.desktop}) {
    margin: 25px;
  }
`;

const BlobRight = styled.img.attrs({ src: rightBlob })`
  position: absolute;
  right: 0;
  max-height: 300px;
  @media only screen and (max-width: ${SIZES.phone}) {
    max-height: 200px;
  }
`;

const WhiteSubtitle = styled(Subtitle)`
  color: ${COLORS.white};
  max-width: 700px;
  z-index: 1;
`;

const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  margin-left: 25px;
  width: 45%;

  @media only screen and (max-width: ${SIZES.desktop}) {
    margin: 20px 0 0 0;
    width: 75%;
  }
`;

const LogoIcon = styled.img`
  height: 100px;
  width: auto;
  margin: 0 2%;

  @media only screen and (max-width: ${SIZES.desktop}) {
    height: 80px;
  }
`;

const LogosSection = () => (
  <BlobWrapper>
    <TealSection>
      <TwoColumnContainer>
        <WhiteSubtitle>{COPY.title}</WhiteSubtitle>
        <LogosContainer>
          {COMPANY_LOGOS.map(({ name, img, altText }) => (
            <LogoIcon key={name} src={img} id={name} alt={altText} />
          ))}
        </LogosContainer>
      </TwoColumnContainer>
    </TealSection>
    <BlobRight />
  </BlobWrapper>
);

const COPY = {
  title:
    'The heart and soul of the most inclusive workplaces begin with Shine at Work',
};

const COMPANY_LOGOS = [
  {
    name: 'Smile Direct Club',
    img: sdcLogo,
    altText: 'Smile Direct Club logo',
  },
  { name: 'Facebook', img: fbLogo, altText: 'Facebook logo' },
  { name: 'Target', img: targetLogo, altText: 'Target logo' },
];
export default LogosSection;
