/*
 *
 * Billing reducer
 *
 */

import { DEFAULT_ACTION, SELECT_PAYMENT_PLAN } from '../constants/actionTypes';
import {
  ANNUAL,
  MONTHLY,
  ANNUAL_PRICE,
  MONTHLY_PRICE,
  createFinePrint,
  createCheckoutModalCopy,
} from '../constants/sharedCopy';

/**
 * @typedef BillingState
 * @property {string} sourceTokem
 * @property {import('./typedefs').PromoPlan |
 * import('./typedefs').DefaultPlan} selectedPlan
 * @property {import('./typedefs').Plans} plans
 */

/**
 * @type {BillingState}
 */
const initialState = {
  sourceToken: '',
  selectedPlan: {
    billingCycle: ANNUAL,
    cost: ANNUAL_PRICE,
    id: process.env.REACT_APP_STRIPE_ANNUAL_PLAN_ID,
    finePrint: createFinePrint(ANNUAL_PRICE, ANNUAL),
    checkoutModalCopy: createCheckoutModalCopy(ANNUAL_PRICE, ANNUAL),
    type: 'default',
  },
  plans: {
    annual: {
      billingCycle: ANNUAL,
      cost: ANNUAL_PRICE,
      id: process.env.REACT_APP_STRIPE_ANNUAL_PLAN_ID,
      finePrint: createFinePrint(ANNUAL_PRICE, ANNUAL),
      checkoutModalCopy: createCheckoutModalCopy(ANNUAL_PRICE, ANNUAL),
      type: 'default',
    },
    monthly: {
      billingCycle: MONTHLY,
      cost: MONTHLY_PRICE,
      id: process.env.REACT_APP_STRIPE_MONTHLY_PLAN_ID,
      finePrint: createFinePrint(MONTHLY_PRICE, MONTHLY),
      checkoutModalCopy: createCheckoutModalCopy(MONTHLY_PRICE, MONTHLY),
      type: 'default',
    },
  },
};

/**
 * Format promo/partner data for billing page and stripe checkout
 * NOTE: currently assuming that ALL promoted plans are for annual subscriptions
 * For future features, it is worth it to make a distinction here.
 * @param {object} data
 */
function formatPromoData(data) {
  const {
    couponId,
    originalPrice,
    discountedPrice,
    planId,
    subscriptionOffered,
    discountPercent,
    discountFlatAmount,
  } = data;
  const includePromo = couponId;
  // TODO: If we decide to use these util funcs to generate billing fine print for promos, we need to revisit the logic to account for promos on monthly subsscriptions.
  const finePrint = createFinePrint(
    discountedPrice,
    subscriptionOffered,
    includePromo
  );
  const checkoutModalCopy = createCheckoutModalCopy(
    discountedPrice,
    subscriptionOffered
  );
  return {
    cost: discountedPrice,
    originalCost: originalPrice,
    finePrint,
    checkoutModalCopy,
    couponId,
    type: 'promo',
    id: planId,
    billingCycle: subscriptionOffered,
    discountPercent,
    discountFlatAmount,
  };
}

function billingReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case SELECT_PAYMENT_PLAN:
      const {
        data: { planName, promoContent },
      } = action;
      // Check if plan name exists in promoData config object
      // and set as selected plan and update
      if (promoContent) {
        return {
          ...state,
          selectedPlan: formatPromoData(promoContent),
          plans: {
            ...state.plans,
            promo: formatPromoData(promoContent),
          },
        };
      }
      return { ...state, selectedPlan: state.plans[planName] };
    default:
      return state;
  }
}

export default billingReducer;
