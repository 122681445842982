import React from 'react';
import styled from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

// Assets
import {
  expertBg,
  expertBgMobile,
  yellowBlobs,
  elishaWebp,
  elisha,
  jamilaWebp,
  jamila,
  jenWebp,
  jen,
  jezzWebp,
  jezz,
  jorWebp,
  jor,
  joyWebp,
  joy,
} from '../../constants/image.config';

// Components
import { BoldSubtitle } from '../homepage/HomepageStyles';
import PeopleList from './PeopleList';

// Utils
import { useWindowWidth } from '../../utils/common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${COLORS.lightYellow};
`;

const BGImage = styled.img`
  width: 100%;
  object-fit: cover;
  @media only screen and (min-width: ${SIZES.phone}) {
    flex-direction: row;
    max-height: 800px;
  }
`;

const ExpertContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: center;
  background-image: url(${yellowBlobs});
  background-repeat: no-repeat;
  background-size: contain;
  @media only screen and (min-width: ${SIZES.phone}) {
    width: 80%;
    background-color: ${COLORS.white};
    justify-content: flex-start;
    /* Overlap container and bg image on desktops */
    margin-top: -70px;
    border-radius: 22px;
    margin-bottom: 115px;
  }
  @media only screen and (min-width: ${SIZES.desktop}) {
    width: 952px;
    border-radius: 32px;
  }
`;

const Title = styled(BoldSubtitle)`
  text-align: center;
  font-size: 28px;
  line-height: 120%;
  margin-top: 60px;
  margin-bottom: 32px;
  @media only screen and (min-width: ${SIZES.phone}) {
    max-width: 550px;
    margin-bottom: 40px;
  }
`;

const HostsSection = () => {
  const expertBgURL =
    useWindowWidth() >= `${SIZES.phoneNoPx}` ? expertBg : expertBgMobile;
  return (
    <Container id="experts">
      <BGImage src={expertBgURL} alt={COPY.expertBgAlt} />
      <ExpertContainer>
        <Title>{COPY.title}</Title>
        <PeopleList list={HOSTS} />
      </ExpertContainer>
    </Container>
  );
};
const COPY = {
  title: 'Learn from teachers and therapists you can relate to.',
  expertBgAlt: 'Black woman lounging with phone on a couch',
};

const HOSTS = [
  {
    img: joyWebp,
    fallback: joy,
    name: 'Joy Ofodu',
    bio: 'Daily Shine Host',
  },
  {
    img: jamilaWebp,
    fallback: jamila,
    name: 'Jamila Reddy',
    bio: 'Meditation Host',
  },
  {
    img: jorWebp,
    fallback: jor,
    name: 'Jor-El Caraballo, L.M.H.C.',
    bio: 'Therapist',
  },
  {
    img: elishaWebp,
    fallback: elisha,
    name: 'Elisha Mudly, L.M.S.W.',
    bio: 'Mindfulness Teacher',
  },
  {
    img: jenWebp,
    fallback: jen,
    name: 'Jen Kluczkowski',
    bio: 'Yoga & Meditation Teacher, Holistic Health Coach',
  },
  {
    img: jezzWebp,
    fallback: jezz,
    name: 'Jezz Chung',
    bio: 'DE&I Facilitator & Leader',
  },
];

export default HostsSection;
