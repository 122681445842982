import React from 'react';
import styled from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import { NON_PROMO_CTA_LINK } from '../../constants/config';

// Assets
import communityImg from '../../assets/images/homepage/community-image.png';
import communityImgWebp from '../../assets/images/homepage/community-image.webp';

// Components
import { BoldSubtitle, DefaultText, Link } from '../homepage/HomepageStyles';
import { YellowButton } from '../../constants/styles/buttons';

// Utils
import { ImgWithFallback } from '../../utils/common';

const Section = styled.div`
  background-color: ${COLORS.lightYellow};
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  overflow: hidden;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  @media only screen and (min-width: ${SIZES.iphone5sWidthMax}) {
    max-height: 800px;
  }
  @media only screen and (min-width: ${SIZES.phoneMin}) {
    max-height: 725px;
  }

  @media only screen and (min-width: ${SIZES.phone}) {
    flex-direction: row;
    width: 1345px;
    justify-content: center;
    max-height: 675px;
    align-items: flex-start;
  }
`;

const Image = styled(ImgWithFallback)`
  height: 436px;
  /* width: 360.44px; */
  align-self: center;
  @media only screen and (min-width: ${SIZES.phone}) {
    margin-bottom: -80px;
  }
  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    height: 852px;
    margin-bottom: -200px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${SIZES.phone}) {
    width: 349px;
    flex: 0.5;
    align-items: center;
    align-self: center;
  }
`;

const Title = styled(BoldSubtitle)`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  text-align: left;
  margin-bottom: 10px;
  @media only screen and (min-width: ${SIZES.phone}) {
    width: 349px;
    margin-bottom: 17px;
    font-size: 40px;
  }
`;

const Description = styled(DefaultText)`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 190%;
  text-align: left;
  margin-bottom: 16px;
  margin-top: 10px;
  color: ${COLORS.darkGrey};
  @media only screen and (min-width: ${SIZES.phone}) {
    width: 349px;
    margin-top: 17px;
    margin-bottom: 17px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: ${SIZES.phone}) {
    width: 349px;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const CommunitySection = () => (
  <Section>
    <Container>
      <Wrapper>
        <Title>You don’t have to go through this alone.</Title>
        <Description>
          Connect with a supportive and inclusive community to get advice and
          support.
        </Description>
        <ButtonWrapper>
          <Link to={NON_PROMO_CTA_LINK} className="community-cta">
            <YellowButton>Get Started</YellowButton>
          </Link>
        </ButtonWrapper>
      </Wrapper>
      <Image
        src={communityImgWebp}
        fallback={communityImg}
        altText="The Shine app community discussion feature"
      ></Image>
    </Container>
  </Section>
);

export default CommunitySection;
