import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  SendGiftPage,
  AccountPage,
  SubscriptionPage,
  EventsPage,
  CancelPage,
  ResubscribeConfirmation,
} from '../index';
import { reAuthCurrentUser } from '../../actions/auth';
import { getSubscription } from '../../actions/subscription';
import { PageLoading } from '../sharedPartials';

class ProtectedRoutes extends Component {
  async componentWillMount() {
    if (!this.props.isAuthenticated) {
      await this.props.reAuthCurrentUser();
      await this.props.getSubscription();
    }
  }

  render() {
    const { isAuthenticated, isAuthenticating } = this.props;
    let routes = [];
    if (isAuthenticated) {
      routes = (
        <Switch>
          {/* Routes placed here are only available after logging in */}
          <Route exact path="/send-gift" component={SendGiftPage} />
          <Route path="/account" component={AccountPage} />
          <Route path="/subscription" component={SubscriptionPage} />
          <Route path="/events" component={EventsPage} />
          <Route path="/cancel" component={CancelPage} />
          <Route
            path="/resubscribe-confirmation"
            component={ResubscribeConfirmation}
          />
        </Switch>
      );
    } else if (isAuthenticating) {
      routes = <PageLoading />;
    } else {
      routes = <Redirect to="/" />;
    }

    return routes;
  }
}

/**
 * CONTAINER
 */
const mapState = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    isAuthenticating: state.user.isAuthenticating,
  };
};

const mapDispatch = {
  reAuthCurrentUser,
  getSubscription,
};

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(connect(mapState, mapDispatch)(ProtectedRoutes));

/**
 * PROP TYPES
 */
ProtectedRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  reAuthCurrentUser: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
};
