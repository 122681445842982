import styled from 'styled-components';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import {
  blobTop,
  blobBottom,
  pitchPageModel,
} from '../../constants/image.config';

// Main Checkout Page background
export const CheckoutBackground = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: ${COLORS.paleGoldenYellow};
  align-content: center;
  justify-content: space-between;
  overflow-x: hidden;
`;

export const CheckoutBlobTop = styled.img.attrs({ src: blobTop })`
  align-self: flex-start;
  width: 25vw;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 33vw;
  }
`;

export const CheckoutBlobBottom = styled.img.attrs({ src: blobBottom })`
  align-self: flex-end;
  margin-top: -21.5%;
  width: 25vw;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 33vw;
    margin-top: -30%;
  }
`;

export const CheckoutPageContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: -21.5%;
  z-index: 10;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-top: -28%;
  }
`;

export const CheckoutNotifyContainer = styled.div`
  margin-bottom: 48px;
`;

// Pitch Page Styles
export const PitchPageContainer = styled.div`
  width: 90%
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 95%;
    flex-direction: column;
  }
`;

export const PitchPageImage = styled.img.attrs({ src: pitchPageModel })`
  width: 40%;
  height: 679px;
  max-width: 536px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  object-fit: cover;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 95%;
    height: 200px;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    align-self: center;
  }
`;

export const PitchContentWrapper = styled.div`
  width: 60%;
  height: 679px;
  max-width: 664px;
  background: ${COLORS.white};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 95%;
    height: auto;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    align-self: center;
    max-width: 536px;
  }
`;
export const PitchContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 79px 56px 48px 56px;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin: 40px 12px;
  }
`;

export const PitchTitle = styled.h1`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: ${COLORS.darkMagentaPink};
  text-align: left;
  width: 80%;
  margin: 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    text-align: center;
    font-size: 20px;
    line-height: 130%;
    width: 100%;
  }
`;

export const PitchBulletWrapper = styled.div`
  margin: 56px 0 16px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin: 32px 0 16px 0;
  }
`;

export const PitchBulletContainer = styled.section`
  display: flex;
  flex-direction: row;
`;

export const PitchBulletIcon = styled.img`
  width: 57.13px;
  height: 52.57px;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 48.15px;
    height: 44px;
  }
`;

export const PitchBulletContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-left: 3.85px;
  }
`;

export const PitchBulletSubtitle = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 110%;
  color: ${COLORS.darkMagentaPink};
  text-align: left;
  margin: 0 0 10px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 16px;
    margin: 0 0 8px 0;
  }
`;

export const PitchBulletDescription = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: ${COLORS.darkGrey};
  text-align: left;
  margin: 0 0 32px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 14px;
  }
`;
