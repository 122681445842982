import React from 'react';
import styled from 'styled-components';

// Constants
import { FOOTER_PAGES } from '../../../constants/config';
import SIZES from '../../../constants/screenSizes';

// Components
import { CategoryTitle, Link } from './styles';

const Container = styled.div`
  display: flex;
  @media only screen and (max-width: ${SIZES.tablet}) {
    flex-wrap: wrap;
  }
`;

const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0.2;
  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 50%;
  }
`;

const Navigation = () => {
  return (
    <Container>
      {FOOTER_PAGES.NAVIGATION_SECTION.map((group) => {
        return (
          <VerticalContainer key={group.title}>
            <CategoryTitle>{group.title}</CategoryTitle>
            {group.pages.map((page) => (
              <Link
                style={{ marginBottom: '12px' }}
                key={page.text}
                href={page.link}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                {page.text}
              </Link>
            ))}
          </VerticalContainer>
        );
      })}
    </Container>
  );
};

export default Navigation;
