import { SAVE_EMAIL_FROM_URL, SAVE_UTM_PARAMS } from '../constants/actionTypes';

/**
 * Save utm parameters as an object
 * @param {object} UTMParams object
 * @return {object} UTMParams object
 */
export const saveUTMParams = (UTMParams) => {
  return { type: SAVE_UTM_PARAMS, payload: UTMParams };
};

/**
 * Save email string from url
 * @param {string} email
 * @return {string} email
 */
export const saveEmailFromUrl = (email) => {
  return {
    type: SAVE_EMAIL_FROM_URL,
    email: email,
  };
};
