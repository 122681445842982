import React from 'react';
import {
  BenefitBulletText,
  BoldLabel,
  BenefitsWrapper,
  BenefitBulletContainer,
  BenefitBullet,
} from '../styles';

const BenefitsList = () => {
  return (
    <BenefitsWrapper>
      <BoldLabel>{COPY.benefitTitle}</BoldLabel>
      {COPY.benefits.map((benefit, index) => {
        return (
          <BenefitBulletContainer key={index}>
            <BenefitBullet />
            <BenefitBulletText>{benefit}</BenefitBulletText>
          </BenefitBulletContainer>
        );
      })}
    </BenefitsWrapper>
  );
};

const COPY = {
  benefitTitle: 'With Shine Premium, you get access to:',
  benefits: [
    'A library of 1,000+ meditations, with new meditations added weekly',
    'Monthly community care virtual events with mental health experts',
    "A daily mood check-in and calendar, with personalized meditations to how you're feeling",
    'A private digital community for 1:1 support',
  ],
};

export default BenefitsList;
