import React from 'react';
import styled from 'styled-components';
import { AttentionSeeker } from 'react-awesome-reveal';

// Constants
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import SIZES from '../../../constants/screenSizes';
import { Section, Subtitle, Title } from '../B2BStyles';

const YellowSection = styled(Section)`
  background: ${COLORS.lightYellow};
  flex-direction: column;
  padding: 50px;
  z-index: 1;
  @media only screen and (max-width: ${SIZES.tablet}) {
    padding: 25px;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 40px 0 0 0;
  @media only screen and (max-width: ${SIZES.tablet}) {
    flex-direction: column;
    align-items: center;
  }
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  width: 32%;
  margin: 0 2%;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 100%;
    margin-bottom: 50px;

    :nth-child(3) {
      margin-bottom: 0px;
    }
  }
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 15px;
`;

const StatText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-size: 18px;
  font-weight: 300;
  color: ${COLORS.darkMagentaPink};
  line-height: 140%;
  margin: 0;
`;

const StatNumber = styled(StatText)`
  font-size: 78px;
  font-weight: bold;
  line-height: 90%;
  color: ${COLORS.gold};

  .percent {
    font-size: 48px;
    line-height: 120%;
  }

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 48px;

    .percent {
      font-size: 28px;
    }
  }
`;

const ImpactSection = () => (
  <YellowSection>
    <Title>{COPY.title}</Title>
    <Subtitle>{COPY.description}</Subtitle>
    <StatsWrapper>
      {COPY.stats.map(({ statNumber, statText }) => (
        <StatContainer>
          <AttentionSeeker effect="pulse" triggerOnce cascade>
            <NumberContainer>
              <StatNumber dangerouslySetInnerHTML={{ __html: statNumber }} />
            </NumberContainer>
          </AttentionSeeker>
          <StatText>{statText}</StatText>
        </StatContainer>
      ))}
    </StatsWrapper>
  </YellowSection>
);

const COPY = {
  title: 'Proven Impact',
  description:
    'Rooted in Acceptance Commitment Therapy (ACT), Shine will help you manage negative thoughts, stress, and more at work.',

  stats: [
    {
      statNumber: '85<span class="percent">%</span>',
      statText:
        ' of users reported that Shine at Work has helped them better care for their mental health',
    },
    {
      statNumber: '79<span class="percent">%</span>',
      statText:
        'of users reported that Shine at Work has helped them better manage stress in their workplace',
    },
    {
      statNumber: '98<span class="percent">%</span>',
      statText: 'of Shine at Work Admins would recommend Shine at Work',
    },
  ],
};

export default ImpactSection;
