import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  SectionContainer,
  SectionHeader,
  FieldsTwoColumn,
  FieldItem,
  Title,
  Label,
  Data,
  EmailIcon,
  FacebookIcon,
  AppleIcon,
  IconWrapper,
} from '../styles';
class ProfileSection extends Component {
  showAccountType() {
    const { providerId } = this.props;

    if (providerId === 'password') {
      return <EmailIcon />;
    }
    if (providerId === 'facebook.com') {
      return <FacebookIcon />;
    }
    if (providerId === 'apple.com') {
      return <AppleIcon />;
    } else return;
  }

  // Adding this here because signing in with Facebook/Apple shows full name
  showNameLabel() {
    const { providerId } = this.props;
    if (providerId !== 'password') {
      return <Label>{COPY.nameLabel}</Label>;
    } else return <Label>{COPY.fNameLabel}</Label>;
  }

  render() {
    const { email, firstName } = this.props;
    return (
      <SectionContainer>
        <SectionHeader>
          <Title>{COPY.title}</Title>
        </SectionHeader>
        <FieldsTwoColumn>
          <FieldItem>
            <Label style={{ marginBottom: '0px' }}>{COPY.emailLabel}</Label>
            <IconWrapper>
              {this.showAccountType()}
              <Data>{email}</Data>
            </IconWrapper>
          </FieldItem>
          <FieldItem>
            {this.showNameLabel()}
            <Data>{firstName}</Data>
          </FieldItem>
        </FieldsTwoColumn>
      </SectionContainer>
    );
  }
}

const COPY = {
  title: 'Account Details',
  emailLabel: 'Email',
  fNameLabel: 'First Name',
  nameLabel: 'Name',
};

const mapState = ({ user }) => {
  return {
    email: user.userData.email,
    providerId: user.userData.providerId,
  };
};

export default connect(mapState, null)(ProfileSection);
