import React from 'react';
import PropTypes from 'prop-types';

import { SmallText, StyledLink } from './FormStyles';

const SignupOrLogin = ({ copy: { link, body, cta } }) => {
  return (
    <div style={{ marginTop: '20px' }}>
      <SmallText>
        {body}
        <StyledLink to={link}>{cta}</StyledLink>
      </SmallText>
    </div>
  );
};

export default SignupOrLogin;

/**
 * PROP TYPES
 * */
SignupOrLogin.propTypes = {
  copy: PropTypes.shape({
    link: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
  }),
};
