import mixpanel from 'mixpanel-browser';

export const trackEvent = (object, action, props) => {
  mixpanel.track(`${object} ${action}`, props);
};

// Identify user on signin
export const identifyUser = id => {
  mixpanel.identify(id);
};

// Alias user on signup using firebase uid
export const aliasUser = firebaseId => {
  mixpanel.alias(firebaseId);
};

export const initializeMixpanel = () => {
  try {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  } catch (err) {
    if (err.name === 'URIError') {
      window.location.replace(encodeURI(window.location.href));
    }
  }
};
