import React from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import { ImgWithFallback } from '../../utils/common';

// Assets
import {
  marahNaomi,
  marahNaomiWebp,
  MNAutograph,
} from '../../constants/image.config';

// Components
import { BoldTitle, DefaultText } from './AboutPageStyles';

const Section = styled.section`
  display: flex;
  flex-direction: row;
  background: ${COLORS.lightYellow};

  @media only screen and (max-width: ${SIZES.desktop}) {
    flex-direction: column-reverse;
  }
`;

const MarahNaomi = styled(ImgWithFallback)`
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 748px;
  min-height: 748px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    min-height: 224px;
    width: 200%;
`;

const TextContainer = styled.div`
  width: 50%;
  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 85%;
    align-self: center;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 5% 34px 8%;

  @media only screen and (max-width: ${SIZES.desktop}) {
    margin: 60px 0 24px 0;
  }
`;

const Title = styled(BoldTitle)`
  max-width: 710px;
  margin: 0 0 28px 0;
  font-size: 28px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 100%
    margin: 0 0 19px 0;
  }
`;

const Description = styled(DefaultText)`
  max-width: 710px;
  margin: 0;
  color: ${COLORS.darkMagentaPink};
  font-size: 16px;
  line-height: 150%;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 100%;
  }
`;

const Autograph = styled.img.attrs({ src: MNAutograph })`
  width: 289px;
  margin-top: 24px;
`;

const Header = () => (
  <Section>
    <MarahNaomi
      src={marahNaomiWebp}
      fallback={marahNaomi}
      altText={COPY.altText}
      styles={{ width: '50%' }}
    />
    <TextContainer>
      <TextWrapper>
        <Title>{COPY.title}</Title>
        <Description dangerouslySetInnerHTML={{ __html: COPY.letter }} />
        <Autograph />
      </TextWrapper>
    </TextContainer>
  </Section>
);

export default Header;

const COPY = {
  altText:
    'Shine at Work co-founders Marah Lidey, a Black woman, and Naomi Hirabayashi, a half-Japanese woman, smiling at the camera',
  title: 'We created Shine for all of us.',
  letter: `We’re on a mission to make caring for your mental and emotional health easier, more representative, and more inclusive—of <i> all </i> of our experiences.
  <br/><br/>
  We started Shine because we didn’t see ourselves—a Black woman and a half-Japanese woman—and our experiences represented in mainstream “wellness.” Our bodies, our skin color, our financial access, our past traumas—it all often felt otherized.
  <br/><br/>
  We met as coworkers, and the support we found in each other changed <b> everything </b>. We knew we had to help more people cope, process, and heal on a daily basis in a way that worked for them.
  <br/><br/>
  That’s what we hope Shine does for you.
  <br/><br/>
  We hope it’s your inclusive self-care toolkit to help you deal with the day-to-day highs and lows, and, with time, find healing that will last you a lifetime.
  <br/><br/>
  You got this,`,
};
