import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';

// Assets
import starsGroup from '../../assets/images/homepage/star-group.png';
import bgImage from '../../assets/images/homepage/social-proof-section-blob.svg';
import quote from '../../assets/images/homepage/quote.svg';

// Components
import { BoldSubtitle } from '../homepage/HomepageStyles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 20%;
  background-color: ${COLORS.darkCyan};
  align-items: center;
  padding: 60px 0;

  @media only screen and (max-width: ${SIZES.tablet}) {
    background-size: 30%;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    background-size: 40%;
  }
`;

const Stars = styled.img`
  width: 218px;
  height: 38px;
`;

const Title = styled(BoldSubtitle)`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  /* or 29px */
  color: ${COLORS.white};
  text-align: center;
  margin: 8px 15px;
  @media only screen and (min-width: ${SIZES.phone}) {
    font-size: 38px;
    max-width: 660px;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 800px;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 100%;
    height: 300px;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  width: 700px;
  height: auto;

  @media only screen and (max-width: ${SIZES.tablet}) {
    flex-direction:column;
    width: 100%;
`;

const QuoteImg = styled.img`
  width: 60px;
  height: auto;
  align-self:flex-start;
  margin-top: 45px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 38px;
    height: 32px;
    margin-top: 25px;
    margin-bottom: -25px;
    align-self:center;
`;

const ReviewText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: justify;
  line-height: 160%;
  color: ${COLORS.white};
  margin: 0;
  padding: 40px;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 16px;
  }
`;

const Loading = keyframes`
  0% {width:0;}
  100% {width: 100%}
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${COLORS.darkModerateCyan};
  width: 250px;
  height: 5px;
  border-radius: 5px;

  @media only screen and (max-width: ${SIZES.tablet}) {
  margin-top: 20px;
`;

const ProgressValue = styled.div`
  animation: ${Loading} 9s linear forwards infinite;
  background: ${COLORS.white};
  width: 0;
  height: 5px;
  border-radius: 5px;
`;

/**
 *
 * Returns an individual carousel item.
 *
 * @typedef {object} Item
 * @property {string} item Carousel text
 * @property {boolean} activeItem If true, sets the visibility of carousel item to visible.
 *
 * @param {Item} object
 * @returns {JSX.Element} Carousel container with quote image and review text
 */

interface Item {
  item: string;
  isActive: boolean;
}

const CarouselItem = ({ item, isActive }: Item): JSX.Element => {
  return (
    <CarouselContainer
      style={{ visibility: isActive ? 'visible' : 'hidden' }}
      key={item}
    >
      <QuoteImg src={quote} alt="quote" />
      <ReviewText>{item}</ReviewText>
    </CarouselContainer>
  );
};

const Carousel = () => {
  const { carouselItems } = COPY;
  const [active, setActive] = useState(0);
  let scrollInterval: any = null;

  useEffect(() => {
    scrollInterval = setInterval(() => {
      setActive((active + 1) % carouselItems.length);
    }, 9000);
    return () => clearInterval(scrollInterval);
  });

  return (
    <CarouselWrapper>
      {carouselItems.map((item, index) => {
        return (
          <CarouselItem item={item} key={item} isActive={index === active} />
        );
      })}
    </CarouselWrapper>
  );
};

const SocialProofCarousel = () => (
  <Container id="testimonials">
    <Stars src={starsGroup} />
    <Title>1 in 3 reviews refer to Shine as life-changing</Title>
    <Carousel />
    <ProgressBar>
      <ProgressValue />
    </ProgressBar>
  </Container>
);

const COPY = {
  carouselItems: [
    'If you have stress or anxiety, a lack of confidence or self esteem, or just want to relax your mind, this app is for you.  Their SHINE method for Anxiety is amazing, and doing the Daily Shine every morning makes my days start off so much better. Paying for all the meditations is well worth it.',
    'I have purchased reflection journals and listened to other apps but this one is different. This app is perfect because in their voices is my voice, my experiences. Every episode so far has resonated deeply with me as a Latina who often suffers from imposter syndrome.',
    "This is so worth it! So inclusive and specific!! The amount of support and help is amazing. Great community. I can't afford therapy and this has helped me more than any therapist. I loved it so much I bought my twin sister and bestie a membership.",
    "I was never really taught anything about self-care or self-healing nor healthy coping skills. This app has really helped open that door for me. I'm a single mother of 5 so life can really be challenging at times, Shine helps ground me and remind me I'm in control.",
    "All the mental health tools I was searching for put together into one amazing app. It's a gratitude journal, inspirational podcast library, meditation library, daily affirmation subscription, and community of mental health positive people. Please give this a try!",
  ],
};

export default SocialProofCarousel;
