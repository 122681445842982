export default {
  iphone5sWidth: '320px',
  iphone5sWidthMax: '325px',
  phoneMin: '375px',
  iphone6Width: '415px',
  phone: '667px',
  phoneNoPx: 667,
  tablet: '768px',
  desktop: '992px',
  ipadProWidth: '1024px',
  largeDesktop: '1048px',
  laptop: '1200px',
  wideDesktop: '1575px',
  // Height
  iphone5Height: '568px',
};
