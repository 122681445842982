import React, { Component } from 'react';
import qS from 'querystring';
import { clone } from 'ramda';

// COMPONENTS
import FormFooter from '../form/FormFooter';
import FormHandler from '../form/FormHandler';
import { LogoHeader } from '../sharedPartials';

// STYLE SHEETS
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
} from '../checkout/styles';
import { FormContainer } from '../form/FormStyles';

// CONSTANTS
import {
  DEFAULT_LOGIN_BUTTON_COPY,
  DEFAULT_LOGIN_FB_BUTTON_COPY,
  DEFAULT_LOGIN_APPLE_BUTTON_COPY,
  SWITCH_TO_SIGNUP_GIFT,
} from '../../constants/sharedCopy';
import { authMethods } from '../../constants/config';
class LoginGiftCard extends Component {
  constructor(props) {
    super(props);

    let switchAuthCopy = clone(SWITCH_TO_SIGNUP_GIFT);
    let queryString = this.props.location.search.split('?')[1];
    if (queryString) {
      let { code } = qS.parse(queryString);
      if (code !== 'undefined') {
        switchAuthCopy.link += `?code=${code}`;
      }
    }

    this.state = { switchAuthCopy };
  }

  render() {
    const buttonText = {
      formSubmitButton: DEFAULT_LOGIN_BUTTON_COPY,
      fbButton: DEFAULT_LOGIN_FB_BUTTON_COPY,
      appleButton: DEFAULT_LOGIN_APPLE_BUTTON_COPY,
    };
    return (
      <CheckoutBackground>
        <CheckoutBlobTop />
        <CheckoutPageContainer>
          <LogoHeader />
          <FormContainer>
            <FormHandler
              authType={authMethods.LOGIN}
              buttonCopy={buttonText}
              switchAuthCopy={this.state.switchAuthCopy}
              isGiftCardRedemption
            />
          </FormContainer>
          <FormFooter />
        </CheckoutPageContainer>
        <CheckoutBlobBottom />
      </CheckoutBackground>
    );
  }
}

export default LoginGiftCard;
