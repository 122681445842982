import axios from 'axios';

const giftUpSecretKey = process.env.REACT_APP_GIFTUP_SECRET_KEY;
const isTestMode =
  process.env.NODE_ENV !== 'production' ||
  process.env.REACT_APP_HOST_ENV === 'staging';

export default axios.create({
  baseURL: 'https://api.giftup.app',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `bearer ${giftUpSecretKey}`,
    'x-giftup-testmode': isTestMode, // for testing
  },
});
