import React from 'react';
import { SmallText, SmallLinkText, StyledLink } from './FormStyles';

const FormFooter = ({ body }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '24px',
        alignItems: 'center',
      }}
    >
      <SmallText>{body || COPY.body}</SmallText>
      <SmallText>
        <StyledLink to={COPY.pwResetLink}>{COPY.pwResetText}</StyledLink>
      </SmallText>
      <SmallText>
        <SmallLinkText href={COPY.emailLink}>{COPY.emailText}</SmallLinkText>
      </SmallText>
      <SmallText>
        <SmallLinkText href={COPY.faqLink}>{COPY.faqText}</SmallLinkText>
      </SmallText>
    </div>
  );
};

export default FormFooter;

const COPY = {
  body: 'Need help?',
  pwResetText: 'Reset your password',
  pwResetLink: '../password/reset',
  faqText: 'FAQ',
  faqLink: '/faq',
  emailText: 'help@headspace.com',
  emailLink: 'mailto:help@headspace.com',
};
