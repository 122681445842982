import React from 'react';
import {
  SectionContainer,
  SectionHeader,
  Title,
  FieldsOneColumn,
  FieldsTwoColumn,
  BenefitsRightSection,
} from '../styles';
import SubscriptionDetailsSection from './SubscriptionDetailsSection';
import SubscriptionCancelSection from './SubscriptionCancelSection';
import BenefitsList from './BenefitsList';

const PremiumPlanView = ({ stripeStatus, freeTrialStatus }) => (
  <SectionContainer>
    <SectionHeader>
      <Title>{COPY.premiumTitle}</Title>
    </SectionHeader>
    <FieldsTwoColumn>
      <SubscriptionDetailsSection
        stripeStatus={stripeStatus}
        freeTrialStatus={freeTrialStatus}
      />
      <BenefitsRightSection>
        <BenefitsList />
      </BenefitsRightSection>
    </FieldsTwoColumn>
    <FieldsOneColumn>
      <SubscriptionCancelSection />
    </FieldsOneColumn>
  </SectionContainer>
);

const COPY = {
  premiumTitle: 'Shine Premium',
};

export default PremiumPlanView;
