import React from 'react';
import styled from 'styled-components';

// Constants
import { FOOTER_PAGES } from '../../../constants/config';
import SIZES from '../../../constants/screenSizes';

// Components
import { CategoryTitle } from './styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    margin-bottom: 0px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Icon = styled.a<{ source: typeof import('*.jpg') }>`
  height: 24px;
  width: 24px;
  background-image: ${(props) => (props.source ? `url(${props.source})` : '')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 32px;
`;

const Social = () => {
  return (
    <Container>
      <CategoryTitle>Follow Us</CategoryTitle>
      <IconContainer>
        {FOOTER_PAGES.SOCIAL_SECTION.map((icon) => (
          <Icon
            key={icon.link}
            href={icon.link}
            source={icon.image}
            target="_blank"
            rel="noopener noreferrer"
          />
        ))}
      </IconContainer>
    </Container>
  );
};

export default Social;
