import React from 'react';
import styled from 'styled-components';
import { Section, Title } from '../B2BStyles';
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';
import {
  digidayLogo,
  healthlineLogo,
  wsjLogo,
  crainsLogo,
} from '../../../constants/image.config';

const WhiteSection = styled(Section)`
  background: ${COLORS.white};
  flex-direction: column;
  margin: 50px;
  @media only screen and (max-width: ${SIZES.desktop}) {
    margin: 25px;
  }
`;

const PressIconsContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (max-width: ${SIZES.desktop}) {
    flex-direction: column;
    height: auto;
  }
`;

const PressIcon = styled.img`
  opacity: 30%;
  max-width: 220px;
  object-fit: contain;

  :hover {
    opacity: 100%;
    transition: opacity 0.5s;
    cursor: pointer;
  }

  @media only screen and (max-width: ${SIZES.desktop}) {
    height: 45px;
    margin: 15px 0;
  }
`;

const B2BPressSection = () => {
  return (
    <WhiteSection>
      <Title>{COPY.title}</Title>
      <PressIconsContainer>
        {COPY.icons.map(({ name, img, altText, link }) => (
          <a href={link} target="_blank" rel="noopener noreferrer">
            <PressIcon key={name} src={img} id={name} alt={altText} />
          </a>
        ))}
      </PressIconsContainer>
    </WhiteSection>
  );
};

const COPY = {
  title: 'Shine at Work in the Press',
  icons: [
    {
      name: 'digiday',
      img: digidayLogo,
      altText: 'Digiday logo',
      link: 'https://digiday.com/media/shine-co-founders-naomi-hirabayashi-and-marah-lidey-on-how-mental-health-went-mainstream/',
    },
    {
      name: 'heathline',
      img: healthlineLogo,
      altText: 'Healthline logo',
      link: 'https://transform.healthline.com/future-of-health/healthcare-v2-how-virtual-care-is-changing-everything',
    },
    {
      name: 'wsj',
      img: wsjLogo,
      altText: 'Wall Street Journal logo',
      link: 'https://www.wsj.com/video/events/optimizing-self-care-for-everyone-with-shine-naomi-hirabayashi/48009AC3-2594-4B5F-847C-0CDE88CF6529.html',
    },
    {
      name: 'crains',
      img: crainsLogo,
      altText: "Craine's New York logo",
      link: 'https://www.crainsnewyork.com/health-care/mental-health-care-and-wellness-black-communities-requires-nuanced-approach',
    },
  ],
};

export default B2BPressSection;
