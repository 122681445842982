import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import asyncRequestMiddleware from '../utils/asyncRequestMiddleware';
import mixpanelMiddleware from '../utils/mixpanelMiddleware';
import leanplumMiddleware from '../utils/leanplumMiddleware';
import axiosMiddleware from 'redux-axios-middleware';
import googleTagManagerMiddleware from '../utils/googleTagManagerMiddleware';
import restClient from '../api/restClient';
import authClient from '../api/auth';
import { rootReducer } from '../reducers/index.tsx';

const logger = createLogger({ collapsed: true });
const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
    asyncRequestMiddleware,
    mixpanelMiddleware,
    axiosMiddleware(restClient, { returnRejectedPromiseOnError: true }),
    leanplumMiddleware,
    googleTagManagerMiddleware,
    logger
  )
);

restClient.interceptors.request.use(async (config) => {
  const idToken = await authClient.auth.getIdToken();
  if (idToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        common: {
          ...config.headers.common,
          'Id-Token': idToken,
        },
      },
    };
  }

  return config;
});

export default store;
