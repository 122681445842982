import React, { Component } from 'react';

// Components
import Banner from './Banner';
import { Footer, Navbar } from '../sharedPartials';

const GIFT_UP_ACCT_ID = '0d72ef02-8be3-4e9a-b55d-c7fe30dcfad5';
const GIFT_UP_PLATFORM = 'Other';

/**
 * COMPONENT
 */
class PurchaseGiftCard extends Component {
  constructor(props) {
    super(props);

    // Initiate instance property
    this.elementRef = null;
    // Receives HTML DOM elemet as arg & store it to an instance property as ref
    // Reference: https://reactjs.org/docs/refs-and-the-dom.html
    this.setElementRef = (element) => (this.elementRef = element);

    this.appendScriptToElement.bind(this);
  }

  appendScriptToElement() {
    // Create <Script> & append it as a child to a stored ref
    // Credit implementation to: https://stackoverflow.com/questions/34424845/adding-script-tag-to-react-jsx
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://cdn.giftup.app/dist/gift-up.js';
    if (this.elementRef) this.elementRef.appendChild(script);
  }

  componentDidMount() {
    // Append <Script> on mount
    this.appendScriptToElement();
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Banner description={COPY.banner} overflow={'hidden'} />
        <div
          className="gift-up-target"
          data-site-id={GIFT_UP_ACCT_ID}
          data-platform={GIFT_UP_PLATFORM}
          // Use the 'ref' callback to store a reference this <div> DOM element
          ref={this.setElementRef}
        />
        <Footer />
      </React.Fragment>
    );
  }
}

const COPY = {
  banner: `Looking to bulk purchase Shine Premium memberships for your team? Head <a style="color:inherit" href="/b2b-plans?utm_source=Shine&utm_medium=GiftPage_Banner">here</a>.`,
};

export default PurchaseGiftCard;
