import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

// CONSTANTS
import { EXPIRED_BUTTON_COPY } from '../../../constants/sharedCopy';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import SIZES from '../../../constants/screenSizes';
import {
  NON_PROMO_CTA_LINK,
  PROMO_CTA_LINK,
  BILLING_LINK,
} from '../../../constants/config';

// COMPONENTS
import { ButtonText } from '../../../constants/styles';

// STYLESHEETS
export const RoundedCTAButton = styled.button`
  cursor: pointer;
  display: flex;
  min-width: 151px;
  height: 50px;
  padding: 4px 12px;
  background: ${COLORS.deepGoldenYellow};
  border-radius: 47px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.poppinsPrimaryRegular};
  color: ${COLORS.darkMagentaPink};
  align-self: flex-start;
  margin-bottom: 24px;
  border: none;
  transition: background 0.5s;

  &:hover {
    background: ${COLORS.shineYellow};
  }

  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  @media only screen and (max-width: ${SIZES.phone}) {
    width: 100%;
  }
`;

const ExpiredRoundedCTAButton = styled(RoundedCTAButton)`
  background-color: ${COLORS.lightGrey};
  box-shadow: ${COLORS.grey} 4px 4px 8px;
  color: ${COLORS.blackTwo};
`;

const LandingPageButton = ({
  isExpired,
  buttonCopy,
  buttonAction,
  enableCompassionQuiz,
  classId,
  isColdTemplate,
  utmParams = {},
  stripeCouponPercentOff,
  email,
}) => {
  let shouldGoToBilling = false;
  const { utm_source, utm_medium } = utmParams;
  if (utm_source === 'Shine' && utm_medium === 'Email' && email !== '') {
    shouldGoToBilling = true;
  }

  /*  Check if promo has quiz enabled
  const showQuiz =
    process.env.REACT_APP_COMPASSION_QUIZ_FEATURE_FLAG === 'true' &&
    enableCompassionQuiz;
  const linkRoute = showQuiz ? '/quiz/1' : PROMO_CTA_LINK;
  */

  /* Deactivate & change the button to grey if user already subscribed*/
  if (isExpired) {
    return (
      <React.Fragment>
        <ExpiredRoundedCTAButton>
          <ButtonText>{EXPIRED_BUTTON_COPY}</ButtonText>
        </ExpiredRoundedCTAButton>
      </React.Fragment>
    );

    /* Direct users coming from Shine Emails to the billing page, except if it's a 100% off promo, where they should still go through auth to redeem*/
  } else if (shouldGoToBilling && stripeCouponPercentOff !== 100) {
    return (
      <React.Fragment>
        <Link
          to={BILLING_LINK}
          style={{ textDecoration: 'none' }}
          className={classId}
        >
          <RoundedCTAButton onClick={buttonAction}>
            {buttonCopy}
          </RoundedCTAButton>
        </Link>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <StyledLink
          to={isColdTemplate ? NON_PROMO_CTA_LINK : PROMO_CTA_LINK}
          className={classId}
        >
          <RoundedCTAButton onClick={buttonAction}>
            {buttonCopy}
          </RoundedCTAButton>
        </StyledLink>
      </React.Fragment>
    );
  }
};

export default withRouter(LandingPageButton);
