import React, { Component } from 'react';
import styled from 'styled-components';
import { Player, GetTheApp } from './index';
import {
  FOOTER_IOS_LINK_AUDIO,
  FOOTER_ANDROID_LINK_AUDIO,
} from '../../constants/sharedCopy';
import defaultBgImage from '../../assets/images/default-player-background.jpg';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${(props) => props.backgroundImage || defaultBgImage});
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const NoTrackContainer = styled.div`
  font-family: 'Work Sans';
  line-height: 1.63;
  max-width: 400px;
  text-align: center;
  width: 80%;
`;

class AudioPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locked: false,
      title: 'Loading...',
      speaker: '',
      trackUrl: '',
      playerBackground: '',
      theme: 'dark',
    };
  }

  componentWillMount() {
    const { trackId } = this.props.match.params;
    this.fetchTrackData(trackId);
  }

  /**
   * API request to get track data and set results to the component's state.
   *
   * @param {number} id Track id
   */
  async fetchTrackData(id) {
    const response = await fetch(
      `${process.env.REACT_APP_GLOW_BACKEND}/audio/tracks/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const json = await response.json();
    const {
      config,
      free,
      title,
      speaker: { name: speaker },
    } = json.data;
    const {
      guided: { audio_url: trackUrl, image_url: playerBackground },
    } = JSON.parse(config);

    // Only going to allow free tracks for now
    if (free) {
      this.setState({
        playerBackground,
        speaker,
        title,
        trackUrl,
      });
    } else {
      this.setState({ locked: true });
    }
  }

  render() {
    const { locked, title, speaker, trackUrl, playerBackground, theme } =
      this.state;

    let content;
    if (!locked) {
      content = (
        <Player
          skipTime={15}
          trackInfo={{
            title,
            speaker,
            trackUrl,
            playerBackground,
            theme,
          }}
        />
      );
    } else {
      content = (
        <NoTrackContainer>
          <p>{COPY.noTrack}</p>
        </NoTrackContainer>
      );
    }

    return (
      <PageContainer backgroundImage={playerBackground}>
        <GetTheApp
          androidLink={FOOTER_ANDROID_LINK_AUDIO}
          iosLink={FOOTER_IOS_LINK_AUDIO}
          titleColor="black"
        />
        {content}
      </PageContainer>
    );
  }
}

const COPY = {
  noTrack: `This track is no longer available. For more daily meditations and
            morning motivations, download the Shine app on the App Store or
            Google Play.`,
};

export default AudioPage;
