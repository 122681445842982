import React from 'react';

// Constants
import { EXPIRED_BUTTON_COPY } from '../../constants/sharedCopy';
import { Page } from '../../constants/styles';

// Components
import { Footer } from '../sharedPartials/index';
import { Header } from './index';

interface TemplateProps {
  heroImage: string;
  heroCopy: string;
  heroHeader: string;
  buttonCopy: string;
  isExpired: boolean;
  bgImage: string;
  theme: string;
  isPromo: boolean;
  buttonAction: ReturnSelectedPlan;
  enableCompassionQuiz: boolean;
  maxSeatsRedeemed: boolean;
  limitedTimeText?: string;
  templateType: string;
  customLogo?: string;
}

type ReturnSelectedPlan = () => undefined;

const MainTemplateB2B = (props: TemplateProps) => {
  const {
    buttonCopy,
    isExpired,
    heroImage,
    heroCopy,
    heroHeader,
    bgImage,
    theme,
    isPromo,
    limitedTimeText,
    buttonAction,
    enableCompassionQuiz,
    templateType,
    customLogo,
    maxSeatsRedeemed,
  } = props;

  const button = isExpired ? EXPIRED_BUTTON_COPY : buttonCopy;

  return (
    <Page>
      <Header
        buttonCopy={button}
        isExpired={isExpired}
        heroCopy={heroCopy}
        heroImage={heroImage}
        heroHeader={heroHeader}
        bgImage={bgImage}
        theme={theme}
        isPromo={isPromo}
        limitedTimeText={limitedTimeText}
        buttonAction={buttonAction}
        enableCompassionQuiz={enableCompassionQuiz}
        templateType={templateType}
        customLogo={customLogo}
        maxSeatsRedeemed={maxSeatsRedeemed}
      />
      <Footer />
    </Page>
  );
};

export default MainTemplateB2B;
