import React from 'react';
import styled from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';

// Assets
import foundersImg from '../../assets/images/homepage/founder-image-static.jpg';
import foundersImgWebp from '../../assets/images/homepage/founder-image-static.webp';
import quote from '../../assets/images/homepage/quote.jpg';

// Components
import { BoldSubtitle } from '../homepage/HomepageStyles';

// Utils
import { ImgWithFallback } from '../../utils/common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 60px;
  align-items: center;
  @media only screen and (min-width: ${SIZES.phone}) {
    max-width: 1439px;
    align-self: center;
  }
`;

const Title = styled(BoldSubtitle)`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  text-align: ${({ isPromo }) => (isPromo ? 'center' : 'left')};
  margin-top: 0;
  margin-bottom: 12px;
  @media only screen and (min-width: ${SIZES.phone}) {
    width: 495px;
    font-size: 38px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: ${SIZES.phone}) {
    display: flex;
    justify-content: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  height: auto;

  @media only screen and (min-width: ${SIZES.phone}) {
    flex-direction: row-reverse;
    margin-bottom: 120px;
  }
`;

const QuoteText = styled.div`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  text-align: left;

  .body {
    line-height: 190%;
    font-size: 16px;
    color: ${COLORS.darkGrey};
    margin-bottom: 12px;
  }
  .author-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .quote-img {
    width: 38px;
    height: 32px;
    margin-right: 16px;
    /* Align quote with founder title top */
    margin-bottom: 12px;
  }
  .founder-title {
    font-weight: 500;
    font-size: 18px;
    /* identical to box height, or 22px */
    line-height: 120%;
    margin-bottom: 3px;
    margin-top: 12px;
  }
  .founder-subtitle {
    font-weight: 500;
    font-size: 14px;
    /* identical to box height, or 21px */
    line-height: 150%;
    color: ${COLORS.darkGrey};
    margin-top: 3px;
  }

  @media only screen and (min-width: ${SIZES.phone}) {
    flex: 0 0 50%;

    .body {
      font-size: 18px;
    }
    .quote-img {
      width: 65px;
      height: 55px;
    }
  }
`;
const Image = styled(ImgWithFallback)`
  width: 100%;
  margin: 0;
`;

class FounderSection extends React.PureComponent {
  static defaultProps = {
    isPromo: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayImage: true,
      displayVideo: false,
    };
  }

  render() {
    return (
      <Container id="founders">
        <TextWrapper>
          <Title isPromo={this.props.isPromo}>
            We made a space with our reality in mind
          </Title>
        </TextWrapper>
        <Wrapper>
          <QuoteText>
            <p className="body">
              We started Shine because we didn’t see ourselves—a Black woman and
              a half-Japanese woman—and our experiences represented in
              mainstream wellness.
              <br />
              <br />
              Stress and anxiety can take a daily toll on our mental health, and
              affects us differently based on our everyday experiences.
              <br />
              <br />
              We were tired of not hearing our voices, not seeing our skin tone
              and of being overlooked—so we made a space with our reality in
              mind.
              <br />
              <br />
              Join us in making self-care a daily ritual, and we’ll help you
              start a lifelong journey of healing.
              <br />
              <br />
              We see you. We hear you. We are you.
            </p>
            <div className="author-wrapper">
              <img className="quote-img" alt="quote" src={quote}></img>
              <div className="text-wrapper">
                <p className="founder-title">Naomi & Marah</p>
                <p className="founder-subtitle">Co-Founders of Shine</p>
              </div>
            </div>
          </QuoteText>
          <Image
            src={foundersImgWebp}
            fallback={foundersImg}
            altText="The Shine app founders Naomi Hirabayashi and Marah Lidey"
          />
        </Wrapper>
      </Container>
    );
  }
}

export default FounderSection;
