export const genericGiftRedemptionErrMsg = `Oops! Something went wrong when redeeming your gift card, please contact us for help`;

export const facebookErrorMsg: { [key: string]: string } = {
  'auth/popup-closed-by-user':
    'Facebook login is not supported while in a private window or incognito. Please try again on a different browser.',
  'auth/popup-blocked':
    'Facebook login was blocked by your browser.  Please try again on a different browser.',
  'auth/cancelled-popup-request':
    'Please close all facebook sign in windows and try again.',
};

export const appleErrorMsg: { [key: string]: string } = {
  'auth/popup-closed-by-user':
    'Apple login is not supported while in a private window or incognito. Please try again on a different browser.',
  'auth/popup-blocked':
    'Apple login was blocked by your browser.  Please try again on a different browser.',
  'auth/cancelled-popup-request':
    'Please close all Apple sign in windows and try again.',
};
