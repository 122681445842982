import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  title: string;
  image: string;
  description: string;
  url: string;
};

const MetaTags = ({ title, image, description, url }: Props) => (
  <React.Fragment>
    <Helmet>
      <title>{title}</title>
      <meta property="og:url" content={url}></meta>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description}></meta>
      <meta property="title" content={title} />
      <meta property="image" content={image} />
      <meta property="description" content={description}></meta>
    </Helmet>
  </React.Fragment>
);

export default MetaTags;
