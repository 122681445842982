import Leanplum from 'leanplum-sdk';
import * as TYPES from '../constants/actionTypes';
import {
  LEANPLUM_EVENTS,
  MOBILE_APP_SUB_STATES,
  LEANPLUM_ATTRIBUTES,
} from '../constants/config';

const leanplumMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (
      action.type === TYPES.AUTH_LOGIN_SUCCESS ||
      (action.type === TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS && !action.isSignup) ||
      (action.type === TYPES.AUTH_APPLE_LOGIN_SUCCESS && !action.isSignup)
    ) {
      const {
        user: { uid },
      } = action;
      const {
        quiz: {
          results: { personality_type_name: personalityName },
        },
        promo: { pageId, templateType },
        enterprise: { companyId, stripeCustomerId },
      } = getState();

      let attributes = {};

      if (personalityName) {
        attributes.webQuizPersonalityName = personalityName;
      }

      if (companyId && stripeCustomerId) {
        attributes.company = companyId;
      } else if (templateType === 'b2b') {
        attributes.company = pageId;
      }

      Leanplum.start(uid, attributes);
      Leanplum.track(LEANPLUM_EVENTS.LOGIN);
    }
    if (
      action.type === TYPES.AUTH_SIGNUP_SUCCESS ||
      (action.type === TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS && action.isSignup) ||
      (action.type === TYPES.AUTH_APPLE_LOGIN_SUCCESS && action.isSignup)
    ) {
      const {
        user: { uid, firstName, email },
      } = action;
      const {
        quiz: {
          results: { personality_type_name: personalityName },
        },
        promo: { pageId, templateType },
        enterprise: { companyId, stripeCustomerId },
      } = getState();

      let attributes = {
        subscriptionStatus: MOBILE_APP_SUB_STATES.TRIAL_ELIGIBLE,
        signupSource: LEANPLUM_ATTRIBUTES.WEB,
        webpageSignup: pageId ? pageId : LEANPLUM_ATTRIBUTES.HOMEPAGE,
        userType: 'Email',
      };
      if (firstName) {
        attributes.firstName = firstName;
      }
      if (email) {
        attributes.email = email.trim().toLowerCase();
      }
      if (personalityName) {
        attributes.webQuizPersonalityName = personalityName;
      }
      if (action.type === TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS) {
        attributes.userType = 'Facebook';
      } else if (action.type === TYPES.AUTH_APPLE_LOGIN_SUCCESS) {
        attributes.userType = 'Apple';
      }

      if (companyId && stripeCustomerId) {
        attributes.company = companyId;
      } else if (templateType === 'b2b') {
        attributes.company = pageId;
      }

      Leanplum.start(uid, attributes);
      Leanplum.track(LEANPLUM_EVENTS.SIGNUP);
    } else if (action.type === TYPES.REDEEM_GIFT_SUBSCRIPTION_SUCCESS) {
      const { giftCardNumber } = action;
      const {
        billing: {
          selectedPlan: { billingCycle },
        },
      } = getState();

      let attributes = {
        subscriptionStatus: MOBILE_APP_SUB_STATES.ACTIVE_AUTO_RENEW,
        subscriptionSource: LEANPLUM_ATTRIBUTES.WEB,
        webpageSubscribe: LEANPLUM_ATTRIBUTES.HOMEPAGE,
        paymentPlan: billingCycle,
        giftCardNumber,
      };

      Leanplum.setUserAttributes(attributes);
      Leanplum.track(LEANPLUM_EVENTS.SUBSCRIBE);
    }

    return next(action);
  };

export default leanplumMiddleware;
