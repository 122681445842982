import React from 'react';
import PropTypes from 'prop-types';

// Constants
import { Page } from '../../constants/styles';
import { EXPIRED_BUTTON_COPY } from '../../constants/sharedCopy';
import COLORS from '../../constants/colors';

// Components
import { Header, StepSection } from './index';
import {
  LibrarySection,
  SocialProofSection,
  FounderSection,
  AppSection,
  Footer,
} from '../sharedPartials/index';

/**
 * COMPONENT
 */
const MainTemplateWarm = (props) => {
  const {
    heroImage,
    heroCopy,
    heroHeader,
    buttonCopy,
    isExpired,
    bgImage,
    theme,
    isPromo,
    limitedTimeText,
    buttonAction,
    enableCompassionQuiz,
    templateType,
    nameOfInviter,
    stepSectionEyeBrowTitle,
    stepSectionTitle,
    stepSectionImage,
    programSteps,
    customLogo,
    utmParams,
    stripeCouponPercentOff,
    email,
  } = props;
  const button = isExpired ? EXPIRED_BUTTON_COPY : buttonCopy;

  return (
    <Page>
      <Header
        buttonCopy={button}
        isExpired={isExpired}
        heroCopy={heroCopy}
        heroImage={heroImage}
        heroHeader={heroHeader}
        bgImage={bgImage}
        theme={theme}
        isPromo={isPromo}
        limitedTimeText={limitedTimeText}
        buttonAction={buttonAction}
        enableCompassionQuiz={enableCompassionQuiz}
        templateType={templateType}
        nameOfInviter={nameOfInviter}
        customLogo={customLogo}
        utmParams={utmParams}
        stripeCouponPercentOff={stripeCouponPercentOff}
        email={email}
      />
      <StepSection
        stepSectionEyeBrowTitle={stepSectionEyeBrowTitle}
        stepSectionTitle={stepSectionTitle}
        stepSectionImage={stepSectionImage}
        programSteps={programSteps}
        buttonAction={buttonAction}
        isPromo={isPromo}
      />
      <LibrarySection
        styles={{ backgroundColor: COLORS.darkGreyPink }}
        buttonCopy="Get Started"
        isPromo={isPromo}
      />
      <SocialProofSection />
      <FounderSection isPromo />
      <AppSection />
      <Footer />
    </Page>
  );
};

/**
 * CONTAINER
 */

export default MainTemplateWarm;

/**
 * PROP TYPES
 */
MainTemplateWarm.propTypes = {
  heroImage: PropTypes.string.isRequired,
  heroCopy: PropTypes.string.isRequired,
  heroHeader: PropTypes.string.isRequired,
  buttonCopy: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  bgImage: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  isPromo: PropTypes.bool.isRequired,
  buttonAction: PropTypes.func.isRequired,
  enableCompassionQuiz: PropTypes.bool.isRequired,
  stepSectionEyeBrowTitle: PropTypes.string.isRequired,
  stepSectionTitle: PropTypes.string.isRequired,
  stepSectionImage: PropTypes.string.isRequired,
  programSteps: PropTypes.oneOf(PropTypes.array, PropTypes.bool).isRequired,
  email: PropTypes.string,
};
