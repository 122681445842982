import React from 'react';
import {
  Page,
  PortalBackground,
  PortalBlobTop,
  PortalBlobBottom,
  BodyContainer,
} from './styles';
import TopNav from './partials/TopNav';
import SidebarNav from './partials/SidebarNav';
import SubscriptionBody from './partials/SubscriptionBody';
import Footer from './partials/Footer';

const SubscriptionPage = () => (
  <Page>
    <TopNav />
    <PortalBackground>
      <PortalBlobTop />
      <BodyContainer>
        <SidebarNav />
        <SubscriptionBody />
      </BodyContainer>
      <PortalBlobBottom />
      <Footer />
    </PortalBackground>
  </Page>
);

export default SubscriptionPage;
