import styled from 'styled-components';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import { bulletCheck } from '../../constants/image.config';

export const Container = styled.section`
  align-content: center;
  align-self: center;
  background-color: ${COLORS.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  padding: 32px 20px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 600px
    padding: 40px 24px;
  }
`;

export const SubText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${COLORS.greyThree};

  @media screen and (max-width: ${SIZES.tablet}) {
    font-size: 14px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border: solid 1px ${COLORS.softGrey};
`;

export const FooterWrapper = styled.div`
  margin-top: 24px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-self: center;
  @media screen and (max-width: ${SIZES.tablet}) {
    width: 90%;
  }
`;

// Card Info Container

export const CardContainer = styled.div`
  width: 296px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  @media screen and (max-width: ${SIZES.iphone5sWidthMax}) {
    width: 280px;
    max-width: 296px;
  }
  @media screen and (min-width: ${SIZES.tablet}) {
    width: 100%;
    max-width: 556px;
  }
`;

export const PlanInfoDescription = styled.span`
  width: 300px;
  font-family: ${FONTS.workSansPrimaryRegular};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.4px;
  text-align: center;
  color: ${COLORS.blackTwo};
  @media screen and (min-width: ${SIZES.iphone6Width}) {
    font-size: 16px;
  }
`;

// Plan Info Styles

export const AnnualDiscountContainer = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) =>
    active ? COLORS.marigold : COLORS.paleGrey};
  z-index: 3;
  margin-left: -11px;
  @media screen and (max-width: ${SIZES.tablet}) {
    margin-top: -23px;
  }
`;

export const AnnualDiscountLabel = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${({ active }) => (active ? COLORS.white : COLORS.grey)};
`;

export const PlanModuleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({ higher }) => (higher ? '180px' : '129px')};
  border-radius: 8px;
  border: ${({ active }) => (active ? `2px solid ${COLORS.marigold}` : `none`)};
  background-color: ${({ active }) =>
    active ? COLORS.paleGoldenYellow : COLORS.softGrey};
  z-index: 1;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: ${SIZES.tablet}) {
    height: ${({ higher }) => (higher ? '200px' : '159px')};
  }
`;

export const RadioButtonWrapper = styled.div`
  width: 25px;
  margin-left: 16px;
`;

export const RadioButton = styled.a`
  width: 20px;
  height: 20px;
  border: ${({ active }) =>
    active ? `2px solid ${COLORS.marigold}` : `2px solid ${COLORS.grey}`};
  background-color: ${({ active }) =>
    active ? COLORS.paleGoldenYellow : COLORS.transparent};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .active-icon {
    ${({ active }) =>
      active
        ? `width: 10px;
      height: 10px;
      border-radius: 50px;
      background-color: ${COLORS.marigold};`
        : `display: none;`}
  }
`;

export const PlanItemWrapper = styled.div`
  display: flex;
  height: 129px;
  width: 85%;
  justify-content: center;

  @media screen and (max-width: ${SIZES.tablet}) {
    height: 177px;
    flex-direction: column;
    width: 100%;
  }
`;

const PlanItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftPlanItemContainer = styled(PlanItemContainer)`
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
  width: 80%;

  @media screen and (max-width: ${SIZES.tablet}) {
    width: 100%;
  }
`;

export const RightPlanItemContainer = styled(PlanItemContainer)`
  align-items: flex-end;
  justify-content: flex-start;
  width: 20%;
  @media screen and (max-width: ${SIZES.tablet}) {
    align-items: flex-start;
    width: 100%;
  }
`;

export const PlanTitleContainer = styled.div`
  width: 100%
  display: flex;
  flex-direction: row;
`;

export const PlanName = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
  text-align: left;
  color: ${COLORS.darkMagentaPink};
  margin: 6px 0;
  text-transform: capitalize;

  @media screen and (max-width: ${SIZES.tablet}) {
    font-size: 16px;
  }
`;

export const PlanCost = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  color: ${COLORS.darkMagentaPink};
  margin: 6px 0;

  @media screen and (max-width: ${SIZES.tablet}) {
    font-size: 18px;
  }
`;

export const OriginalPlanCost = styled(PlanCost)`
  color: ${COLORS.strikedOutCrimson};
  margin: 6px 6px 6px 0;
`;

export const StrikedOutContainer = styled.div`
  position: relative;
`;

const Strike = styled.div`
  position: absolute;
  top: 3px;
  z-index: 10;
  border-width: 0 0 5px 0;
  border-color: ${COLORS.strikedOutPink};
  border-style: solid;
  border-radius: 50%;
  width: 55px;
  opacity: 75%;
`;

export const StrikeTopBottom = styled(Strike)`
  transform-origin: top left;
  -ms-transform-origin: top left;
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -moz-transform: rotate(25deg);
  -webkit-transform: rotate(25deg);
`;
export const StrikeBottomTop = styled(Strike)`
  transform-origin: top left;
  -ms-transform-origin: top left;
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  margin-top: 1.3em;
  transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -moz-transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg);
`;

export const PlanDescription = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: ${COLORS.darkGrey};
  margin: 6px 0;
  text-align: left;

  @media screen and (max-width: ${SIZES.tablet}) {
    font-size: 11px;
    max-width: 90%;
  }
`;

export const PlanLabel = styled.div`
  width: ${({ wider }) => (wider ? '215px' : '132px')};
  height: 28px;
  background: ${({ active }) =>
    active ? COLORS.darkModerateCyan : COLORS.paleGrey};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ wider }) => (wider ? '20px 0 0 0' : '20px 16px 0 0')};
  padding: 5px 0;

  @media screen and (max-width: ${SIZES.tablet}) {
    margin: 0 0 0 16px;
  }
`;
export const PlanLabelText = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${({ active }) => (active ? COLORS.white : COLORS.grey)};
`;

// Membership Benefits

export const MembershipTitle = styled.span`
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 110%;
  color: ${COLORS.darkMagentaPink};
  margin-top: 32px;

  @media screen and (max-width: ${SIZES.tablet}) {
    font-size: 20px;
  }
`;

export const MembershipBenefitsList = styled.ul`
  list-style-type: none;
  text-align: left;
  padding-inline-start: 24px;
  text-indent: -24px;

  @media only screen and (max-width: ${SIZES.phone}) {
    padding-right: 2%;
    font-size: 12px;
  }
`;

export const MembershipBenefitsBulletPoints = styled.li`
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 150%;
  padding-bottom: 8px;
  color: ${COLORS.darkGrey};

  ::before {
    content: '';
    display: inline-block;
    height: 24px;
    width: 24px;
    background-size: 24px;
    background-image: url(${bulletCheck});
    background-repeat: no-repeat;
    position: relative;
    right: 5px;
    top: 7px;
  }

  @media screen and (max-width: ${SIZES.tablet}) {
    font-size: 15px;
  }
`;
