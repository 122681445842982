import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';
import { Section, Title } from '../B2BStyles';
import {
  elishaWebp,
  elisha,
  jamilaWebp,
  jamila,
  jenWebp,
  jen,
  jezzWebp,
  jezz,
  jorWebp,
  jor,
  marielWebp,
  mariel,
} from '../../../constants/image.config';
import PeopleList from '../../sharedPartials/PeopleList';

const YellowSection = styled(Section)`
  background: ${COLORS.lightYellow};
  margin-top: -1px;
`;

const ExpertContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  width: 100%;
  background-color: ${COLORS.lightYellow};

  @media only screen and (min-width: ${SIZES.phone}) {
    background-color: ${COLORS.white};
    padding: 30px 24px 0px 24px;
    border-radius: 22px;
    width: 80%;
  }
  @media only screen and (min-width: ${SIZES.desktop}) {
    width: 952px;
    padding-top: 50px;
    border-radius: 32px;
  }
`;

const ExpertsSection = () => (
  <YellowSection>
    <ExpertContainer>
      <Title>{COPY.title}</Title>
      <PeopleList list={EXPERTS} />
    </ExpertContainer>
  </YellowSection>
);

const COPY = {
  title: 'Meet Our In-App Experts',
};

const EXPERTS = [
  {
    img: jamilaWebp,
    fallback: jamila,
    name: 'Jamila Reddy',
    bio: 'Meditation Host',
  },
  {
    img: jorWebp,
    fallback: jor,
    name: 'Jor-El Caraballo, L.M.H.C.',
    bio: 'Therapist',
  },
  {
    img: elishaWebp,
    fallback: elisha,
    name: 'Elisha Mudly, L.M.S.W.',
    bio: 'Mindfulness Teacher',
  },
  {
    img: marielWebp,
    fallback: mariel,
    name: 'Dr. Mariel Buqué',
    bio: 'Psychologist',
  },
  {
    img: jenWebp,
    fallback: jen,
    name: 'Jen Kluczkowski',
    bio: 'Yoga & Meditation Teacher, Holistic Health Coach',
  },
  {
    img: jezzWebp,
    fallback: jezz,
    name: 'Jezz Chung',
    bio: 'DE&I Facilitator & Leader',
  },
];

export default ExpertsSection;
