import React from 'react';
import PropTypes from 'prop-types';

// Constants
import { Page } from '../../constants/styles';
import { EXPIRED_BUTTON_COPY } from '../../constants/sharedCopy';
import COLORS from '../../constants/colors';
import { libraryBg } from '../../constants/image.config';

// Components
import Header from './promoPartials/Header';
import {
  FeaturedSection,
  LibrarySection,
  HostsSection,
  BenefitSection,
  SocialProofSection,
  CommunitySection,
  FounderSection,
  AppSection,
  Footer,
} from '../sharedPartials/index';

const MainTemplateCold = (props) => {
  const {
    heroImage,
    heroCopy,
    heroHeader,
    buttonCopy,
    isExpired,
    bgImage,
    theme,
    isPromo,
    limitedTimeText,
    buttonAction,
    enableCompassionQuiz,
    templateType,
    nameOfInviter,
  } = props;
  const button = isExpired ? EXPIRED_BUTTON_COPY : buttonCopy;

  return (
    <Page>
      <Header
        buttonCopy={button}
        isExpired={isExpired}
        heroCopy={heroCopy}
        heroImage={heroImage}
        heroHeader={heroHeader}
        bgImage={bgImage}
        theme={theme}
        isPromo={isPromo}
        limitedTimeText={limitedTimeText}
        buttonAction={buttonAction}
        enableCompassionQuiz={enableCompassionQuiz}
        templateType={templateType}
        nameOfInviter={nameOfInviter}
      />
      <BenefitSection />
      <LibrarySection
        styles={{
          backgroundImage: `url(${libraryBg})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top left',
          backgroundSize: '30%',
          backgroundColor: `${COLORS.darkModerateCyan}`,
        }}
        buttonCopy="Get Started"
      />
      <HostsSection />
      <FeaturedSection />
      <SocialProofSection />
      <CommunitySection />
      <FounderSection />
      <AppSection />
      <Footer />
    </Page>
  );
};

/**
 * CONTAINER
 */

export default MainTemplateCold;

/**
 * PROP TYPES
 */
MainTemplateCold.propTypes = {
  heroImage: PropTypes.string.isRequired,
  heroCopy: PropTypes.string.isRequired,
  heroHeader: PropTypes.string.isRequired,
  buttonCopy: PropTypes.string.isRequired,
  isExpired: PropTypes.bool.isRequired,
  bgImage: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  isPromo: PropTypes.bool.isRequired,
  buttonAction: PropTypes.func.isRequired,
  enableCompassionQuiz: PropTypes.bool.isRequired,
  templateType: PropTypes.string.isRequired,
};
