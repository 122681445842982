import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qS from 'querystring';
import FormInputComponent from './FormInputComponent';
import TermsAndPolicy from './TermsAndPolicy';
import { Header } from '../sharedPartials';
import SignupOrLogin from './SignupOrLogin';

// StyleSheets
import {
  FormSubmitButton,
  BlueButton,
  BlackButton,
  GreyButton,
  ButtonText,
  DisabledButtonText,
  Spinner,
  FbIcon,
  AppleIcon,
} from '../../constants/styles';
import {
  ErrorMessage,
  Form,
  FormButtonContainer,
  FormTitle,
  Card,
} from './FormStyles';

// CONSTANTS
import { authMethods } from '../../constants/config';

// UTILS
import { validatePassword } from '../../utils/common';

/**
 * COMPONENT
 *
 * This component is used to render the different necessary input fields for specific forms
 */
class FormGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      firstName: '',
      giftCardNumber: '',
      password: '',
      passwordHidden: true,
      isValidPassword: false,
    };
    this.inputChange = this.inputChange.bind(this);
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.handleFbButtonClick = this.handleFbButtonClick.bind(this);
    this.handleAppleButtonClick = this.handleAppleButtonClick.bind(this);

    let queryString = this.props.location.search.split('?')[1];
    if (queryString) {
      let { code } = qS.parse(queryString);
      if (code !== 'undefined') {
        this.state.giftCardNumber = code;
      }
    }
  }

  renderSecondaryButtons = () => {
    const {
      classId,
      buttonCopy: { fbButton, appleButton },
      isLoading,
      isB2b,
      isEnterprise,
    } = this.props;

    if (isB2b || isEnterprise) return null;

    if (isLoading) {
      return (
        <GreyButton>
          <DisabledButtonText>Loading...</DisabledButtonText>
        </GreyButton>
      );
    }
    return (
      <React.Fragment>
        <FormButtonContainer>
          <BlueButton
            onClick={this.handleFbButtonClick}
            className={classId ? classId + '-fb' : 'submit-fb'}
          >
            <FbIcon />
            <ButtonText>{fbButton}</ButtonText>
          </BlueButton>
        </FormButtonContainer>
        <FormButtonContainer>
          <BlackButton
            onClick={this.handleAppleButtonClick}
            className={classId ? classId + '-apple' : 'submit-apple'}
          >
            <AppleIcon />
            <ButtonText>{appleButton}</ButtonText>
          </BlackButton>
        </FormButtonContainer>
      </React.Fragment>
    );
  };

  render() {
    const {
      classId,
      buttonCopy: { formSubmitButton },
      errorMsg,
      isLoading,
      formContent,
      formTitle,
      noStepper,
      quizResults,
      switchAuthCopy,
      // Actions
      handleSubmit,
      bogoPromoType,
    } = this.props;

    return (
      <Card>
        {formTitle && <FormTitle>{formTitle}</FormTitle>}
        <Header
          noStepper={noStepper}
          completedQuiz={quizResults && quizResults.id}
          bogoPromoType={bogoPromoType}
        />
        <Form onSubmit={handleSubmit}>
          {formContent.map((field) => (
            <FormInputComponent
              key={field.label}
              label={field.label}
              inputName={field.inputName}
              inputType={field.inputType}
              inputValue={this.state[field.inputName]}
              onChange={this.inputChange}
              onClick={this.toggleShowPassword}
              passwordHidden={this.state.passwordHidden}
              isSignup={this.props.isSignup}
              isValidPassword={this.state.isValidPassword}
            />
          ))}
          {errorMsg && <ErrorMessage> {errorMsg} </ErrorMessage>}
          <FormButtonContainer>
            {isLoading ? (
              <Spinner />
            ) : (
              <FormSubmitButton type="submit" className={classId || 'submit'}>
                <ButtonText>{formSubmitButton}</ButtonText>
              </FormSubmitButton>
            )}
          </FormButtonContainer>
        </Form>
        <FormButtonContainer>
          {this.renderSecondaryButtons()}
        </FormButtonContainer>
        <TermsAndPolicy />
        <SignupOrLogin copy={switchAuthCopy} />
      </Card>
    );
  }

  /**
   * Update displayed valued as user types, hide password tool tip if password is valid
   */
  inputChange(event) {
    if (this.props.errorMsg) {
      this.props.clearError();
    }
    this.setState({ [event.target.name]: event.target.value });

    const isValidPassword = validatePassword(event.target.value);
    this.setState({
      isValidPassword: isValidPassword,
    });
  }

  /**
   * Toggle show/hide password.
   */
  toggleShowPassword() {
    this.setState({ passwordHidden: !this.state.passwordHidden });
  }

  /**
   * Handle Clicking FB Button
   */
  handleFbButtonClick() {
    this.props.handleClick(authMethods.FB_LOGIN, this.state.giftCardNumber);
  }

  /**
   * Handle Clicking Apple Button
   */
  handleAppleButtonClick() {
    this.props.handleClick(authMethods.APPLE_LOGIN, this.state.giftCardNumber);
  }
}

export default withRouter(FormGenerator);

/**
 * PROP TYPES
 */
FormGenerator.propTypes = {
  errorMsg: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  formContent: PropTypes.array.isRequired,
  buttonCopy: PropTypes.shape({
    formSubmitButton: PropTypes.string.isRequired,
    fbButton: PropTypes.string.isRequired,
    appleButton: PropTypes.string.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  handleClickFb: PropTypes.func.isRequired,
  handleClickApple: PropTypes.func.isRequired,
  hideStepper: PropTypes.bool,
  switchAuthCopy: PropTypes.object.isRequired,
  bogoPromoType: PropTypes.string,
  isB2b: PropTypes.bool,
  isEnterprise: PropTypes.bool,
  isSignup: PropTypes.bool,
};
