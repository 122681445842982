// UTILS
import {
  findOrCreateCustomer,
  subscribeUser,
  findCustomerSubscriptions,
  updateSubscriptionFromGiftCard,
} from '../utils/stripeUtils';
import giftUpClient from '../api/giftUpClient';

// CONSTANTS
import { REDEEM_GIFT_SUBSCRIPTION_FAILED } from '../constants/actionTypes';
import { genericGiftRedemptionErrMsg } from '../constants/errorMessages';
import { acceptedLifetimeGiftCardRegex } from '../constants/config';
import { UserDataWithGiftCard, UTMParams } from '../types/actions/auth-actions';

class CustomError extends Error {
  msg?: string;
  type?: string;
  status?: number;
}

interface GenericErrorType {
  response?: {
    status: number;
    data: any;
  };
  msg?: string;
  message?: string;
  status?: number;
}

/**
 * Verify the gift card can be redeemed and get the planID/SKU
 */
export const verifyGiftCard = async (code: string) => {
  //url  /gift-cards/{code}
  const result = {
    canBeRedeemed: false,
    planId: '', // stripe subscription plan id
    interval: '', // month/year
  };
  try {
    // Get gift card info
    const response = await giftUpClient.get(`/gift-cards/${code}`);
    if (response.status === 200) {
      const {
        data: { canBeRedeemed, sku: planId },
      } = response;
      result.canBeRedeemed = canBeRedeemed;
      result.planId = planId;
      result.interval =
        process.env.REACT_APP_STRIPE_ANNUAL_PLAN_ID === planId
          ? 'year'
          : 'month';
    }

    if (result.canBeRedeemed) {
      return result;
    } else {
      const customError = new CustomError();
      customError.message = `Gift code: [${code}] was already redeemed`;
      customError.status = response.status;
      throw customError;
    }
  } catch (error) {
    console.error(error);
    const e = error as GenericErrorType;

    // If the gift card is invalid dispatch action
    const customError = new CustomError();
    if (e.status === 200) {
      customError.msg = e.message;
    } else if (
      e.response &&
      (e.response.status === 404 || e.response.status === 400)
    ) {
      customError.msg = `Gift code: [${code}] was not found. Please try again or contact us for help`;
    } else {
      customError.msg = genericGiftRedemptionErrMsg;
    }
    customError.type = REDEEM_GIFT_SUBSCRIPTION_FAILED;
    throw customError;
  }
};

/**
 * Redeem the gift card on GiftUp.
 */
export const redeemGiftCard = async (code: string, userId: string) => {
  await giftUpClient.post(`/gift-cards/${code}/redeem-in-full`, {
    reason: `Redeemed gift card for ${userId}`,
  });
};

/**
 * Create a subscription after a gift card's been redeemed.
 */
export const createGiftSubscription = async (
  user: UserDataWithGiftCard,
  planId: string,
  interval: string,
  giftCardNumber?: string,
  utmParams?: UTMParams
) => {
  // check if user has an account on stripe already or create one
  const { customerId } = await findOrCreateCustomer(user, utmParams);

  // check if a user has a subscription and update or create new subscription
  if (customerId)
    await subscribeOrUpdate(customerId, planId, interval, giftCardNumber);
};

/**
 * Create new subscriptions for users who don't have any yet. Or update their
 * existing subscription if we find they already have one.
 */
export const subscribeOrUpdate = async function (
  stripeId: string,
  planId: string,
  interval: string,
  giftCardNumber?: string
) {
  const isLifetimeDiscount =
    !!giftCardNumber &&
    giftCardNumber.match(acceptedLifetimeGiftCardRegex) !== null;
  let giftcard = isLifetimeDiscount
    ? process.env.REACT_APP_STRIPE_100PERCENT_FOREVER_DISCOUNT
    : process.env.REACT_APP_STRIPE_100PERCENT_DISCOUNT;

  const currentSubscriptions = await findCustomerSubscriptions(stripeId);
  if (currentSubscriptions.length === 0) {
    await subscribeUser(stripeId, planId, giftcard, false);
  } else {
    await updateSubscriptionFromGiftCard(
      currentSubscriptions[0],
      interval,
      isLifetimeDiscount
    );
  }
};
