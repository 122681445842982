import React, { Component } from 'react';
import { OpenedRoutes } from './components';
import { initializeMixpanel } from './utils/trackEvent';
import { startLeanplum } from './utils/leanplum';
import { META_TAGS } from './constants/config';
import MetaTags from './components/sharedPartials/MetaTags';

/**
 * COMPONENT
 */
class App extends Component {
  componentDidMount() {
    initializeMixpanel();
    startLeanplum();
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags
          title={META_TAGS.default.title}
          description={META_TAGS.default.description}
          image={META_TAGS.default.image}
          url={window.location.href}
        />
        <OpenedRoutes />
      </React.Fragment>
    );
  }
}

export default App;
