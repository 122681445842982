import React from 'react';
import styled from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

// Components
import { BoldTitle } from '../homepage/HomepageStyles';
import TrackList from './library/TrackList';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 550px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    min-height: 740px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 696px;
  }
`;

const Title = styled(BoldTitle)`
  color: ${COLORS.white};
  font-size: 24px;
  @media only screen and (min-width: ${SIZES.phone}) {
    font-size: 38px;
  }
`;

const Library = (props) => {
  const { styles } = props;
  return (
    <Container id="library" style={styles}>
      <TitleContainer>
        <Title>
          Listen to new meditations every day that are relevant to your world.
        </Title>
      </TitleContainer>
      <TrackList />
    </Container>
  );
};

export default Library;
