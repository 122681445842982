import styled from 'styled-components';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

export const Container = styled.section`
  align-content: center;
  align-self: center;
  background-color: ${COLORS.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  padding: 32px 20px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 600px
    padding: 40px 24px;
  }
`;

export const NextStepsBulletList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NextStepsBulletContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 32px;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-bottom: 24px;
  }
`;

export const NextStepsBulletPoint = styled.span`
  color: ${COLORS.white};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.02em;
  height: 36px;
  width: 36px;
  min-width: 36px;
  background-color: ${COLORS.darkCyan};
  border-radius: 50%;
  margin: 0 16px 0 24px;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 16px;
    margin: 0 12px 0 0;
  }
`;

export const NextStepsBulletContent = styled.p`
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: ${COLORS.darkMagentaPink};
  text-align: left;
  margin: 0 24px 0 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 16px;
    margin: 0;
  }
`;

export const BodyText = styled(NextStepsBulletContent)`
  font-size: 16px;
  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 12px;
  }
`;

export const Anchor = styled.a`
  align-self: center;
  width: 35%;
  margin: 0 2.5%;
  @media only screen and (max-width: ${SIZES.phone}) {
    width: 50%;
  }
`;

export const Badge = styled.img`
  align-self: center;
  width: 100%;
`;

export const StoreBadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 32px;
`;

export const Divider = styled.div`
  width: 100%;
  border: solid 1px ${COLORS.darkMagentaPink};
  color: ${COLORS.darkMagentaPink};
  margin: 42px 0 30px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin: 32px 0 20px 0;
  }
`;
