import { trackEvent, identifyUser, aliasUser } from './trackEvent';
import * as TYPES from '../constants/actionTypes';
import mixpanel from 'mixpanel-browser';

const mixpanelMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (action.type === TYPES.AUTH_LOGIN_SUCCESS) {
      const {
        user: { uid },
      } = action;
      const {
        enterprise: { companyId, stripeCustomerId },
        promo: { pageId, templateType },
      } = getState();

      identifyUser(uid);
      /**
       * Track company as a super property and user property
       * on login sucess if promo type is b2b
       */
      if (companyId && stripeCustomerId) {
        mixpanel.register({ company: companyId });
        mixpanel.people.set({ company: companyId });
      } else if (templateType === 'b2b' && pageId) {
        mixpanel.register({ company: pageId });
        mixpanel.people.set({ company: pageId });
      }
      trackEvent('User', 'Signin', { platform: 'web', source: 'Email' });
    } else if (
      action.type === TYPES.AUTH_SIGNUP_SUCCESS ||
      action.type === TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS ||
      action.type === TYPES.AUTH_APPLE_LOGIN_SUCCESS
    ) {
      const {
        user: { uid },
      } = action;

      const {
        enterprise: { companyId, stripeCustomerId },
        quiz: {
          results: { id: personalityId, name: personalityName },
        },
        promo: { pageId, templateType },
      } = getState();
      let otherProperties = {};

      if (personalityId) {
        otherProperties.personality_type_id = personalityId;
        otherProperties.personality_name = personalityName;
      }

      let userType;
      if (action.type === TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS) {
        userType = 'Facebook';
      } else if (action.type === TYPES.AUTH_APPLE_LOGIN_SUCCESS) {
        userType = 'Apple';
      } else {
        userType = 'Email';
      }
      aliasUser(uid);

      /**
       * Track company as a super property on
       * signup sucess if promo type is b2b
       */
      if (companyId && stripeCustomerId) {
        mixpanel.register({ company: companyId });
        mixpanel.people.set({ company: companyId });
      } else if (templateType === 'b2b' && pageId) {
        mixpanel.register({ company: pageId });
        mixpanel.people.set({ company: pageId });
      }

      trackEvent('User', 'Signup', {
        platform: 'web',
        source: `${userType}`,
        ...otherProperties,
      });
    }
    return next(action);
  };

export default mixpanelMiddleware;
