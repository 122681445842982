import React from 'react';
import {
  PlanModuleContainer,
  RadioButtonWrapper,
  RadioButton,
  PlanItemWrapper,
  LeftPlanItemContainer,
  PlanTitleContainer,
  PlanName,
  OriginalPlanCost,
  PlanCost,
  PlanDescription,
  RightPlanItemContainer,
  PlanLabel,
  PlanLabelText,
  StrikedOutContainer,
  StrikeTopBottom,
  StrikeBottomTop,
} from './BillingStyles';
import {
  displayPrice,
  displayPriceWithConfigs,
  ANNUAL_PRICE,
} from '../../constants/sharedCopy';
// T
export const PromoInfo = (props) => {
  let discountLabel;
  if (props.discountFlatAmount) {
    discountLabel = displayPrice(
      props.discountFlatAmount,
      props.displayMonthlyPrice
    );
  } else if (props.discountPercent) {
    discountLabel = `${props.discountPercent}%`;
  }

  return (
    <React.Fragment>
      <PlanModuleContainer active higher={props.isBOGOPromo}>
        <RadioButtonWrapper>
          <RadioButton active>
            <div className="active-icon" />
          </RadioButton>
        </RadioButtonWrapper>
        {props.isBOGOPromo ? (
          <PlanItemWrapper>
            <LeftPlanItemContainer>
              <PlanName>{COPY.bogoPromo.planName}</PlanName>
              <PlanTitleContainer>
                <StrikedOutContainer>
                  <OriginalPlanCost
                    dangerouslySetInnerHTML={{
                      __html: COPY.bogoPromo.getOriginalPrice(
                        props.originalCost
                      ),
                    }}
                  />
                </StrikedOutContainer>
                <PlanCost>
                  {COPY.bogoPromo.getDiscountedPrice(props.cost)}
                </PlanCost>
              </PlanTitleContainer>
              <PlanDescription>
                {COPY.bogoPromo.createFinePrint(props.cost)}
              </PlanDescription>
            </LeftPlanItemContainer>
            <RightPlanItemContainer>
              <PlanLabel active wider>
                <PlanLabelText active>{COPY.bogoPromo.planLabel}</PlanLabelText>
              </PlanLabel>
            </RightPlanItemContainer>
          </PlanItemWrapper>
        ) : (
          <PlanItemWrapper>
            <LeftPlanItemContainer>
              <PlanName>{props.billingCycle}</PlanName>
              <PlanTitleContainer>
                <StrikedOutContainer>
                  <OriginalPlanCost>
                    {displayPriceWithConfigs(props.originalCost, {
                      planType: props.billingCycle,
                      convertToMonthly: true,
                    })}
                  </OriginalPlanCost>
                  <StrikeBottomTop />
                  <StrikeTopBottom />
                </StrikedOutContainer>
                <PlanCost>
                  {displayPriceWithConfigs(props.cost, {
                    planType: props.billingCycle,
                    convertToMonthly: true,
                    appendPerMonth: true,
                  })}
                </PlanCost>
              </PlanTitleContainer>
              <PlanDescription>{props.finePrint}</PlanDescription>
            </LeftPlanItemContainer>
            <RightPlanItemContainer>
              <PlanLabel active wider>
                <PlanLabelText active>
                  {COPY.createPlanLabel(discountLabel)}
                </PlanLabelText>
              </PlanLabel>
            </RightPlanItemContainer>
          </PlanItemWrapper>
        )}
      </PlanModuleContainer>
    </React.Fragment>
  );
};

const COPY = {
  createPlanLabel: (discountLabel) => `${discountLabel} off discount applied!`,
  // Change Plan Module Copy specifically for BOGO Promos
  bogoPromo: {
    getOriginalPrice: (price) => `<s>$${Math.ceil(price * 2)} total</s>`,
    getDiscountedPrice: (promoPrice) => `$${promoPrice} total`,
    planName: 'Two Annual Memberships',
    planLabel: '1 year for you + 1 year to gift',
    createFinePrint: (promoPrice) =>
      `Special holiday offer, billed one time as $${promoPrice.toFixed(
        2
      )}. Renews automatically after a year at $${ANNUAL_PRICE}.`,
  },
};
