import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopNav from '../portal/partials/TopNav';
import Footer from '../portal/partials/Footer';
import {
  ButtonLink,
  Page,
  PortalBackground,
  PortalBlobTop,
  PortalBlobBottom,
} from '../portal/styles';
import {
  BackButton,
  SurveyWrapper,
  SurveyContainer,
  SurveyText,
  SurveyTitle,
} from './styles';
import { formatDate } from '../../utils/common';

class ResubscribeConfirmation extends Component {
  // If currently in free trial, show free trial ending copy
  render() {
    const { subscriptionStatus, subscriptionExpDate } = this.props;
    return (
      <Page>
        <TopNav />
        <PortalBackground>
          <PortalBlobTop />
          <SurveyWrapper>
            <ButtonLink to="/subscription">
              <BackButton>{COPY.backButton}</BackButton>
            </ButtonLink>
            <SurveyContainer>
              <SurveyTitle>{COPY.thankYouTitle}</SurveyTitle>
              <SurveyText>
                {subscriptionStatus === 'isInFreeTrialPeriod'
                  ? COPY.freeTrialDescription
                  : COPY.activeSubDescription}
                {formatDate(subscriptionExpDate)}
                {'.'}
              </SurveyText>
              <SurveyText>{COPY.help}</SurveyText>
            </SurveyContainer>
          </SurveyWrapper>
          <PortalBlobBottom />
          <Footer />
        </PortalBackground>
      </Page>
    );
  }
}

const COPY = {
  backButton: '< Back to Subscription',
  thankYouTitle: 'Thanks for being a Shine Premium member.',
  activeSubDescription:
    'Your membership has been extended. You will be charged $32.49 (50% off) your next year of Shine Premium starting on ',
  freeTrialDescription:
    'You will be charged $32.49 (50% off) for your annual Shine Premium membership, which will start after your free trial ends on ',
  help: 'The details on your subscription page will be updated at that time and you will receive an email confirmation. If you have any questions about your subscription, please email our support team at help@headspace.com.',
};

const mapState = ({ user }) => {
  return {
    subscriptionExpDate: user.subscriptionData.subscription.exp_date,
    subscriptionStatus: user.subscriptionData.subscription.subscription_status,
  };
};

export default connect(mapState, null)(ResubscribeConfirmation);
