import React, { Component } from 'react';
import dayjs from 'dayjs';
import { YellowButton, LockIcon } from '../styles';
import {
  SectionWrapper,
  Title,
  EventTitle,
  Description,
  UpcomingEventsWrapper,
  UpcomingEventCard,
  UpcomingEventImage,
  EventDetails,
  DateWrapper,
  DateIcon,
  EventCTA,
} from '../eventStyles';
import calendarIcon from '../../../assets/images/portal/events.svg';
import timeIcon from '../../../assets/images/portal/clock.svg';

import EventArchiveJson from '../EventArchive.json';
import {
  MOBILE_APP_ACCESS,
  EVENTS_PAGE_UPSELL_LINK,
} from '../../../constants/config';

interface Props {
  accessType: string;
}
interface Event {
  title: string;
  date: string;
  time: string;
  imageLink: string;
  registrationLink?: string;
}

class UpcomingEventsSection extends Component<Props> {
  renderRSVPButton(accessType: string, event: Event) {
    if (accessType === MOBILE_APP_ACCESS.PREMIUM && event.registrationLink) {
      return (
        <EventCTA>
          <a
            href={event.registrationLink}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            target="_blank"
            className="rsvp"
          >
            <YellowButton>{COPY.RSVPButton}</YellowButton>
          </a>
        </EventCTA>
      );
    }

    if (accessType === MOBILE_APP_ACCESS.FREE && event.registrationLink) {
      return (
        <EventCTA>
          <a
            href={EVENTS_PAGE_UPSELL_LINK}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            className="rsvp-upgrade"
          >
            <YellowButton>
              <LockIcon />
              <EventCTA>{COPY.upgradeToAttendButton}</EventCTA>
            </YellowButton>
          </a>
        </EventCTA>
      );
    }
  }

  render() {
    const { accessType } = this.props;

    // Return events that are before the current date and time
    const upcomingEventsOnly = EventArchiveJson.filter((event: Event) =>
      dayjs().isBefore(dayjs(event.date))
    );

    if (upcomingEventsOnly.length === 0) {
      return null;
    } else {
      return (
        <>
          <SectionWrapper>
            <Title>{COPY.title}</Title>
            <Description>{COPY.description}</Description>
          </SectionWrapper>
          <UpcomingEventsWrapper>
            {upcomingEventsOnly.map((event) => (
              <UpcomingEventCard>
                <UpcomingEventImage src={event.imageLink} />
                <EventDetails style={{ padding: '30px' }}>
                  <EventTitle>{event.title}</EventTitle>
                  <DateWrapper>
                    <DateIcon src={calendarIcon} />
                    <Description>{event.date}</Description>
                  </DateWrapper>
                  <DateWrapper>
                    <DateIcon src={timeIcon} />
                    <Description>{event.time}</Description>
                  </DateWrapper>
                  {this.renderRSVPButton(accessType, event)}
                </EventDetails>
              </UpcomingEventCard>
            ))}
          </UpcomingEventsWrapper>
        </>
      );
    }
  }
}

const COPY = {
  title: 'Upcoming Events',
  description: 'Register for our latest community events.',
  RSVPButton: 'RSVP to Attend',
  upgradeToAttendButton: 'Upgrade to Attend',
};

export default UpcomingEventsSection;
