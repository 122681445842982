import React from 'react';
import { Link } from 'react-router-dom';
// Page Components
import { LogoHeader } from '../sharedPartials';
import { FormSubmitButton, ButtonText } from '../../constants/styles';
// StyleSheets
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
  PitchPageContainer,
  PitchPageImage,
  PitchContentWrapper,
  PitchContentContainer,
  PitchTitle,
  PitchBulletWrapper,
  PitchBulletContainer,
  PitchBulletIcon,
  PitchBulletContentContainer,
  PitchBulletSubtitle,
  PitchBulletDescription,
} from './styles';
// Constants
import {
  pitchIconCommunity,
  pitchIconCustomization,
  pitchIconMeditation,
} from '../../constants/image.config';

const PitchPage = () => {
  return (
    <CheckoutBackground>
      <CheckoutBlobTop />
      <CheckoutPageContainer>
        <LogoHeader />
        <PitchPageContainer>
          <PitchPageImage />
          <PitchContentWrapper>
            <PitchContentContainer>
              <PitchTitle>{COPY.title}</PitchTitle>
              <PitchBulletWrapper>
                {COPY.bullets.map((bullet) => {
                  return (
                    <PitchBulletContainer key={bullet.subtitle}>
                      <PitchBulletIcon src={bullet.icon} />
                      <PitchBulletContentContainer>
                        <PitchBulletSubtitle>
                          {bullet.subtitle}
                        </PitchBulletSubtitle>
                        <PitchBulletDescription>
                          {bullet.description}
                        </PitchBulletDescription>
                      </PitchBulletContentContainer>
                    </PitchBulletContainer>
                  );
                })}
              </PitchBulletWrapper>
              <Link to="/signup">
                <FormSubmitButton className="pitch">
                  <ButtonText>{COPY.button}</ButtonText>
                </FormSubmitButton>
              </Link>
            </PitchContentContainer>
          </PitchContentWrapper>
        </PitchPageContainer>
      </CheckoutPageContainer>
      <CheckoutBlobBottom />
    </CheckoutBackground>
  );
};

export default PitchPage;

const COPY = {
  title: 'Build more skills to care for your mental health.',
  bullets: [
    {
      icon: pitchIconMeditation,
      subtitle: 'Meditations created by diverse experts',
      description: 'Access an exclusive library of 1,000+ meditations',
    },
    {
      icon: pitchIconCommunity,
      subtitle: 'Personalized tools and content based on your needs',
      description:
        'Get personalized meditations based on how you’re feeling in the moment',
    },
    {
      icon: pitchIconCustomization,
      subtitle: 'Support from an inclusive community and mental health experts',
      description:
        'Get invited to monthly community care events, plus a private digital community for 1:1 support',
    },
  ],
  button: 'Continue',
};
