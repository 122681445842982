import styled from 'styled-components';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { Link as RouterLink } from 'react-router-dom';

export const BoldTitle = styled.h1`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: ${COLORS.darkMagentaPink};
  font-size: 1.8em;
`;

export const BoldSubtitle = styled.h2`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  font-size: 38px;
  text-align: center;
  color: ${COLORS.darkMagentaPink};
`;

export const DefaultText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${COLORS.darkMagentaPink};
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  cursor: pointer;
`;
