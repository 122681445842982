import React from 'react';
import { Line } from 'rc-progress';
import COLORS from '../../constants/colors';
import { ScrubberContainer, TimeText } from './styles';

const Scrubber = ({ progressPercent, currentTime, remainingTime, theme }) => {
  return (
    <ScrubberContainer>
      <TimeText theme={theme}>{convertTime(currentTime)}</TimeText>
      <Line
        percent={progressPercent}
        strokeWidth="1.5"
        strokeColor={theme === 'dark' ? COLORS.white : COLORS.blackTwo}
        trailWidth="1.5"
        trailColor={theme === 'dark' ? COLORS.blackTwo : COLORS.white}
        style={{ height: '5px', width: '65%' }}
      />
      <TimeText theme={theme}>{convertTime(remainingTime)}</TimeText>
    </ScrubberContainer>
  );
};

export default Scrubber;

// Convert time (sec) to timestamp
const convertTime = timestamp => {
  let minutes = Math.floor(timestamp / 60);
  let seconds = timestamp - minutes * 60;
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return `${minutes}:${seconds}`;
};
