import React, { useState } from 'react';
import {
  SurveyContainer,
  SurveyTitle,
  SurveyText,
  TextArea,
  SurveyYellowButton,
} from './styles';
import { SurveyConfig } from '../../types/retention-survey';

const SurveyPageP5 = (props) => {
  const { onSubmit } = props;
  const [text, setText] = useState('');

  return (
    <SurveyContainer>
      <SurveyTitle>{COPY.title}</SurveyTitle>
      <SurveyText>{COPY.description}</SurveyText>
      <TextArea
        placeholder={COPY.placeholder}
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
      <SurveyYellowButton
        onClick={() =>
          onSubmit({
            question: COPY.placeholder,
            answer: text,
            nextPage: SurveyConfig.FEEDBACK_SUBMITTED,
            cancelSub: false,
          })
        }
      >
        {COPY.buttonSubmit}
      </SurveyYellowButton>
    </SurveyContainer>
  );
};

const COPY = {
  title: 'You have successfully cancelled your membership.',
  description:
    "You'll be able to access the benefits of Shine Premium until your subscription end date and you can re-subscribe at any time.",
  placeholder:
    'Would you be open to sharing more on how we can improve Shine Premium in the future?',
  buttonSubmit: 'Submit',
};

export default SurveyPageP5;
