/*
 *
 * Billing actions
 *
 */
import {
  createCustomer,
  findCustomer,
  subscribeUser,
  updateCustomer,
  upsertSubscriptionSchedule,
  releaseSubscriptionSchedule,
  findSubscriptionSchedule,
  cancelSubscriptionAtPeriodEnd,
} from '../utils/stripeUtils';
import {
  DEFAULT_ACTION,
  SELECT_PAYMENT_PLAN,
  SUBSCRIBE_USER_SUCCESS,
  SUBSCRIBE_USER_FAILED,
  INVALID_PROMO_ID,
  EXTEND_SUBSCRIPTION_SUCCESS,
  EXTEND_SUBSCRIPTION_FAILED,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILED,
} from '../constants/actionTypes';
import history from '../history';
import { getPromoContent } from '../api/contentfulClient';
import { ANNUAL, MONTHLY } from '../constants/sharedCopy';
import { isBOGOPromo } from '../constants/config';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export const selectPaymentPlan = (planName) => async (dispatch) => {
  let promoContent;
  if (planName !== ANNUAL && planName !== MONTHLY) {
    try {
      // For promotions, this will be the 'pageId' in Contentful
      promoContent = await getPromoContent(planName);
    } catch (err) {
      console.error(err);
      dispatch({ type: INVALID_PROMO_ID });
    }
  }
  dispatch({ type: SELECT_PAYMENT_PLAN, data: { planName, promoContent } });
};

export const switchPaymentPlan = (planName) => async (dispatch) => {
  let promoContent;
  if (planName !== ANNUAL && planName !== MONTHLY) {
    try {
      promoContent = await getPromoContent(planName);
    } catch (err) {
      console.error(err);
      dispatch({ type: INVALID_PROMO_ID });
    }
  }
  dispatch({ type: SELECT_PAYMENT_PLAN, data: { planName, promoContent } });
};

/**
 * @typedef {object} CreateSubscriptionParameter
 * @property {string | null} token Token for default card payment
 * @property {InputUserData} user Customer data
 * @property {string} planId Plan ID to subscribe to
 * @property {string | null} couponId Coupon ID
 * @property {boolean} isTrial Set to true to start subscription with a free trial
 * @property {object} utmParams From Stripe passed to ChartMOgul to track performance
 * @property {object} b2bTags Set for B2B end users under V1 system to suppress Stripe emails
 */

/**
 * Subscribe a customer to a new subscription.
 *
 * @param {CreateSubscriptionParameter} params
 */
export const createSubscription = (params) => async (dispatch) => {
  const {
    token,
    user,
    planId,
    couponId,
    isTrial,
    discountPercent,
    discountFlatAmount,
    pageId,
    utmParams = {},
    b2bTags = {},
  } = params;
  try {
    // Find and create or update customer and their default card payment
    let stripeId;
    let customer = await findCustomer(user.email);
    if (customer) {
      stripeId = customer.id;
      await updateCustomer(stripeId, {
        ...user,
        token,
        discountPercent,
        discountFlatAmount,
        pageId,
        ...utmParams,
        ...b2bTags,
      });
    } else {
      stripeId = await createCustomer({
        ...user,
        token,
        ...utmParams,
        ...b2bTags,
      });
    }

    // Subscribe user to selected plan
    await subscribeUser(stripeId, planId, couponId, isTrial);
    dispatch({ type: SUBSCRIBE_USER_SUCCESS });

    if (isBOGOPromo(couponId)) {
      history.push(`/send-gift`);
    } else {
      history.push(`/confirmation`);
    }
  } catch (error) {
    dispatch({ type: SUBSCRIBE_USER_FAILED });
    console.error(error);
    throw error;
  }
  return;
};

/**
 *
 * Extends a customer's subscription with the given params.
 *
 * @typedef {object} SubscriptionScheduleParameters
 * @property {string} subscriptionId Stripe subscription ID
 * @property {string} planId Plan ID to subscribe to for the next phase in the subscription schedule
 * @property {string} couponId Coupon ID to apply to the next phase of subscription schedule
 *
 * @param {SubscriptionScheduleParameters} params
 */
export const extendSubscription = (params) => async (dispatch) => {
  try {
    await upsertSubscriptionSchedule(params);
    dispatch({ type: EXTEND_SUBSCRIPTION_SUCCESS });
    window.dataLayer.push({
      event: 'extend-subscription',
    });
    history.push('/resubscribe-confirmation');
  } catch (error) {
    dispatch({ type: EXTEND_SUBSCRIPTION_FAILED });
    console.error(error);
    window.alert(
      'Error upserting subscription schedule.  Please reach out to help@headspace.com for assistance.'
    );
  }
};

/**
 * Cancel a customer's subscription. If they have a subscription schedule, release it first, then cancel subscription at period end.
 *
 * @param {string|undefined} subscriptionId
 */
export const cancelSubscription = (subscriptionId) => async (dispatch) => {
  let scheduleId;
  const existingSchedule = await findSubscriptionSchedule(subscriptionId);

  if (existingSchedule) {
    scheduleId = existingSchedule.id;
    try {
      await releaseSubscriptionSchedule(scheduleId);
    } catch (error) {
      console.error(error);
      window.alert(
        'Error encountered on releasing subscription schedule. Please reach out to help@headspace.com for assistance.'
      );
      throw error;
    }
  }

  try {
    await cancelSubscriptionAtPeriodEnd(subscriptionId);
    dispatch({ type: CANCEL_SUBSCRIPTION_SUCCESS });
    window.dataLayer.push({
      event: 'cancel-subscription',
    });
  } catch (error) {
    dispatch({ type: CANCEL_SUBSCRIPTION_FAILED });
    console.error(error);
    window.alert(
      'Error encountered on cancelling subscription. Please reach out to help@headspace.com for assistance.'
    );
    throw error;
  }
};
