import React from 'react';
import {
  SurveyContainer,
  SurveyTitle,
  SurveyText,
  SurveyIconContainer,
  SurveyBulletText,
  SurveyIconWrapper,
  SurveyYellowBorderButton,
  ButtonsWrapperHorizontal,
  ThumbsUpIcon,
  MeditationIcon,
  StarBadgeIcon,
  HoldingHeartIcon,
} from './styles';
import { SurveyConfig } from '../../types/retention-survey';

const SurveyPageP0 = (props) => (
  <SurveyContainer>
    <SurveyTitle>{COPY.title}</SurveyTitle>
    <SurveyText>{COPY.subTitle}</SurveyText>
    <SurveyIconContainer>
      {COPY.surveyBullets.map((item) => (
        <SurveyIconWrapper key={item.description}>
          {item.icon}
          <SurveyBulletText>{item.description}</SurveyBulletText>
        </SurveyIconWrapper>
      ))}
    </SurveyIconContainer>
    <SurveyText dangerouslySetInnerHTML={{ __html: COPY.proceedText }} />
    <ButtonsWrapperHorizontal>
      <SurveyYellowBorderButton
        className="cancel-yes"
        onClick={() => props.updateSurveyPage(SurveyConfig.REASONS)}
      >
        {COPY.buttonYes}
      </SurveyYellowBorderButton>
      <SurveyYellowBorderButton
        className="cancel-no"
        style={{ marginLeft: '10px' }}
        onClick={() => props.updateSurveyPage(SurveyConfig.STAY_GIVE_FEEDBACK)}
      >
        {COPY.buttonNo}
      </SurveyYellowBorderButton>
    </ButtonsWrapperHorizontal>
  </SurveyContainer>
);

const COPY = {
  title:
    'Shine Premium members play a key role in helping us make self-care more accessible and inclusive.',
  subTitle: 'Our business is funded by subscriptions which have allowed us to:',
  surveyBullets: [
    {
      icon: <ThumbsUpIcon />,
      description: 'Make the Daily Shine free for all.',
    },
    {
      icon: <MeditationIcon />,
      description:
        'Create free meditations to support Black Mental Health, LGBTQ+ Mental Health, and AAPI Mental Health.',
    },
    {
      icon: <StarBadgeIcon />,
      description:
        'Provide 2,000 free memberships for essential workers on the frontlines of the COVID-19 pandemic.',
    },
    {
      icon: <HoldingHeartIcon />,
      description:
        "Support non-profit organizations like Sad Girls Club, the National Queer and Trans Therapists Color Network, The Foundation for Black Women's Wellness, and more.",
    },
  ],
  proceedText:
    "By cancelling your membership, you'll also lose access to the library of 1,000 meditations, your daily mood check-in, and invites to monthly community care events.<br><br>Would you still like to proceed with cancelling your Shine Premium membership?",
  buttonYes: 'Yes',
  buttonNo: 'No',
};

export default SurveyPageP0;
