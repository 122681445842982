/**
 * Util for backend api calls shared between our frontend and backend code.
 */

export const API_ACTIONS = {
  // POST actions
  SAVE_FEEDBACK: 'saveFeedback',
  REDEEM_ENTERPRISE_SUBSCRIPTION: 'redeemEnterpriseSubscription',

  // GET actions
};
