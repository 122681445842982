import React, { useState } from 'react';
import {
  SurveyContainer,
  SurveyText,
  TextArea,
  SurveyYellowButton,
} from './styles';
import { SurveyConfig } from '../../types/retention-survey';

const SurveyPageP6 = (props) => {
  const [text, setText] = useState('');
  const { onSubmit } = props;

  return (
    <SurveyContainer>
      <SurveyText>{COPY.question}</SurveyText>
      <TextArea
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
      <SurveyYellowButton
        onClick={() =>
          onSubmit({
            question: COPY.question,
            answer: text,
            nextPage: SurveyConfig.FEEDBACK_SUBMITTED,
            cancelSub: true,
          })
        }
      >
        {COPY.buttonSubmit}
      </SurveyYellowButton>
    </SurveyContainer>
  );
};

const COPY = {
  question:
    'Would you be open to sharing more on how we can improve Shine Premium in the future?',
  buttonSubmit: 'Submit & Cancel Subscription',
};

export default SurveyPageP6;
