import React from 'react';
import styled from 'styled-components';
import SIZES from '../../../constants/screenSizes';
import COLORS from '../../../constants/colors';
import { rightBlob } from '../../../constants/image.config';
import { Section, Title } from '../B2BStyles';
import HubspotContactForm from './HubspotForm';

const WhiteTitle = styled(Title)`
  color: ${COLORS.white};
`;

const TealSection = styled(Section)`
  flex-direction: column;
  padding: 30px;
  background-color: ${COLORS.darkModerateCyan};
  background-image: url(${rightBlob});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 20%;

  @media only screen and (max-width: ${SIZES.desktop}) {
    padding: 25px;
    background-size: 30%;
  }
`;

const HubspotFormSection = () => (
  <TealSection id="b2b-contact-form">
    <WhiteTitle>{COPY.title}</WhiteTitle>
    <HubspotContactForm />
  </TealSection>
);

const COPY = {
  title: 'Learn how to bring Shine at Work to your team',
};

export default HubspotFormSection;
