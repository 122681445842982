import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../../constants/styles';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageLoading = () => {
  return (
    <Container>
      <Spinner style={{ margin: '0', width: '80px', height: '80px' }} />
    </Container>
  );
};

export default PageLoading;
