/**
 * `components/index.js` exists simply as a 'central export' for our components.
 * This way, we can import all of our components from the same place, rather than
 * having to figure out which file they belong to!
 * inspired by: https://github.com/FullstackAcademy/boilermaker
 */

export { default as BillingPage } from './billing/BillingPage';
export { default as PitchPage } from './checkout/PitchPage';

export { default as LoginPage } from './form/LoginPage';
export { default as SignupPage } from './form/SignupPage';
export { default as PasswordResetForm } from './form/PasswordResetForm';

export { default as ProtectedRoutes } from './navigation/ProtectedRoutes'; // Routes only avail after auth
export { default as OpenedRoutes } from './navigation/OpenedRoutes'; // Routes that are open to public

export { default as Confirmation } from './checkout/Confirmation';
export { default as PromoChecker } from './PromoChecker';

//Gifting
export { default as PurchaseGiftCard } from './gifting/PurchaseGiftCard';
export { default as SignupGiftCard } from './gifting/SignupGiftCard';
export { default as LoginGiftCard } from './gifting/LoginGiftCard';

//Audio Player
export { Player } from './audio';
export { default as AudioPage } from './audio/AudioPage';

// B2B Pages
export { default as B2BPage } from './b2b/B2BPage';
export { default as SMBCheckoutPage } from './b2b/SMBCheckoutPage';
export { default as SMBCheckoutConfirmation } from './b2b/SMBCheckoutConfirmation';

// Compassion Quiz Results
export { default as QuizResults } from './quiz/Results';

// About Page
export { default as AboutPage } from './about/AboutPage';

// Send Gift Page
export { default as SendGiftPage } from './gifting/SendGiftPage';

// Portal Pages
export { default as AccountPage } from './portal/AccountPage';
export { default as SubscriptionPage } from './portal/SubscriptionPage';
export { default as CancelPage } from './portal/CancelPage.tsx';
export { default as ResubscribeConfirmation } from './retention-survey/ResubscribeConfirmation';
export { default as EventsPage } from './portal/EventsPage.tsx';
