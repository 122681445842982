import React from 'react';
import styled from 'styled-components';

// Constants
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';
import FONTS from '../../../constants/fonts';
import { marahNaomi, marahNaomiWebp } from '../../../constants/image.config';
import { DefaultText } from '../../about/AboutPageStyles';
import { YellowButton } from '../../../constants/styles/buttons';

// Utils
import { ImgWithFallback } from '../../../utils/common';

const Section = styled.section`
  display: flex;
  flex-direction: row;
  background: ${COLORS.lightYellow};
  z-index: 1;

  @media only screen and (max-width: ${SIZES.laptop}) {
    flex-direction: column-reverse;
  }
`;

const MarahNaomi = styled(ImgWithFallback)`
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  min-height: 850px;
  max-height: 525px;

  @media only screen and (max-width: ${SIZES.laptop}) {
    max-height: 550px;
    min-height: 224px;
    width: 200%;
  }
`;

const TextContainer = styled.div`
  width: 50%;
  align-self: center;
  max-width: 710px;

  @media only screen and (max-width: ${SIZES.laptop}) {
    width: 85%;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    margin-bottom: 25px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8%;

  @media only screen and (max-width: ${SIZES.laptop}) {
    margin: 6% 0;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    margin: 10% 3% 0;
  }
`;

const Title = styled.h3`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.02em;
  text-align: left;
  color: ${COLORS.blackTwo};
  margin: 0 0 28px 0;
`;

const Description = styled(DefaultText)`
  max-width: 710px;
  margin: 0;
  color: ${COLORS.darkMagentaPink};
  font-size: 18px;
  line-height: 150%;

  @media only screen and (max-width: ${SIZES.laptop}) {
    width: 100%;
    font-size 16px;
  }
`;

const StyledLink = styled.a`
  text-decoration: none;
  margin-top: 28px;
`;

const FounderButton = styled(YellowButton)`
  width: 220px;
`;

const Header = () => (
  <Section>
    <MarahNaomi
      src={marahNaomiWebp}
      fallback={marahNaomi}
      altText="Shine at Work co-founders Marah Lidey, a Black woman, and Naomi Hirabayashi, a half-Japanese woman, smiling at the camera"
      styles={{ width: '50%' }}
    />
    <TextContainer>
      <TextWrapper>
        <Title>
          We started Shine at Work to create more psychologically safe
          workplaces.
        </Title>
        <Description dangerouslySetInnerHTML={{ __html: COPY.description }} />
        <StyledLink href="/about">
          <FounderButton>{COPY.btnCopy}</FounderButton>
        </StyledLink>
      </TextWrapper>
    </TextContainer>
  </Section>
);

export default Header;

const COPY = {
  btnCopy: 'Read More About Shine',
  description: `
At Shine, we’re on a mission to make caring for our mental and emotional health easier, more representative, and more inclusive—of <i>all</i> of our experiences.
<br/><br/>
We started Shine because we didn’t see ourselves—a Black woman and a half-Japanese woman—and our experiences represented in mainstream “wellness.” We met as coworkers, and the support we found in each other changed everything. 
<br/><br/>
We knew we had to help more people cope, process, and heal on a daily basis—and help more workplaces prioritize mental health care.
<br/><br/>
We’re proud to say that Shine at Work is the most inclusive mental health benefit in the market and a proven, accessible tool to help your employees navigate depression, anxiety, stress, and more—and feel seen and supported along the way.`,
};
