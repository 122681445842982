export const AUTH_APPLE_LOGIN_REQUEST = 'AUTH_APPLE_LOGIN_REQUEST';
export const AUTH_APPLE_LOGIN_SUCCESS = 'AUTH_APPLE_LOGIN_SUCCESS';
export const AUTH_APPLE_LOGIN_FAIL = 'AUTH_APPLE_LOGIN_FAIL';

export const AUTH_FACEBOOK_LOGIN_REQUEST = 'AUTH_FACEBOOK_LOGIN_REQUEST';
export const AUTH_FACEBOOK_LOGIN_SUCCESS = 'AUTH_FACEBOOK_LOGIN_SUCCESS';
export const AUTH_FACEBOOK_LOGIN_FAIL = 'AUTH_FACEBOOK_LOGIN_FAIL';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_POST_LOGIN_COMPLETE = 'AUTH_POST_LOGIN_COMPLETE';

export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

export const AUTH_SAVE_FIRST_NAME = 'AUTH_SAVE_FIRST_NAME';
export const AUTH_CLEAR_ERROR_STATE = 'AUTH_CLEAR_ERROR_STATE';
export const AUTH_DISPLAY_ERROR_STATE = 'AUTH_DISPLAY_ERROR_STATE';

export const AUTH_REAUTHENTICATE_REQUEST = 'AUTH_REAUTHENTICATE_REQUEST';
export const AUTH_REAUTHENTICATE_SUCCESS = 'AUTH_REAUTHENTICATE_SUCCESS';
export const AUTH_REAUTHENTICATE_FAIL = 'AUTH_REAUTHENTICATE_FAIL';

export const AUTH_SIGNUP_REQUEST = 'AUTH_SIGNUP_REQUEST';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_FAIL = 'AUTH_SIGNUP_FAIL';

export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_COMPLETE = 'AUTH_COMPLETE';

export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAIL = 'AUTH_RESET_PASSWORD_FAIL';

export const DEFAULT_ACTION = 'DEFAULT_ACTION';

export const SELECT_PAYMENT_PLAN = 'SELECT_PAYMENT_PLAN';
export const SUBSCRIBE_USER_SUCCESS = 'SUBSCRIBE_USER_SUCCESS';
export const SUBSCRIBE_USER_FAILED = 'SUBSCRIBE_USER_FAILED';
export const INVALID_PROMO_ID = 'INVALID_PROMO_ID';
export const GET_PROMO_DATA = 'GET_PROMO_DATA';
export const SAVE_UTM_PARAMS = 'SAVE_UTM_PARAMS';
export const SAVE_EMAIL_FROM_URL = 'SAVE_EMAIL_FROM_URL';

export const REDEEM_GIFT_SUBSCRIPTION_REQUEST =
  'REDEEM_GIFT_SUBSCRIPTION_REQUEST';
export const REDEEM_GIFT_SUBSCRIPTION_SUCCESS =
  'REDEEM_GIFT_SUBSCRIPTION_SUCCESS';
export const REDEEM_GIFT_SUBSCRIPTION_FAILED =
  'REDEEM_GIFT_SUBSCRIPTION_FAILED';

export const QUIZ_COMPLETED = 'QUIZ_COMPLETED';
export const UPDATE_QUIZ_LIST = 'UPDATE_QUIZ_LIST';

// Checking backend for user's subscription status
export const GET_SUBSCRIPTION_REQUEST = 'GET_SUBSCRIPTION_REQUEST';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAIL = 'GET_SUBSCRIPTION_FAIL';
export const SAVE_SUBSCRIPTION_DATA = 'SAVE_SUBSCRIPTION_DATA';

export const EXTEND_SUBSCRIPTION_SUCCESS = 'EXTEND_SUBSCRIPTION_SUCCESS';
export const EXTEND_SUBSCRIPTION_FAILED = 'EXTEND_SUBSCRIPTION_FAILED';

export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED';

// Get enterprise configuration stored on contentful
export const GET_ENTERPRISE_CONTENT_REQUEST = 'GET_ENTERPRISE_CONTENT_REQUEST';
export const GET_ENTERPRISE_CONTENT_SUCCESS = 'GET_ENTERPRISE_CONTENT_SUCCESS';
export const GET_ENTERPRISE_CONTENT_FAIL = 'GET_ENTERPRISE_CONTENT_FAIL';

// Redeem enterprise subscription
export const REDEEM_ENTERPRISE_SUBSCRIPTION_REQUEST =
  'REDEEM_ENTERPRISE_SUBSCRIPTION_REQUEST';
export const REDEEM_ENTERPRISE_SUBSCRIPTION_SUCCESS =
  'REDEEM_ENTERPRISE_SUBSCRIPTION_SUCCESS';
export const REDEEM_ENTERPRISE_SUBSCRIPTION_FAIL =
  'REDEEM_ENTERPRISE_SUBSCRIPTION_FAIL';

export const SAVE_MEMBER_ID = 'SAVE_MEMBER_ID';
