import Leanplum from 'leanplum-sdk';

const appId = process.env.REACT_APP_LEANPLUM_APP_ID;
const clientKey = process.env.REACT_APP_LEANPLUM_CLIENT_KEY;

export const startLeanplum = () => {
  const isDevelopmentMode = process.env.NODE_ENV !== 'production';

  if (isDevelopmentMode) {
    Leanplum.setAppIdForDevelopmentMode(appId, clientKey);
  } else {
    Leanplum.setAppIdForProductionMode(appId, clientKey);
  }
};

/**
 * Set user's firebase Id as unique identifier in Leanplum
 *
 * @param {string} firebaseId
 * @return {void}
 */
export const setUserIdInLeanplum = (firebaseId) => {
  if (!firebaseId) {
    console.warn(
      `Missing firebaseId: Failed set firebaseId as user id in Leanplum`
    );
    return;
  }
  Leanplum.setUserId(firebaseId);
};
