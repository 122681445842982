import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'react-router-hash-link';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import SIZES from '../../../constants/screenSizes';
import { Section, Title, Subtitle, TwoColumnContainer } from '../B2BStyles';
import { lightYellowBlobBottom } from '../../../constants/image.config';

const shineAtWork = require('../../../assets/video/shine-at-work-program-no-events.mp4');

const BlobWrapper = styled.div`
  background-image: url(${lightYellowBlobBottom});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
`;

const ClearSection = styled(Section)`
  background: ${COLORS.transparent};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 50px 100px 50px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin: 25px;
  }
`;

const VideoContainer = styled.video`
  width: inherit;
  margin: 0 50px 0 0;
  max-width: 508px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    max-width: 100%;
    height: auto;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 508px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    max-width: 100%;
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const ListIcon = styled.div`
  min-width: 34px;
  height: 34px;
  border-radius: 17px;
  background-color: ${COLORS.lightYellow};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  p {
    font-family: ${FONTS.poppinsPrimaryRegular};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: ${COLORS.darkMagentaPink};
  }
  @media only screen and (min-width: ${SIZES.phone}) {
    min-width: 44px;
    height: 44px;
    border-radius: 22px;
    p {
      font-size: 20px;
    }
  }
`;

const ListItemText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 30px 0;

  @media only screen and (min-width: ${SIZES.phone}) {
    font-style: normal;
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.02em;
  }
`;

export const StyledLink = styled(HashLink)`
  text-decoration: none;
  margin-bottom: 25px;
`;

const renderListItems = (steps: Array<string>) => {
  return steps.map((step: string, i: number) => (
    <ListItem key={step}>
      <ListIcon>
        <p>{i + 1}</p>
      </ListIcon>
      <ListItemText>{step}</ListItemText>
    </ListItem>
  ));
};

const ProgramSection = () => (
  <BlobWrapper>
    <ClearSection>
      <Title>{COPY.title}</Title>
      <Subtitle>{COPY.description}</Subtitle>
      <TwoColumnContainer>
        <VideoContainer autoPlay loop muted playsInline>
          <source src={shineAtWork} type="video/mp4" />
        </VideoContainer>
        <ListContainer>{renderListItems(COPY.programSteps)}</ListContainer>
      </TwoColumnContainer>
    </ClearSection>
  </BlobWrapper>
);

const COPY = {
  title: ' Support your team with the Shine at Work program',
  description:
    'Create a psychologically safe workplace with the Shine at Work program, rooted in culturally competent content.',
  programSteps: [
    'Meditations voiced by a diverse group of experts, daily mood and gratitude check-ins, recommended articles',
    'Resources and programming to support employee engagement',
    'Expert-led events and workshops around employee wellbeing',
    'Easy-to-use reporting and analytics dashboard',
  ],
  btnCopy: 'Get Started',
};
export default ProgramSection;
