import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import qS from 'querystring';

// Actions
import { getPromoData } from '../actions/promo';

// Constants
import { B2B } from '../constants/sharedCopy';
import { excludedB2bMigrationCompanyIds } from '../constants/config';

// Components
import { TemplateHandler } from './promoTemplates';

/*
 * NOTE / TODO: Might need to refactor or rename this component.
 * Originally, this was intended to create landing pages for promotions
 * However, we now wish to create landing pages without promotions or other variations of lp
 * Thus, it can be misleading for this Component to be named 'PromoChecker' as its main function
 * Is to get copy from Contentful in order to render the lp; regardless of promo or non-promo
 */
class PromoChecker extends Component {
  async componentWillMount() {
    const { promoCode: urlSlug } = this.props.match.params;
    this.props.loadInitialData(urlSlug);
  }

  render() {
    const { isChecking, pageId, templateType } = this.props;
    const queryString = this.props.location.search.split('?')[1];
    let { name } = qS.parse(queryString);

    if (isChecking) {
      return null;
    } else {
      // The presence of 'pageId' signifies that we want to create a page using one of the templates
      if (templateType && templateType === B2B && pageId) {
        if (excludedB2bMigrationCompanyIds.includes(pageId)) {
          return <TemplateHandler nameOfInviter={name} />;
        }
        return <Redirect to={`/work/${pageId}`} />;
      } else if (pageId && templateType) {
        return <TemplateHandler nameOfInviter={name} />;
      } else {
        return <Redirect to="/" />;
      }
    }
  }
}

/**
 * CONTAINER
 */
const mapState = (state) => {
  return {
    isChecking: state.promo.isChecking,
    pageId: state.promo.pageId,
    templateType: state.promo.templateType,
  };
};

const mapDispatch = (dispatch) => {
  return {
    loadInitialData(urlSlug) {
      dispatch(getPromoData(urlSlug));
    },
  };
};

export default connect(mapState, mapDispatch)(PromoChecker);

/**
 * PROP TYPES
 */
PromoChecker.propTypes = {
  isChecking: PropTypes.bool.isRequired,
  pageId: PropTypes.string.isRequired,
  templateType: PropTypes.string.isRequired,
  loadInitialData: PropTypes.func.isRequired,
};
