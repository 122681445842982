import React from 'react';
import styled from 'styled-components';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';

// Assets
import { pinkBlob } from '../../constants/image.config';

// Components
import { BoldTitle, DefaultText } from './AboutPageStyles';

const Section = styled.section`
  background-color: ${COLORS.white};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PinkBlob = styled.img.attrs({ src: pinkBlob })`
  align-self: flex-end;
  margin-top: -20%;

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin-top: -45%;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    margin-top: -50%;
  }
  @media only screen and (max-width: ${SIZES.iphone6Width}) {
    margin-top: -85%;
  }
`;

const TextWrapper = styled.div`
  max-width: 1200px;
  min-width: 720px;
  width: 90%
  display: flex;
  flex-direction: column;
  margin: 100px 0px 0px 0px;
  align-self: center;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 85%;
    max-width: unset;
    min-width: unset;
    margin: 60px 0px 0px 0px;
  }
`;

const Title = styled(BoldTitle)`
  margin: 0 0 60px 0;
  width: 100%;
  color: ${COLORS.darkMagentaPink};

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin: 0 0 60px 0;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media only screen and (max-width: ${SIZES.phone}) {
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  width: 45%;
  margin: 0 24px 60px 0;
  flex-direction: column;
  max-width: unset;
  z-index: 1;

  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    max-width: 344px;
    width: 30%;
    margin: 0 34px 60px 0;
  }

  @media only screen and (max-width: ${SIZES.tablet}) {
    max-width: unset;
    margin: 0 24px 60px 0;
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 100%;
    max-width: unset;
    margin: 0 0px 40px 0;
  }
`;
const SubTitle = styled(Title)`
  min-height: 90px;
  font-size: 32px;
  font-weight: 300;
  line-height: 140%;
  margin: 0 0 12px 0;

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 22px;
    min-height: 60px;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    min-height: unset;
    margin: 0 0 12px 0;
  }
`;
const Description = styled(DefaultText)`
  width: 100%;
  color: ${COLORS.darkMagentaPink};
  line-height: 160%;
  text-align: left;
  margin: 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    line-height: 140%;
  }
`;

const TeamValuesSection = () => (
  <Section id="team-values-section">
    <TextWrapper>
      <Title>Our Values</Title>
      <ListContainer>
        {VALUES_COPY.map((value) => (
          <ItemContainer>
            <SubTitle dangerouslySetInnerHTML={{ __html: value.subtitle }} />
            <Description>{value.description}</Description>
          </ItemContainer>
        ))}
      </ListContainer>
    </TextWrapper>
    <PinkBlob />
  </Section>
);

export default TeamValuesSection;

const VALUES_COPY = [
  {
    subtitle: 'We’re <strong> fueled by our frustration. </strong>',
    description:
      'Our desire to disrupt the pricey, preachy, and privileged world of wellness is what gets us out of bed each morning. ',
  },
  {
    subtitle: 'We <strong> own </strong> it.',
    description: `We don’t take our power to make a difference lightly. We're proud when we get it right and we're humbled by our learnings. Whether it's a high or a low—we own it.
    `,
  },
  {
    subtitle: 'We <strong> Go Big </strong> and <strong> Go Home. </strong>',
    description:
      'We practice sustainable ambition, amplifying our impact while taking care of ourselves along the way.',
  },
  {
    subtitle: 'We keep it <strong> 100. </strong>',
    description: `A 50-50 relationship means one of us can't have an off day. We live by the 100-100 principle and meet our members and teammates all the way, with proactive support and empathy.`,
  },
  {
    subtitle: 'We <strong> find a way. </strong>',
    description:
      'We lead with solutions. We greet challenges as creative opportunities and trust our resilience to carry us through.',
  },
];
