import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

// Components
import {
  renderShineLogo,
  renderPartnerLogo,
} from '../promoTemplates/promoPartials/Header';

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoHeader = ({
  isB2b,
  templateType,
  customLogo,
  companyLogo,
  companyId,
}) => {
  const logos = [renderShineLogo({ templateType })];
  if (isB2b && customLogo) {
    logos.push(renderPartnerLogo({ customLogo }));
  } else if (companyId && companyLogo) {
    logos.push(renderPartnerLogo({ companyLogo }));
  }

  return <LogoWrapper>{logos}</LogoWrapper>;
};

LogoHeader.defaultProps = {
  isB2b: false,
  templateType: '',
  customLogo: '',
};

LogoHeader.propTypes = {
  isB2b: PropTypes.bool,
  templateType: PropTypes.string,
  customLogo: PropTypes.string,
  companyLogo: PropTypes.string,
  companyId: PropTypes.string,
};

const mapState = (state) => {
  return {
    isB2b: state.promo.templateType === 'b2b',
    templateType: state.promo.templateType,
    customLogo: state.promo.customLogo,
    companyLogo: state.enterprise.companyLogo,
    companyId: state.enterprise.companyId,
  };
};
export default connect(mapState, null)(LogoHeader);
