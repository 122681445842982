import { QUIZ_COMPLETED, UPDATE_QUIZ_LIST } from '../constants/actionTypes';

/**
 * Initial state
 */

const initialState = {
  results: {
    name: '',
    description: '',
    recommended_content: {},
    image: '',
    id: false,
    personality_type_name: '',
    lists: {
      1: {
        title: 'Leading Up to the Election:',
        items: [],
        img:
          'https://images.ctfassets.net/9uvjlyak4ne1/22HZ58RAIsyg2jtBnZSaAk/e42dbcd59bcbc910b7ecfc30de343ad4/podium.jpg',
      },
      2: {
        title: 'Election Day and After:',
        items: [],
        img:
          'https://images.ctfassets.net/9uvjlyak4ne1/3m8xxbc1FeQrDo8Pr0ZNpg/f93aef50313e6a7f6c851869c3d98a8e/election-star.png',
      },
      3: {
        title: 'Your Go-to Self-Care Rituals:',
        items: [],
        img:
          'https://images.ctfassets.net/9uvjlyak4ne1/2llcmtfF56sV6l43lkGOMW/8eeaef5ba350d81267eb038ba42d4fe7/thumbsup.png',
      },
    },
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case QUIZ_COMPLETED:
      return { ...state, results: { ...state.results, ...action.payload } };
    case UPDATE_QUIZ_LIST:
      let newArray = state.results.lists[action.payload.key].items.concat(
        action.payload.item
      );
      return {
        ...state,
        results: {
          ...state.results,
          lists: {
            ...state.results.lists,
            [action.payload.key]: {
              ...state.results.lists[action.payload.key],
              items: newArray,
            },
          },
        },
      };
    default:
      return state;
  }
}
