import React from 'react';
import PropTypes from 'prop-types';

import {
  FormInput,
  ShowHidePwButton,
  FormInputPasswordContainer,
} from './FormStyles';

const FormInputComponent = ({
  label,
  inputName,
  inputType,
  inputValue,
  onChange,
  onClick,
  passwordHidden,
  optional,
  isSignup,
  isValidPassword,
}) => {
  let buttonText;
  if (inputName === 'password') {
    inputType = passwordHidden ? 'password' : 'text';
    buttonText = passwordHidden ? 'SHOW' : 'HIDE';
    return (
      <FormInputPasswordContainer>
        <FormInput
          required={!optional}
          name={inputName}
          type={inputType}
          value={inputValue}
          onChange={onChange}
          placeholder={label}
        />
        {/* Show tooltip on signup page only and when password entered is not valid */}
        {isSignup && !isValidPassword ? (
          <span
            className="tooltiptext"
            dangerouslySetInnerHTML={{ __html: COPY.password }}
          />
        ) : null}
        <ShowHidePwButton onClick={onClick}>{buttonText}</ShowHidePwButton>
      </FormInputPasswordContainer>
    );
  } else {
    return (
      <FormInput
        required={!optional}
        name={inputName}
        type={inputType}
        value={inputValue}
        onChange={onChange}
        placeholder={label}
      />
    );
  }
};

const COPY = {
  password:
    'Password must:<br>- be least 8 characters long<br>- have at least 1 capital letter<br> - have at least 1 lowercase letter<br> - have at least 1 number',
};

export default FormInputComponent;

FormInputComponent.propTypes = {
  label: PropTypes.string,
  inputName: PropTypes.string,
  inputType: PropTypes.string,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  passwordHidden: PropTypes.bool,
  optional: PropTypes.bool,
  isSignup: PropTypes.bool,
  isValidPassword: PropTypes.bool,
};
