import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

// Assets
import { phoneScreens, phoneScreensWebp } from '../../constants/image.config';

// Components
import { BoldTitle, DefaultText } from './AboutPageStyles';
import { YellowButton } from '../../constants/styles/buttons';
import { ImgWithFallback } from '../../utils/common';

const Section = styled.section`
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px;
  @media only screen and (max-width: ${SIZES.phone}) {
    margin: 50px;
  }
`;

const TextWrapper = styled.div`
  width: 85%;
  max-width: 997px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-bottom: 25px;
    width: 100%;
  }
`;

const Title = styled(BoldTitle)`
  width: 100%;
  margin: 0 0 40px 0;
  color: ${COLORS.darkMagentaPink};
  text-align: center;

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin: 0 0 19px 0;
  }
`;

const Description = styled(DefaultText)`
  width: 100%;
  text-align: center;
  margin: 0 0 19px 0;
`;

const Image = styled(ImgWithFallback)`
  display: block;
  max-height: 600px;
  object-fit: cover;
  object-position: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    max-height: 325px;
  }
`;

const CTAButton = styled(YellowButton)`
  width: 280px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SupportForEmployeesSection = () => (
  <Section id="support-for-employees">
    <TextWrapper>
      <Title>{COPY.title}</Title>
      <Description>{COPY.description}</Description>
      <StyledLink to="/shine-at-work" className="cta-support-for-employees">
        <CTAButton>{COPY.btnCopy}</CTAButton>
      </StyledLink>
    </TextWrapper>
    <Image
      src={phoneScreensWebp}
      fallback={phoneScreens}
      altText={COPY.altText}
    />
  </Section>
);

const COPY = {
  title: 'Support mental health in the workplace',
  description:
    'Bring daily, culturally competent support to your organization with Shine at Work—your employee wellbeing program based on Shine’s award-winning app.',
  btnCopy: 'Learn More About Shine at Work',
  altText: 'Shine app phone screenshots',
};

export default SupportForEmployeesSection;
