export default {
  black: '#000000',
  blackTwo: '#191919',
  white: '#ffffff',
  transparent: 'rgba(0, 0, 0, 0.0)',
  grey: '#868686',
  greyThree: '#333333',
  darkGrey: '#777777',
  lightGrey: '#C4C4C4',
  greyShadow: 'rgba(25, 25, 25, 0.5)',
  greyShadow75: 'rgba(25, 25, 25, 0.75)',
  lightYellow: '#F8EED8',
  lighterYellow: '#FAF5E8',
  paleGoldenYellow: '#FBF5E8',
  deepGoldenYellow: '#F7CA5D',
  shineYellow: '#E4A42C',
  marigold: '#DBA647',
  activeYellow: '#F4B641',
  darkCyan: '#4D908F',
  darkModerateCyan: ' #347371',
  darkGreyPink: '#624554',
  darkGreyPink70: '#62455470',
  lightGreyPink: '#735C68',
  lighterGreyPink: '#907A85',
  eggplant: '#634453',
  darkMagentaPink: '#442F3A',
  pinkRed: '#874347',

  // Quiz
  lightRed: '#FF343E',
  lightBlue: '#6486DD',
  cobaltBlue: '#081839',
  fauxPineapple: '#0F2654',

  // Navbar
  whiteHalfTransparent: 'rgba(255, 255, 255, 0.5)',
  whiteSheer: 'rgba(255, 255, 255, 0.90)',

  // Promo Templates
  lightGoldenYellow: '#f9e1a4',
  lightGreen: '#c9e7ca',
  paleBlueDark: '#b3d2ff',

  // Checkout
  softGrey: '#eaeaea',
  paleGrey: '#DADADA',
  paleRed: '#da5442',
  strikedOutPink: '#D8A29A',
  strikedOutCrimson: '#9E1818',
  fbBlue: 'rgb(75, 114, 187)',

  // About Us
  gold: '#b98b17',
  greyBlack: '#1A1D1D',
  paleBlueGreen: 'rgba(77, 144, 143, 0.25)',
  paleCyan: '#366261',

  // Portal
  paleGoldenShadow: '#ECE4D0',
  paleYellow: '#FFFDF8',
  darkBeige: '#B0A278',

  // Footer
  lavender: '#cfc3f5',
  faintLavender: '#F2EDF0',
};
