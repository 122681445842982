import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SIZES from '../../../constants/screenSizes';
import COLORS from '../../../constants/colors';

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.darkModerateCyan};
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: ${(props) => (props.overflow ? `${props.overflow}` : 'scroll')};
  margin-bottom: 20px;

  @media (max-width: ${SIZES.phone}) {
    width: 100%;
    height: 100%;
    padding: 2% 0 2%;
  }
`;

const BannerText = styled.p`
  width: 100%;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.6px;
  color: white;
  text-align: center;
  align-self: center;

  @media (max-width: ${SIZES.phone}) {
    font-size: 14px;
    line-height: 1.44;
    letter-spacing: 0.5px;
    padding: 0 4% 0 4%;
  }
  @media only screen and (max-width: ${SIZES.ipadProWidth}) {
    padding: 0 6% 0 6%;
  }
  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    padding: 0;
  }
`;

function Banner({ description, overflow }) {
  return (
    <Container overflow={overflow}>
      <BannerText dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
  );
}

Banner.propTypes = {
  description: PropTypes.string.isRequired,
};

export default Banner;
