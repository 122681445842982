import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// Actions
import {
  clearErrorState,
  postAuthHandler,
  authenticateUser,
  redeemEnterpriseSubscription,
} from '../../actions/auth';
import { saveMemberId } from '../../actions/enterprise';
import { createSubscription } from '../../actions/billing';
import { AUTH_FAIL } from '../../constants/actionTypes';

// Form Components
import FormGenerator from './FormGenerator';
//Copy
import {
  B2B,
  LOGIN_FORM_FIELDS,
  SIGNUP_FORM_FIELDS,
  LOGIN_GIFT_FORM_FIELDS,
  SIGNUP_GIFT_FORM_FIELDS,
  SHINE_AT_WORK_LOGIN_FORM_FIELDS,
  SHINE_AT_WORK_SIGNUP_FORM_FIELDS,
} from '../../constants/sharedCopy';
import { authMethods, getBOGOPromoType } from '../../constants/config';
import {
  validateEmailDomain,
  checkReCaptcha,
  validatePassword,
} from '../../utils/common';

/**
 * This component is in charge of handling the submission for authentication
 */
class FormHandler extends Component {
  componentWillMount() {
    checkReCaptcha('signup_login_page_load');
    if (this.props.errorMsg) {
      this.props.clearError();
    }
  }

  render() {
    const {
      authType,
      buttonCopy,
      formTitle,
      isHeader,
      couponId,
      templateType,
      emailDomain,
      emailDomainList = [],
      stripeCustomerId,
      isGiftCardRedemption,
      actions,
      showMemberIdField,
      memberIdPlaceholder,
    } = this.props;

    const isB2b = templateType === B2B;
    const isEnterprise = stripeCustomerId.length > 0;
    const isSignup = authType === authMethods.SIGNUP;

    // If a specific email domain is required for enterprise subscriptions then add work email copy.
    const isDomainNameSet = emailDomain || emailDomainList.length > 0;
    const useWorkEmail = (isB2b || isEnterprise) && isDomainNameSet;

    return (
      <React.Fragment>
        <FormGenerator
          {...this.props}
          formContent={this.determineContentByAuthType(
            authType,
            useWorkEmail,
            showMemberIdField,
            memberIdPlaceholder,
            isGiftCardRedemption
          )}
          handleSubmit={this.handleSubmit}
          handleClick={this.handleClick}
          clearError={actions.clearErrorState}
          buttonCopy={{ ...buttonCopy }}
          formTitle={formTitle}
          isHeader={isHeader}
          bogoPromoType={getBOGOPromoType(couponId)}
          isB2b={isB2b}
          isEnterprise={isEnterprise}
          isSignup={isSignup}
        />
      </React.Fragment>
    );
  }

  postAuth = async () => {
    const {
      stripeCouponPercentOff,
      actions,
      user,
      selectedPlan,
      templateType,
      promoName,
      quizResults,
      stripeCustomerId,
      companyId,
      memberId,
      utmParams,
      isAuthenticated,
    } = this.props;
    const companyName = templateType === B2B ? promoName : undefined;

    if (isAuthenticated) {
      if (companyId && stripeCustomerId) {
        await actions.redeemEnterpriseSubscription(user.uid, {
          stripeCustomerId,
          companyId,
          memberId,
        });
      } else {
        await actions.postAuthHandler({
          quizResults,
          companyName,
          user,
          subscriptionToApply: {
            stripeCouponPercentOff,
            planId: selectedPlan.id,
            couponId: selectedPlan.couponId ? selectedPlan.couponId : null,
          },
          utmParams,
        });
      }
    }
  };

  determineContentByAuthType = (
    authType,
    useWorkEmail,
    showMemberIdField,
    memberIdPlaceholder,
    useGiftCardForm
  ) => {
    let formFields = [];
    switch (authType) {
      case authMethods.LOGIN:
        if (useWorkEmail) formFields = SHINE_AT_WORK_LOGIN_FORM_FIELDS;
        else if (useGiftCardForm) formFields = LOGIN_GIFT_FORM_FIELDS;
        else formFields = LOGIN_FORM_FIELDS;
        break;
      case authMethods.SIGNUP:
        if (useWorkEmail) formFields = SHINE_AT_WORK_SIGNUP_FORM_FIELDS;
        else if (useGiftCardForm) formFields = SIGNUP_GIFT_FORM_FIELDS;
        else formFields = SIGNUP_FORM_FIELDS;
        break;
      default:
        formFields = SIGNUP_FORM_FIELDS;
    }

    const memberIdField = {
      label: memberIdPlaceholder,
      inputName: 'memberId',
      inputType: 'text',
    };

    // Check to see that memberId field doesn't already exist in the fields array before pushing it in
    const index = formFields.findIndex(
      (formFields) => formFields.inputName === 'memberId'
    );
    if (showMemberIdField && index === -1) {
      formFields.push(memberIdField);
    }

    return formFields;
  };

  handleClick = (authMethod, giftCardNumber) => {
    const { actions, promoName, quizResults, authType, templateType } =
      this.props;
    const isSignup = authType === authMethods.SIGNUP;
    const companyName = templateType === B2B ? promoName : undefined;
    actions
      .authenticateUser(
        authMethod,
        { isSignup, companyName, giftCardNumber },
        quizResults
      )
      .then(() => this.postAuth(giftCardNumber));
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    const firstName = event.target.firstName
      ? event.target.firstName.value
      : null;
    const giftCardNumber = event.target.giftCardNumber
      ? event.target.giftCardNumber.value
      : null;
    const memberId = event.target.memberId ? event.target.memberId.value : null;
    const {
      promoName,
      authType,
      emailDomain,
      templateType,
      emailDomainList,
      actions,
    } = this.props;

    const companyName = templateType === B2B ? promoName : undefined;
    let validPassword;
    if (authType === authMethods.SIGNUP) {
      validPassword = validatePassword(password);
    }

    // Validate email domain if one is set on the contentful promoConfig model
    if (
      emailDomainList &&
      emailDomainList.length > 0 &&
      !emailDomainList.some((domain) => validateEmailDomain(email, domain))
    ) {
      this.props.dispatchError(
        'Please enter a valid email. The email used is not valid for this promotion.'
      );
    } else if (emailDomain && !validateEmailDomain(email, emailDomain)) {
      this.props.dispatchError(
        'Please enter a valid email. The email used is not valid for this promotion.'
      );
    } else if (authType === authMethods.SIGNUP && !validPassword) {
      this.props.dispatchError(
        'Please enter a password that is at least 8 characters long, includes 1 capital letter, 1 lowercase letter, and 1 number.'
      );
    } else {
      if (memberId) {
        actions.saveMemberId(memberId);
      }
      actions
        .authenticateUser(authType, {
          email,
          password,
          firstName,
          companyName,
          giftCardNumber,
        })
        .then(() => this.postAuth());
    }
  };
}

const mapLogin = ({ promo, enterprise, user, quiz, utmParams, billing }) => {
  return {
    promoName: promo.pageId,
    couponId: promo.couponId,
    emailDomain: promo.emailDomain,
    templateType: promo.templateType,
    emailDomainList:
      promo.emailDomainList && promo.emailDomainList.length > 0
        ? promo.emailDomainList
        : enterprise.emailDomainList,
    errorMsg: user.errorMsg || enterprise.errorMessage,
    isLoading: user.isLoading,
    isAuthenticated: user.isAuthenticated,
    quizResults: quiz.results,
    utmParams: utmParams,

    // Enterprise
    stripeCustomerId: enterprise.stripeCustomerId,
    companyId: enterprise.companyId,
    memberId: enterprise.memberId,
    showMemberIdField: enterprise.showMemberIdField,
    memberIdPlaceholder: enterprise.memberIdPlaceholder,

    // Post Auth
    selectedPlan: billing.selectedPlan,
    stripeCouponPercentOff: promo.stripeCouponPercentOff,
    user: user.userData,
  };
};

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        authenticateUser,
        clearErrorState,
        createSubscription,
        postAuthHandler,
        redeemEnterpriseSubscription,
        saveMemberId,
      },
      dispatch
    ),
    dispatchError: (errorMsg) => {
      dispatch({ type: AUTH_FAIL, errorMsg });
    },
  };
};

export default connect(mapLogin, mapDispatch)(FormHandler);

/**
 * PROP TYPES
 */
FormHandler.propTypes = {
  errorMsg: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  quizResults: PropTypes.object,
  authType: PropTypes.string,
  buttonCopy: PropTypes.object.isRequired,
  isHeader: PropTypes.bool,
  couponId: PropTypes.string,
  utmParams: PropTypes.object,
  emailDomain: PropTypes.string,
  promoName: PropTypes.string,
  templateType: PropTypes.string,
  formTitle: PropTypes.string,
  dispatchError: PropTypes.func.isRequired,
  actions: {
    authenticateUser: PropTypes.func.isRequired,
    postAuthHandler: PropTypes.func.isRequired,
    clearErrorState: PropTypes.func.isRequired,
    createSubscription: PropTypes.func.isRequired,
    redeemEnterpriseSubscription: PropTypes.func.isRequired,
    saveMemberId: PropTypes.func.isRequired,
  },
  isGiftCardRedemption: PropTypes.bool,
};
