import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import SIZES from '../../../constants/screenSizes';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { newPlayButton } from '../../../constants/image.config';
import { newPlayButtonHover } from '../../../constants/image.config';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: ${COLORS.white};
  width: 224px;
  height: 201px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 354px;
    height: 317px;
  }

  &:hover {
    transform: translateY(-15px);
  }
`;
const ImageContainer = styled.div`
  width: 224px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 354px;
  }
`;

const TrackImage = styled.img`
  width: 90%;
  height: auto;
  border-radius: 5px;
`;

const PlayButton = styled.img`
  width: 45px;
  height: 45px;
  position: absolute;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 60px;
    height: 60px;
  }
`;

const TrackDescriptionContainer = styled.div`
  width: 224px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 354px;
  }
`;
const TrackTitle = styled.p`
  font-family: ${FONTS.workSansPrimaryRegular};
  color: ${COLORS.blackTwo};
  font-style: normal
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  text-align: left;
  margin: 3px 0;
  padding: 0 11px;
  min-height: 46px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    font-size: 24px;
    min-height: 60px;
    margin: 5px 0;
    padding: 0 15px;
  }
`;

const TrackLabel = styled(TrackTitle)`
  font-size: 12px;
  color: ${COLORS.darkGrey};
  font-weight: 500;
  min-height: auto;

  @media only screen and (min-width: ${SIZES.tablet}) {
    font-size: 14px;
  }
`;

interface Props {
  track: {
    trackImg: string;
    title: string;
    speaker: string;
  };
  openPlayer: (args: any) => void;
}

const TrackCard: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  track: { trackImg, title, speaker },
  openPlayer,
}) => {
  const [playButtonImg, setPlayButtonImg] = useState(newPlayButton);
  const showPlayButtonHover = () => setPlayButtonImg(newPlayButtonHover);
  const showPlayButton = () => setPlayButtonImg(newPlayButton);

  return (
    <Container
      onClick={openPlayer}
      onMouseEnter={showPlayButtonHover}
      onMouseLeave={showPlayButton}
    >
      <ImageContainer>
        <TrackImage src={trackImg} />
        <PlayButton src={playButtonImg} />
      </ImageContainer>
      <TrackDescriptionContainer>
        <TrackLabel>{speaker}</TrackLabel>
        <TrackTitle>{title}</TrackTitle>
      </TrackDescriptionContainer>
    </Container>
  );
};

export default TrackCard;

/**
 * PROP TYPES
 */
TrackCard.propTypes = {
  openPlayer: PropTypes.func.isRequired,
};
