import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import FONTS from '../../constants/fonts';
import {
  blobTop,
  blobBottomPortal,
  portalIcons,
} from '../../constants/image.config';
import logo from '../../assets/shine-logo.png';

// Dashboard Styles
export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: 325px;
`;

export const PortalBackground = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 92px);
  background-color: ${COLORS.paleGoldenYellow};
  justify-content: space-between;
  align-content: center;
`;

export const PortalBlobTop = styled.img.attrs({ src: blobTop })`
  width: 25vw;
  position: absolute;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 33vw;
  }
`;

export const PortalBlobBottom = styled.img.attrs({ src: blobBottomPortal })`
  width: 25vw;
  position: absolute;
  right: 0px;
  bottom: 0px;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 33vw;
  }
`;

// Top Nav Styles
export const TopNavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 15px 30px;
  border-bottom: 1px solid ${COLORS.faintLavender};
`;

export const Logo = styled.img.attrs({ src: logo })`
  display: flex;
  height: 60px;
  width: 60px;
  cursor: pointer;
  z-index: 1;
`;

export const TopNavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
  align-items: center;
  margin-left: auto;
`;

export const TopNavLink = styled.a`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: semibold;
  color: ${COLORS.darkMagentaPink};
  text-decoration: none;
  transition: color 0.3s linear;
  margin: 0px 20px;
  padding-bottom: 3px;
  border-bottom: 2px solid transparent;
  z-index: 1;

  &:hover {
    cursor: pointer;
    border-bottom: 2px solid;
  }
`;

// Body Styles
export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: flex-start;
  margin: 45px;
  z-index: 1;

  @media only screen and (max-width: ${SIZES.desktop}) {
    flex-direction: column;
    align-items: center;
    margin: 25px;
  }
`;

// Sidebar Nav Styles
export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.paleGoldenShadow};
  border-radius: 8px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

export const SidebarNavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  padding: 24px;
`;

export const SidebarNavItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  margin: 10px;
  color: ${COLORS.paleGrey};
  &:hover {
    color: ${COLORS.activeYellow};
  }
  path {
    fill: ${COLORS.paleGrey};
  }
  &:hover path {
    fill: ${COLORS.activeYellow};
  }

  &.${(props) => props.activeClassName} {
    color: ${COLORS.activeYellow};
    path {
      fill: ${COLORS.activeYellow};
    }
`;

export const SidebarIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

// Main Content Styles
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 45px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    margin-left: 0px;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.paleYellow};
  border-radius: 12px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    margin-bottom: 25px;
  }
`;

export const YellowSectionContainer = styled(SectionContainer)`
  border: 2px solid ${COLORS.activeYellow};
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  background-color: ${COLORS.paleYellow};
  padding: 30px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom: 1px solid ${COLORS.faintLavender};
`;

export const FieldsOneColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 20px 30px;
`;

export const FieldsTwoColumn = styled(FieldsOneColumn)`
  flex-direction: row;

  @media only screen and (max-width: ${SIZES.desktop}) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const FreeViewColumn = styled(FieldsOneColumn)`
  max-width: 400px;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  align-items: flex-start;
  flex: 1;

  @media only screen and (max-width: ${SIZES.desktop}) {
    margin-right: 0px;
  }
`;

export const FieldItem = styled.div`
  flex-direction: row;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 10px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    margin-right: 0px;
  }
`;

export const Title = styled.h1`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: ${COLORS.darkMagentaPink};
  margin: 0px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    font-size: 20px;
  }
`;

export const Label = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${COLORS.darkMagentaPink};
  margin: 8px 0px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    font-size: 14px;
  }
`;

export const BoldLabel = styled(Label)`
  font-weight: 500;
`;

export const Data = styled(Label)`
  font-size: 18px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    font-size: 16px;
  }
`;

export const UpdatedText = styled(Label)`
  color: ${COLORS.darkModerateCyan};
`;

export const CancelText = styled.div`
  p {
    font-family: ${FONTS.poppinsPrimaryRegular};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: ${COLORS.darkMagentaPink};
    margin: 8px 0px;

    @media only screen and (max-width: ${SIZES.desktop}) {
      font-size: 14px;
    }
  }

  li {
    font-family: ${FONTS.poppinsPrimaryRegular};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${COLORS.darkMagentaPink};
    margin: 8px 0px;

    @media only screen and (max-width: ${SIZES.desktop}) {
      font-size: 14px;
    }
`;

export const BenefitsRightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  padding-left: 30px;
  border-left: 1px solid ${COLORS.faintLavender};
  flex: 2;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 100%;
    padding-left: 0px;
    padding-top: 30px;
    border-left: none;
    border-top: 1px solid ${COLORS.faintLavender};
  }
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 100%;
  }
`;

export const BenefitBulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const BenefitBulletText = styled(Label)`
  width: 100%;
`;

export const PortalIcons = styled.div`
  width: 24px;
  height: 24px;
  margin: 8px 5px 8px 0px;
  background-image: url(${portalIcons});
  background-repeat: no-repeat;
`;

export const EmailIcon = styled(PortalIcons)`
  background-position: -48px -24px;
`;

export const FacebookIcon = styled(PortalIcons)`
  background-position: 0 -24px;
`;
export const AppleIcon = styled(PortalIcons)`
  background-position: -24px -24px;
`;

export const BenefitBullet = styled(PortalIcons)`
  background-position: -75px 0px;
`;

export const StripeIcon = styled(PortalIcons)`
  background-position: 0 0;
`;

export const ItunesIcon = styled(PortalIcons)`
  background-position: -24px 0;
`;

export const GooglePlayIcon = styled(PortalIcons)`
  background-position: -48px 0;
`;

export const LockIcon = styled(PortalIcons)`
  background-position: -72px -24px;
`;

export const RoundedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 45px;
  width: max-content;
  border-radius: 47px;
  padding: 0 20px;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    width: inherit;
  }
`;

export const GreenButton = styled(RoundedButton)`
  background-color: ${COLORS.darkCyan};
  color: ${COLORS.white};
  &:hover {
    background: ${COLORS.darkModerateCyan};
  }
`;

export const YellowButton = styled(RoundedButton)`
  background-color: ${COLORS.activeYellow};
  color: ${COLORS.white};
  &:hover {
    background: ${COLORS.shineYellow};
  }
`;

export const YellowBorderButton = styled(RoundedButton)`
  height: 41px;
  color: ${COLORS.activeYellow};
  background-color: ${COLORS.white};
  border: 2px solid ${COLORS.activeYellow};
  margin-left: 20px;

  &:hover {
    color: ${COLORS.white};
    background: ${COLORS.shineYellow};
    border: 2px solid ${COLORS.shineYellow};
  }
  @media only screen and (max-width: ${SIZES.ipadProWidth}) {
    margin-left: 0px;
  }
`;

export const ButtonLink = styled(Link)`
  text-decoration: none;
  width: inherit;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: ${SIZES.desktop}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const IconWrapper = styled(ButtonsWrapper)`
  @media only screen and (max-width: ${SIZES.desktop}) {
    flex-direction: row;
  }
`;

// Footer
export const FooterSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${COLORS.paleGoldenYellow};
  margin-bottom:30px;
  z-index:1;
  @media only screen and (max-width: ${SIZES.desktop}) {
    flex-direction: column;
    justify-content: space-evenly;
`;

export const Line = styled.hr`
  border: 1px solid ${COLORS.darkGrey};
  width: 85%;
  margin-bottom: 30px;
`;
