// File containing shared text that is user-facing

// Pricing - Monthly
export const MONTHLY_PRICE = `14.99`;

// Pricing - Annual
export const ANNUAL_PRICE = `64.99`;
export const ANNUAL_PRICE_ZERO = `$0 due today`;

// SAVINGS
export const DEFAULT_SAVINGS = `64%`; // 5.42/month VS 14.99/month

//Expired Promo Button Copy
export const EXPIRED_BUTTON_COPY = 'Promotion Expired';

// Billing Copy For Annual discount
export const createFinePrint = (
  price: number,
  type: string,
  includePromo?: string
) => {
  const annualPrice = parseFloat(price.toString()).toFixed(2);
  const monthCopy = `Cancel anytime.`;
  const annualPromoCopy = `Renews automatically after a year at $${ANNUAL_PRICE}.`;
  const annualByMonthCopy = `Billed annually as $${annualPrice} after trial. Cancel anytime.`;
  return includePromo
    ? annualPromoCopy
    : type === MONTHLY
    ? monthCopy
    : annualByMonthCopy;
};

export const createCheckoutModalCopy = (price: number, type: string) => {
  const priceToString = parseFloat(price.toString()).toFixed(2);
  return type === MONTHLY
    ? `1 month / $${priceToString}`
    : `1 year / $${priceToString} after 7-day free trial.`;
};

export const displayPrice = (price: number, asMonthly = false) => {
  if (asMonthly) {
    return `$${(price / 12).toFixed(2)}/month`;
  }
  return `$${parseFloat(price.toString()).toFixed(2)}`;
};

export const displayPriceWithConfigs = (
  price: number,
  configs: {
    planType: string;
    convertToMonthly?: boolean;
    appendPerMonth?: boolean;
  }
) => {
  const {
    planType,
    convertToMonthly = false,
    appendPerMonth = false,
  } = configs;
  if (planType === ANNUAL && convertToMonthly) {
    return `$${price}`;
  } else if (planType === MONTHLY) {
    return `$${price} per month`;
  }
};

export const createTotalCostFinePrint = (config: {
  billingCycle: string;
  couponId?: string;
  isPromo: boolean;
  cost: string;
  calculateTrialEndDate: () => string;
}) => {
  const { billingCycle, couponId, isPromo, cost, calculateTrialEndDate } =
    config;
  const displayCost = parseFloat(cost).toFixed(2);
  if (
    (billingCycle === ANNUAL && couponId && isPromo) ||
    billingCycle === MONTHLY
  ) {
    return `$${displayCost} will be due immediately, cancel any time. Refund requests accepted within 30 days of purchase.`;
  } else {
    return `Your first 7 days are free. $${displayCost} will be due on ${calculateTrialEndDate()}, unless you cancel beforehand. Refund requests accepted within 30 days of purchase.`;
  }
};

// PlanName
export const ANNUAL = 'annual';
export const MONTHLY = 'monthly';

// Page Template Type
export const WARM = 'warm';
export const COLD = 'cold';
export const B2B = 'b2b';
export const ENTERPRISE = 'enterprise';

// BottomCTA
export const ONE_YEAR_OF_SHINE = 'ONE YEAR OF SHINE FOR ONLY';
// AUTH FORM COPY

export const SWITCH_TO_LOGIN = {
  body: `Already have a Shine account? `,
  cta: `Log in`,
  link: '/login',
};
export const SWITCH_TO_LOGIN_GIFT = {
  body: `Already have a Shine account? `,
  cta: `Log in`,
  link: '/gift/login',
};

export const SWITCH_TO_SIGNUP = {
  body: `Don’t have a Shine account? `,
  cta: `Create one`,
  link: '/signup',
};
export const SWITCH_TO_SIGNUP_GIFT = {
  body: `Don’t have a Shine account? `,
  cta: `Create one`,
  link: '/gift/signup',
};
export const DEFAULT_SIGNUP_BUTTON_COPY = `Create an account`;
export const DEFAULT_LOGIN_BUTTON_COPY = `Log in`;
export const DEFAULT_SIGNUP_FB_BUTTON_COPY = `Sign up with Facebook`;
export const DEFAULT_LOGIN_FB_BUTTON_COPY = `Log in with Facebook`;
export const DEFAULT_SIGNUP_APPLE_BUTTON_COPY = `Sign up with Apple`;
export const DEFAULT_LOGIN_APPLE_BUTTON_COPY = `Sign in with Apple`;
export const SEND_BUTTON_COPY = `Send`;

export const CHECKOUT_STEPS = {
  plans: {
    step: 'PLANS',
    title: 'Choose a <br/> Shine Premium plan',
  },
  login: {
    step: '1',
    title: 'Log in to your account',
  },
  signup: {
    step: '1',
    title: 'Create an account',
  },
  billing: {
    step: '2',
    title: 'Enter your billing info',
  },
  confirmation: {
    step: 'final',
    title: null,
    createTitle: (firstName?: string, isB2b?: boolean) => {
      if (isB2b) {
        return firstName
          ? `Welcome to Shine at Work, ${firstName}!`
          : 'Welcome to Shine at Work!';
      }
      return firstName
        ? `Welcome to Shine, ${firstName}!`
        : 'Welcome to Shine!';
    },
  },
  giftlogin: {
    step: null,
    title: `Log in to redeem your gift`,
  },
  giftsignup: {
    step: null,
    title: `Sign up to redeem your gift`,
  },
  'send-gift': {
    step: null,
    createStep: (bogoPromoType?: string) => {
      // For BOGO lifetime promos, we skip billing which makes the send-gift portion step 2
      if (bogoPromoType === 'lifetime') return '2';
      if (bogoPromoType === 'default') return '3';
      return undefined;
    },
    title: 'Send Your Gift',
  },
};

// AUTH-FORM Fields

export const SIGNUP_FORM_FIELDS = [
  {
    label: 'First Name*',
    inputName: 'firstName',
    inputType: 'text',
  },
  {
    label: 'Email*',
    inputName: 'email',
    inputType: 'email',
  },
  {
    label: 'Password*',
    inputName: 'password',
    inputType: 'password',
  },
];

export const LOGIN_FORM_FIELDS = [
  {
    label: 'Email*',
    inputName: 'email',
    inputType: 'email',
  },
  {
    label: 'Password*',
    inputName: 'password',
    inputType: 'password',
  },
];

export const SIGNUP_GIFT_FORM_FIELDS = [
  {
    label: 'Gift Card Number*',
    inputName: 'giftCardNumber',
    inputType: 'text',
  },
  {
    label: 'First Name*',
    inputName: 'firstName',
    inputType: 'text',
  },
  {
    label: 'Email*',
    inputName: 'email',
    inputType: 'email',
  },
  {
    label: 'Password*',
    inputName: 'password',
    inputType: 'password',
  },
];

export const LOGIN_GIFT_FORM_FIELDS = [
  {
    label: 'Gift Card Number*',
    inputName: 'giftCardNumber',
    inputType: 'text',
  },
  {
    label: 'Email*',
    inputName: 'email',
    inputType: 'email',
  },
  {
    label: 'Password*',
    inputName: 'password',
    inputType: 'password',
  },
];

// Shine at Work AUTH-FORM Fields

export const SHINE_AT_WORK_SIGNUP_FORM_FIELDS = [
  {
    label: 'First Name*',
    inputName: 'firstName',
    inputType: 'text',
  },
  {
    label: 'Work Email*',
    inputName: 'email',
    inputType: 'email',
  },
  {
    label: 'Password*',
    inputName: 'password',
    inputType: 'password',
  },
];

export const SHINE_AT_WORK_LOGIN_FORM_FIELDS = [
  {
    label: 'Work Email*',
    inputName: 'email',
    inputType: 'email',
  },
  {
    label: 'Password*',
    inputName: 'password',
    inputType: 'password',
  },
];

// Footer App Store Badge Links
export const FOOTER_IOS_DOWNLOAD_LINK =
  'https://shineapp.onelink.me/fkx8/44b3cf8c';
export const FOOTER_ANDROID_DOWNLOAD_LINK =
  'https://shineapp.onelink.me/wx87/b66fbe2a';
export const FOOTER_IOS_LINK_AUDIO = 'https://go.onelink.me/app/74ad9dcc';
export const FOOTER_ANDROID_LINK_AUDIO = 'https://go.onelink.me/app/be6dcfb3';

// Confirmation Page App Store Badge Links
export const CONFIRM_DOWNLOAD_LINK =
  'https://shineapp.onelink.me/Unhk/997e5d64';

export const createInviteFriendHeader = (name: string) => {
  const formattedName = name[0].toUpperCase() + name.slice(1);
  return `${formattedName} invited you to join Shine!`;
};

// Enterprise Template Page
export const REDEEMED_SEATS_COPY =
  'Oh no! All of your Shine at Work subscriptions have been filled. Please contact your HR Administrator for access.';

// Podcast Pages
export const WHERE_TO_LISTEN_COPY = 'Where to Listen';
export const AND_MORE_COPY = '+ More';
export const LISTEN_NOW_COPY = 'Listen Now';
