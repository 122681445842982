import styled from 'styled-components';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import FONTS from '../../constants/fonts';

export const UpcomingEventsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

export const UpcomingEventCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: calc(33% - 20px);
  min-height: 475px;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.paleGoldenShadow};
  border-radius: 12px;

  @media only screen and (max-width: ${SIZES.laptop}) {
    flex-basis: calc(49% - 20px);
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    flex-basis: 100%;
    margin-right: 0;
    min-height: auto;
  }
`;

export const UpcomingEventImage = styled.img`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  align-content: center;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;

  @media only screen and (max-width: ${SIZES.phone}) {
    max-width: 100%;
    margin-right: 0;
  }
`;

export const PastEventCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.paleGoldenShadow};
  border-radius: 12px;

  @media only screen and (max-width: ${SIZES.phone}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

export const PastEventImage = styled.img`
  display: flex;
  flex: 1;
  max-width: 200px;
  align-content: center;
  margin-right: 25px;

  @media only screen and (max-width: ${SIZES.phone}) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

export const EventDetails = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const DateIcon = styled.img`
  color: ${COLORS.darkMagentaPink};
  margin-right: 5px;
`;

export const Description = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: ${COLORS.darkMagentaPink};
  margin: 8px 0;
  a {
    color: ${COLORS.darkMagentaPink};
  }
`;

export const SectionWrapper = styled.div`
  @media only screen and (max-width: ${SIZES.phone}) {
    padding: 0 10px 10px 10px;
  }
`;

export const Title = styled(Description)`
  font-weight: 500;
`;

export const EventTitle = styled(Title)`
  min-height: 50px;
`;

export const EventCTA = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${SIZES.phone}) {
    justify-content: flex-start;
  }
`;
