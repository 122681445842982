import { SAVE_UTM_PARAMS } from '../constants/actionTypes';

/**
 * Initial state
 */

const initialState = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_UTM_PARAMS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
