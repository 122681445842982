import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qS from 'querystring';
import { logout } from '../../actions/auth';
import { Header, LogoHeader } from '../sharedPartials';

// CONSTANTS
import { CONFIRM_DOWNLOAD_LINK } from '../../constants/sharedCopy';

// UTILS
import { getBOGOPromoType } from '../../constants/config';

//StyleSheets
import IOSBadge from '../../assets/images/app-badges/apple-store-badge-with-border.png';
import AndroidBadge from '../../assets/images/app-badges/google-play-badge.png';
import {
  Container,
  Anchor,
  Badge,
  StoreBadgesContainer,
  NextStepsBulletList,
  NextStepsBulletContainer,
  NextStepsBulletPoint,
  NextStepsBulletContent,
  Divider,
  BodyText,
} from './ConfirmationStyles';
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
} from './styles';

class Confirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /*
       * Derived from the URL's query string to help with rendering copy
       * specific for BOGO subscriptions
       * value can be "sent" or "skipped"
       */
      sendGiftStatus: null,
    };
  }
  componentWillMount() {
    // URL can contain a query param ?code={SAMPLE_CODE}
    let parsedQueryString = qS.parse(this.props.location.search.split('?')[1]);

    if (parsedQueryString.sendGiftStatus) {
      this.setState({ sendGiftStatus: parsedQueryString.sendGiftStatus });
    }
  }

  componentDidMount() {
    //When the page refreshes, react doesn't have the chance to unmount the components as normal.
    //reference: https://stackoverflow.com/questions/39084924/componentwillunmount-not-being-called-when-refreshing-the-current-page
    //signout when component is unmounted or page refreshes
    window.addEventListener('beforeunload', this.props.handleSignOut);
  }

  componentWillUnmount() {
    this.props.handleSignOut();
    window.removeEventListener('beforeunload', this.props.handleSignOut);
  }

  renderGiftSentMessage = (sendGiftStatus, firstName) => {
    let giftSentMessage;
    if (sendGiftStatus === 'sent') {
      giftSentMessage = COPY.generateGiftSentMsg(
        firstName,
        this.props.location.state.recipientName,
        this.props.location.state.recipientEmail
      );
    } else if (sendGiftStatus === 'skipped') {
      giftSentMessage = COPY.generateSkippedGiftSentMsg;
    }
    return giftSentMessage;
  };

  render() {
    const { firstName, templateType, companyId, couponId } = this.props;
    const { hasSentGift, sendGiftStatus } = this.state;

    const isB2b = templateType === 'b2b';

    const isEnterprise = companyId && companyId.length > 0;

    const steps = isB2b || isEnterprise ? COPY.shineAtWorkSteps : COPY.steps;

    // Determine which copy to render if user went through BOGO flow
    let giftSentMessage;
    if (sendGiftStatus === 'sent') {
      giftSentMessage = COPY.generateGiftSentMsg(
        firstName,
        this.props.location.state.recipientName,
        this.props.location.state.recipientEmail
      );
    } else if (sendGiftStatus === 'skipped') {
      giftSentMessage = COPY.generateSkippedGiftSentMsg;
    }

    return (
      <CheckoutBackground>
        <CheckoutBlobTop />
        <CheckoutPageContainer>
          <LogoHeader />
          <Container>
            {sendGiftStatus ? (
              <React.Fragment>
                <BodyText
                  dangerouslySetInnerHTML={{
                    __html: giftSentMessage,
                  }}
                />
                <Divider />
              </React.Fragment>
            ) : null}
            <Header
              firstName={firstName}
              isBOGOPromo={hasSentGift}
              isB2b={isB2b || isEnterprise}
              bogoPromoType={getBOGOPromoType(couponId)}
            />
            <NextStepsBulletList>
              {steps.map((step) => {
                if (step.downloadApp) {
                  return (
                    <React.Fragment>
                      <NextStepsBulletContainer key={step.number}>
                        <NextStepsBulletPoint>
                          {step.number}
                        </NextStepsBulletPoint>
                        <NextStepsBulletContent
                          dangerouslySetInnerHTML={{ __html: step.content }}
                        />
                      </NextStepsBulletContainer>
                      <StoreBadgesContainer>
                        <Anchor href={COPY.downloadLink}>
                          <Badge src={IOSBadge} />
                        </Anchor>
                        <Anchor href={COPY.downloadLink}>
                          <Badge src={AndroidBadge} />
                        </Anchor>
                      </StoreBadgesContainer>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <NextStepsBulletContainer>
                      <NextStepsBulletPoint>{step.number}</NextStepsBulletPoint>
                      <NextStepsBulletContent
                        dangerouslySetInnerHTML={{ __html: step.content }}
                      />
                    </NextStepsBulletContainer>
                  );
                }
              })}
            </NextStepsBulletList>
          </Container>
        </CheckoutPageContainer>
        <CheckoutBlobBottom />
      </CheckoutBackground>
    );
  }
}

const mapState = ({ user, promo, billing, enterprise }) => {
  return {
    firstName: user.userData.firstName,
    templateType: promo.templateType,
    couponId: billing.selectedPlan.couponId,
    companyId: enterprise.companyId,
  };
};

const mapDispatch = (dispatch) => {
  return {
    handleSignOut() {
      dispatch(logout());
    },
  };
};

export default connect(mapState, mapDispatch)(Confirmation);

/**
 * PROP TYPES
 */
Confirmation.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
  templateType: PropTypes.string.isRequired,
};

const COPY = {
  steps: [
    {
      number: 1,
      content: 'To use your membership, first download the Shine app:',
      downloadApp: true,
    },
    {
      number: 2,
      content:
        'Log in using the email and password you just set (or Facebook/Apple Login) to access your Premium account',
    },
    {
      number: 3,
      content:
        'You’re in! Start listening to meditations, tracking your mood, and building your daily self-care ritual',
    },
  ],
  shineAtWorkSteps: [
    {
      number: 1,
      content: 'To get started with your membership, download the Shine app:',
      downloadApp: true,
    },
    {
      number: 2,
      content:
        'Log in using your work email and password you created to access your Shine account',
    },
    {
      number: 3,
      content:
        'Start building your daily mental health routine and check out our library of 1,000+ meditations',
    },
    {
      number: 4,
      content:
        'If you have questions or need help, send us an email at <a href="mailto:shineatwork@theshineapp.com">shineatwork@theshineapp.com</a>',
    },
    {
      number: 5,
      content:
        '<strong>Your privacy matters.</strong> Admins can see your name, email, and the date you enrolled, but any usage data is anonymized and aggregated',
    },
    {
      number: 6,
      content:
        'Additional questions about your membership? Contact your administrator at your company',
    },
  ],
  downloadLink: CONFIRM_DOWNLOAD_LINK,
  generateGiftSentMsg: (firstName, recipientName, recipientEmail) =>
    `Thank you for gifting Shine Premium, ${firstName}! An email with your gift card was sent to <i>${recipientName}</i> at <i>${recipientEmail}</i> moments ago.`,
  generateSkippedGiftSentMsg: `Email <a href="mailto:help@headspace.com">help@headspace.com</a> and we will help you send a gift card to a friend.`,
};
