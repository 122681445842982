import React, { useState } from 'react';
import {
  SurveyContainer,
  SurveyTitle,
  SurveyText,
  SurveyYellowButton,
  TextArea,
} from './styles';
import { SurveyConfig } from '../../types/retention-survey';

const SurveyPageE1 = (props) => {
  const [text, setText] = useState('');
  const { onSubmit } = props;

  return (
    <SurveyContainer>
      <SurveyTitle>{COPY.title}</SurveyTitle>
      <SurveyText>{COPY.thankYou}</SurveyText>
      <TextArea
        placeholder={COPY.placeholder}
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
      <SurveyYellowButton
        onClick={() =>
          onSubmit({
            question: COPY.placeholder,
            answer: text,
            nextPage: SurveyConfig.FEEDBACK_SUBMITTED,
            cancelSub: false,
          })
        }
      >
        {COPY.buttonSubmit}
      </SurveyYellowButton>
    </SurveyContainer>
  );
};

const COPY = {
  title: 'Thanks for being a Shine Premium member.',
  thankYou:
    "We're grateful to have you in the Shine Premium community and to be a part of your self-care ritual. Look out for new meditations every week and our next virtual community care event.",
  placeholder:
    'Have a suggestion for how we can improve Shine Premium? Share it here.',
  buttonSubmit: 'Submit',
};

export default SurveyPageE1;
