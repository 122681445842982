import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { MainContent } from '../styles';
import { UserState } from '../../../types/reducers/user-state';

import UpcomingEventsSection from './UpcomingEventsSection';
import PastEventsSection from './PastEventsSection';

interface Props extends PropsFromRedux {
  accessType: string;
}

class EventsBody extends React.Component<Props> {
  render() {
    const { accessType } = this.props;
    return (
      <MainContent>
        <UpcomingEventsSection accessType={accessType} />
        <PastEventsSection accessType={accessType} />
      </MainContent>
    );
  }
}

const mapState = ({ user }: { user: UserState }) => {
  return {
    accessType: user.subscriptionData.subscription.access_type,
  };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EventsBody);
