import styled from 'styled-components';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';

// images
import playButton from '../../assets/images/audio-player/playL.png';
import pauseButton from '../../assets/images/audio-player/pauseL.png';
import rewindButton from '../../assets/images/audio-player/rewind.png';
import fastForwardButton from '../../assets/images/audio-player/fastForward.png';

export const Container = styled.div`
  height: 590px;
  width: 100%;
  background-image: url(${(props) => props.src});
  background-repeat: round;
  background-size: cover;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 4px 12px 0 rgba(25, 25, 25, 0.5);

  @media only screen and (min-device-width: ${SIZES.iphone5sWidth}) and (max-device-width: ${SIZES.iphone5sWidthMax}) {
    height: 500px;
    width: 250px;
  }

  @media only screen and (min-device-width: ${SIZES.tablet}) {
    height: 590px;
    width: 300px;
  }
`;

// Track Info

export const TrackInfoContainer = styled.div`
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.56;
  text-align: center;
  color: ${(props) =>
    props.theme === 'dark' ? COLORS.white : COLORS.blackTwo};
  background-color: ${(props) =>
    props.theme === 'dark' ? COLORS.blackTwo : COLORS.white};
  padding: 0 10px;
`;

export const ArtistText = styled.span`
  height: 26px;
  font-family: 'Work Sans';
  font-size: 14px;
  font-weight: bold;
  line-height: 1.63;
  letter-spacing: 0.4px;
  color: ${(props) =>
    props.theme === 'dark' ? COLORS.white : COLORS.blackTwo};
  background-color: ${(props) =>
    props.theme === 'dark' ? COLORS.blackTwo : COLORS.white};
  text-align: center;
  padding: 2px 10px;
`;

// SCRUBBER

export const ScrubberContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;

export const TimeText = styled.div`
  color: ${(props) =>
    props.theme === 'dark' ? COLORS.white : COLORS.blackTwo};
  font-size: 12px;
  font-family: 'Work Sans';
  height: 14px;
  text-align: center;
  letter-spacing: 0.43px;
  width: 27px;
`;

// Buttons

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 200px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const VerticalControlContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 95px;
  justify-content: space-evenly;
  margin: 0 5%;
`;

export const PlayButton = styled.img.attrs({ src: `${playButton}` })``;
export const PauseButton = styled.img.attrs({ src: `${pauseButton}` })``;
export const RewindButton = styled.img.attrs({ src: `${rewindButton}` })``;
export const FastForwardButton = styled.img.attrs({
  src: `${fastForwardButton}`,
})``;
