import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MainContent } from '../styles';
import PasswordSection from './PasswordSection';
import ProfileSection from './ProfileSection';

class AccountBody extends Component {
  // show password section only for password accounts
  showPasswordSection() {
    const { providerId, email } = this.props;
    if (providerId === 'password') {
      return <PasswordSection email={email} />;
    } else return null;
  }

  render() {
    const { firstName, email } = this.props;
    return (
      <MainContent>
        <ProfileSection firstName={firstName} email={email} />
        {this.showPasswordSection()}
      </MainContent>
    );
  }
}

const mapState = ({ user }) => {
  return {
    firstName: user.userData.firstName,
    email: user.userData.email,
    providerId: user.userData.providerId,
  };
};

export default connect(mapState)(AccountBody);
