import React, { useState, useEffect } from 'react';
import { load } from 'recaptcha-v3';
import dayjs from 'dayjs';
import netlifyFnClient from '../api/netlifyFnClient';

// Get query parameters from URL. utmParams will be saved to Stripe metadata. Email will be saved to use on billing page
export function getUrlParams() {
  const queryString = window.location.search;
  const results = {
    utmParams: {},
    email: '',
  };

  return (
    queryString
      .substring(1) // remove '?' in querystring
      .split('&')
      // Using `reduce` to parse through remaining querystring & storing param values in a new object that resembles the structure of `results`
      .reduce((previousValue, currentValue) => {
        let [key, value] = currentValue.split('=');
        if (key.toLowerCase() === 'utm_source') {
          previousValue.utmParams[key.toLowerCase()] = value;
        } else if (key.toLowerCase() === 'utm_medium') {
          previousValue.utmParams[key.toLowerCase()] = value;
        } else if (key.toLowerCase() === 'utm_campaign') {
          previousValue.utmParams[key.toLowerCase()] = value;
        } else if (key.toLowerCase() === 'email') {
          previousValue[key.toLowerCase()] = value;
        }
        return previousValue;
      }, results)
  );
}

// Creates a picture tag to serve a webp image or a fallback image
export const ImgWithFallback = ({
  src,
  fallback,
  type = 'image/webp',
  altText,
  styles,
  ...delegated
}) => {
  return (
    <picture style={styles}>
      <source srcSet={src} type={type} />
      <img src={fallback} alt={altText} {...delegated} />
    </picture>
  );
};

// Shuffle options for quiz
export function shuffleArray(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
}

// Gets window width to be used for serving responsive background images
export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return windowWidth;
}

// Validates emails for B2B page templates
export function validateEmailDomain(email, domain) {
  if (!domain) return true;
  const re = new RegExp(`.*@${domain}$`, 'g');
  // Test if the email contains the domain.
  // Test if it's from the right domain (Second argument is to check that the string ENDS with this domain, and that it doesn't just contain it)
  if (
    re.test(email) &&
    email.indexOf(domain, email.length - domain.length) !== -1
  ) {
    return true;
  }
  return false;
}

// Records reCaptcha score
export const checkReCaptcha = async (action = 'default') => {
  let score = 0;
  try {
    const recaptcha = await load(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY);
    const token = await recaptcha.execute(action);
    const res = await fetch('/.netlify/functions/send-captcha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });
    // Check Recaptcha
    if (res.status === 200) {
      const resJson = await res.json();
      score = resJson.body.score;
    }
  } catch (error) {
    score = 1.0;
    console.log('ReCaptcha Error: ', error);
  }
  return { score };
};

// Formats Unix timestamps into Month DD, YYYY (e.g. October 18, 2021)
export const formatDate = (unixTimestamp) => {
  if (unixTimestamp) {
    let formattedDate = dayjs.unix(unixTimestamp).format('MMMM D, YYYY');
    return formattedDate;
  } else return 'Not Available';
};

// Saves user activity to backend
export const postUserActivity = async (action, data) => {
  try {
    await netlifyFnClient.post('activity-handler', {
      action,
      data,
    });
  } catch (err) {
    console.error(err);
  }
};

/**
 *
 * @param {object} d date object [object Date]
 * @returns {boolean}
 */
export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

/**
 *
 * Check that password contains 1 lowercase letter, 1 uppercase letter,
 * 1 number, and is minimum 8 characters
 *
 * @param {string} password
 * @returns {boolean}
 */

export function validatePassword(password) {
  let securePassword = new RegExp(/(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+).{8,}/);
  if (securePassword.test(password)) {
    return true;
  }
  return false;
}
