import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Elements, StripeProvider } from 'react-stripe-elements';
import history from '../../history';
import { pathOr } from 'ramda';

// ACTIONS
import { switchPaymentPlan, createSubscription } from '../../actions/billing';
import { checkReCaptcha } from '../../utils/common';

// COMPONENTS
import { PromoInfo } from './PromoInfo';
import { NonPromoInfo } from './NonPromoInfo';
import { MembershipInfo } from './MembershipInfo';
import { EmailInfo } from './EmailInfo.tsx';
import CardInfo from './CardInfo';
import { Header, PageLoading, LogoHeader } from '../sharedPartials';
import TermsAndPolicy from '../form/TermsAndPolicy';

// CONSTANTS
import {
  ANNUAL,
  MONTHLY,
  createTotalCostFinePrint,
  B2B,
} from '../../constants/sharedCopy';
import { isBOGOPromo, getBOGOPromoType } from '../../constants/config';
// STYLESHEETS
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
} from '../checkout/styles';
import { Container, FooterWrapper, Divider, SubText } from './BillingStyles';

class BillingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageLoading: true,
      isLoading: false, // TODO: Do something when processing payments
      captchaError: false,
    };
  }

  async componentDidMount() {
    // Get user captcha score on page mount
    checkReCaptcha('billing_page_load');
    this.setState({ isPageLoading: false });
  }

  onConfirmSubscription = async (stripeCardData) => {
    this.setState({ isLoading: true });
    try {
      const { tokenId } = stripeCardData;
      checkReCaptcha('submit_payment');

      const {
        createSubscription,
        user,
        selectedPlan: {
          id: planId,
          couponId,
          billingCycle,
          discountPercent,
          discountFlatAmount,
        },
        pageId,
        templateType,
        utmParams,
      } = this.props;

      const isTrial = this.isTrialSubscription(couponId, billingCycle);

      const b2bTags =
        templateType === B2B
          ? {
              company: pageId,
              isB2b: true,
            }
          : {};

      await createSubscription({
        token: tokenId,
        user,
        planId,
        couponId,
        isTrial,
        discountPercent,
        discountFlatAmount,
        pageId,
        utmParams,
        b2bTags,
      });
    } catch (error) {
      // Alert if rate limit reached
      if (error.response.status === 429) {
        window.alert(error.response.data);
      }
      // Alert user with Stripe error message
      if (error.response) {
        const errorMessage = pathOr(
          'Error processing subscription.',
          ['data', 'raw', 'message'],
          error.response
        );
        window.alert(errorMessage);
      } else {
        window.alert('Error processing subscription.');
      }
    }
    this.setState({ isLoading: false });
  };

  isTrialSubscription = (couponId, billingCycle) => {
    // Monthly and All promos have no free trial
    if (billingCycle === MONTHLY) {
      return false;
    } else if (couponId && billingCycle === ANNUAL) {
      return false;
    }
    // Only Annual non-promo subs have free-trial
    return true;
  };

  onClickSwitchPlan() {
    // Switch selected plan
    // If user is coming from  a promo page then switch annual plan to promoted plan
    // NOTE: currently this logic assumes that all promoted plans are ANNUAL plans
    const {
      selectedPlan: { billingCycle },
      switchPaymentPlan,
      couponId,
    } = this.props;
    let newPlan = MONTHLY;
    let billing = MONTHLY;
    if (couponId && billingCycle === MONTHLY) {
      newPlan = couponId;
      billing = ANNUAL;
    } else if (billingCycle === MONTHLY) {
      newPlan = ANNUAL;
      billing = ANNUAL;
    }

    history.push(`/billing`);
    switchPaymentPlan(newPlan, billing);
  }

  calculateTrialEndDate() {
    const now = new Date();
    const trialEndDays = 7;

    return new Date(
      now.setTime(now.getTime() + trialEndDays * 24 * 60 * 60 * 1000)
    ).toDateString();
  }

  renderFooterText = () => {
    const {
      selectedPlan: { cost, billingCycle },
      couponId,
      isPromo,
    } = this.props;
    const costText = createTotalCostFinePrint({
      billingCycle,
      couponId,
      isPromo,
      cost,
      calculateTrialEndDate: this.calculateTrialEndDate,
    });
    const helpText =
      'Email us at <a href="mailto:help@headspace.com">help@headspace.com</a> with any questions or if you need support.';
    return (
      <FooterWrapper>
        <SubText>{costText}</SubText>
        <SubText dangerouslySetInnerHTML={{ __html: helpText }} />
      </FooterWrapper>
    );
  };

  render() {
    const { isPromo, selectedPlan, annual, monthly, couponId, email } =
      this.props;
    const { isPageLoading, captchaError } = this.state;
    if (isPageLoading) {
      return <PageLoading />;
    } else {
      return (
        <CheckoutBackground>
          <CheckoutBlobTop />
          <CheckoutPageContainer>
            <LogoHeader />
            <Container>
              <Header bogoPromoType={getBOGOPromoType(couponId)} />
              {process.env.REACT_APP_HOST_ENV === 'staging' ? (
                <Link to="/confirmation">
                  STAGING_ENV_ONLY: SKIP TO CONFIRMATION PAGE
                </Link>
              ) : null}
              {isPromo ? (
                <PromoInfo
                  {...this.props.selectedPlan}
                  isBOGOPromo={isBOGOPromo(couponId)}
                />
              ) : (
                <NonPromoInfo
                  selectedPlan={selectedPlan}
                  annualPlan={annual}
                  monthlyPlan={monthly}
                  switchPlan={this.onClickSwitchPlan.bind(this)}
                />
              )}
              <MembershipInfo />
              <Divider />
              <EmailInfo email={email} />
              <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
                <Elements>
                  <CardInfo
                    isLoading={this.state.isLoading}
                    selectedPlan={this.props.selectedPlan}
                    onConfirmSubscription={this.onConfirmSubscription}
                    captchaError={captchaError}
                  />
                </Elements>
              </StripeProvider>
              <TermsAndPolicy />
            </Container>
            {this.renderFooterText()}
          </CheckoutPageContainer>
          <CheckoutBlobBottom />
        </CheckoutBackground>
      );
    }
  }
}

const mapState = (state) => {
  return {
    selectedPlan: state.billing.selectedPlan,
    user: state.user.userData,
    email: state.user.userData.email,
    isPromo: state.promo.isPromo,
    couponId: state.promo.couponId,
    pageId: state.promo.pageId,
    stripeCouponPercentOff: state.promo.stripeCouponPercentOff,
    annual: state.billing.plans.annual,
    monthly: state.billing.plans.monthly,
    utmParams: state.utmParams,
    templateType: state.promo.templateType,
  };
};

const mapDispatch = {
  switchPaymentPlan,
  createSubscription,
};

export default connect(mapState, mapDispatch)(BillingPage);
