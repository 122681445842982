import React from 'react';
import {
  PlanModuleContainer,
  PlanCost,
  PlanDescription,
  PlanLabel,
  PlanLabelText,
  PlanName,
  PlanTitleContainer,
  PlanItemWrapper,
  LeftPlanItemContainer,
  RightPlanItemContainer,
  RadioButton,
  RadioButtonWrapper,
  AnnualDiscountLabel,
  AnnualDiscountContainer,
} from './BillingStyles';
import {
  ANNUAL_PRICE_ZERO,
  DEFAULT_SAVINGS,
  displayPriceWithConfigs,
} from '../../constants/sharedCopy';

export const NonPromoInfo = ({
  annualPlan,
  monthlyPlan,
  selectedPlan,
  switchPlan,
}) => {
  let isActiveAnnual = annualPlan.billingCycle === selectedPlan.billingCycle;
  let isActiveMonthly = monthlyPlan.billingCycle === selectedPlan.billingCycle;
  return (
    <React.Fragment>
      {/* ANNUAL PLAN OPTION */}
      <AnnualDiscountContainer active={isActiveAnnual}>
        <AnnualDiscountLabel active={isActiveAnnual}>
          {COPY.annualDiscountLabel}
        </AnnualDiscountLabel>
      </AnnualDiscountContainer>
      <PlanModuleContainer
        active={isActiveAnnual}
        onClick={!isActiveAnnual ? switchPlan : undefined}
        style={{ margin: '-30px 0 24px 0' }}
      >
        <RadioButtonWrapper>
          <RadioButton
            active={isActiveAnnual}
            onClick={!isActiveAnnual ? switchPlan : undefined}
          >
            <div className="active-icon" />
          </RadioButton>
        </RadioButtonWrapper>
        <PlanItemWrapper>
          <LeftPlanItemContainer>
            <PlanTitleContainer>
              <PlanName>{annualPlan.billingCycle}</PlanName>
            </PlanTitleContainer>
            <PlanCost>{ANNUAL_PRICE_ZERO}</PlanCost>
            <PlanDescription>{annualPlan.finePrint}</PlanDescription>
          </LeftPlanItemContainer>
          <RightPlanItemContainer>
            <PlanLabel active={isActiveAnnual}>
              <PlanLabelText active={isActiveAnnual}>
                {COPY.freeTrial}
              </PlanLabelText>
            </PlanLabel>
          </RightPlanItemContainer>
        </PlanItemWrapper>
      </PlanModuleContainer>

      {/* MONTHLY PLAN OPTION */}

      <PlanModuleContainer
        active={isActiveMonthly}
        onClick={!isActiveMonthly ? switchPlan : undefined}
      >
        <RadioButtonWrapper>
          <RadioButton
            active={isActiveMonthly}
            onClick={!isActiveMonthly ? switchPlan : undefined}
          >
            <div className="active-icon" />
          </RadioButton>
        </RadioButtonWrapper>
        <PlanItemWrapper>
          <LeftPlanItemContainer>
            <PlanTitleContainer>
              <PlanName>{monthlyPlan.billingCycle}</PlanName>
            </PlanTitleContainer>
            <PlanCost>
              {displayPriceWithConfigs(monthlyPlan.cost, {
                planType: monthlyPlan.billingCycle,
              })}
            </PlanCost>
            <PlanDescription>{monthlyPlan.finePrint}</PlanDescription>
          </LeftPlanItemContainer>
          <RightPlanItemContainer>
            <PlanLabel active={isActiveMonthly}>
              <PlanLabelText active={isActiveMonthly}>
                {COPY.noFreeTrial}
              </PlanLabelText>
            </PlanLabel>
          </RightPlanItemContainer>
        </PlanItemWrapper>
      </PlanModuleContainer>
    </React.Fragment>
  );
};

const COPY = {
  annualDiscountLabel: `SAVE ${DEFAULT_SAVINGS}`,
  freeTrial: '7-day free trial',
  noFreeTrial: 'Subscribe Today',
};
