import React from 'react';
import styled from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

// Assets
import {
  darkGreenBlob,
  appleTodayReviewGroup,
} from '../../constants/image.config';

// Components
import { BoldTitle, DefaultText } from './AboutPageStyles';

const Section = styled.section`
  background-color: ${COLORS.darkCyan};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DarkGreenBlob = styled.img.attrs({ src: darkGreenBlob })`
  align-self: flex-start;

  @media only screen and (max-width: ${SIZES.tablet}) {
    width: 40vw;
  }
`;

const TextWrapper = styled.div`
max-width: 700px;
width: 85%;
display: flex;
flex-direction: column;
margin-top: -17%
align-self: center;
margin-bottom: 108px;

@media only screen and (max-width: ${SIZES.tablet}) {
  margin-top: -19%
}

@media only screen and (max-width: ${SIZES.phone}) {
  margin-bottom: 60px;
}
`;

const Title = styled(BoldTitle)`
  width: 100%;
  color: ${COLORS.white};
  text-align: center;

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 28px;
  }
`;

const Description = styled(DefaultText)`
  width: 100%;
  color: ${COLORS.white};
  font-size: 20px;
  text-align: center;
  margin-bottom: 50px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 18px;
  }
`;

const Image = styled.img.attrs({ src: appleTodayReviewGroup })`
  max-width: 324px;
  height: 65.84px;
  margin-top: 50px;
  align-self: center;
`;

const BottomCTA = () => (
  <Section id="about-bottom-section">
    <DarkGreenBlob />
    <TextWrapper>
      <Title>
        The Shine app is your support system for daily stress and anxiety.
      </Title>
      <Description>
        Prioritizing your mental health starts here. Learn a new self-care
        strategy every day, get support from a diverse community, and explore an
        audio library of over 800+ original meditations, bedtime stories, and
        calming sounds to help you shift your mindset or mood.
      </Description>
      <Image />
    </TextWrapper>
  </Section>
);

export default BottomCTA;
