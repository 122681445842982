import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Stepper } from '.';
import QuizHeader from '../quiz/LoginHeader';

interface PassedProps {
  location: {
    pathname: string;
  };
  completedQuiz?: boolean;
  noStepper?: boolean;
  firstName?: string;
  bogoPromoType?: 'lifetime' | 'default';
  isB2b?: boolean;
}

class Header extends Component<PassedProps & RouteComponentProps> {
  render() {
    const {
      location: { pathname },
      completedQuiz,
      noStepper,
      firstName,
      bogoPromoType,
      isB2b,
    } = this.props;
    if (noStepper) {
      return null;
    } else if (completedQuiz) {
      return <QuizHeader isLogin={pathname.indexOf('login') >= 0} />;
    } else {
      return (
        <Stepper
          routeName={pathname}
          firstName={firstName}
          bogoPromoType={bogoPromoType}
          isB2b={isB2b}
        />
      );
    }
  }
}

export default withRouter(Header);
