import React from 'react';
import styled from 'styled-components';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';

// Assets
import journal from '../../assets/images/homepage/journal.png';
import love from '../../assets/images/homepage/love.png';
import meditation from '../../assets/images/homepage/meditation.png';
import community from '../../assets/images/homepage/community-icon.png';
import appDemo from '../../assets/video/appDemo_DailyShine.mp4';

// Components
import { DefaultText, BoldSubtitle } from '../homepage/HomepageStyles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px 2em;

  @media only screen and (min-width: ${SIZES.phone}) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    max-width: 1345px;
    align-self: center;
    height: 650px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: ${SIZES.phone}) {
    align-items: flex-start;
  }
`;

const Title = styled(BoldSubtitle)`
  font-style: normal;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 20px;

  @media only screen and (min-width: ${SIZES.phone}) {
    max-width: 440px;
    text-align: left;
  }
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-top: 20px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 320px;
  margin-bottom: 24px;
`;

const ListImage = styled.img`
  align-self: center;
  width: 42px;
  @media only screen and (min-width: ${SIZES.phone}) {
    width: 62px;
  }
`;

const ListTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 1em;
`;

const ListItemTitle = styled(BoldSubtitle)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 110%;
  text-align: left;
  margin-bottom: 8px;
`;

const ListItemDescription = styled(BoldSubtitle)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${COLORS.darkGrey};
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const EyeBrowTitle = styled(DefaultText)`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 110%;
  margin-bottom: 8px;
`;

const VideoContainer = styled.video`
  height: 650px;
  align-self: center;
  @media only screen and (min-width: ${SIZES.desktop}) {
    align-self: flex-start;
    margin-left: 40px;
  }
`;

const BenefitSection = () => {
  return (
    <Container id="benefits">
      <InfoContainer>
        <EyeBrowTitle>{COPY.eyeBrowTitle}</EyeBrowTitle>
        <Title>{COPY.title}</Title>
        <ListContainer>
          {COPY.items.map((item) => (
            <ListItem>
              <ListImage src={item.src}></ListImage>
              <ListTextWrapper>
                <ListItemTitle>{item.title}</ListItemTitle>
                <ListItemDescription>{item.description}</ListItemDescription>
              </ListTextWrapper>
            </ListItem>
          ))}
        </ListContainer>
      </InfoContainer>
      <VideoContainer autoPlay loop muted playsInline>
        <source src={appDemo} type="video/mp4" />
      </VideoContainer>
    </Container>
  );
};

const COPY = {
  eyeBrowTitle: 'Named Best of the Year by Apple',
  title: 'Start your mental wellness journey with Shine Premium',
  items: [
    {
      title: 'Daily Meditations',
      description:
        'Listen and learn a new self-care skill every weekday with the Daily Shine',
      src: meditation,
    },
    {
      title: 'Self-Care Courses',
      description:
        'Go deeper on topics like stress, boundaries, and more with audio courses hosted by experts',
      src: journal,
    },
    {
      title: 'Personalized Support',
      description:
        'Get recommendations from a library of 1,000+ meditations with representation at the forefront',
      src: love,
    },
    {
      title: 'Virtual Community Workshops',
      description:
        'Join monthly virtual workshops to get support from experts and our inclusive community',
      src: community,
    },
  ],
};
export default BenefitSection;
