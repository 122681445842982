import { GET_PROMO_DATA, INVALID_PROMO_ID } from '../constants/actionTypes';
import { getPromoContent } from '../api/contentfulClient';
import { findCouponById } from '../utils/stripeUtils';

export const getPromoData = (promoCode) => async (dispatch) => {
  let promoContent;
  try {
    promoContent = await getPromoContent(promoCode);

    if (promoContent.isPromo) {
      const { percent_off, valid, times_redeemed, max_redemptions } =
        await findCouponById(promoContent.couponId);

      const isB2b = promoContent.templateType === 'b2b';

      // Check if a the promo is not valid or if the max redemptions was reached
      // push users to default landing page.
      // Check if the promo is a b2b promo and if all of the seats have been redeemed.

      // TODO: Consider removing this isB2b if-block since seat checking should
      // now be handled within TemplateEnterprise
      if (isB2b && max_redemptions && times_redeemed === max_redemptions) {
        promoContent.maxSeatsRedeemed = true;
      } else if (
        // TODO: Revisit this logic. At least temporarily relegating this check
        // to only B2C promo codes so that legacy B2B codes can still redirect
        // from /promo to /work even though the original coupons are expired.
        !isB2b &&
        (!valid || (max_redemptions && times_redeemed === max_redemptions))
      ) {
        dispatch({ type: INVALID_PROMO_ID });
      } else {
        promoContent.stripeCouponPercentOff = percent_off;
      }
    }
    dispatch({ type: GET_PROMO_DATA, data: promoContent });
  } catch (err) {
    console.error(`${JSON.stringify(err, null, 2)}`);
    dispatch({ type: INVALID_PROMO_ID });
  }
};
