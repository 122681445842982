import styled from 'styled-components';
import COLORS from '../colors';

export const Page = styled.div`
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: space-around;
  text-align: center;
  width: 100%;
`;

// Desktop Homepage
export const Container = styled.div`
  align-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-repeat: no-repeat;
  width: 100%;
`;
