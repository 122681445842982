// Header Section
export const headerHero = require('../assets/images/header_hero_covidscreens.png');
export const headerBg = require('../assets/images/header_bg_calmwave.jpg');

// Social Proof Section
export const brandBestOf2018 = require('../assets/images/homepage/best-of-2018@2x.png');
export const brandFeaturedOnToday = require('../assets/images/homepage/featured-on-today@2x.png');
export const brandFiveStar = require('../assets/images/homepage/5-star-reviews@2x.jpg');
export const socialProofGif = require('../assets/images/homepage/social-proof-16k.gif');

// Core Feature Section
export const coreFeatureGrow = require('../assets/images/homepage/icon-grow.png');
export const coreFeatureMeditate = require('../assets/images/homepage/icon-meditate.png');
export const coreFeatureSleep = require('../assets/images/homepage/icon-sleep.png');
export const coreFeatureSupport = require('../assets/images/homepage/icon-support.png');

// Audio Sample Section
export const listenToASample = require('../assets/images/audio-player/listen-to-a-sample.png');

// Carousel Images
export const carouselImageOne = require('../assets/images/audio-player/carousel-track-1.png');
export const carouselImageTwo = require('../assets/images/audio-player/carousel-track-2.png');
export const carouselImageThree = require('../assets/images/audio-player/carousel-track-3.png');

// Membership Highlight Section
export const community = require('../assets/images/homepage/community.png');
export const moodCheckIn = require('../assets/images/homepage/mood-check-in-3-x@2x.png');
export const nightcaps = require('../assets/images/homepage/nightcaps-header.png');

// Testimonial Section
export const reviewStar = require('../assets/images/homepage/review-star.png');

// Shine Founder Section
export const shineFounders = require('../assets/images/homepage/naomi_and_marah.png');
export const shineFoundersWebp = require('../assets/images/homepage/naomi_and_marah.webp');
export const shineFoundersBgImage = require('../assets/images/homepage/shine-founders-background-blob.svg');

// Homepage - Library
export const libraryBg = require('../assets/images/homepage/library-section-blob.svg');
export const trackYellow = require('../assets/images/audio-player/track-yellow.jpg');
export const trackMauve = require('../assets/images/audio-player/track-mauve.jpg');
export const trackPurple = require('../assets/images/audio-player/track-purple.jpg');
export const trackRed = require('../assets/images/audio-player/track-red.jpg');
export const trackTeal = require('../assets/images/audio-player/track-teal.jpg');

// Homepage - Footer
export const facebookIcon = require('../assets/images/homepage/social-facebook.jpg');
export const instagramIcon = require('../assets/images/homepage/social-instagram.jpg');
export const pinterestIcon = require('../assets/images/homepage/social-pinterest.jpg');
export const spotifyIcon = require('../assets/images/homepage/social-spotify.jpg');
export const twitterIcon = require('../assets/images/homepage/social-twitter.jpg');

// SHARED ASSETS
export const star = require('../assets/images/homepage/premium-marker.png');
export const playButton = require('../assets/images/audio-player/playL.png');
export const newPlayButton = require('../assets/images/audio-player/play-button.svg');
export const newPlayButtonHover = require('../assets/images/audio-player/play-button-hover.svg');
export const sampleArrow = require('../assets/images/homepage/library-sample-arrow.svg');
export const previewPlay = require('../assets/images/audio-player/play.svg');
export const previewPause = require('../assets/images/audio-player/pause.svg');
export const iosBadge = require('../assets/images/app-badges/apple-store-badge-with-border.png');
export const androidBadge = require('../assets/images/app-badges/google-play-badge.png');
export const logo = require('../assets/shine-logo.png');
export const logoNoBorder = require('../assets/images/shine-logo-no-border.png');
export const appleTodayReviewGroup = require('../assets/images/homepage/apple-today-reviews-2020.png');
export const modelLoungingImg = require('../assets/images/homepage/expert-section-bg-image.jpg');

export const expertBg = require('../assets/images/homepage/expert-section-bg-image.jpg');
export const expertBgMobile = require('../assets/images/homepage/expert-section-bg-image-mobile.jpg');
export const yellowBlobs = require('../assets/images/homepage/yellow-blobs.png');
export const elishaWebp = require('../assets/images/experts/elisha.webp');
export const elisha = require('../assets/images/experts/elisha.png');
export const marielWebp = require('../assets/images/experts/mariel.webp');
export const mariel = require('../assets/images/experts/mariel.png');
export const jamilaWebp = require('../assets/images/experts/jamila.webp');
export const jamila = require('../assets/images/experts/jamila.png');
export const jenWebp = require('../assets/images/experts/jen.webp');
export const jen = require('../assets/images/experts/jen.png');
export const jezzWebp = require('../assets/images/experts/jezz.webp');
export const jezz = require('../assets/images/experts/jezz.png');
export const jorWebp = require('../assets/images/experts/jor.webp');
export const jor = require('../assets/images/experts/jor.png');
export const joyWebp = require('../assets/images/experts/joy.webp');
export const joy = require('../assets/images/experts/joy.png');

// Checkout
export const blobTop = require('../assets/images/checkout/blob-top.png');
export const blobBottom = require('../assets/images/checkout/blob-bottom.png');
export const fbIconWhite = require('../assets/images/checkout/facebook-icon.png');
export const appleIconWhite = require('../assets/images/checkout/apple-icon.png');
export const bulletCheck = require('../assets/images/checkout/bullet-check.jpg');
export const pitchPageModel = require('../assets/images/checkout/pitch-model.jpg');
export const pitchIconCommunity = require('../assets/images/checkout/pitch-icon-community.jpg');
export const pitchIconCustomization = require('../assets/images/checkout/pitch-icon-customization.jpg');
export const pitchIconMeditation = require('../assets/images/checkout/pitch-icon-meditation.jpg');
export const googlePayIcon = require('../assets/images/checkout/gpay.png');
export const giftcard = require('../assets/images/checkout/giftcard.jpg');
export const giftcardMcDonalds = require('../assets/images/checkout/giftcard-lifetime-mcdonalds.png');

// About Us Page
export const teamPhoto = require('../assets/images/about/team-group-photo.jpg');
export const darkGreyBlob = require('../assets/images/about/dark-grey-blob.png');
export const goldBlob = require('../assets/images/about/gold-blob.png');
export const darkGreenBlob = require('../assets/images/about/dark-green-blob.png');
export const marahNaomi = require('../assets/images/about/marah-naomi.jpg');
export const marahNaomiWebp = require('../assets/images/about/marah-naomi.webp');
export const MNAutograph = require('../assets/images/about/mn-autograph.png');
export const phoneScreens = require('../assets/images/about/shine-app-phone-screens.png');
export const phoneScreensWebp = require('../assets/images/about/shine-app-phone-screens.webp');

export const mashableColor = require('../assets/images/about/press-logos/mashable-color.png');
export const cbsColor = require('../assets/images/about/press-logos/cbs-color.png');
export const nbcColor = require('../assets/images/about/press-logos/nbcnews-color.png');
export const popsugarColor = require('../assets/images/about/press-logos/popsugar-color.png');
export const marieclaireColor = require('../assets/images/about/press-logos/marieclaire-color.png');
export const teenvogueColor = require('../assets/images/about/press-logos/teenvogue-color.png');
export const pinkBlob = require('../assets/images/about/pink-blob.png');
export const electionLogo = require('../assets/images/quiz/shine-election.png');
export const downloadIcon = require('../assets/images/quiz/download-icon.png');
export const whiteTwitterIcon = require('../assets/images/quiz/twitter.png');
export const podiumIcon = require('../assets/images/quiz/podium-icon.jpg');
export const skimmIcon = require('../assets/images/quiz/theskimm.png');

export const emptyStarTrackRating = require('../assets/images/audio-player/star-empty.png');
export const filledStarTrackRating = require('../assets/images/audio-player/star-filled.png');
export const halfStarTrackRating = require('../assets/images/audio-player/star-half.png');

// Portal Pages
export const blobBottomPortal = require('../assets/images/checkout/blob-bottom-portal.png');
export const portalIcons = require('../assets/images/portal/portal-icons.png');
export const premiumBannerImage = require('../assets/images/portal/premium-banner-image.png');
export const surveyBannerP2 = require('../assets/images/portal/survey-banner-p2.png');
export const surveyBannerP3 = require('../assets/images/portal/survey-banner-p3.png');
export const surveyBannerP4 = require('../assets/images/portal/survey-banner-p4.png');
export const goldBadge = require('../assets/images/portal/gold-badge.png');

// B2B Pages
export const b2bHeaderImg = require('../assets/images/b2b/black-man-working-from-home-with-laptop.jpg');
export const b2bHeaderImgWebp = require('../assets/images/b2b/black-man-working-from-home-with-laptop.webp');
export const sdcLogo = require('../assets/images/b2b/sdcLogo.svg');
export const fbLogo = require('../assets/images/b2b/fbLogo.svg');
export const targetLogo = require('../assets/images/b2b/targetLogo.svg');
export const testimonialBg = require('../assets/images/b2b/testimonial-blob.svg');
export const quote = require('../assets/images/b2b/quote.svg');
export const digidayLogo = require('../assets/images/b2b/press-logos/digiday.svg');
export const healthlineLogo = require('../assets/images/b2b/press-logos/healthline.svg');
export const wsjLogo = require('../assets/images/b2b/press-logos/wsj.svg');
export const crainsLogo = require('../assets/images/b2b/press-logos/crains.svg');
export const lightYellowBlobBottom = require('../assets/images/b2b/light-yellow-blob-bottom.svg');
export const leftBlob = require('../assets/images/b2b/form-section-left-blob.svg');
export const rightBlob = require('../assets/images/b2b/form-section-right-blob.svg');
export const checkmark = require('../assets/images/b2b/checkmark.svg');
export const checkmarkThin = require('../assets/images/checkmark-thin.svg');

// Portal Pages
export const yellowCornerBlob = require('../assets/images/podcasts/yellow-corner-blob.svg');
export const dsCover = require('../assets/images/podcasts/thedailyshine-cover.jpeg');
export const shineAppLogo = require('../assets/images/podcasts/shine-app-logo.svg');
export const applePodcastLogo = require('../assets/images/podcasts/applepodcast.svg');
export const spotifyPodcastLogo = require('../assets/images/podcasts/spotify.svg');
export const fwmhbCover = require('../assets/images/podcasts/fwmhb-cover.jpeg');
export const purpleWave = require('../assets/images/podcasts/light-purple-bottom-wave.svg');
export const reshma = require('../assets/images/podcasts/reshma.png');
export const sarah = require('../assets/images/podcasts/sarah.png');
export const andy = require('../assets/images/podcasts/andy.png');
export const jenFisher = require('../assets/images/podcasts/jen-fisher.png');
export const alisha = require('../assets/images/podcasts/alisha.png');
export const trinity = require('../assets/images/podcasts/trinity.png');
export const faye = require('../assets/images/podcasts/faye.png');
