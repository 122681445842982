import React from 'react';
import {
  Wrapper,
  BulletList,
  BulletContainer,
  BulletIcon,
  BulletText,
  HelpText,
} from '../B2BStyles';
import checkmarkThin from '../../../assets/images/checkmark-thin.svg';
import { YellowButton } from '../../../constants/styles/buttons';

// UTILS
import { goToSMBStripeCheckout } from '../../../utils/stripeUtils';

// Stripe Coupon ID for 25% off annual plan for one year
const SMB_COUPON_ID = 'partner-discount-25';

class SMBPlan extends React.Component {
  createCheckoutSession() {
    const couponId = SMB_COUPON_ID;
    const cancelUrl = window.location.href;
    const hostUrl = window.location.origin;
    const successUrl = `${hostUrl}/smb-checkout-confirmation`;
    goToSMBStripeCheckout(couponId, cancelUrl, successUrl);
  }
  render() {
    return (
      <Wrapper>
        <BulletList>
          {COPY.benefits.map((bullet) => (
            <BulletContainer key={bullet}>
              <BulletIcon src={checkmarkThin} />
              <BulletText>{bullet}</BulletText>
            </BulletContainer>
          ))}
        </BulletList>
        <YellowButton
          onClick={() => this.createCheckoutSession()}
          className="smb-start-checkout"
        >
          {COPY.btnCopy}
        </YellowButton>
        <HelpText
          dangerouslySetInnerHTML={{
            __html: COPY.subText,
          }}
        />
      </Wrapper>
    );
  }
}

const COPY = {
  btnCopy: 'Buy Now',
  benefits: [
    'Annual Shine Premium Access',
    'Customized Enrollment Page with your logo',
    'Data Metrics Reporting and Analytics',
    'Annual Self-Care Calendar',
    'Digital Launch & Engagement Promotional Toolkit',
  ],
  subText:
    'If you have questions, email <a href="mailto:help@headspace.com"/>help@headspace.com</a>.',
};

export default SMBPlan;
