import React from 'react';

import { TrackInfoContainer, Title, ArtistText } from './styles';

const TrackInformation = ({ title, speaker, theme }) => {
  return (
    <TrackInfoContainer>
      <div
        style={{ maxWidth: '250px', marginBottom: '5%', textAlign: 'center' }}
      >
        <Title theme={theme}>{title}</Title>
      </div>
      <div style={{ maxWidth: '150px', textAlign: 'center' }}>
        <ArtistText theme={theme}>{speaker}</ArtistText>
      </div>
    </TrackInfoContainer>
  );
};

export default TrackInformation;
