import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import Navbar from '../../components/sharedPartials/Navbar';
import Footer from '../../components/sharedPartials/Footer';

// CONSTANTS
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import {
  CheckoutBackground,
  CheckoutPageContainer,
  CheckoutBlobTop,
  CheckoutBlobBottom,
} from '../checkout/styles';
import { Container } from '../checkout/ConfirmationStyles';
import { Page } from '../../constants/styles';

const BodyText = styled.p`
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: ${COLORS.darkMagentaPink};
  text-align: left;
  margin: 0 24px 0 0;
  text-align: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 16px;
    margin: 0;
  }

  a {
    color: ${COLORS.darkModerateCyan};
  }
`;

class SMBCheckoutConfirmation extends Component {
  componentDidMount() {
    //When the page refreshes, react doesn't have the chance to unmount the components as normal.
    //reference: https://stackoverflow.com/questions/39084924/componentwillunmount-not-being-called-when-refreshing-the-current-page
    //signout when component is unmounted or page refreshes
    window.addEventListener('beforeunload', this.props.handleSignOut);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.props.handleSignOut);
  }

  render() {
    const COPY =
      'Thanks for being a Shine at Work partner! Our team will reach out shortly to help get you and your team setup for launch in 2 days. If you have any questions in the meantime, feel free to send us an email at <a href="mailto:shineatwork@theshineapp.com">shineatwork@theshineapp.com</a>.';

    return (
      <Page>
        <Navbar hasAdminPortalURL />
        <CheckoutBackground style={{ backgroundColor: COLORS.darkCyan }}>
          <CheckoutBlobTop />
          <CheckoutPageContainer>
            <Container>
              <BodyText
                dangerouslySetInnerHTML={{
                  __html: COPY,
                }}
              />
            </Container>
          </CheckoutPageContainer>
          <CheckoutBlobBottom />
        </CheckoutBackground>
        <Footer />
      </Page>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    handleSignOut() {
      dispatch(logout());
    },
  };
};

export default connect(null, mapDispatch)(SMBCheckoutConfirmation);

/**
 * PROP TYPES
 */
SMBCheckoutConfirmation.propTypes = {
  handleSignOut: PropTypes.func.isRequired,
};
