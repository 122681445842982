import React from 'react';
import styled from 'styled-components';

// Constants
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';
import FONTS from '../../../constants/fonts';
import { FOOTER_PAGES } from '../../../constants/config';

// Components
import { Link } from './styles';

const Container = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media only screen and (min-width: ${SIZES.tablet}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Note = styled.p`
  color: ${COLORS.darkGrey};
  display: inline-block;
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  font-size: 15px;
  margin: 0;
`;

const LeftTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 300px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin: 16px 0;
  }
`;

const Footnotes = () => {
  return (
    <Container>
      <LeftTextContainer>
        <Note>
          © {new Date().getFullYear()} The Shine App. All rights reserved
        </Note>
      </LeftTextContainer>
    </Container>
  );
};

export default Footnotes;
