import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { selectPaymentPlan } from '../../actions/billing';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { displayPrice } from '../../constants/sharedCopy';
import {
  NON_PROMO_CTA_LINK,
  PROMO_CTA_LINK,
  APPSFLYER_LINK,
} from '../../constants/config';

// Assets
import bgBlobTop from '../../assets/images/homepage/app-section-blob-top.svg';
import bgBlobBottom from '../../assets/images/homepage/app-section-blob-bottom.svg';
import checkmarkThin from '../../assets/images/checkmark-thin.svg';
import appleTodayReviewGroup from '../../assets/images/homepage/apple-today-reviews-2020.png';

// Components
import { BoldSubtitle, Link } from '../homepage/HomepageStyles';
import { YellowButton } from '../../constants/styles/buttons';

const BgContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLORS.darkGreyPink};

  @media only screen and (max-width: ${SIZES.phone}) {
    padding: 0 12px;
  }
`;

const BgBlobTop = styled.img.attrs({ src: bgBlobTop })`
  align-self: flex-start;
  width: 35vw;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 55vw;
    margin-left: -5%;
  }
`;

const BgBlobBottom = styled.img.attrs({ src: bgBlobBottom })`
  align-self: flex-start;
  margin-top: -23%;
  width: 30vw;

  @media only screen and (max-width: ${SIZES.desktop}) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -5%;
`;

const Title = styled(BoldSubtitle)`
  font-size: 47px;
  line-height: 110%;
  font-weight: 400;
  color: ${COLORS.white};
  margin: 0 0 40px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin-bottom: 36px;
    font-size: 36px;
  }
`;

const ComparisonTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const PremiumWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: ${COLORS.white}
  border-radius:8px;
  padding: 32px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    padding: 34px;
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    width:98%;
    padding: 34px 0;
  }
`;

const PremiumHeader = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-size: 22px;
  font-weight: 400;
  color: ${COLORS.darkGrey};
  margin: 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    margin: 0;
    font-size: 17px;
  }
`;

const PremiumPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: baseline;
`;

const PremiumPriceNumber = styled(BoldSubtitle)`
  color: ${COLORS.darkMagentaPink};
  margin: 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 32px;
`;

const PremiumPriceText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: ${COLORS.darkGrey};
  margin: 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 17px;
  }
`;

const PremiumButton = styled(YellowButton)`
  width: 200px;
  border: 1px solid ${COLORS.deepGoldenYellow};
`;

const FreeWrapper = styled(PremiumWrapper)`
  width: 355px;
  height: auto;
  padding: 38px 0;
  background-color: ${COLORS.lightGreyPink};

  @media only screen and (max-width: ${SIZES.desktop}) {
    margin-top: 16px;
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 98%;
    height: auto;
    padding: 32px 0;
  }
`;

const FreeHeader = styled(PremiumHeader)`
  color: ${COLORS.white};
`;

const FreePrice = styled(PremiumPriceNumber)`
  color: ${COLORS.white};
`;

const FreeButton = styled(PremiumButton)`
  background: ${COLORS.transparent};
  border: 1px solid ${COLORS.activeYellow};
  color: ${COLORS.white};

  &:hover {
    background: ${COLORS.shineYellow};
    border: 1px solid ${COLORS.shineYellow};
    color: ${COLORS.darkMagentaPink};
  }
`;

const StyledLink = styled(Link)`
  width: 200px;
  height: 45px;
  margin-top: 24px;
`;

const BulletList = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    align-self: flex-start;
    margin-left: 8px;
  }
`;

const BulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const BulletIcon = styled.img.attrs({ src: checkmarkThin })`
  width: 32px;
  height: 32px;
`;

const BulletText = styled.p`
  width: 100%;
  font-family: ${FONTS.poppinsPrimaryRegular}
  font-size: 16px;
  color: ${COLORS.darkGrey};
  text-align: left;
  margin: 8px;
`;

const WhiteBulletText = styled(BulletText)`
  color: ${COLORS.white};
`;

const Image = styled.img`
  max-width: 324px;
  height: 65.84px;
  margin: 2em 0 4em 0;
  align-self: center;
`;

const PremiumPriceNumberStrike = styled(PremiumPriceNumber)`
  font-weight: 300;
  margin-right: 20px;
`;

const DiscountPercentText = styled.div`
  font-family: ${FONTS.poppinsPrimaryRegular};
  color: ${COLORS.deepGoldenYellow};
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: -8px;

  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 17px;
  }
`;

class AppSection extends React.Component {
  selectPlan = () => {
    const { isPromo, selectPaymentPlan, pageId } = this.props;
    if (isPromo && pageId) {
      // Lock users into promo plan
      selectPaymentPlan(pageId);
    }
  };
  renderOriginalPriceWithStrikethrough = (originalPrice) => {
    return {
      __html: `<s>${displayPrice(originalPrice)}</s>`,
    };
  };

  displayDiscount = (discountPercent, discountFlatAmount) => {
    if (discountPercent) {
      return `${discountPercent}% OFF`;
    } else if (discountFlatAmount) {
      return `$${discountFlatAmount} OFF`;
    }
  };

  showPremiumBenefits = (isPromo) => {
    return (
      <React.Fragment>
        <BulletList>
          {COPY.premiumBenefits.map((bullet) => (
            <BulletContainer key={bullet}>
              <BulletIcon />
              <BulletText>{bullet}</BulletText>
            </BulletContainer>
          ))}
        </BulletList>
        <StyledLink
          to={isPromo ? PROMO_CTA_LINK : NON_PROMO_CTA_LINK}
          className="premium-footer-cta"
        >
          <PremiumButton>{COPY.premiumBtnCopy}</PremiumButton>
        </StyledLink>
      </React.Fragment>
    );
  };

  showFreeBenefits = () => {
    return (
      <React.Fragment>
        <BulletList>
          {COPY.freeBenefits.map((bullet) => (
            <BulletContainer key={bullet}>
              <BulletIcon />
              <WhiteBulletText>{bullet}</WhiteBulletText>
            </BulletContainer>
          ))}
        </BulletList>
        <StyledLink to={APPSFLYER_LINK} className="free-footer-cta">
          <FreeButton>{COPY.freeBtnCopy}</FreeButton>
        </StyledLink>
      </React.Fragment>
    );
  };

  render() {
    const {
      isPromo,
      discountedPrice,
      discountFlatAmount,
      discountPercent,
      originalPrice,
    } = this.props;
    return (
      <BgContainer id="app">
        <BgBlobTop />
        <Container>
          <Title>{isPromo ? COPY.promoHeader : COPY.homepageHeader}</Title>
          {isPromo ? (
            // Promo single benefits list
            <ComparisonTable>
              <PremiumWrapper isPromo>
                <PremiumHeader>{COPY.premiumHeader}</PremiumHeader>
                <PremiumPriceWrapper>
                  <PremiumPriceNumberStrike
                    dangerouslySetInnerHTML={this.renderOriginalPriceWithStrikethrough(
                      originalPrice
                    )}
                  />
                  <PremiumPriceNumber>
                    {`$${discountedPrice}`}
                  </PremiumPriceNumber>
                  <PremiumPriceText>{COPY.premiumPriceText}</PremiumPriceText>
                </PremiumPriceWrapper>
                <DiscountPercentText>
                  {this.displayDiscount(discountPercent, discountFlatAmount)}
                </DiscountPercentText>
                {this.showPremiumBenefits(isPromo)}
              </PremiumWrapper>
            </ComparisonTable>
          ) : (
            // NonPromo comparison table
            <ComparisonTable>
              <PremiumWrapper>
                <PremiumHeader>{COPY.premiumHeader}</PremiumHeader>
                <PremiumPriceWrapper>
                  <PremiumPriceNumber>{COPY.premiumPrice}</PremiumPriceNumber>
                  <PremiumPriceText>{COPY.premiumPriceText}</PremiumPriceText>
                </PremiumPriceWrapper>
                {this.showPremiumBenefits()}
              </PremiumWrapper>
              <FreeWrapper>
                <FreeHeader>{COPY.freeHeader}</FreeHeader>
                <FreePrice>{COPY.freePrice}</FreePrice>
                {this.showFreeBenefits()}
              </FreeWrapper>
            </ComparisonTable>
          )}
          <Image src={appleTodayReviewGroup}></Image>
        </Container>
        <BgBlobBottom />
      </BgContainer>
    );
  }
}

const COPY = {
  homepageHeader: 'Become a Shine Premium member',
  promoHeader: 'Your most powerful life starts now.',
  premiumHeader: 'Shine Premium membership',
  premiumPrice: '$64.99',
  premiumPriceText: '/yr',
  premiumBtnCopy: 'Become a Member',
  premiumBenefits: [
    'A new Daily Shine each weekday',
    'Exclusive library of 1,000+ meditations',
    'Meditations personalized to you',
    'Log and track your mood',
    'Members-only events',
    'Private digital community for 1:1 support',
  ],
  freeHeader: 'Free in the app',
  freePrice: '$0',
  freeBtnCopy: 'Download App',
  freeBenefits: ['A daily meditation', 'A daily article', 'Log your gratitude'],
};

const mapState = (state) => {
  return {
    isPromo: state.promo.isPromo,
    pageId: state.promo.pageId,
    discountedPrice: state.promo.discountedPrice,
    originalPrice: state.promo.originalPrice,
    discountPercent: state.promo.discountPercent,
    discountFlatAmount: state.promo.discountFlatAmount,
    displayMonthlyPrice: state.promo.displayMonthlyPrice,
  };
};

const mapDispatch = {
  selectPaymentPlan,
};

AppSection.propTypes = {
  isPromo: PropTypes.bool.isRequired,
  pageId: PropTypes.string.isRequired,
  discountedPrice: PropTypes.number,
  originalPrice: PropTypes.number,
  discountPercent: PropTypes.number,
  discountFlatAmount: PropTypes.number,
  displayMonthlyPrice: PropTypes.number,
};

export default connect(mapState, mapDispatch)(AppSection);
