import React from 'react';
import styled from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import { NON_PROMO_CTA_LINK } from '../../constants/config';

// Assets
import featuredPhone from '../../assets/images/homepage/featured-phone.png';
import featuredPhoneWebp from '../../assets/images/homepage/featured-phone.webp';
import bgURL from '../../assets/images/homepage/featured-section-blob.svg';

// Components
import { BoldSubtitle, DefaultText, Link } from '../homepage/HomepageStyles';
import { YellowButton } from '../../constants/styles/buttons';

// Utils
import { ImgWithFallback } from '../../utils/common';

const Section = styled.div`
  background-image: url(${bgURL});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 23%;
  background-color: ${COLORS.white};
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: ${SIZES.tablet}) {
    background-size: 40%;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    background-size: 70%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 60px;
  overflow: hidden;
  height: 939px;

  @media only screen and (min-width: ${SIZES.tablet}) {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: flex-start;
    margin-top: 75px;
    max-width: 1345px;
    align-self: center;
    height: 710px;
  }
`;

const Image = styled(ImgWithFallback)`
  width: 270px;
  align-self: center;
  padding-top: 25px;
  @media only screen and (min-width: ${SIZES.tablet}) {
    margin-right: 5em;
  }
  @media only screen and (min-width: ${SIZES.desktop}) {
    width: 425px;
    height: auto;
    align-self: flex-start;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 349px;
    flex: 0.5;
    align-items: center;
  }
`;

const Title = styled(BoldSubtitle)`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  text-align: left;
  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 349px;
    font-size: 40px;
  }
`;

const Description = styled(DefaultText)`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 190%;
  text-align: left;
  color: ${COLORS.darkGrey};
  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 349px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: ${SIZES.tablet}) {
    width: 349px;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const FeaturedSection = () => (
  <Section id="featured">
    <Container>
      <Wrapper>
        <Title>5 minutes a day to a calmer you.</Title>
        <Description>
          Your self-care ritual starts with the Daily Shine meditation. Each
          weekday, listen to new episodes based on timely topics, to help you
          connect with yourself, get grounded, and start your day with
          intention.
        </Description>
        <ButtonWrapper>
          <Link to={NON_PROMO_CTA_LINK} className="program-cta">
            <YellowButton>Get Started</YellowButton>
          </Link>
        </ButtonWrapper>
      </Wrapper>
      <Image
        src={featuredPhone}
        fallback={featuredPhoneWebp}
        altText="Shine app meditation - Greet anxiety with action"
      ></Image>
    </Container>
  </Section>
);

export default FeaturedSection;
