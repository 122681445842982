import { pathOr } from 'ramda';
import history from '../history';
import client from '../api/auth';
import firebase from 'firebase';
import axios from 'axios';

// ACTIONS
import { createSubscription } from './billing';
import { _getSubscriptionStatus, saveSubscriptionData } from './subscription';

// UTILS
import {
  createGiftSubscription,
  verifyGiftCard,
  redeemGiftCard,
} from './gifting';

// CONSTANTS
import * as TYPES from '../constants/actionTypes';
import {
  genericGiftRedemptionErrMsg,
  facebookErrorMsg,
  appleErrorMsg,
} from '../constants/errorMessages';
import {
  authMethods,
  MOBILE_APP_SUB_STATES,
  PAYMENT_PLATFORM,
} from '../constants/config';
import { API_ACTIONS } from '../utils/apiActions';

// TYPE DEFINITIONS
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  AuthUserCredentials,
  CompanyCredentials,
  DetermineAuthNextStepsParams,
  PostAuthHandlerParams,
  UserDataWithGiftCard,
  UTMParams,
} from '../types/actions/auth-actions';

class CustomError extends Error {
  msg: string | undefined;
  type: string | undefined;
}

interface GenericErrorType {
  response?: {
    status: number;
    data: any;
  };
  msg?: string;
  message?: string;
}

// Authenticate user either by completing Form, Facebook or Apple
export const authenticateUser =
  (
    authType: string,
    credentials: AuthUserCredentials
  ): ThunkAction<void, unknown, unknown, AnyAction> =>
  async (dispatch) => {
    let user;

    try {
      switch (authType) {
        case `${authMethods.APPLE_LOGIN}`:
          dispatch({ type: TYPES.AUTH_APPLE_LOGIN_REQUEST });
          user = await _appleLogin({ ...credentials });
          dispatch({
            type: TYPES.AUTH_APPLE_LOGIN_SUCCESS,
            user,
            isSignup: credentials.isSignup,
          });
          break;
        case `${authMethods.FB_LOGIN}`:
          dispatch({ type: TYPES.AUTH_FACEBOOK_LOGIN_REQUEST });
          user = await _fbLogin({ ...credentials });
          dispatch({
            type: TYPES.AUTH_FACEBOOK_LOGIN_SUCCESS,
            user,
            isSignup: credentials.isSignup,
          });
          break;
        case `${authMethods.LOGIN}`:
          dispatch({ type: TYPES.AUTH_LOGIN_REQUEST });
          user = await _login({ ...credentials });
          dispatch({ type: TYPES.AUTH_LOGIN_SUCCESS, user });
          break;
        case `${authMethods.SIGNUP}`:
          dispatch({ type: TYPES.AUTH_SIGNUP_REQUEST });
          user = await _signup({ ...credentials });
          dispatch({ type: TYPES.AUTH_SIGNUP_SUCCESS, user });
          break;
        default:
          break;
      }

      dispatch({ type: TYPES.AUTH_COMPLETE });
    } catch (error) {
      console.error(`Auth or billing error ${error}`);
      const e = error as GenericErrorType;
      if (e.response && e.response.status === 429) {
        return dispatch({
          type: TYPES.AUTH_FAIL,
          errorMsg: e.response.data,
        });
      }
      dispatch({ type: TYPES.AUTH_FAIL, errorMsg: e.msg || e.message });
    }
  };

export const postAuthHandler =
  ({
    quizResults,
    companyName,
    user,
    subscriptionToApply,
    utmParams,
  }: PostAuthHandlerParams): ThunkAction<
    Promise<void>,
    unknown,
    unknown,
    AnyAction
  > =>
  async (dispatch) => {
    try {
      const { data: subscriptionData } = await _getSubscriptionStatus();
      dispatch(saveSubscriptionData(subscriptionData));
      const userType = determineAuthNextSteps({
        quizResults,
        companyName,
        giftCardNumber: user.giftCardNumber,
        currentSubscriptionData: subscriptionData,
        subscriptionToApply,
      });
      dispatch(
        routeAuthenticatedUser(userType, {
          quizResults,
          companyName,
          user,
          subscriptionToApply,
          utmParams,
        })
      );
    } catch (error) {
      console.error(`_getSubscriptionStatus error ${error}`);
      const e = error as GenericErrorType;
      dispatch({
        type: TYPES.AUTH_FAIL,
        errorMsg: `Please try again or reach out to help@headspace.com for assistance. (Authentication error: ${
          e.msg || e.message
        })`,
      });
    }
  };

export const redeemEnterpriseSubscription =
  (
    uid: string,
    companyCredentials: CompanyCredentials
  ): ThunkAction<void, unknown, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      /* Redeem Enterprise Subscription & Create/update employee row in user_company table */
      dispatch({ type: TYPES.REDEEM_ENTERPRISE_SUBSCRIPTION_REQUEST });
      const response = await axios.post(
        `/.netlify/functions/enterprise-subscription-handler`,
        {
          uid,
          scid: companyCredentials.stripeCustomerId,
          action: API_ACTIONS.REDEEM_ENTERPRISE_SUBSCRIPTION,
          company_id: companyCredentials.companyId,
          member_id: companyCredentials.memberId,
        }
      );
      if (response.status === 200) {
        dispatch({ type: TYPES.REDEEM_ENTERPRISE_SUBSCRIPTION_SUCCESS });
        history.push('/confirmation');
      } else {
        dispatch({
          type: TYPES.REDEEM_ENTERPRISE_SUBSCRIPTION_FAIL,
          errorMessage:
            'Oops. An error occurred, please try again later or contact the shine support team at help@headspace.com.',
        });
      }
    } catch (error) {
      const e = error as GenericErrorType;
      dispatch({
        type: TYPES.REDEEM_ENTERPRISE_SUBSCRIPTION_FAIL,
        errorMessage: e.msg || e.message,
      });
    }
  };

const redeemGiftSubscription =
  (
    user: UserDataWithGiftCard,
    utmParams?: UTMParams
  ): ThunkAction<void, unknown, unknown, AnyAction> =>
  async (dispatch) => {
    const { giftCardNumber, uid } = user;
    try {
      const { planId, interval } = await verifyGiftCard(giftCardNumber);

      dispatch({ type: TYPES.REDEEM_GIFT_SUBSCRIPTION_REQUEST });

      await createGiftSubscription(
        user,
        planId,
        interval,
        giftCardNumber,
        utmParams
      );
      await redeemGiftCard(giftCardNumber, uid);

      dispatch({
        type: TYPES.REDEEM_GIFT_SUBSCRIPTION_SUCCESS,
        giftCardNumber,
      });
      history.push(`/confirmation?code=${giftCardNumber}`);
    } catch (error) {
      console.error(`Error: ${error}`);

      const e = error as GenericErrorType;
      dispatch({
        type: TYPES.REDEEM_GIFT_SUBSCRIPTION_FAILED,
        errorMsg: e.msg ? e.msg : genericGiftRedemptionErrMsg,
      });
    }
  };

export const logout =
  (): ThunkAction<void, unknown, unknown, AnyAction> => (dispatch) => {
    dispatch({ type: TYPES.AUTH_LOGOUT_SUCCESS });
    client.auth.logout();
  };

/**
 * Reauthenticates previously logged in user
 */
export const reAuthCurrentUser =
  (): ThunkAction<void, unknown, unknown, AnyAction> => async (dispatch) => {
    let user;
    try {
      dispatch({ type: TYPES.AUTH_REAUTHENTICATE_REQUEST });
      user = await client.auth.getCurrentUser();
      dispatch({ type: TYPES.AUTH_REAUTHENTICATE_SUCCESS, user });
    } catch (error) {
      dispatch({ type: TYPES.AUTH_REAUTHENTICATE_FAIL, error });
    }
  };

export const clearErrorState = () => ({ type: TYPES.AUTH_CLEAR_ERROR_STATE });

export const displayErrorState = (message: string) => ({
  type: TYPES.AUTH_DISPLAY_ERROR_STATE,
  errorMsg: message,
});

/**
 * PRIVATE UTIL FUNCTIONS & CONSTANTS
 */

export const sendPasswordResetEmail = (email: string) => {
  client.auth.forgotPassword(email);
};

interface FirebaseErrorObject {
  code: string;
  message: string;
}

/**
 * Private helper function that makes API calls to firebase & glow-backend
 * when a user logs in via Apple
 */
async function _appleLogin(credentials: AuthUserCredentials) {
  try {
    const user = await client.auth.loginApple(credentials);
    return user;
  } catch (error) {
    const e = error as FirebaseErrorObject;
    let customError = new CustomError();
    if (appleErrorMsg.hasOwnProperty(e.code)) {
      customError.msg = appleErrorMsg[e.code];
    } else {
      customError.msg = e.message;
    }

    customError.type = TYPES.AUTH_FAIL;
    throw customError;
  }
}

/**
 * Private helper function that makes API calls to firebase & glow-backend
 * when a user logs in via FB
 */
async function _fbLogin(credentials: AuthUserCredentials) {
  try {
    const user = await client.auth.loginFacebook(credentials);
    return user;
  } catch (error) {
    const e = error as FirebaseErrorObject;
    let customError = new CustomError();
    if (facebookErrorMsg.hasOwnProperty(e.code)) {
      customError.msg = facebookErrorMsg[e.code];
    } else {
      customError.msg = e.message;
    }

    customError.type = TYPES.AUTH_FAIL;
    throw customError;
  }
}

/**
 * Private helper function that makes API calls to firebase & glow-backend
 * when a user logs in
 */
async function _login(credentials: AuthUserCredentials) {
  try {
    const user = await client.auth.login(credentials);
    return user;
  } catch (error) {
    const e = error as FirebaseErrorObject;
    let customError = new CustomError();
    customError.msg = e.message;
    customError.type = TYPES.AUTH_FAIL;
    throw customError;
  }
}

/**
 * Private helper function that makes API calls to firebase & glow-backend
 * when a user signs up
 */
async function _signup(credentials: AuthUserCredentials) {
  try {
    const user = await client.auth.register(credentials);
    return user;
  } catch (error) {
    const e = error as FirebaseErrorObject;
    let customError = new CustomError();
    customError.msg = e.message;
    customError.type = TYPES.AUTH_FAIL;
    throw customError;
  }
}

async function saveUserPersonalityType(personalityTypeId: number | boolean) {
  if (!firebase.auth().currentUser) {
    return;
  }
  const data = {
    type: 'save-personality-type',
    personalityTypeId,
  };
  // @ts-ignore Current User Object is possibly null error
  const idToken = await firebase.auth().currentUser.getIdToken();
  await axios({
    method: 'post',
    url: `${process.env.REACT_APP_GLOW_BACKEND}/me/activity`,
    responseType: 'json',
    headers: {
      'Id-Token': idToken,
    },
    data: data,
  });
}

/**
 * Check user credentials for shine at work data and create object to tag on
 * stripe and chart mogul
 */
const formatB2bTags = (companyName?: string) => {
  if (companyName) {
    return {
      company: companyName,
      isB2b: true,
    };
  }
  return {};
};

export const USER = {
  IS_REDEEMING_100_OFF_SUBSCRIPTION: 'IS_REDEEMING_100_OFF_SUBSCRIPTION',
  IS_REDEEMING_GIFTCARD: 'IS_REDEEMING_GIFTCARD',
  HAS_COMPLETED_QUIZ: 'HAS_COMPLETED_QUIZ',
  HAS_PREMIUM_SUB: 'HAS_PREMIUM_SUB',
  HAS_EXPIRED_SUB_B2C: 'HAS_EXPIRED_SUB_B2C',
  DEFAULT: 'DEFAULT',
};

/**
 * Util function to add a layer of readability when we
 * check various flags to determine next steps after a user authenticates
 */
export const determineAuthNextSteps = ({
  quizResults,
  companyName,
  giftCardNumber,
  currentSubscriptionData,
  subscriptionToApply,
}: DetermineAuthNextStepsParams) => {
  if (quizResults && quizResults.name && quizResults.id) {
    return USER.HAS_COMPLETED_QUIZ;
  } else if (
    subscriptionToApply &&
    subscriptionToApply.stripeCouponPercentOff === 100
  ) {
    return USER.IS_REDEEMING_100_OFF_SUBSCRIPTION;
  } else if (giftCardNumber) {
    return USER.IS_REDEEMING_GIFTCARD;
  }

  // Parse user's subscription data
  const platform = pathOr(null, ['platform'], currentSubscriptionData);
  const status = pathOr(
    null,
    ['subscription', 'subscription_status'],
    currentSubscriptionData
  );

  if (
    status === MOBILE_APP_SUB_STATES.ACTIVE_AUTO_RENEW ||
    status === MOBILE_APP_SUB_STATES.TRIAL ||
    platform === PAYMENT_PLATFORM.STRIPE_B2B
  ) {
    return USER.HAS_PREMIUM_SUB;
  } else if (
    (status === MOBILE_APP_SUB_STATES.EXPIRED &&
      platform !== PAYMENT_PLATFORM.STRIPE_B2B) ||
    (status === MOBILE_APP_SUB_STATES.EXPIRED && !companyName)
  ) {
    return USER.HAS_EXPIRED_SUB_B2C;
  } else {
    return USER.DEFAULT;
  }
};

/**
 * Util action function that routes users to respective URL
 */
export const routeAuthenticatedUser =
  (
    userType: string,
    {
      quizResults,
      companyName,
      user,
      subscriptionToApply,
      utmParams,
    }: PostAuthHandlerParams
  ): ThunkAction<Promise<void>, unknown, unknown, AnyAction> =>
  async (dispatch) => {
    if (userType === USER.IS_REDEEMING_100_OFF_SUBSCRIPTION) {
      const b2bTags = formatB2bTags(companyName);
      dispatch(
        createSubscription({
          token: null,
          user,
          planId: subscriptionToApply.planId,
          couponId: subscriptionToApply.couponId,
          isTrial: false,
          utmParams,
          b2bTags,
        })
      );
    } else if (userType === USER.IS_REDEEMING_GIFTCARD && user.giftCardNumber) {
      const userWithGiftCard = { ...user, giftCardNumber: user.giftCardNumber };
      dispatch(redeemGiftSubscription(userWithGiftCard));
    } else if (userType === USER.HAS_COMPLETED_QUIZ) {
      saveUserPersonalityType(quizResults.id);
      let resultNameSlug = quizResults.name.toLowerCase().split(' ').join('_');
      history.push(`/results/${resultNameSlug}`);
    } else if (userType === USER.HAS_PREMIUM_SUB) {
      history.push(`/events`);
    } else if (userType === USER.HAS_EXPIRED_SUB_B2C) {
      history.push(`/subscription`);
    } else {
      history.push(`/account`);
    }
  };
