import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { NAV_BAR, navbarLinksB2B } from '../../constants/config';
import { logoNoBorder } from '../../constants/image.config';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-items: center;
  height: 91px;
`;

export const Logo = styled.img.attrs({ src: logoNoBorder })`
  margin-top: 15px;
  margin-left: 7%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: 64px;
`;

const StyledBurger = styled.button`
  position: absolute;
  margin-top: 2em;
  right: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2em;
  height: 2em;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  &:focus {
    outline: none;
  }
  div {
    width: 2em;
    height: 0.18em;
    background: ${COLORS.blackTwo};
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
  @media only screen and (min-width: ${SIZES.tablet}) {
    display: none;
  }
`;

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: row;
  position: absolute;
  font-family: ${FONTS.poppinsPrimaryRegular};
  right: 0;
  margin-top: 32px;
  z-index: 2;
  align-items: baseline;
  a {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px
    text-transform: capitalize;
    color: ${COLORS.darkMagentaPink};
    text-decoration: none;
    transition: color 0.3s linear;
    margin-right: 38px;
    border-bottom: 2px solid transparent;

    &:hover {
      cursor: pointer;
      border-bottom: 2px solid;
    }

    @media (max-width: ${SIZES.tablet}) {
      font-size: 1em;
      text-align: center;
      margin-top: 43px;
    }
  }

  .get-started-button {
    background: ${COLORS.deepGoldenYellow};
    border-radius: 50px;
    padding: 10px 24px;
    border-bottom: 0px;
    transition: background 0.5s;
    
    &:hover {
      background:${COLORS.shineYellow}
      border-bottom: 0px;
    }
  }

  transition: transform 0.3s ease-in-out;

  @media (max-width: ${SIZES.tablet}) {
    width: 100%;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    flex-direction: column;
    background: ${COLORS.lightYellow};
    height: 100vh;
    margin-top: 0px;
    align-items: center;
    a {
      color: ${COLORS.darkMagentaPink};
    }
  }
`;

class Navbar extends React.Component {
  constructor() {
    super();
    this.state = { open: false };
  }

  setOpen = (value) => {
    this.setState({ open: value });
  };

  render() {
    const LINKS = this.props.hasAdminPortalURL
      ? navbarLinksB2B
      : NAV_BAR.getLinks();

    return (
      <Container>
        <a href="/">
          <Logo />
        </a>
        <StyledBurger
          open={this.state.open}
          onClick={() => this.setOpen(!this.state.open)}
        >
          <div />
          <div />
          <div />
        </StyledBurger>
        <StyledMenu open={this.state.open}>
          {LINKS.map((item) => (
            <a
              key={item.link}
              href={item.link}
              rel="noopener noreferrer"
              onClick={() => this.setOpen(false)}
              className={item.className || ''}
            >
              {item.copy}
            </a>
          ))}
        </StyledMenu>
      </Container>
    );
  }
}
Navbar.propTypes = {
  hasAdminPortalURL: PropTypes.bool,
};

export default Navbar;
