import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth';
import { TopNavWrapper, Logo, TopNavContainer, TopNavLink } from '../styles';

class TopNav extends Component {
  render() {
    return (
      <TopNavWrapper>
        <Link to="/">
          <Logo />
        </Link>
        <TopNavContainer>
          <TopNavLink href="/faq" target="_blank">
            {COPY.help}
          </TopNavLink>
          <TopNavLink onClick={() => this.props.handleSignOut()}>
            {COPY.logout}
          </TopNavLink>
        </TopNavContainer>
      </TopNavWrapper>
    );
  }
}

const COPY = {
  help: 'Help',
  logout: 'Logout',
};

const mapDispatch = (dispatch) => {
  return {
    handleSignOut() {
      dispatch(logout());
    },
  };
};

export default connect(null, mapDispatch)(TopNav);
