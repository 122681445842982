import React, { Component } from 'react';
import dayjs from 'dayjs';
import { YellowButton, LockIcon } from '../styles';
import {
  SectionWrapper,
  Title,
  Description,
  PastEventCard,
  PastEventImage,
  EventDetails,
  DateWrapper,
  DateIcon,
  EventCTA,
} from '../eventStyles';
import calendarIcon from '../../../assets/images/portal/events.svg';

import EventArchiveJson from '../EventArchive.json';
import { MOBILE_APP_ACCESS } from '../../../constants/config';
import { EVENTS_PAGE_UPSELL_LINK } from '../../../constants/config';

interface Props {
  accessType: string;
}
interface Event {
  title: string;
  date: string;
  imageLink: string;
  recapLink?: string;
  pdf?: string;
}
class PastEventsSection extends Component<Props> {
  renderWatchButton(accessType: string, event: Event) {
    if (accessType === MOBILE_APP_ACCESS.PREMIUM && event.recapLink) {
      return (
        <EventCTA>
          <a
            href={event.recapLink}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            target="_blank"
            className="watch-event"
          >
            <YellowButton>{COPY.watchButton}</YellowButton>
          </a>
        </EventCTA>
      );
    }

    if (accessType === MOBILE_APP_ACCESS.FREE && event.recapLink) {
      return (
        <EventCTA>
          <a
            href={EVENTS_PAGE_UPSELL_LINK}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            className="watch-event-upgrade"
          >
            <YellowButton>
              <LockIcon />
              {COPY.upgradeToWatchButton}
            </YellowButton>
          </a>
        </EventCTA>
      );
    }
  }

  renderReadLink(accessType: string, event: Event) {
    if (accessType === MOBILE_APP_ACCESS.PREMIUM && event.pdf) {
      return (
        <a
          href={event.pdf}
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
          target="_blank"
          className="read-recap"
        >
          {COPY.readLink}
        </a>
      );
    }
    if (accessType === MOBILE_APP_ACCESS.FREE && event.pdf) {
      return (
        <a
          href={EVENTS_PAGE_UPSELL_LINK}
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline' }}
          className="read-recap-upgrade"
        >
          {COPY.upgradeToReadLink}
        </a>
      );
    }
  }

  render() {
    const { accessType } = this.props;

    // Filters for events after the current date and time and reverse the order so new events show at the top
    const pastEventsOnly = EventArchiveJson.filter((event: Event) =>
      dayjs().isAfter(dayjs(event.date))
    ).reverse();

    return (
      <>
        <SectionWrapper>
          <Title>{COPY.title}</Title>
          <Description>{COPY.description}</Description>
        </SectionWrapper>
        {pastEventsOnly.map((event) => (
          <PastEventCard>
            <PastEventImage src={event.imageLink} />
            <EventDetails>
              <Title>{event.title}</Title>
              <DateWrapper>
                <DateIcon src={calendarIcon} />
                <Description>{event.date}</Description>
              </DateWrapper>
              <Description>
                {this.renderReadLink(accessType, event)}
              </Description>
            </EventDetails>
            {this.renderWatchButton(accessType, event)}
          </PastEventCard>
        ))}
      </>
    );
  }
}

const COPY = {
  title: 'Past Events',
  description: 'Rewatch our latest community events.',
  watchButton: 'Watch Event',
  upgradeToWatchButton: 'Upgrade to Watch',
  readLink: 'Read Recap',
  upgradeToReadLink: 'Upgrade to Read Recap',
};

export default PastEventsSection;
