import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import giftUpClient from '../../api/giftUpClient';
import history from '../../history';
import { Link } from 'react-router-dom';

// Form Components
import FormInputComponent from '../form/FormInputComponent';
import {
  ErrorMessage,
  Form,
  FormButtonContainer,
  Card,
  Divider,
  GiftCard,
  SkipButton,
  SkipButtonWrapper,
} from '../form/FormStyles';
import { FormSubmitButton, ButtonText, Spinner } from '../../constants/styles';
import { Header } from '../sharedPartials';
// Constant
import { SEND_BUTTON_COPY } from '../../constants/sharedCopy';
import { giftcard, giftcardMcDonalds } from '../../constants/image.config';
import { getBOGOPromoType } from '../../constants/config';

interface RootState {
  user: {
    userData: {
      firstName: string;
      email: string;
    };
  };
  billing: {
    selectedPlan: {
      billingCycle: string;
      couponId?: string;
    };
  };
}

interface Props extends PropsFromRedux {}

interface State {
  firstName: string;
  email: string;
  recipientName: string;
  recipientEmail: string;
  message: string;
  isLoading: boolean;
  hasError: boolean;
  errorMsg: string;
}

class SendGiftForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: this.props.userData.firstName,
      email: this.props.userData.email,
      recipientName: '',
      recipientEmail: '',
      message: '',
      isLoading: false,
      hasError: false,
      errorMsg: '',
    };
  }

  handleSubmit = async (event: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      const target = event.target as typeof event.target & {
        firstName: { value: string };
        email: { value: string };
        recipientName: { value: string };
        recipientEmail: { value: string };
        message: { value: string };
      };
      const purchaserName = target.firstName.value;
      const purchaserEmail = target.email.value;
      const recipientName = target.recipientName.value;
      const recipientEmail = target.recipientEmail.value;
      const message = target.message ? target.message.value : null;

      this.setState({ isLoading: true });

      try {
        const giftcardId =
          getBOGOPromoType(this.props.couponId) === 'lifetime'
            ? process.env.REACT_APP_GIFTUP_BOGO_LIFETIME_GIFTCARD_ID
            : process.env.REACT_APP_GIFTUP_BOGO_GIFTCARD_ID;
        const response = await giftUpClient.post(`/orders`, {
          disableAllEmails: false,
          purchaserEmail,
          purchaserName,
          tip: 0,
          serviceFee: 0,
          discount: 0,
          revenue: 0,
          itemDetails: [
            {
              quantity: 1,
              name: 'Shine Premium',
              backingType: 'Currency',
              id: giftcardId,
            },
          ],
          recipientDetails: {
            recipientName,
            recipientEmail,
            message,
          },
        });

        if (
          response &&
          response.data &&
          response.data.fulfilments.length &&
          response.data.fulfilments[0].success
        ) {
          history.push(`/confirmation?sendGiftStatus=sent`, {
            recipientName,
            recipientEmail,
          });
        }
      } catch (err) {
        if (err.response && err.response.data) {
          if (err.response.data.length) {
            console.error(`Error ${err.response.data[0].error}`);
          } else {
            console.error('Response Data', JSON.stringify(err.response.data));
            console.error(
              'Response Status',
              JSON.stringify(err.response.status)
            );
            console.error(
              'Response Header',
              JSON.stringify(err.response.header)
            );
          }
        }
        console.error(
          `Error Message ${err.message} & ${JSON.stringify(err, null, 2)}`
        );
        this.setState({
          hasError: true,
          errorMsg: 'Error sending gift card, please try again.',
          isLoading: false,
        });
      }
    }
  };

  /**
   * Update displayed valued as user types
   */
  inputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.state.hasError) {
      this.setState({ hasError: false, errorMsg: '' });
    }
    if (event.target.name === 'firstName')
      this.setState({ firstName: event.target.value });
    if (event.target.name === 'email')
      this.setState({ email: event.target.value });
    if (event.target.name === 'recipientName')
      this.setState({ recipientName: event.target.value });
    if (event.target.name === 'recipientEmail')
      this.setState({ recipientEmail: event.target.value });
    if (event.target.name === 'message')
      this.setState({ message: event.target.value });
    return;
  };

  render() {
    const { isLoading, errorMsg } = this.state;
    const bogoPromoType = getBOGOPromoType(this.props.couponId);
    const cardImage =
      bogoPromoType === 'lifetime' ? giftcardMcDonalds : giftcard;
    return (
      <React.Fragment>
        <Card>
          <Header bogoPromoType={bogoPromoType} />
          <GiftCard src={cardImage} />
          <Form onSubmit={this.handleSubmit}>
            <FormInputComponent
              key="Your Name*"
              label="Your Name*"
              inputName="firstName"
              inputType="text"
              inputValue={this.state.firstName}
              onChange={this.inputChange}
            />
            <FormInputComponent
              key="Your Email*"
              label="Your Email*"
              inputName="email"
              inputType="text"
              inputValue={this.state.email}
              onChange={this.inputChange}
            />
            <Divider />
            <FormInputComponent
              key="Recipient Name*"
              label="Recipient Name*"
              inputName="recipientName"
              inputType="text"
              inputValue={this.state.recipientName}
              onChange={this.inputChange}
            />
            <FormInputComponent
              key="Recipient Email*"
              label="Recipient Email*"
              inputName="recipientEmail"
              inputType="text"
              inputValue={this.state.recipientEmail}
              onChange={this.inputChange}
            />
            <FormInputComponent
              key="Message (Optional)"
              label="Message (Optional)"
              inputName="message"
              inputType="text"
              inputValue={this.state.message}
              onChange={this.inputChange}
              optional
            />
            {errorMsg && <ErrorMessage> {errorMsg} </ErrorMessage>}
            <FormButtonContainer>
              {isLoading ? (
                <Spinner />
              ) : (
                <FormSubmitButton type="submit" className="send-gift">
                  <ButtonText>{SEND_BUTTON_COPY}</ButtonText>
                </FormSubmitButton>
              )}
            </FormButtonContainer>
            <FormButtonContainer>
              <SkipButtonWrapper>
                <Link
                  to="/confirmation?sendGiftStatus=skipped"
                  className="send-gift-form-skip"
                  style={{ textDecoration: 'none' }}
                >
                  <SkipButton>Skip for now</SkipButton>
                </Link>
              </SkipButtonWrapper>
            </FormButtonContainer>
          </Form>
        </Card>
      </React.Fragment>
    );
  }
}

const mapState = (state: RootState) => {
  return {
    userData: state.user.userData,
    billing: state.billing.selectedPlan.billingCycle,
    couponId: state.billing.selectedPlan.couponId,
  };
};

const connector = connect(mapState);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SendGiftForm);
