import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import {
  PageContainer,
  HeroImageContainer,
  HeroImage,
  ResultHeroImageRowContainer,
  Line,
  BlobOutline,
  PlayTrackButton,
  MeditationLeftContainer,
  MeditationRightContainer,
  MeditationCardTitle,
  MeditationCardDescription,
  ResultsLogo,
  MeditationLink,
  MainContainer,
  FirstContainer,
  SecondContainer,
  Header,
  MeditationHeader,
  MeditationCard,
  VotePlanCard,
  VotePlanText,
  VotePlanIcon,
  HeaderContainer,
  MantraTitle,
  ResultDescription,
  ShareContainer,
  TwitterButton,
  ShareIcon,
  TweetText,
  ListContainer,
  ListHeader,
  ListHeaderImage,
  ListItem,
  ListItemDescription,
  DownloadButton,
  DownloadText,
} from './ResultsStyles';
import { Footer } from '../sharedPartials';

import {
  whiteTwitterIcon,
  skimmIcon,
  downloadIcon,
} from '../../constants/image.config';

class Results extends Component {
  renderLists = () => {
    const {
      results: { lists },
    } = this.props;
    const list = [];
    for (let i = 1; i <= 3; i++) {
      let listObj = lists[i];
      list.push(
        <ListContainer>
          <ListHeaderImage src={listObj.img} />
          <ListHeader>{listObj.title}</ListHeader>
          {listObj.items.map((item, idx) => (
            <ListItem key={idx}>
              <ListItemDescription dangerouslySetInnerHTML={{ __html: item }} />
            </ListItem>
          ))}
        </ListContainer>
      );
    }
    return list;
  };
  saveOrPrintResults() {
    window.print();
  }
  render() {
    const { results } = this.props;
    return (
      <React.Fragment>
        <PageContainer className="page-container">
          <BlobOutline />
          <ResultsLogo />
          <MainContainer>
            <FirstContainer id="main" className="page">
              <ResultHeroImageRowContainer>
                <HeroImageContainer>
                  <HeroImage src={results.image} />
                </HeroImageContainer>
              </ResultHeroImageRowContainer>
              <HeaderContainer>
                <Header>{COPY.mantra}</Header>
                <MantraTitle>{results.name}</MantraTitle>
                <ResultDescription>{results.description}</ResultDescription>
                <ShareContainer>
                  <a
                    href={results.share_tweet}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                    }}
                  >
                    <TwitterButton>
                      <ShareIcon src={whiteTwitterIcon} />
                      <TweetText>{COPY.share_tweet}</TweetText>
                    </TwitterButton>
                  </a>
                  <DownloadButton onClick={this.saveOrPrintResults}>
                    <ShareIcon src={downloadIcon} />
                    <DownloadText>{COPY.share_tweet}</DownloadText>
                  </DownloadButton>
                </ShareContainer>
              </HeaderContainer>
              <Line className="line" />
              {this.renderLists()}
            </FirstContainer>
            <SecondContainer>
              <MeditationHeader>{COPY.recommended}</MeditationHeader>
              <MeditationLink
                href={results.recommended_content.app_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MeditationCard>
                  <MeditationLeftContainer>
                    <PlayTrackButton />
                  </MeditationLeftContainer>
                  <MeditationRightContainer>
                    <MeditationCardTitle>
                      {results.recommended_content.track_name}
                    </MeditationCardTitle>
                    <MeditationCardDescription numberOfLines={2}>
                      {results.recommended_content.track_description}
                    </MeditationCardDescription>
                  </MeditationRightContainer>
                </MeditationCard>
              </MeditationLink>
              <VotePlanCard>
                <VotePlanIcon src={skimmIcon}></VotePlanIcon>
                <VotePlanText
                  dangerouslySetInnerHTML={{ __html: COPY.votePlan }}
                />
              </VotePlanCard>
            </SecondContainer>
          </MainContainer>
        </PageContainer>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapState = (state) => {
  return {
    results: state.quiz.results,
    user: state.user.userData,
  };
};

/**
 * PROP TYPES
 */
Results.propTypes = {
  results: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapState)(Results);

const COPY = {
  header: (name) => `${name || 'Hey'}, your mealtime mood is:`,
  mantra: 'Your election mantra is:',
  opportunities: 'YOUR SELF-CARE OPPORTUNITIES',
  recommended: 'Your recommended meditation is:',
  buttonCopy: 'Start Your 7-Day Free Trial',
  community: (name) =>
    name ? name.toUpperCase() + `, YOU'RE NOT ALONE` : `YOU'RE NOT ALONE`,
  twitter_icon: 'https://img.icons8.com/color/48/000000/twitter.png',
  share_tweet: 'Share Your Results',
  alt: 'Click to Tweet',
  bannerHeader: 'Listen to this meditation in the Shine App',
  votePlan:
    'Need help creating a voting plan?<p>Head to<a href="https://skimmth.is/34Svn7c" target="_blank"> theSkimm\'s </a>election hub to vote on your terms in your state.</p>',
};
