import firebase from 'firebase';
import { pathOr } from 'ramda';
import Profile from './Profile';
import {
  BasicAuthCredentials,
  FormSignupCredentials,
  FirebaseAuthUser,
  LoginCredentials,
} from '../../../types/api/auth/lib/auth-types';

export default class Auth {
  glowURL: string;
  /**
   * Constructor
   *
   * @param {string} glowURL Base URL to Shine's Glow API
   */
  constructor(glowURL: string) {
    this.glowURL = glowURL;
  }

  /**
   * @typedef {object} FirebaseErrorObject
   * @property {string} code
   * @property {string} message
   */

  /**
   * Signs user into the Firebase app through Facebook authentication.
   * @return {Promise.<object>} On success, returns a user object
   * @throws {FirebaseErrorObject} Firebase error object returned if auth fails
   */
  async loginFacebook(credentials: BasicAuthCredentials) {
    let result, fbSignIn;

    const provider = new firebase.auth.FacebookAuthProvider();
    result = (await firebase
      .auth()
      .signInWithPopup(provider)) as FirebaseAuthUser;
    // The signed-in user info.
    fbSignIn = result.user;

    // Update Firebase user record with displayName and photoURL from FB profile
    let profile = {} as { [key: string]: any };
    if (fbSignIn.displayName) {
      profile.displayName = fbSignIn.displayName;
    }
    if (fbSignIn.photoURL) {
      profile.photoURL = fbSignIn.photoURL;
    }
    // @ts-ignore Current User Object is possibly null error
    firebase.auth().currentUser.updateProfile(profile);

    // Update user on the glow backend
    let fname, lname;
    if (fbSignIn.displayName) {
      const names = fbSignIn.displayName.split(' ');
      fname = names[0];
      if ((names.length = 1)) {
        lname = '';
      } else {
        lname = names[names.length - 1];
      }
    }

    await Profile.updateGlow(this.glowURL, {
      email: fbSignIn.email,
      firstName: fname,
      giftCardNumber: credentials ? credentials.giftCardNumber : null,
      lastName: lname,
      phone: fbSignIn.phoneNumber,
      photo: fbSignIn.photoURL,
      companyName: credentials ? credentials.companyName : undefined,
    });

    return {
      firstName: fname,
      email: fbSignIn.email,
      uid: fbSignIn.uid,
      companyName: credentials ? credentials.companyName : undefined,
      providerId: pathOr('', ['providerData', 0, 'providerId'], fbSignIn),
      giftCardNumber: credentials ? credentials.giftCardNumber : null,
    };
  }

  /**
   * Signs user into the Firebase app through Apple authentication.
   * @return {Promise.<object>} On success, returns a user object
   * @throws {FirebaseErrorObject} Firebase error object returned if auth fails
   */
  async loginApple(credentials: BasicAuthCredentials) {
    let result, appleSignIn;

    const provider = new firebase.auth.OAuthProvider('apple.com');
    result = (await firebase
      .auth()
      .signInWithPopup(provider)) as FirebaseAuthUser;
    // The signed-in user info.
    appleSignIn = result.user;

    // Update Firebase user record with displayName and photoURL from Apple ID
    let profile = {} as { [key: string]: any };
    if (appleSignIn.displayName) {
      profile.displayName = appleSignIn.displayName;
    }

    // @ts-ignore Current User Object is possibly null error
    firebase.auth().currentUser.updateProfile(profile);

    // Update user on the glow backend
    let fname, lname;
    if (appleSignIn.displayName) {
      const names = appleSignIn.displayName.split(' ');
      fname = names[0];
      if ((names.length = 1)) {
        lname = '';
      } else {
        lname = names[names.length - 1];
      }
    }

    await Profile.updateGlow(this.glowURL, {
      email: appleSignIn.email,
      firstName: fname,
      giftCardNumber: credentials ? credentials.giftCardNumber : null,
      lastName: lname,
      phone: appleSignIn.phoneNumber,
      companyName: credentials ? credentials.companyName : undefined,
    });

    return {
      firstName: fname,
      email: appleSignIn.email,
      uid: appleSignIn.uid,
      companyName: credentials ? credentials.companyName : undefined,
      providerId: pathOr('', ['providerData', 0, 'providerId'], appleSignIn),
      giftCardNumber: credentials ? credentials.giftCardNumber : null,
    };
  }

  /**
   * Registers a new user and logs them in.
   *
   * @return {Promise.<object>} On success, returns a user objet
   */
  async register({
    email,
    firstName,
    giftCardNumber,
    lastName,
    password,
    phone,
    photo,
    companyName,
  }: FormSignupCredentials) {
    email = email.trim().toLowerCase();
    password = password.trim();

    // Create the user
    if (email && password)
      await firebase.auth().createUserWithEmailAndPassword(email, password);

    // Update the Firebase user record
    let profile = {} as { [key: string]: any };
    if (firstName) {
      profile.displayName = firstName;
    }
    if (photo) {
      profile.photoURL = photo;
    }

    if (Object.keys(profile).length > 0) {
      // @ts-ignore Current User Object is possibly null error
      await firebase.auth().currentUser.updateProfile(profile);
    }

    // And update the profile on the glow backend
    await Profile.updateGlow(this.glowURL, {
      email,
      firstName,
      giftCardNumber,
      lastName,
      phone,
      photo,
      companyName,
    });

    // Login the user
    const loginResult = await this.login({ email, password });

    return {
      firstName,
      email: loginResult.email,
      uid: loginResult.uid,
      companyName,
      providerId: loginResult.providerId,
      giftCardNumber,
    };
  }

  /**
   * Login to get access tokens.
   * @return {Promise.<object>} On success, returns a user object
   */
  async login({
    email,
    password,
    giftCardNumber = '',
    companyName = '',
  }: LoginCredentials) {
    const result = (await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)) as FirebaseAuthUser;

    // And update the profile on the glow backend
    await Profile.updateGlow(this.glowURL, {
      email,
      giftCardNumber,
      companyName,
    });

    return {
      firstName: result.user.displayName,
      email: result.user.email,
      uid: result.user.uid,
      companyName,
      providerId: pathOr('', ['providerData', 0, 'providerId'], result.user),
      giftCardNumber,
    };
  }

  /**
   * Sign out user from this application.
   *
   * @return {Promise.<void>}
   */
  logout() {
    return firebase.auth().signOut();
  }

  /**
   * Initiates the forgot password flow.
   * @return {Promise.<void>}
   */
  forgotPassword(email: string) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  /**
   * get logged in users' info
   *
   * @return {Promise.<Object>} On success, returns a user object
   */
  async getCurrentUser() {
    return new Promise((resolve, reject) => {
      // onAuthStateChanged adds a listener to changes to the user's sign-in state
      // it returns a reference to the listener so we can unsubscribe later
      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve({
            firstName: user.displayName,
            email: user.email,
            uid: user.uid,
            providerId: pathOr('', ['providerData', 0, 'providerId'], user),
          });
        } else {
          reject({
            error: 'User not found',
          });
        }
        // unsubscribe to the listener once we have resolved the inner func
        unsubscribe();
      });
    });
  }

  /**
   * Get the logged-in user's ID token.
   *
   * @return {Promise.<string>}
   */
  getIdToken() {
    if (firebase.auth().currentUser) {
      // @ts-ignore Current User Object is possibly null error
      return firebase.auth().currentUser.getIdToken();
    } else {
      return undefined;
    }
  }
}
