import styled from 'styled-components';
import { electionLogo } from '../../constants/image.config';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import bgSvg from '../../assets/images/quiz/blob651.svg';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  @media (min-width: ${SIZES.ipadProWidth}) {
    margin-top: 200px;
  }
`;

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
  @media (min-width: ${SIZES.ipadProWidth}) {
    margin-bottom: 300px;
  }
`;

export const Logo = styled.img.attrs({ src: electionLogo })`
  height: auto;
  width: 200px;
  object-fit: contain;
  cursor: pointer;
  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    width: 264px;
  }
`;

export const QuestionWrapper = styled.div`
  width: 330px;
  text-align: center;
  margin-bottom: 40px;
  @media (min-width: ${SIZES.ipadProWidth}) {
    width: 528px;
    margin-bottom: 60px;
  }
  @media (max-width: ${SIZES.iphone5sWidth}) {
    width: 312px;
  }
`;

export const QuestionText = styled.span`
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 110%;
  text-align: center;
  color: ${COLORS.white};
  @media (min-width: ${SIZES.ipadProWidth}) {
    font-size: 28px;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.cobaltBlue};
  background-image: url(${bgSvg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
`;

export const SkipButton = styled.span`
  width: 70px;
  height: 23px;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.black};
`;

export const Button = styled.button`
  width: 330px;
  height: 58px;
  border-radius: 100px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${COLORS.shineYellow};
  opacity: ${({ active }) => (active ? '100%' : '50%')};
  margin-top: 30px;
  @media (min-width: ${SIZES.ipadProWidth}) {
    width: 552px;
    margin-top: 40px;
  }
  @media (max-width: ${SIZES.iphone5sWidth}) {
    width: 312px;
  }
  margin-bottom: 20px;
`;

export const ButtonText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: ${COLORS.cobaltBlue};

  @media (max-width: ${SIZES.phone}) {
    font-size: 15px;
  }
`;
