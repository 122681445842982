import React from 'react';
import styled from 'styled-components';

import { IconWrapper, EmailIcon } from '../portal/styles';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  justify-content: center;
`;

const EmailText = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-size: 16px;
  font-weight: normal;
  color: ${COLORS.darkMagentaPink};
  width: calc(100%-5px);
`;

interface Props {
  email: string;
}

export const EmailInfo = (props: Props) => {
  const { email } = props;
  return (
    <Wrapper>
      <IconWrapper>
        <EmailIcon style={{ alignSelf: 'center' }} />
        <EmailText>
          {COPY.loggedIn}
          {email}
        </EmailText>
      </IconWrapper>
    </Wrapper>
  );
};

const COPY = {
  loggedIn: 'Logged in as ',
};

export default EmailInfo;
