import * as TYPES from '../constants/actionTypes';
import firebase from 'firebase';
import axios from 'axios';

export const getSubscription = () => async (dispatch) => {
  try {
    dispatch({ type: TYPES.GET_SUBSCRIPTION_REQUEST });
    const { data } = await _getSubscriptionStatus();
    dispatch({ type: TYPES.GET_SUBSCRIPTION_SUCCESS, subscriptionData: data });
  } catch (error) {
    const errorMsg = error.message;
    dispatch({ type: TYPES.GET_SUBSCRIPTION_FAIL, errorMsg });
  }
};

export const saveSubscriptionData = (data) => ({
  type: TYPES.SAVE_SUBSCRIPTION_DATA,
  subscriptionData: data,
});

/**
 * Get user's subscription status
 * @param {string} personalityTypeId
 * @throws {object} error
 */
export async function _getSubscriptionStatus() {
  if (!firebase.auth().currentUser) {
    return;
  }

  const idToken = await firebase.auth().currentUser.getIdToken();

  const result = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_GLOW_BACKEND}/get-subscription-status`,
    responseType: 'json',
    headers: {
      'Id-Token': idToken,
    },
  });

  if (result && result.data) return result.data;
}
