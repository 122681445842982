import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';
import {
  b2bHeaderImg,
  b2bHeaderImgWebp,
} from '../../../constants/image.config';
import { ImgWithFallback } from '../../../utils/common';
import { Title } from '../B2BStyles';

// STYLESHEETS
const Section = styled.section`
  display: flex;
  flex-direction: row;
  background: ${COLORS.lightYellow};
  z-index: 1;
  @media only screen and (max-width: ${SIZES.laptop}) {
    flex-direction: column;
  }
`;

const Description = styled.p`
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-weight: 400;
  font-size: 20px;
  color: ${COLORS.darkMagentaPink};
  text-align: center;
  line-height: 140%;
  /* identical to box height, or 61px */
  letter-spacing: 0.02em;
  margin: 0 0 20px 0;
  @media only screen and (min-width: ${SIZES.laptop}) {
    text-align: left;
  }
`;

const HeaderInnerLeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  @media only screen and (min-width: ${SIZES.laptop}) {
    align-items: flex-start;
    width: 50%;
    padding: 50px;
  }
`;

const HeaderInnerRightContainer = styled(ImgWithFallback)`
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center;
  min-height: 405px;
  max-height: 525px;

  @media only screen and (max-width: ${SIZES.laptop}) {
    min-height: 660px;
    width: 200%;
  }
  @media only screen and (max-width: ${SIZES.phone}) {
    min-height: 224px;
  }
`;

export const StyledLink = styled(HashLink)`
  text-decoration: none;
`;

const Header = () => (
  <Section>
    <HeaderInnerLeftContainer>
      <Title>{COPY.title}</Title>
      <Description>{COPY.description}</Description>
    </HeaderInnerLeftContainer>
    <HeaderInnerRightContainer
      src={b2bHeaderImgWebp}
      fallback={b2bHeaderImg}
      altText={COPY.altText}
      styles={{ width: '50%' }}
    />
  </Section>
);

const COPY = {
  altText:
    'Shine at Work image of a Black man working from home in his kitchen',
  title: 'Support mental health in the workplace',
  description:
    'Bring daily, culturally competent support to your organization with Shine at Work—your employee wellbeing program based on Shine’s award-winning app.',
  btnCopy: 'Get Started',
};

export default Header;
