export { default as Header } from './partials/Header.tsx';
export { default as LogosSection } from './partials/LogosSection.tsx';
export { default as ExpertsSection } from './partials/ExpertsSection.tsx';
export { default as TestimonialsSection } from './partials/TestimonialsSection.tsx';
export { default as ProgramSection } from './partials/ProgramSection.tsx';
export { default as PressSection } from './partials/B2BPressSection.tsx';
export { default as ImpactSection } from './partials/ImpactSection.tsx';
export { default as LaunchSection } from './partials/LaunchSection.tsx';
export { default as HubspotFormSection } from './partials/HubspotFormSection.tsx';
export { default as FounderSection } from './partials/FounderSection.tsx';

export { default as HubspotForm } from './partials/HubspotForm';
export { default as SMBPlan } from './partials/SMBPlan.tsx';
