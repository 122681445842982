import firebase from 'firebase';
import Auth from './lib/Auth';
import Profile from './lib/Profile';

class Client {
  constructor({ glowURL }) {
    this.auth = new Auth(glowURL);
    this.profile = new Profile(glowURL);
  }
}

export default ({ apiKey, authDomain, databaseURL, glowURL }) => {
  firebase.initializeApp({
    apiKey: apiKey,
    authDomain: authDomain,
    databaseURL: databaseURL,
  });

  return new Client({ glowURL });
};
