// Shared values that are not user-facing
import {
  facebookIcon,
  instagramIcon,
  twitterIcon,
  pinterestIcon,
  spotifyIcon,
  trackMauve,
  trackPurple,
  trackRed,
  trackTeal,
  trackYellow,
} from './image.config';

// APP Subscription states
export const MOBILE_APP_SUB_STATES = {
  TRIAL_ELIGIBLE: 'isEligibleForFreeTrial',
  CANCELLED_MID_TRIAL: 'isCancelledMidTrialWithAccess',
  TRIAL: 'isInFreeTrialPeriod',
  ACTIVE_AUTO_RENEW: 'isActiveAutoRenew',
  CANCELLED_MID_CYCLE: 'isCancelledMidCycleWithAccess',
  EXPIRED: 'isLapsed',
};

export const MOBILE_APP_ACCESS = {
  FREE: 'Free',
  PREMIUM: 'Premium',
};

export const PAYMENT_PLATFORM = {
  ANDROID: 'android',
  ITUNES: 'itunes',
  STRIPE: 'stripe',
  STRIPE_B2B: 'stripe-b2b',
};

export const STRIPE_SUB_STATE = {
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
};

// AUTH TYPES CONSTANTS
export const authMethods = {
  APPLE_LOGIN: 'APPLE_LOGIN',
  FB_LOGIN: 'FB_LOGIN',
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
};

// LeanPlum Event Names
export const LEANPLUM_EVENTS = {
  LOGIN: 'User Signin',
  SIGNUP: 'User Signup',
  SUBSCRIBE: 'User Subscribed',
  PURCHASE: 'Purchase',
};

// LeanPlum Default Attributes
export const LEANPLUM_ATTRIBUTES = {
  WEB: 'Web',
  HOMEPAGE: 'Homepage',
};

// Homepage - Library - Tracks list
const SPEAKERS = {
  AISHA: 'Aisha Beau',
  ELISHA: 'Elisha Mudly',
  MEL: 'Mel Chanté',
  JAMILA: 'Jamila Reddy',
  JOREL: 'Jor-El Caraballo',
  TIFFANY: 'Tiffany Walker',
};

export const TRACKLIST = [
  {
    title: 'Representation Burnout',
    speaker: SPEAKERS.TIFFANY,
    trackUrl:
      'https://d2ay59vokmlek9.cloudfront.net/audio_previews/RepresentationBurnout-Preview.mp3',
    playerBackground: trackPurple,
    theme: 'light',
    trackImg: trackPurple,
  },
  {
    title: 'Focus On What You Can Control',
    speaker: SPEAKERS.TIFFANY,
    trackUrl:
      'https://d2ay59vokmlek9.cloudfront.net/audio_previews/FocusOnWhatYouCanControl-Preview.mp3',
    playerBackground: trackMauve,
    theme: 'light',
    trackImg: trackMauve,
  },
  {
    title: 'Conquer Your Inner Critic',
    speaker: SPEAKERS.JAMILA,
    trackUrl:
      'https://d2ay59vokmlek9.cloudfront.net/audio_previews/ConquerYourInnerCritic-Preview2.mp3',
    playerBackground: trackRed,
    theme: 'light',
    trackImg: trackRed,
  },
  {
    title: 'Honor Your Whole Self',
    speaker: SPEAKERS.MEL,
    trackUrl:
      'https://d2ay59vokmlek9.cloudfront.net/audio_previews/HonorYourWholeSelf-Preview.mp3',
    playerBackground: trackYellow,
    theme: 'light',
    trackImg: trackYellow,
  },
  {
    title: 'Love Your Small Wins',
    speaker: SPEAKERS.AISHA,
    trackUrl:
      'https://d2ay59vokmlek9.cloudfront.net/audio_previews/LoveYourSmallWins-Preview.mp3',
    playerBackground: trackTeal,
    theme: 'light',
    trackImg: trackTeal,
  },
  {
    title: 'Permission to Take Up Space',
    speaker: SPEAKERS.JOREL,
    trackUrl:
      'https://d2ay59vokmlek9.cloudfront.net/audio_previews/TakingUpSpace-Preview.mp3',
    playerBackground: trackPurple,
    theme: 'light',
    trackImg: trackPurple,
  },
  {
    title: 'Practice Acceptance',
    speaker: SPEAKERS.ELISHA,
    trackUrl:
      'https://d2ay59vokmlek9.cloudfront.net/audio_previews/PracticeAcceptance-Preview.mp3',
    playerBackground: trackMauve,
    theme: 'light',
    trackImg: trackMauve,
  },
];

// CTA Button Link
export const BILLING_LINK = '/billing';
export const PROMO_CTA_LINK = '/get-started';
export const NON_PROMO_CTA_LINK = '/get-started';

// Homepage - Footer
export const FOOTER_PAGES = {
  SOCIAL_SECTION: [
    {
      link: 'https://instagram.com/theshineapp/',
      image: instagramIcon,
    },
    {
      link: 'https://www.facebook.com/TheShineApp/',
      image: facebookIcon,
    },
    {
      link: 'https://twitter.com/theshineapp',
      image: twitterIcon,
    },
    {
      link: 'https://www.pinterest.com/TheShineApp/',
      image: pinterestIcon,
    },
    {
      link: 'https://open.spotify.com/show/0q5HKfqOiwSh2bwsiz2keP',
      image: spotifyIcon,
    },
  ],
  NAVIGATION_SECTION: [
    {
      title: 'Company',
      pages: [
        {
          link: '/about',
          text: 'About',
        },
        {
          link: '/shine-at-work',
          text: 'Shine at Work',
        },
        {
          link: '/podcasts',
          text: 'Podcasts',
        },
      ],
    },
    {
      title: 'Resources',
      pages: [
        {
          link: `/advice`,
          text: `Get Advice`,
        },
      ],
    },
    {
      title: 'Help',
      pages: [
        {
          link: `/faq`,
          text: `FAQ`,
        },
        {
          link: 'https://help.headspace.com/hc/en-us/categories/203929567-Contact-Us',
          text: 'Contact',
        },
      ],
    },
  ]
};

// About page & Homepage- Navbar
const navbarLinks = [
  /* Hide until holiday season
  {
    link: '/gift?utm_source=Shine&utm_medium=Homepage_Header&utm_campaign=Holiday_Gifts_2021',
    copy: 'Gift Shine Premium',
    className: 'gift',
  },
  */
  {
    link: '/about',
    copy: 'About',
  },
  {
    link: '/shine-at-work',
    copy: 'Shine at Work',
  },
  {
    link: '/podcasts',
    copy: 'Podcasts',
  },
];

export const NAV_BAR = {
  getLinks: () => navbarLinks,
  getLinksWithGetStartedUrl: (getStartedUrl: string) =>
    navbarLinks.map((item) => {
      if (item.link === NON_PROMO_CTA_LINK) {
        item.link = getStartedUrl;
      }
      return item;
    }),
};

// Shine At Work - Navbar
export const navbarLinksB2B = [
  /* Hide until holiday season
  {
    link: '/gift?utm_source=Shine&utm_medium=ShineAtWork_Header&utm_campaign=Holiday_Gifts_2021',
    copy: 'Gift Shine Premium',
    className: 'gift',
  },
  */
  {
    link: '/about',
    copy: 'About',
  },
  {
    link: '/shine-at-work',
    copy: 'Shine at Work',
  },
  {
    link: '/podcasts',
    copy: 'Podcasts',
  }
];

export const META_TAGS = {
  default: {
    title: 'Shine | Calm Anxiety & Stress',
    image:
      'https://images.ctfassets.net/9uvjlyak4ne1/5CllXEJYiaCSasTmP1FbbC/6e7c3f3fdd36c951c9cbbd6e88a1261c/Shine_Metatag_Image.jpg',
    description: 'The daily self-care app to help you rest, heal, and grow.',
  },
  quiz: {
    title: 'What’s Your 2020 Election Self-Care Plan?',
    description:
      'Take the quiz to create a personalized self-care plan to care for your mental health this election season.',
    image:
      'https://images.ctfassets.net/9uvjlyak4ne1/4HzAPt9iNBbuimqtvJtRGD/aed249b7a4b876aa5bfe49626f71673a/election-selfcare-plan-metaimage.png',
  },
  b2b: {
    title: 'Shine at Work | Decrease stress and anxiety in the workplace',
    description:
      'Shine at Work is the most inclusive mental health benefit and a proven, accessible tool to help your employees navigate depression, anxiety, stress, and more.',
    image:
      'https://images.ctfassets.net/9uvjlyak4ne1/2fXIHQnwZsK6PR44eLgpRf/d37cff076fed599a763312e3733281ed/shine-at-work-metaimage.jpg',
  },
  podcasts: {
    title: 'Shine Podcasts | Friends with Mental Health Benefits',
    description:
      'From the Shine app’s co-founders Marah Lidey and Naomi Hirabayashi comes “Friends With Mental Health Benefits,” a podcast highlighting the untold mental health stories of founders and senior leaders.Hear how folks navigate their own wellbeing in the workplace and support the mental health of their teams—especially those most marginalized.',
    image:
      'https://images.ctfassets.net/awpxl2koull4/21TXB1k6H55I1WWE4TWTHG/2fd30f703cd4e5aec4927042b7a023f1/In-App_Playlist_Artwork__1_.jpg',
  },
};

export const isBOGOPromo = (couponId?: string) => {
  return (
    (couponId &&
      process.env.REACT_APP_GIFTUP_ACCEPTED_BOGO_PROMO_IDS &&
      process.env.REACT_APP_GIFTUP_ACCEPTED_BOGO_PROMO_IDS.includes(
        couponId
      )) ||
    (couponId &&
      process.env.REACT_APP_GIFTUP_ACCEPTED_BOGO_LIFETIME_PROMO_IDS &&
      process.env.REACT_APP_GIFTUP_ACCEPTED_BOGO_LIFETIME_PROMO_IDS.includes(
        couponId
      ))
  );
};

export const getBOGOPromoType = (couponId?: string) => {
  if (
    couponId &&
    process.env.REACT_APP_GIFTUP_ACCEPTED_BOGO_PROMO_IDS &&
    process.env.REACT_APP_GIFTUP_ACCEPTED_BOGO_PROMO_IDS.includes(couponId)
  )
    return 'default';

  if (
    couponId &&
    process.env.REACT_APP_GIFTUP_ACCEPTED_BOGO_LIFETIME_PROMO_IDS &&
    process.env.REACT_APP_GIFTUP_ACCEPTED_BOGO_LIFETIME_PROMO_IDS.includes(
      couponId
    )
  )
    return 'lifetime';
};

export const acceptedLifetimeGiftCardRegex = /mcds/;

export const APPSFLYER_LINK = 'https://shineapp.onelink.me/Unhk/bb115f86';
export const APPSFLYER_AUDIO_PREVIEW_LINK =
  'https://shineapp.onelink.me/Unhk/3dd911e';

export const RETENTION_SURVEY_COUPON_ID = 'retention-survey';
export const APP_CANCEL_TYPEFORM_LINK =
  'https://shine32.typeform.com/to/CO1OZHhs';

export const excludedB2bMigrationCompanyIds = [
  'mcc' /* MiraCosta Community College */,
  'sdcc' /* San Diego City College Mental Health Counseling Center */,
  'sdcc-aapi' /* SDCC AAPI Students */,
  'sdcc-chicanx-latinx' /* SDCC Chicanx/Latinx Students */,
  'nvf' /* New Voices Foundation */,
];

export const SUBSCRIPTION_PAGE_UPSELL_LINK =
  '/billing?utm_source=Shine_WebPortal&utm_medium=SubscriptionUpsellCTA';

export const EVENTS_PAGE_UPSELL_LINK =
  '/billing?utm_source=Shine_WebPortal&utm_medium=EventsUpsellCTA';

// Podcasts Page

export const PODCASTS_PAGE_APPSFLYER_LINK =
  'https://shineapp.onelink.me/Unhk/ic3mskdx';

export const DS_SPOTIFY_LINK =
  'https://open.spotify.com/show/0q5HKfqOiwSh2bwsiz2keP';

export const DS_APPLE_PODCAST_LINK =
  'https://podcasts.apple.com/us/podcast/the-daily-shine/id1507425010';

export const DS_MORE_LINK = 'https://anchor.fm/theshineapp';

export const FWMHB_SPOTIFY_LINK =
  'https://open.spotify.com/show/0SwVAE9mIDR6j7BaGKrJqd';

export const FWMHB_APPLE_PODCAST_LINK =
  'https://podcasts.apple.com/us/podcast/friends-with-mental-health-benefits/id1617166621';

export const FWMHB_MORE_LINK = 'https://anchor.fm/shine280';
