import styled from 'styled-components';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import FONTS from '../../constants/fonts';
import leftBlob from '../../assets/images/b2b/form-section-left-blob.svg';

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: 'Poppins';
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  color: ${COLORS.darkMagentaPink};
  text-align: center;
  line-height: 130%;
  margin: 0;
  letter-spacing: 0.02em;
  margin-bottom: 20px;
  @media only screen and (min-width: ${SIZES.laptop}) {
    text-align: left;
    font-size: 38px;
    line-height: 160%;
  }
`;

export const Subtitle = styled.h2`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  line-height: 130%;
  font-weight: 400;
  color: ${COLORS.darkMagentaPink};
  font-size: 22px;
  margin: 0;
  max-width: 875px;
  text-align: center;

  @media only screen and (min-width: ${SIZES.laptop}) {
    font-size: 28px;
    line-height: 150%;
  }
`;

export const TwoColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: ${SIZES.desktop}) {
    flex-direction: column;
  }
`;

// SMB Pages
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: ${COLORS.white}
  border-radius:8px;
  padding: 32px;
  max-width: 460px;
  margin: 0 20px;
  z-index:1;

  @media only screen and (max-width: ${SIZES.tablet}) {
    margin: 0 10px 20px 10px;
    padding: 20px;
  }
`;

export const Description = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: ${COLORS.darkGrey};

  margin: 10px 0;
  @media only screen and (max-width: ${SIZES.phone}) {
    margin: 0;
    font-size: 17px;
  }
`;

export const BulletList = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    align-self: flex-start;
    margin-left: 8px;
  }
`;

export const BulletContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

export const BulletIcon = styled.img`
  width: 32px;
  height: 32px;
  padding-top: 8px;
`;

export const BulletText = styled.p`
  width: 100%;
  font-family: ${FONTS.poppinsPrimaryRegular}
  font-size: 16px;
  color: ${COLORS.darkGrey};
  text-align: left;
  margin: 8px;
`;

export const HelpText = styled(BulletText)`
  text-align: center;
  a {
    color: ${COLORS.darkMagentaPink};
  }
`;

export const BlobWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background-color: ${COLORS.darkModerateCyan};
  background-image: url(${leftBlob});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 20%;
  padding: 60px 0;

  @media only screen and (max-width: ${SIZES.phone}) {
    background-size: 30%;
  }
`;

export const CenterContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;

export const TwoColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: ${SIZES.phone}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const BlobRight = styled.img`
  width: 20%;
  position: absolute;
  right: 0;
  @media only screen and (max-width: ${SIZES.phone}) {
    width: 30%;
  }
`;

export const WhiteTitle = styled.p`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: ${COLORS.white};
  text-align: center;
  margin: 30px;

  @media only screen and (max-width: ${SIZES.tablet}) {
    font-size: 32px;
  }
`;
