import React from 'react';

import {
  MembershipTitle,
  MembershipBenefitsList,
  MembershipBenefitsBulletPoints,
} from './BillingStyles';

export const MembershipInfo = () => {
  return (
    <React.Fragment>
      <MembershipTitle>{COPY.title}</MembershipTitle>
      <MembershipBenefitsList>
        {COPY.benefits.map((benefit, index) => {
          return (
            <MembershipBenefitsBulletPoints
              key={index}
              dangerouslySetInnerHTML={{ __html: benefit }}
            />
          );
        })}
      </MembershipBenefitsList>
    </React.Fragment>
  );
};

const COPY = {
  title: 'Membership Benefits',
  benefits: [
    '1,000+ meditations, created with representation at the forefront',
    'Research-backed exercises and articles based on how you’re feeling',
    'Members-only monthly community care events with mental health experts',
    'Access to a private, inclusive digital community for 1:1 support',
  ],
};

export default MembershipInfo;
