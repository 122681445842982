import React from 'react';
import styled from 'styled-components';

// Constants
import COLORS from '../../../constants/colors';
import SIZES from '../../../constants/screenSizes';

// Stylesheets
const Text = styled.p`
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${COLORS.darkMagentaPink};

  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    text-align: left;
  }
`;

/**
 * COMPONENT
 */
const LimitedTime = ({ limitedTimeText }: { limitedTimeText?: string }) => (
  <Text
    className="limited-time-text"
    dangerouslySetInnerHTML={{
      __html: limitedTimeText || COPY.text,
    }}
  />
);

const COPY = {
  text: `LIMITED TIME ONLY`,
};

export default LimitedTime;
