import React from 'react';
import { Page } from '../../constants/styles';
import { META_TAGS } from '../../constants/config';

import {
  Header,
  ProgramSection,
  ExpertsSection,
  TestimonialsSection,
  PressSection,
  ImpactSection,
  LaunchSection,
  FounderSection,
} from './index';
import MetaTags from '../sharedPartials/MetaTags';
import Footer from '../sharedPartials/Footer';
import Navbar from '../sharedPartials/Navbar';

const B2BPage = () => (
  <React.Fragment>
    <MetaTags
      title={META_TAGS.b2b.title}
      description={META_TAGS.b2b.description}
      image={META_TAGS.b2b.image}
      url={window.location.href}
    />
    <Page>
      <Navbar hasAdminPortalURL />
      <Header />
      <ProgramSection />
      <ExpertsSection />
      <TestimonialsSection />
      <PressSection />
      <ImpactSection />
      <LaunchSection />
      <FounderSection />
      <Footer />
    </Page>
  </React.Fragment>
);

export default B2BPage;
