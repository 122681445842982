import React from 'react';
import styled from 'styled-components';
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import {
  FOOTER_IOS_DOWNLOAD_LINK,
  FOOTER_ANDROID_DOWNLOAD_LINK,
} from '../../constants/sharedCopy';

import iosBadge from '../../assets/images/app-badges/apple-store-badge-with-border.png';
import androidBadge from '../../assets/images/app-badges/google-play-badge.png';

// Stylesheets
const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
  @media only screen and (max-width: ${SIZES.phone}) {
    height: 200px;
  }
  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    height: 230px;
  }
`;
const SectionTitle = styled.p`
  color: ${(props) => props.color || COLORS.paleGrey};
  display: inline-block;
  text-decoration: none;
  font-family: 'Poppins';
  padding-top: 1%;
  font-size: 22px;
  font-weight: normal;
  @media only screen and (max-width: ${SIZES.phone}) {
    font-size: 20px;
  }
  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    font-size: 24px;
  }
`;

const Line = styled.hr`
  border: 0.5px solid ${COLORS.paleGrey};
  width: 75%;
`;

const BadgeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    flex-direction: column;
    justify-content: space-around;
  }
`;

const BadgeLink = styled.a`
  text-align: center;
`;

const Badge = styled.img`
  max-width: 220px;
  min-width: 140px;
  width: 85%;

  @media only screen and (min-width: ${SIZES.largeDesktop}) {
    max-width: 260px;
  }
`;
const GetTheApp = (props) => (
  <Vertical>
    <SectionTitle color={props.titleColor}>Get the Shine App</SectionTitle>
    <BadgeContainer>
      <BadgeLink
        href={props.iosLink || FOOTER_IOS_DOWNLOAD_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Badge src={iosBadge} />
      </BadgeLink>
      <BadgeLink
        href={props.androidLink || FOOTER_ANDROID_DOWNLOAD_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Badge src={androidBadge} />
      </BadgeLink>
    </BadgeContainer>
    <Line />
  </Vertical>
);

export default GetTheApp;
