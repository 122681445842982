import React from 'react';
import styled from 'styled-components';
import { Fade } from 'react-awesome-reveal';

// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { DefaultText } from '../homepage/HomepageStyles';
import { ImgWithFallback } from '../../utils/common';

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: 20px 0px;
`;

const ListItem = styled.div`
  width: 195px;
  margin-bottom: 35px;

  @media only screen and (min-width: ${SIZES.desktop}) {
    width: 260px;
  }
`;

const ListImage = styled(ImgWithFallback)`
  width: 120px;
  height: 120px;

  @media only screen and (min-width: ${SIZES.desktop}) {
    width: 180px;
    height: 180px;
  }
`;

const ListTitle = styled.h3`
  font-family: ${FONTS.poppinsPrimaryRegular};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-bottom: 2px;
  color: ${COLORS.darkMagentaPink};
`;

const ListSubtitle = styled(DefaultText)`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${COLORS.darkGrey};
  margin-top: 2px;
`;

interface Person {
  img: string; // webp image
  fallback: string; // png image fallback
  name: string;
  bio: string;
}

interface List {
  list: Person[];
}

const PeopleList = (props: List) => (
  <List>
    <Fade triggerOnce cascade>
      {props.list.map((item) => (
        <ListItem>
          <ListImage
            src={item.img}
            fallback={item.fallback}
            altText={item.name}
          ></ListImage>
          <ListTitle>{item.name}</ListTitle>
          <ListSubtitle>{item.bio}</ListSubtitle>
        </ListItem>
      ))}
    </Fade>
  </List>
);

export default PeopleList;
