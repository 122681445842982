import React from 'react';
import {
  SurveyContainerNoPadding,
  BannerContainer,
  BannerImage,
  SubBannerContainer,
  SurveyTitle,
  SurveyText,
  ButtonsWrapperVertical,
  SurveyYellowButton,
  SurveyYellowBorderButton,
} from './styles';
import { surveyBannerP4 } from '../../constants/image.config';
import { SurveyConfig } from '../../types/retention-survey';

const SurveyPageP4 = (props) => (
  <SurveyContainerNoPadding>
    <BannerContainer>
      <BannerImage src={surveyBannerP4} />
    </BannerContainer>
    <SubBannerContainer>
      <SurveyTitle>{COPY.title}</SurveyTitle>
      <SurveyText>{COPY.description}</SurveyText>
      <ButtonsWrapperVertical>
        <SurveyYellowButton
          onClick={() =>
            props.updateSurveyPage(SurveyConfig.STAY_GIVE_FEEDBACK)
          }
        >
          {COPY.buttonKeep}
        </SurveyYellowButton>
        <SurveyYellowBorderButton
          onClick={() => props.onCancel(SurveyConfig.CANCELLED_GIVE_FEEDBACK)}
        >
          {COPY.buttonCancel}
        </SurveyYellowBorderButton>
      </ButtonsWrapperVertical>
    </SubBannerContainer>
  </SurveyContainerNoPadding>
);

const COPY = {
  title: "We're adding new meditations every week.",
  description:
    'Keep your Shine Premium membership to get unlimited access to 1,000+ meditations. New meditations on topics like burnout, boundaries, sleep, and more are added every week just for Shine Premium members.',
  buttonKeep: 'Keep my membership',
  buttonCancel: 'Cancel my membership',
};

export default SurveyPageP4;
