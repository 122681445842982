import React from 'react';
import styled from 'styled-components';
import { Line } from 'rc-progress';
import { trackEvent } from '../../utils/trackEvent';

// Contants
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import { previewPlay, previewPause } from '../../constants/image.config';
import { APPSFLYER_AUDIO_PREVIEW_LINK } from '../../constants/config';

const defaultBGImage =
  'https://d2ay59vokmlek9.cloudfront.net/track_images/yellow-1-52.jpg';

class PreviewPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playStatus: 'play',
      currentTime: 0,
      remainingTime: 0,
      progressPercent: 0,
      intervalTimerId: null,
      trackLength: 0,
      skipTime: props.skipTime || 5,
    };
  }

  handleTogglePlay = () => {
    const { playStatus } = this.state;
    const { trackUrl, trackTitle, audioId } = this.props;

    if (!trackUrl) {
      window.open(APPSFLYER_AUDIO_PREVIEW_LINK, '_blank');
      trackEvent('Preview', 'Link-Tapped', {
        title: trackTitle,
        audio_id: audioId,
      });
      return;
    } else {
      trackEvent('Preview', 'Played', { title: trackTitle, audio_id: audioId });
    }

    let status = playStatus;
    const audio = document.getElementById('audio');
    if (status === 'play') {
      status = 'pause';
      audio.play();
      const intervalTimerId = setInterval(() => {
        const currentTime = Math.floor(audio.currentTime);
        const duration = this.state.trackLength;
        // Calculate percent of song played
        const percent = (currentTime / duration) * 100;
        this.updateScrubber(percent);
        if (currentTime < duration) {
          this.updateCurrentTime(currentTime);
        }
        if (currentTime > 0 || currentTime === 0) {
          this.updateTrackTimeRemaining(duration, currentTime);
        }
      }, 1000);
      this.setState({ intervalTimerId: intervalTimerId });
    } else {
      status = 'play';
      audio.pause();
      clearInterval(this.state.intervalTimerId);
    }
    this.setState({ playStatus: status });
  };

  updateCurrentTime = (currentTime) => {
    currentTime = Math.floor(currentTime);
    this.setState({ currentTime: currentTime });
  };

  updateTrackTimeRemaining = (duration, currentTime) => {
    let remainingTime = Math.floor(duration) - Math.floor(currentTime);
    this.setState({ remainingTime: remainingTime });
  };

  updateScrubber = (percent) => {
    this.setState({ progressPercent: percent });
  };

  componentDidMount() {
    const audio = document.getElementById('audio');
    audio.onloadedmetadata = () => {
      this.setState({
        trackLength: Math.floor(audio.duration),
        remainingTime: Math.floor(audio.duration),
      });
    };
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalTimerId);
  }

  render() {
    const { speakerImageUrl, trackUrl } = this.props;
    const { playStatus, currentTime, remainingTime, progressPercent } =
      this.state;
    return (
      <Container
        className="wrap"
        onMouseOver={() => this.setState({ active: false })}
        onMouseOut={() => this.setState({ active: true })}
        onTouchEnd={() => this.setState({ active: true })}
      >
        <Image src={speakerImageUrl ? speakerImageUrl : defaultBGImage} />
        <audio id="audio">
          {trackUrl ? (
            <source src={trackUrl.replace(/['"]+/g, '')} />
          ) : undefined}
        </audio>
        <AudioControlWrapper
          className={
            this.state.active && playStatus === 'pause' ? 'active-player' : ''
          }
        >
          <PlayButton
            onClick={this.handleTogglePlay}
            src={playStatus === 'play' ? previewPlay : previewPause}
          />
          <PreviewText>
            {trackUrl ? COPY.previewText : COPY.noAudioPreviewText}
          </PreviewText>
          {trackUrl ? (
            <Scrubber
              progressPercent={progressPercent}
              currentTime={currentTime}
              remainingTime={remainingTime}
            />
          ) : null}
        </AudioControlWrapper>
      </Container>
    );
  }
}

const Scrubber = ({ progressPercent, currentTime, remainingTime, theme }) => {
  return (
    <ScrubberContainer>
      <TimeText theme={theme}>{convertTime(currentTime)}</TimeText>
      <Line
        percent={progressPercent}
        strokeWidth="1.5"
        strokeColor={COLORS.lightYellow}
        trailWidth="1.5"
        trailColor={COLORS.paleGrey}
        style={{ height: '5px', width: '65%' }}
        strokeLinecap="round"
      />
      <TimeText theme={theme}>{convertTime(remainingTime)}</TimeText>
    </ScrubberContainer>
  );
};

// Copy
const COPY = {
  previewText: 'LISTEN TO A PREVIEW',
  noAudioPreviewText:
    'Listen to this meditation & 1,000 more with the Shine app',
};
const convertTime = (timestamp) => {
  let minutes = Math.floor(timestamp / 60);
  let seconds = timestamp - minutes * 60;
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return `${minutes}:${seconds}`;
};

const Container = styled.div`
  position: relative;
  max-height: 420px;
  border-radius: 5px;

  .active-player {
    visibility: hidden;
    opacity: 0;

    -webkit-transition: visibility 1.5s, opacity 1.5s;
    -moz-transition: visibility 1.5s, opacity 1.5s;
    -ms-transition: visibility 1.5s, opacity 1.5s;
    -o-transition: visibility 1.5s, opacity 1.5s;
    transition: visibility 1.5s, opacity 1.5s;

    /* Wait a moment before fading out the control bar */
    -webkit-transition-delay: 2s;
    -moz-transition-delay: 2s;
    -ms-transition-delay: 2s;
    -o-transition-delay: 2s;
    transition-delay: 2s;
  }

  @media only screen and (min-width: ${SIZES.tablet}) {
    border-radius: 5px;
    overflow: hidden;
    padding-right: 24px;
  }

  @media only screen and (min-width: ${SIZES.tablet}) {
    padding-right: 0px;
  }
`;

const Image = styled.img`
  width: 100%;
  max-height: 420px;
  object-fit: cover;
  @media only screen and (min-width: ${SIZES.tablet}) {
    -webkit-box-shadow: inset 0 0 5px 2px #c4c4c4;
    box-shadow: inset 0 0 5px 2px #c4c4c4;
    border-radius: 5px;
    overflow: hidden;
  }
`;

const AudioControlWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PlayButton = styled.img`
  width: 81px;
  height: 81px;
  cursor: pointer;
  align-self: center;
  margin-top: 40px;
  @media only screen and (min-width: ${SIZES.tablet}) {
  }
`;

const ScrubberContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0;
  margin-bottom: 40px;
  position: absolute;
`;

const TimeText = styled.div`
  color: ${COLORS.white};
  font-size: 12px;
  font-family: 'Work Sans';
  height: 14px;
  text-align: center;
  letter-spacing: 0.43px;
  width: 27px;
`;

const PreviewText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: ${COLORS.white};
  text-transform: uppercase;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  text-shadow: 0.15em 0.15em 0.15em ${COLORS.blackTwo};
`;

export default PreviewPlayer;
