import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';

// Constants
import SIZES from '../../../constants/screenSizes';
import COLORS from '../../../constants/colors';
import FONTS from '../../../constants/fonts';
import { PROMO_CTA_LINK } from '../../../constants/config';

import phoneGroup from '../../../assets/images/homepage/today-tab-group.png';

// Components
import { DefaultText, BoldSubtitle } from '../../homepage/HomepageStyles';
import { RoundedCTAButton } from './LandingPageButton';

const EyeBrowTitle = styled(DefaultText)`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 110%;
  margin-bottom: 8px;
  margin-top: 48px;
  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    margin-top: 68px;
  }
`;

const Title = styled(BoldSubtitle)`
  font-style: normal;
  font-size: 26px;
  line-height: 120%;
  text-align: center;
  margin: 5px 10px 0;
  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    max-width: 702px;
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  min-height: 500px;

  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    flex-direction: row;
    overflow: hidden;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  @media only screen and (min-width: ${SIZES.ipadProWidth}) {
    max-width: 500px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  margin: 20px;

  @media only screen and (max-width: ${SIZES.desktop}) {
    width: 90%;
    margin: 0;
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  align-items: center;
  width: auto;

  @media only screen and (max-width: ${SIZES.ipadProWidth}) {
    align-self: center;
  }

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 90%;
    margin: 0;
  }
`;

const ListContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
`;

const ListIcon = styled.div`
  min-width: 34px;
  height: 34px;
  border-radius: 17px;
  background-color: ${COLORS.darkCyan};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 18px;
  p {
    font-family: ${FONTS.poppinsPrimaryRegular};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: ${COLORS.white};
  }
  @media only screen and (min-width: ${SIZES.phone}) {
    min-width: 44px;
    height: 44px;
    border-radius: 22px;
    p {
      font-size: 22px;
    }
  }
`;

const ListItemText = styled(DefaultText)`
  font-family: ${FONTS.poppinsPrimaryRegular};
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  bold {
    font-weight: 600;
  }
  @media only screen and (min-width: ${SIZES.phone}) {
    max-width: 508px;
    font-style: normal;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  align-self: center;

  @media only screen and (max-width: ${SIZES.phone}) {
    width: 100%;
  }
`;

const renderListItems = (steps) => {
  return steps.map((step, i) => (
    <ListItem key={step}>
      <ListIcon>
        <p>{i + 1}</p>
      </ListIcon>
      <ListItemText dangerouslySetInnerHTML={{ __html: step }} />
    </ListItem>
  ));
};
const StepSection = ({
  stepSectionImage,
  programSteps,
  stepSectionEyeBrowTitle,
  stepSectionTitle,
  buttonAction,
}) => (
  <Container>
    <ImageContainer>
      <Image src={stepSectionImage} />
    </ImageContainer>
    <StepContainer>
      <EyeBrowTitle>{stepSectionEyeBrowTitle}</EyeBrowTitle>
      <Title>{stepSectionTitle}</Title>
      <ListContainer>
        {renderListItems(programSteps)}
        <StyledLink to={PROMO_CTA_LINK} className="steps-cta">
          <RoundedCTAButton onClick={buttonAction}>
            Get Started
          </RoundedCTAButton>
        </StyledLink>
      </ListContainer>
    </StepContainer>
  </Container>
);

StepSection.defaultProps = {
  programSteps: [
    `<bold>Become a Shine Premium member </bold>to unlock the entire self-care toolkit`,
    `<bold>Start your program </bold>focused on anxiety, stress, and more to build the skills you need`,
    `<bold>Connect with others </bold>in the Shine community to feel supported along the way`,
  ],
  stepSectionEyeBrowTitle: 'Named Best of 2020 by Apple',
  stepSectionTitle:
    'The self-care app to help you rest, heal, and grow through difficulty.',
  stepSectionImage: phoneGroup,
};

export default withRouter(StepSection);
