import React, { Component } from 'react';
import { TrackInformation, Scrubber } from './';
import {
  Container,
  ControlsContainer,
  VerticalControlContainer,
  RewindButton,
  PlayButton,
  PauseButton,
  FastForwardButton,
  TimeText,
} from './styles';

export default class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playStatus: 'play',
      currentTime: 0,
      remainingTime: 0,
      progressPercent: 0,
      intervalTimerId: null,
      trackLength: 0,
      skipTime: props.skipTime || 5,
    };

    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleFastForward = this.handleFastForward.bind(this);
    this.handleRewind = this.handleRewind.bind(this);
    this.updateCurrentTime = this.updateCurrentTime.bind(this);
    this.updateTrackTimeRemaining = this.updateTrackTimeRemaining.bind(this);
    this.updateScrubber = this.updateScrubber.bind(this);
  }

  handleTogglePlay() {
    let status = this.state.playStatus;
    const audio = document.getElementById('audio');
    if (status === 'play') {
      status = 'pause';
      audio.play();
      const intervalTimerId = setInterval(() => {
        const currentTime = Math.floor(audio.currentTime);
        const duration = this.state.trackLength;
        // Calculate percent of song played
        const percent = (currentTime / duration) * 100;
        this.updateScrubber(percent);
        if (currentTime < duration) {
          this.updateCurrentTime(currentTime);
        }
        if (currentTime > 0 || currentTime === 0) {
          this.updateTrackTimeRemaining(duration, currentTime);
        }
      }, 1000);
      this.setState({ intervalTimerId: intervalTimerId });
    } else {
      status = 'play';
      audio.pause();
      clearInterval(this.state.intervalTimerId);
    }
    this.setState({ playStatus: status });
  }

  handleFastForward() {
    const audio = document.getElementById('audio');
    const currentTime = this.state.currentTime;
    const trackLength = this.state.trackLength;
    const newTime = Math.floor(currentTime + this.state.skipTime);
    if (newTime < trackLength) {
      audio.currentTime = newTime;
      this.setState({ currentTime: newTime });
    }
  }
  handleRewind() {
    const audio = document.getElementById('audio');
    const currentTime = this.state.currentTime;
    const newTime = Math.floor(currentTime - this.state.skipTime);
    if (newTime > 0) {
      audio.currentTime = newTime;
      this.setState({ currentTime: newTime });
    }
  }

  updateCurrentTime(currentTime) {
    currentTime = Math.floor(currentTime);
    this.setState({ currentTime: currentTime });
  }

  updateTrackTimeRemaining(duration, currentTime) {
    let remainingTime = Math.floor(duration) - Math.floor(currentTime);
    this.setState({ remainingTime: remainingTime });
  }

  updateScrubber(percent) {
    this.setState({ progressPercent: percent });
  }

  componentDidMount() {
    const audio = document.getElementById('audio');
    audio.onloadedmetadata = () => {
      this.setState({
        trackLength: Math.floor(audio.duration),
        remainingTime: Math.floor(audio.duration),
      });
    };
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalTimerId);
  }

  render() {
    const {
      playStatus,
      currentTime,
      remainingTime,
      progressPercent,
      skipTime,
    } = this.state;
    const {
      title,
      speaker,
      trackUrl,
      playerBackground,
      theme,
    } = this.props.trackInfo;

    return (
      <Container src={playerBackground}>
        <TrackInformation title={title} speaker={speaker} theme={theme} />
        <Scrubber
          progressPercent={progressPercent}
          currentTime={currentTime}
          remainingTime={remainingTime}
        />
        <ControlsContainer>
          <VerticalControlContainer>
            <RewindButton onClick={this.handleRewind} />
            <TimeText>-{skipTime}s</TimeText>
          </VerticalControlContainer>
          <VerticalControlContainer
            style={{
              justifyContent: 'flex-start',
              margin: '0',
              marginBottom: '5%',
            }}
          >
            {playStatus === 'play' ? (
              <PlayButton
                onClick={this.handleTogglePlay}
                className="play-audio"
              />
            ) : (
              <PauseButton onClick={this.handleTogglePlay} />
            )}
          </VerticalControlContainer>
          <VerticalControlContainer>
            <FastForwardButton onClick={this.handleFastForward} />
            <TimeText>+{skipTime}s</TimeText>
          </VerticalControlContainer>
        </ControlsContainer>

        <audio id="audio">
          {
            /* Playing audio doesn't seem to work if trackUrl is undefined when
            this first renders. So for instance when we're pulling the audio
            from the network, we shouldn't render this until we get the url. */
            trackUrl ? <source src={trackUrl} /> : undefined
          }
        </audio>
      </Container>
    );
  }
}
