import { getShineAtWorkContent } from '../api/contentfulClient';
import {
  GET_ENTERPRISE_CONTENT_FAIL,
  GET_ENTERPRISE_CONTENT_SUCCESS,
  GET_ENTERPRISE_CONTENT_REQUEST,
  SAVE_MEMBER_ID,
} from '../constants/actionTypes';

export const fetchEnterpriseContent = (id) => async (dispatch) => {
  let enterpriseContent;
  if (!id) {
    dispatch({ type: GET_ENTERPRISE_CONTENT_FAIL });
  }

  try {
    dispatch({ type: GET_ENTERPRISE_CONTENT_REQUEST });
    enterpriseContent = await getShineAtWorkContent(id);
  } catch (error) {
    console.error(error);
    dispatch({ type: GET_ENTERPRISE_CONTENT_FAIL });
  }

  if (enterpriseContent) {
    dispatch({
      type: GET_ENTERPRISE_CONTENT_SUCCESS,
      payload: { enterpriseContent },
    });
  } else {
    dispatch({ type: GET_ENTERPRISE_CONTENT_FAIL });
  }
};

export const saveMemberId = (memberId) => {
  return {
    type: SAVE_MEMBER_ID,
    payload: { memberId: memberId },
  };
};
