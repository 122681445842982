import styled from 'styled-components';
// Constants
import SIZES from '../../constants/screenSizes';
import COLORS from '../../constants/colors';
//Images
import { ReactComponent as BlobOutlineSvg } from '../../assets/images/quiz/blob403.svg';
import bgSvg from '../../assets/images/quiz/blob651.svg';
import playBtn from '../../assets/images/quiz/brown-play-button.png';
// Components
import { Logo } from './QuizStyles';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.cobaltBlue};
  background-image: url(${bgSvg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  width: 100%;
`;

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
  @media (min-width: ${SIZES.desktop}) {
  }
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
`;

export const ResultHeroImageRowContainer = styled(RowContainer)`
  margin-top: -40px;

  @media only screen and (min-width: ${SIZES.desktop}) {
    margin-top: -65px;
  }
`;

export const HeroImageContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${COLORS.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  align-self: flex-start;

  @media only screen and (min-width: ${SIZES.desktop}) {
    margin-left: 0px;
    width: 140px;
    height: 140px;
  }
`;

export const HeroImage = styled.img`
  width: 80px;
  height: 80px;
  height: auto;
  overflow: visible;
  @media only screen and (min-width: ${SIZES.desktop}) {
    width: 120px;
    height: 120px;
  }
`;

export const Line = styled.hr`
  border: 1px solid #c7c6c4;
  width: 95%;
`;

export const Title = styled.p`
  width: auto;
  font-family: Work Sans;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: ${COLORS.blackTwo};
  text-align: center;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: ${COLORS.darkMagentaPink};
`;
export const BulletList = styled.ul`
  width: 95%;
  padding-left: 5%;
  margin: 0;
`;
export const Bullet = styled.li`
  width: 100%;
  font-family: Work Sans;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.4px;
  text-align: left;
  color: ${COLORS.blackTwo};
  align-self: center;
  margin: 0 0 3% 0;
  @media only screen and (min-width: ${SIZES.desktop}) {
    margin: 0 0 2% 0;
  }
`;
export const Button = styled.button`
  width: 100%;
  height: 45px;
  border: none;
  box-shadow: ${COLORS.grey} 4px 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  background-color: ${COLORS.blackTwo};
`;
export const RecommendedContent = styled.img`
  width: 100%;
  height: auto;
`;

export const QuoteAuthorImg = styled.img`
  width: 100px;
  height: auto;
  border-radius: 50%;
  margin-right: 2%;
`;

export const BlobOutline = styled(BlobOutlineSvg)`
  width: 100%;
  height: auto;
  position: absolute;
  @media print {
    display: none;
  }
`;
export const MeditationLink = styled.a`
  text-decoration: none;
`;

export const MeditationLeftContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: 16px;
`;

export const MeditationRightContainer = styled.div`
  width: 75%;
  margin-left: 20px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media (max-width: ${SIZES.desktop}) {
    padding-right: 0;
  }
`;

export const MeditationCardTitle = styled.h3`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: ${COLORS.white};
  margin-bottom: 0px;
`;

export const MeditationCardDescription = styled.p`
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${COLORS.white};
  padding-right: 4px;
  margin-top: 5px;
`;

export const PlayTrackButton = styled.img.attrs({ src: playBtn })`
  width: 59.83px;
  height: 59.83px;
`;

export const ResultsLogo = styled(Logo)`
  height: 180px;
  align-self: center;
  margin-top: 34px;
  @media print {
    display: none;
  }
`;

export const MainContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
  margin-top: 52px;
  @media (min-width: ${SIZES.desktop}) {
    flex-direction: row;
  }
`;

export const FirstContainer = styled.div`
  align-content: center;
  align-self: center;
  box-shadow: 0 4px 12px 0 ${COLORS.greyShadow};
  background-color: ${COLORS.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 18px;
  padding-bottom: 18px;
  width: 298px;
  @media (min-width: ${SIZES.desktop}) {
    width: 790px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 54px;
    margin-bottom: 60px;
    margin-left: 24px;
  }
  @media print {
    width: 160mm;
    margin: 0;
  }
`;

export const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 44px;
  max-width: 332px;
  @media only screen and (min-width: ${SIZES.desktop}) {
    max-width: 346px;
    margin-left: 44px;
    margin-right: 24px;
    align-self: flex-start;
  }
  @media print {
    display: none;
  }
`;

export const Header = styled.h2`
  color: ${COLORS.fauxPineapple};
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.02em;
  margin-bottom: 4px;
`;

export const MeditationHeader = styled(Header)`
  color: ${COLORS.white};
`;

export const MeditationCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #22407b;
  border-radius: 6.07018px;
  height: 117px;
  margin-top: 24px;
  padding-left: 4px;
  padding-right: 4px;
`;

export const VotePlanCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 160px;
  background-color: ${COLORS.cobaltBlue};
  border-radius: 6.07018px;
  border: 0.867168px solid #22407b;
  width: 332px;
  margin-top: 24px;
  @media only screen and (min-width: ${SIZES.desktop}) {
    max-width: 346px;
  }
`;

export const VotePlanText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: ${COLORS.white};
  margin-bottom: 4px;
  padding-right: 4px;
  padding-left: 4px;
  p {
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: ${COLORS.white};
    a {
      text-decoration: none;
      color: ${COLORS.lightRed};
    }
  }
`;

export const VotePlanIcon = styled.img`
  width: 129px;
  object-fit: contain;
  margin-left: 8px;
  margin-left: 8px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MantraTitle = styled.h1`
  color: ${COLORS.lightBlue};
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: 0.02em;
  margin-bottom: 4px;
  margin-top: 4px;
  @media only screen and (min-width: ${SIZES.desktop}) {
    font-size: 40px;
  }
`;

export const ResultDescription = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.02em;
  margin-top: 4px;
  color: ${COLORS.blackTwo};
  @media only screen and (min-width: ${SIZES.desktop}) {
    font-size: 20px;
  }
`;

export const ShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  align-self: center;
  align-items: center;
  justify-content: center;
  @media only screen and (min-width: ${SIZES.desktop}) {
    flex-direction: row;
    margin-bottom: 44px;
  }
  @media print {
    display: none;
  }
`;

export const TwitterButton = styled.div`
  display: flex;
  border-radius: 100px;
  width: 202px;
  height: 30px;
  padding: 4px;
  background-color: ${COLORS.shineYellow};
  justify-content: center;
  align-items: center;
`;

export const ShareIcon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 8px;
`;

export const TweetText = styled.p`
  color: ${COLORS.white};
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`;

export const ListHeader = styled.h3`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 110%;
  letter-spacing: 0.02em;
  color: #081839;
  text-align: center;
  margin-top: 22px;
`;

export const ListHeaderImage = styled.img`
  width: 100px;
  height: auto;
  align-self: center;
`;

export const ListItem = styled.div``;

export const ListItemDescription = styled.p`
  margin-top: 0px;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  letter-spacing: 0.02em;
  b {
    margin-top: 0px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    letter-spacing: 0.02em;
  }
`;

export const DownloadButton = styled(TwitterButton)`
  background-color: ${COLORS.white};
  border-radius: 0px;
  cursor: pointer;
`;

export const DownloadText = styled(TweetText)`
  color: ${COLORS.blackTwo};
`;
