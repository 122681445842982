import React from 'react';
import styled from 'styled-components';

// Constants
import COLORS from '../../constants/colors';
import SIZES from '../../constants/screenSizes';
import { CHECKOUT_STEPS } from '../../constants/sharedCopy';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0;
`;
const StepText = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
  color: ${COLORS.darkGrey};
  height: 19px;
  max-width: 150px;
  margin: 0;

  @media only screen and (min-width: ${SIZES.tablet}) {
    font-size: 16px;
  }
`;

const Title = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;

  text-align: center;
  letter-spacing: 0.02em;
  color: ${COLORS.darkMagentaPink};
  height: 31px;
  margin: 12px 0 48px 0;

  @media only screen and (min-width: ${SIZES.tablet}) {
    margin: 16px 0 32px 0;
    font-size: 28px;
    line-height: 110%;
  }
`;

const generateStepText = (bogoPromoType, currentStep) => {
  const totalSteps = bogoPromoType === 'default' ? '4' : '3';

  if (currentStep) {
    if (currentStep === 'final') {
      return `Step ${totalSteps} of ${totalSteps}`;
    } else {
      return `Step ${currentStep} of ${totalSteps}`;
    }
  }
  return undefined;
};

const Stepper = ({ routeName, firstName, isB2b, bogoPromoType }) => {
  const pathName = routeName && routeName.split('/').join('');
  const step =
    (pathName && CHECKOUT_STEPS[pathName].step) ||
    (pathName &&
      CHECKOUT_STEPS[pathName].createStep &&
      CHECKOUT_STEPS[pathName].createStep(bogoPromoType));
  let title =
    CHECKOUT_STEPS[pathName].title ||
    CHECKOUT_STEPS[pathName].createTitle(firstName, isB2b);
  const stepText = generateStepText(bogoPromoType, step);

  return (
    <Container>
      {stepText ? <StepText>{stepText}</StepText> : null}
      <Title dangerouslySetInnerHTML={{ __html: title }} />
    </Container>
  );
};

export default Stepper;
