// Retention Survey Pages
export enum SurveyConfig {
  START,
  REASONS,
  REASON_EXPENSIVE,
  REASON_NOT_USING,
  REASON_NOT_ENOUGH_CONTENT,
  CANCELLED_GIVE_FEEDBACK,
  REASON_OTHER_GIVE_FEEDBACK,
  STAY_GIVE_FEEDBACK,
  FEEDBACK_SUBMITTED,
}

// Values when submitting feedback in retention survey
export interface SubmitFeedback {
  question: string;
  answer: string;
  nextPage: SurveyConfig;
  cancelSub: boolean;
}
