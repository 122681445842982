import React from 'react';
import { SmallText, SmallLinkText } from './FormStyles';

const TermsAndPolicy = () => {
  return (
    <div style={{ margin: '15px 0 ', textAlign: 'center' }}>
      <SmallText>
        By continuing, you agree to our{' '}
        <SmallLinkText href="/terms-of-service?utm_source=Shine&utm_medium=Glow_Web">
          Terms of Use
        </SmallLinkText>{' '}
        and{' '}
        <SmallLinkText href="/privacy-policy?utm_source=Shine&utm_medium=Glow_Web">
          Privacy Policy
        </SmallLinkText>
        .
      </SmallText>
    </div>
  );
};

export default TermsAndPolicy;
